import styled from 'styled-components';

export const ContainerModal = styled.div`
  background: ${props => props.theme.colors.backgroundWhite};
  border-radius: 16px;
  position: relative;
  height: calc(100vh - 200px);
`;

export const ContainerHeaderDados = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${props => props.theme.colors.textBlack};
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin: 30px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1152px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  strong {
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 20px;
    padding: 13px 34px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.textBlack};
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  column-gap: 10px;
  background-color: ${props => props.theme.colors.whitePrimary};
  border-radius: 16px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  .user {
    display: flex;
    width: 300px;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    img {
      height: 100px;
      width: 100px;
      border-radius: 50px;
      object-fit: cover;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: ${props => props.theme.colors.textBlack};
      max-height: 200px;
      text-align: center;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      p {
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        color: ${props => props.theme.colors.bluePrimary};
        font-weight: 600;
      }
    }
    p {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      color: ${props => props.theme.colors.textBlack};
      font-weight: 600;
    }
    span {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      color: ${props => props.theme.colors.textBlack};
    }
  }
`;

export const ListCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ModalHistoricoProcedimento } from '../../../components/ModalHistoricoProcedimento';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import Pagination from '../../../components/Pagination';
import IconFilter from '../../../assets/IconFilter.svg';
import DropDownPagination from '../../../components/DropDownPagination';
import HeaderTable from '../../../components/HeaderTable';

import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
  DivCheck,
} from './styles';
import { Empresa } from '../../../models/Empresa';
import { ContainerSelect } from '../DashboardColaboradores/styles';
import { HistoricCompany } from '../../../models/HistoricAgend';
import { UserGet } from '../../../models/User';

interface ResponseGet {
  // ANDAR_ENV_PROCEDURES_NAO_REALIZADO: string;
  AVATAR_TASK_AMBIENTE_NAO_REALIZADO: string;
  // CATEGORIA_ENV_PROCEDURES_NAO_REALIZADO: number;
  // COMPANYID_TASK_AMBIENTE_NAO_REALIZADO: number;
  CPF_TASK_AMBIENTE_NAO_REALIZADO: string;
  // CREATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  // CompanyId_Ambiente: number;
  // DATA_NASCIMENTO_TASK_AMBIENTE_NAO_REALIZADO: string;
  // DIAS_LIMPEZA_ENV_PROCEDURES_NAO_REALIZADO: number;
  // DataCriacaoAmbiente: string;
  DataHoraCreatedAt_task_nao_realizada_duplicada: string;
  // DataHora_RevisaoFinalizada: string;
  EMAIL_TASK_AMBIENTE_NAO_REALIZADO: string;
  // ID_AMBIENTE_HISTORICO: number;
  ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  // ID_ENV_PROCEDURES_NAO_REALIZADO: number;
  // ID_PROCEDIMENTO_PREDEFINIDO: number;
  ID_TASK_PROCEDIMENTO_PREDEFINIDO: number;
  // ID_TASK_REALIZADA: number;
  // Id_Dom_StatusRevisao: number;
  NAME_ENV_PROCEDURES_NAO_REALIZADO: string;
  NAME_PROCEDIMENTO_NAO_REALIZADO: string;
  NOME_USER_TASK_AMBIENTE_NAO_REALIZADO: string;
  // NomeCategoria: string;
  // NomeProcedimentos: string;
  // NomeSetor: string;
  // Nome_Ambiente: string;
  // QTD_DIAS_LIMPEZAE_ENV_PROCEDURES_NAO_REALIZADO: number;
  // REVISAO_ENV_PROCEDURES_NAO_REALIZADO: boolean;
  // REVISAO_HORA_FINAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  // REVISAO_HORA_INICIAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  // Revisao: boolean;
  // RevisaoHoraFinal: string;
  // RevisaoHoraInicial: string;
  // SETORID_ENV_PROCEDURES_NAO_REALIZADO: number;
  TASK_ID_AMBIENTE_NAO_REALIZADO: number;
  TASK_NAO_REALIZADA: boolean;
  TELEFONE_TASK_AMBIENTE_NAO_REALIZADO: string;
  // UPDATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  USER_ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  // UserId_Historico: number;
  // andar: string;
  // avatar: string;
  // categoriaId: number;
  companyId: number;
  // cpf: string;
  // createdAt: string;
  createdAt_TASK_NAO_REALIZADO: string;
  // dataNascimento: string;
  // diasLimpezaId: number;
  // email: string;
  // image: string;
  // nome: string;
  observacao: string;
  // setorId: number;
  // statusRevisaoNome: string;
  // telefone: string;
  // updatedAt: string;
  updatedAt_TASK_NAO_REALIZADO: string;
  escondido: boolean;
}

interface DateHistAgend {
  created: string;
  idAmbiente: number;
  idUser: number;
  companyId: number;
  procedures: {
    id: number;
    name: string;
  }[];
  hist: HistoricCompany[];
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  {
    name: 'Nome do procedimento',
    field: 'NAME_PROCEDIMENTO_NAO_REALIZADO',
    sortable: false,
  },
];

export const DashboardProcedimentosV2: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filialId, setFilialId] = useState(-1);
  const [filiais, setFiliais] = useState<Empresa[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [asgs, setAsgs] = useState<UserGet[]>([]);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(true);
  const [dadosModal, setDadosModal] = useState<ResponseGet[]>();
  const [dadosTaskIdModal, setDadosTaskIdModal] = useState<number>();
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [isShowDadosOld, setIsShowDadosOld] = useState(false);

  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: false,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'Ambiente',
    },
    {
      status: true,
      name: 'Tarefa',
    },
    {
      status: false,
      name: 'Intervalo de datas',
    },
  ]);

  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const getItensOld = useCallback(
    async (
      empresaId: string,
      dateStart: string,
      dateEnd: string,
    ): Promise<ResponseGet[]> => {
      return api
        .get(
          `/observacao?dataInicio=${dateStart}&dataFim=${dateEnd}&id=${empresaId}`,
        )
        .then(data => {
          const respAll: ResponseGet[] = data.data.objetoFodaResponse;
          if (isCoordenador) {
            return respAll.filter(i => i.companyId === user.companyId);
          }
          return respAll;
        })
        .catch(err => {
          // console.log(err.message);
          return [];
        });
    },
    [isCoordenador, user.companyId],
  );

  const getItens = useCallback(
    async (dateStart: string, dateEnd: string) => {
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const filId = user.companyId;

      const aux = isCoordenador
        ? `filialId=${filId}`
        : `companyId=${companyId}`;

      api
        .get(
          `/historic/v2/company?${aux}&dataIni=${dateStart}&dataFim=${dateEnd}`,
        )
        .then(async resp => {
          const histTask: HistoricCompany[] = resp.data;
          const histTaskWithObervacao = histTask
          // .filter(
          //   i => !!i.observacao && i.observacao !== '-',
          // );
          console.log('HIST', histTask);

          const dateHist: DateHistAgend[] = [];

          histTaskWithObervacao.forEach(item => {
            const findIndex = dateHist.findIndex(
              i =>
                item.createdAt.slice(0, 19).split('T').join(' ') ===
                  i.created &&
                i.idAmbiente === item.Agendamento.Agenda.Id_Environments &&
                i.idUser === item.userId,
            );
            if (findIndex !== -1) {
              dateHist[findIndex].hist.push(item);
            } else {
              dateHist.push({
                created: item.createdAt.slice(0, 19).split('T').join(' '),
                companyId: item.Agendamento.companyId,
                hist: [item],
                idAmbiente: item.Agendamento.Agenda.Id_Environments,
                idUser: item.userId,
                procedures: [
                  { id: item.Procedure.id, name: item.Procedure.name },
                ],
              });
            }
          });

          const histProc: ResponseGet[] = [];
          dateHist.forEach(item => {
            const notHistProc = item.procedures.filter(proc =>
              item.hist.find(hist => hist.tasksId === proc.id),
            );
            notHistProc.forEach(proc => {
              const newObj: ResponseGet = {
                AVATAR_TASK_AMBIENTE_NAO_REALIZADO: '',
                CPF_TASK_AMBIENTE_NAO_REALIZADO: '',
                createdAt_TASK_NAO_REALIZADO: item.created
                  .slice(0, 19)
                  .replace('T', ' '),
                DataHoraCreatedAt_task_nao_realizada_duplicada: item.created
                  .slice(0, 19)
                  .replace('T', ' '),
                EMAIL_TASK_AMBIENTE_NAO_REALIZADO: '',
                escondido: false,
                ID_AMBIENTE_TASK_NAO_REALIZADO: item.idAmbiente,
                ID_TASK_PROCEDIMENTO_PREDEFINIDO: proc.id,
                NAME_ENV_PROCEDURES_NAO_REALIZADO: '',
                NAME_PROCEDIMENTO_NAO_REALIZADO: proc.name,
                NOME_USER_TASK_AMBIENTE_NAO_REALIZADO: '',
                observacao: item.hist[0].observacao,
                TASK_ID_AMBIENTE_NAO_REALIZADO: proc.id,
                TASK_NAO_REALIZADA: true,
                TELEFONE_TASK_AMBIENTE_NAO_REALIZADO: '',
                USER_ID_AMBIENTE_TASK_NAO_REALIZADO: item.idUser,
                updatedAt_TASK_NAO_REALIZADO: item.hist[0].updatedAt
                  .slice(0, 19)
                  .replace('T', ' '),
                companyId: item.companyId,
              };
              histProc.push(newObj);
            });
          });

          if (isShowDadosOld) {
            const dadosOld = await getItensOld(
              String(companyId),
              dateStart,
              dateEnd,
            );

            dadosOld.forEach(item => {
              const find = histProc.find(
                i =>
                  i.createdAt_TASK_NAO_REALIZADO ===
                    item.createdAt_TASK_NAO_REALIZADO
                      .slice(0, 19)
                      .replace('T', ' ') &&
                  i.ID_AMBIENTE_TASK_NAO_REALIZADO ===
                    item.ID_AMBIENTE_TASK_NAO_REALIZADO &&
                  i.CPF_TASK_AMBIENTE_NAO_REALIZADO ===
                    item.CPF_TASK_AMBIENTE_NAO_REALIZADO,
              );
              if (!find) {
                histProc.push(item);
              }
            });
          }

          setDadosHistoricoDatasModal(
            `De ${handleMaskDate(dateStart)} até ${handleMaskDate(dateEnd)}`,
          );
console.log('HISTPROC', histProc)
          setResponse(histProc);
          setLoading(false);
        })
        .catch(_ => {
          // console.log(err);
          // console.log(err.message);
          // console.log(err.response.data);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      addToast,
      empresaPrincipal,
      getItensOld,
      handleMaskDate,
      isCoordenador,
      isShowDadosOld,
      user.companyId,
    ],
  );

  const loadingItens = useCallback(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    const mesDataInicial = moment(searchDataInicial).format('YYYY-MM-DD');
    const mesDataFinal = moment(searchDataFinal).format('YYYY-MM-DD');
    const dataInicio = moment().format('YYYY-MM-01');
    const dataFim = moment().format('YYYY-MM-DD');

    const dateStart =
      searchDataInicial.length === 10 && searchDataInicial.substr(0, 1) !== '0'
        ? mesDataInicial
        : dataInicio;
    const dateEnd =
      searchDataFinal.length === 10 && searchDataFinal.substr(0, 1) !== '0'
        ? mesDataFinal
        : dataFim;
    if (dateStart <= mesAtual && dateEnd <= mesAtual) {
      getItens(dateStart, dateEnd);
    } else {
      addToast({
        type: 'info',
        title: 'Filtro inválido',
        description: 'Intervalo de datas inválidos, favor verificar',
      });
    }
  }, [addToast, getItens, searchDataFinal, searchDataInicial]);

  const getEmpresas = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        setFiliais(resp.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [empresaPrincipal]);

  const getUsers = useCallback(async () => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`/allUsers/${companyId}`)
      .then(resp => {
        // setLoading(false);
        const users: UserGet[] = resp.data;
        if (isCoordenador) {
          setAsgs(users.filter(i => user.companyId === i.companyId));
        } else {
          setAsgs(users);
        }
      })
      .catch(err => {
        // setLoading(false);
        console.log(err.message);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal, isCoordenador, user.companyId]);

  useEffect(() => {
    getEmpresas();
    getUsers();
  }, [getEmpresas, getUsers]);

  useEffect(() => {
    loadingItens();
  }, [loadingItens]);

  useEffect(() => {
    setSearchDataInicial('');
    setSearchDataFinal('');
  }, [search]);

  useEffect(() => {
    setSearch('');
  }, [searchDataInicial, searchDataFinal]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    let computedResponsesAux: ResponseGet[] = [];
    const arrayResponseFilter: ResponseGet[] = [];
    const arrayResponseIdsTasks: number[] = [];
    console.log("TESTE",response)

    computedResponses = response.map(item => {
      const userFind = asgs.find(
        i => i.id === item.USER_ID_AMBIENTE_TASK_NAO_REALIZADO,
      );
      if (userFind) {
        return {
          ...item,
          CPF_TASK_AMBIENTE_NAO_REALIZADO: userFind.cpf,
          EMAIL_TASK_AMBIENTE_NAO_REALIZADO: userFind.email,
          NOME_USER_TASK_AMBIENTE_NAO_REALIZADO: userFind.nome,
          TELEFONE_TASK_AMBIENTE_NAO_REALIZADO: userFind.telefone,
          AVATAR_TASK_AMBIENTE_NAO_REALIZADO: userFind.avatar,
        };
      }
      return item;
    });


    if (filialId !== -1 && !isCoordenador) {
      computedResponses = computedResponses.filter(
        i => i.companyId === filialId,
      );
    }

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case 'Colaborador':
                dataFilter.push(res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO);
                break;
              case 'Ambiente':
                dataFilter.push(res.NAME_ENV_PROCEDURES_NAO_REALIZADO);
                break;
              case 'Tarefa':
                dataFilter.push(res.NAME_PROCEDIMENTO_NAO_REALIZADO);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    computedResponses.forEach((element, index) => {
      if (
        element.TASK_NAO_REALIZADA === true &&
        element.createdAt_TASK_NAO_REALIZADO !== null &&
        element.updatedAt_TASK_NAO_REALIZADO !== null &&
        arrayResponseIdsTasks.indexOf(
          element.TASK_ID_AMBIENTE_NAO_REALIZADO,
        ) === -1
      ) {
        arrayResponseIdsTasks.push(element.TASK_ID_AMBIENTE_NAO_REALIZADO);
        arrayResponseFilter.push(element);
      }

      if (index === computedResponses.length - 1) {
        computedResponsesAux = arrayResponseFilter;
      }
    });

    setTotalItems(computedResponsesAux.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponsesAux;
    }

    return computedResponsesAux.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    asgs,
    currentPage,
    dataFilterModal,
    filialId,
    isCoordenador,
    response,
    search,
  ]);

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashTarefasIncompletas"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal && !!dadosTaskIdModal && !!dadosHistoricoDatasModal && (
        <ModalHistoricoProcedimento
          isOpen={!modalDetalhesAmbiente}
          dados={dadosModal}
          dadosIdTask={dadosTaskIdModal}
          dadosHistoricoDatas={dadosHistoricoDatasModal}
          onRequestClose={() => setModalDetalhesAmbiente(true)}
        />
      )}

      <Container>
        <ContainerTitle>
          <h1>Tarefas Incompletas</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização detalhada do histórico de atividades não realizadas pelos ASGs, tarefas incompletas com suas justificativas. Filtragem a partir dos procedimentos/tarefas."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        {!isCoordenador && (
          <ContainerSelect>
            <select
              value={filialId}
              onChange={event => {
                const val = parseInt(event.target.value, 10);
                setFilialId(val);
              }}
            >
              <option value={-1}>Todos</option>
              {filiais.map(i => (
                <option key={i.ID} value={i.ID}>
                  {i.NOME}
                </option>
              ))}
            </select>
          </ContainerSelect>
        )}

        <ContainerSearch isFilterDateInterval={dataFilterModal[3].status}>
          {!dataFilterModal[3].status ? (
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />
          ) : (
            <>
              <Search
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                nomePlaceHolder="Buscar data inicial"
                type="date"
              />

              <Search
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                nomePlaceHolder="Buscar data final"
                type="date"
              />
            </>
          )}

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        <DivCheck>
          <input
            type="checkbox"
            checked={isShowDadosOld}
            onChange={() => setIsShowDadosOld(!isShowDadosOld)}
          />
          <p>Mostrar dados anteriores à Agosto/2022</p>
          <Tooltip
            title="Marque somente caso queira obter dados da versão anterior do sistema referente aos dados inseridos antes de agosto de 2022."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </DivCheck>

        <ContainerListItens>
          <>
            {responseData.map(res => (
              <ContainerItem
                key={`${res.TASK_ID_AMBIENTE_NAO_REALIZADO}-${res.ID_AMBIENTE_TASK_NAO_REALIZADO}-${res.createdAt_TASK_NAO_REALIZADO}-${res.updatedAt_TASK_NAO_REALIZADO}`}
                type="button"
                onClick={() => {
                  setDadosModal(responseData);
                  setDadosTaskIdModal(res.ID_TASK_PROCEDIMENTO_PREDEFINIDO);
                  setModalDetalhesAmbiente(false);
                }}
              >
                <strong>{res.NAME_PROCEDIMENTO_NAO_REALIZADO}</strong>
              </ContainerItem>
            ))}

            <table id="tableRelatorioProcedimentos" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {responseData.map(res => (
                  <tr
                    key={`${res.TASK_ID_AMBIENTE_NAO_REALIZADO}-${res.ID_AMBIENTE_TASK_NAO_REALIZADO}-${res.createdAt_TASK_NAO_REALIZADO}-${res.updatedAt_TASK_NAO_REALIZADO}`}
                  >
                    <td>{res.NAME_PROCEDIMENTO_NAO_REALIZADO}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {responseData.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'Todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioProcedimentos"
                filename={`relatorio-procedimentos-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText="Exportar Excel"
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};

import React, { useCallback, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ContainerTh } from './styles';

interface Header {
  field: string;
  name: string;
  sortable?: boolean;
  onClick?: () => void;
}

interface HeaderTable {
  headers: Header[];
  // eslint-disable-next-line react/require-default-props
  onSorting?: (field: string, order: string) => void;
}

const HeaderTable = ({
  headers,
  onSorting = (field: string, order: string) => {
    return [field, order];
  },
}: HeaderTable): JSX.Element => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = useCallback(
    (field: string) => {
      const order =
        field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

      setSortingField(field);
      setSortingOrder(order);
      onSorting(field, order);
    },
    [onSorting, sortingField, sortingOrder],
  );

  return (
    <thead>
      <tr>
        {headers.map(
          ({ name, field, sortable, onClick = () => console.log('click') }) => (
            <ContainerTh
              key={field}
              onClick={() => (sortable ? onSortingChange(field) : onClick())}
            >
              {name}

              {sortingField && sortingField === field && (
                <button type="button">
                  {sortingOrder === 'asc' ? (
                    <AiOutlineArrowDown size={20} />
                  ) : (
                    <AiOutlineArrowUp size={20} />
                  )}
                </button>
              )}
            </ContainerTh>
          ),
        )}
      </tr>
    </thead>
  );
};

export default HeaderTable;

import React, { useEffect, useState } from 'react';
import centro from '../../assets/logaCentro.png';
import leftDown from '../../assets/logaLeftDown.png';
import RightTop from '../../assets/logaRightUp.png';
import LeftTop from '../../assets/logaLeftUp.png';
import RightDown from '../../assets/logaRightDown.png';
import ProgressBar from '../ProgressBar';
import {
  Container,
  Content,
  ContentItens,
  ContentItemCentro,
  ContentItemLeftDown,
  ContentItemRightTop,
  ContentItemLeftTop,
  ContentItemRightDown,
  ContentItemCentro1,
  ContentItemLeftDown1,
  ContentItemRightTop1,
  ContentItemLeftTop1,
  ContentItemRightDown1,
  ContentItemCentro2,
  ContentItemLeftDown2,
  ContentItemRightTop2,
  ContentItemLeftTop2,
  ContentItemRightDown2,
  ContentItemCentro3,
  ContentItemLeftDown3,
  ContentItemRightTop3,
  ContentItemLeftTop3,
  ContentItemRightDown3,
  Text,
} from './styles';

interface ProgressBarInterface {
  porcentagem?: number;
  component?: boolean;
}

export const Loading: React.FC<ProgressBarInterface> = ({
  porcentagem,
  component,
}) => {
  const [visible, setVisible] = useState(true);
  const [visibleUm, setVisibleUm] = useState(true);
  const [visibleDois, setVisibleDois] = useState(true);
  const [visibleTres] = useState(true);

  useEffect(() => {
    const vis = setTimeout((): void => {
      setVisible(false);
    }, 2000);
    const vis1 = setTimeout((): void => {
      setVisibleUm(false);
    }, 4100);
    const vis2 = setTimeout((): void => {
      setVisibleDois(false);
    }, 6200);
    return () => {
      clearTimeout(vis);
      clearTimeout(vis1);
      clearTimeout(vis2);
    };
  }, []);

  return (
    <Container>
      <Content component={component} />
      {visible && (
        <ContentItens component={component}>
          <ContentItemCentro component={component}>
            <img src={centro} alt="Logo Servnac centro" />
          </ContentItemCentro>

          <ContentItemLeftDown component={component}>
            <img src={leftDown} alt="Logo Servnac leftDown" />
          </ContentItemLeftDown>

          <ContentItemRightTop component={component}>
            <img src={RightTop} alt="Logo Servnac LeftUp" />
          </ContentItemRightTop>

          <ContentItemLeftTop component={component}>
            <img src={LeftTop} alt="Logo Servnac RightDown" />
          </ContentItemLeftTop>

          <ContentItemRightDown component={component}>
            <img src={RightDown} alt="Logo Servnac RightUp" />
          </ContentItemRightDown>
          {/* <ContentItemRightDown>
            <Text>
              <ProgressBar percenta={porcentagem} />{' '}
            </Text>
          </ContentItemRightDown> */}
        </ContentItens>
      )}

      {visibleUm && (
        <ContentItens component={component}>
          <ContentItemCentro1 component={component}>
            <img src={centro} alt="Logo Servnac centro" />
          </ContentItemCentro1>

          <ContentItemLeftDown1 component={component}>
            <img src={leftDown} alt="Logo Servnac leftDown" />
          </ContentItemLeftDown1>

          <ContentItemRightTop1 component={component}>
            <img src={RightTop} alt="Logo Servnac LeftUp" />
          </ContentItemRightTop1>

          <ContentItemLeftTop1 component={component}>
            <img src={LeftTop} alt="Logo Servnac RightDown" />
          </ContentItemLeftTop1>

          <ContentItemRightDown1 component={component}>
            <img src={RightDown} alt="Logo Servnac RightUp" />
          </ContentItemRightDown1>
          {/* <ContentItemRightDown1>
            <Text>
              <ProgressBar percenta={porcentagem} />{' '}
            </Text>
          </ContentItemRightDown1> */}
        </ContentItens>
      )}

      {visibleDois && (
        <ContentItens component={component}>
          <ContentItemCentro2 component={component}>
            <img src={centro} alt="Logo Servnac centro" />
          </ContentItemCentro2>

          <ContentItemLeftDown2 component={component}>
            <img src={leftDown} alt="Logo Servnac leftDown" />
          </ContentItemLeftDown2>

          <ContentItemRightTop2 component={component}>
            <img src={RightTop} alt="Logo Servnac LeftUp" />
          </ContentItemRightTop2>

          <ContentItemLeftTop2 component={component}>
            <img src={LeftTop} alt="Logo Servnac RightDown" />
          </ContentItemLeftTop2>

          <ContentItemRightDown2 component={component}>
            <img src={RightDown} alt="Logo Servnac RightUp" />
          </ContentItemRightDown2>
          {/* <ContentItemRightDown2>
            <Text>
              <ProgressBar percenta={porcentagem} />{' '}
            </Text>
          </ContentItemRightDown2> */}
        </ContentItens>
      )}

      {visibleTres && (
        <ContentItens component={component}>
          <ContentItemCentro3 component={component}>
            <img src={centro} alt="Logo Servnac centro" />
          </ContentItemCentro3>

          <ContentItemLeftDown3 component={component}>
            <img src={leftDown} alt="Logo Servnac leftDown" />
          </ContentItemLeftDown3>

          <ContentItemRightTop3 component={component}>
            <img src={RightTop} alt="Logo Servnac LeftUp" />
          </ContentItemRightTop3>

          <ContentItemLeftTop3 component={component}>
            <img src={LeftTop} alt="Logo Servnac RightDown" />
          </ContentItemLeftTop3>

          <ContentItemRightDown3 component={component}>
            <img src={RightDown} alt="Logo Servnac RightUp" />
          </ContentItemRightDown3>
        </ContentItens>
      )}
      <ContentItens component={component}>
        <Text component={component}>
          {porcentagem ? (
            <ProgressBar percentage={porcentagem} />
          ) : (
            'Carregando...'
          )}
        </Text>
      </ContentItens>
    </Container>
  );
};

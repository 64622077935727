import moment from 'moment';
import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import Search from '../Search';

import imgAvatar from '../../assets/IconAvatar.svg';
import {
  Card,
  ContainerHeaderDados,
  ContainerModal,
  ContainerSearch,
  ListCard,
} from './styles';

export interface TarefasImp {
  nomeUser: string;
  avatarUser: string;
  tarefas: {
    id: number;
    name: string;
    isCompleted: boolean;
  }[];
  date: string;
  motivo: string;
}

interface Props {
  isVisible: boolean;
  onClose(): void;
  fraseDatas: string;
  nomeAmbiente: string;
  listaTarefas: TarefasImp[];
}

export const ModalTarefasIncompletas: React.FC<Props> = ({
  isVisible,
  onClose,
  fraseDatas,
  nomeAmbiente,
  listaTarefas,
}) => {
  const [search, setSearch] = useState('');

  const list = useMemo(() => {
    let computedResponses = listaTarefas;
    if (search) {
      computedResponses = computedResponses.filter(res =>
        [
          res.nomeUser,
          moment(res.date.slice(0, 10)).format('DD/MM/YYYY'),
          res.motivo,
        ].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }
    return computedResponses;
  }, [listaTarefas, search]);

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <ContainerModal>
        <ContainerHeaderDados>
          Tarefas Incompletas de {nomeAmbiente}
        </ContainerHeaderDados>

        <ContainerSearch>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />

          <strong>{fraseDatas}</strong>
        </ContainerSearch>
        <ListCard>
          {list.map(item => (
            <Card>
              <div className="user">
                <img
                  src={
                    item.avatarUser
                      ? `https://app-qrcode-avatar.s3.amazonaws.com/${item.avatarUser}`
                      : imgAvatar
                  }
                  alt={`Foto do ${item.nomeUser}`}
                />
                <p>{item.nomeUser}</p>
              </div>
              <div className="info">
                <div>
                  <p>Tarefas</p>
                  <span>
                    {moment(item.date.slice(0, 10)).format('DD/MM/YYYY')}
                  </span>
                </div>
                {item.tarefas.map(taf => {
                  if (taf.isCompleted) {
                    return <span key={taf.id}>{taf.name}</span>;
                  }
                  return <p key={taf.id}>{taf.name} - IMCOMPLETO</p>;
                })}
                <div>
                  <p>Motivo</p>
                </div>
                <p>{item.motivo}</p>
              </div>
            </Card>
          ))}
        </ListCard>
      </ContainerModal>
    </Modal>
  );
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { FiCheckCircle, FiEdit, FiXCircle } from 'react-icons/fi';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { EmpresaPincipal } from '../../../models/Empresa';
import { QuadroAuditoria } from '../../../models/QuadroAuditoria';
import Search from '../../../components/Search';
import Button from '../../../components/Button';
import { InputOverview } from '../../../components/InputOverview';
import HeaderTable from '../../../components/HeaderTable';
import Pagination from '../../../components/Pagination';
import { maskCnpj } from '../../../components/InputOverview/mask';
import DropDownPagination from '../../../components/DropDownPagination';
import { Loading } from '../../../components/Loading';
import { ModalEditEmpresaP } from '../../../components/ModalEditEmpresaP';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Aba,
  AnimationContainer,
  Container,
  ContainerAba,
  ContainerPagination,
  ContainerSemResultados,
  ContainerTable,
  DivTest,
  TableDiv,
  Title,
} from './styles';

interface SignUpFormData {
  bairro: string;
  cidade: string;
  cnpj: string;
  confirmPassword: string;
  cpf: string;
  email: string;
  endereco: string;
  nascimento: string;
  nome: string;
  nomeuser: string;
  numero: string;
  password: string;
  telefone: string;
}

interface PostEmpresa {
  nome: string;
  cnpj: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  quadroIds: number[];
  max_number_users: number;
  userAdmin: {
    nome: string;
    email: string;
    password: string;
    dataNascimento: string;
    cpf: string;
    telefone: string;
  };
}

export interface QuadrosSel extends QuadroAuditoria {
  select: boolean;
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'Status', field: 'inativar', sortable: false },
  { name: 'Editar Dados', field: 'editar', sortable: false },
];

const headersViewer = [
  { name: 'ID', field: 'ID', sortable: true },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'Status', field: 'inativar', sortable: false },
];

export const EmpresaPrincipal: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { colors } = useContext(ThemeContext);
  const { addToast } = useToast();
  const { go } = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [isCadastro, setIsCadastro] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [empresas, setEmpresas] = useState<EmpresaPincipal[]>([]);
  const [editEmpresa, setEditEmpresa] = useState<EmpresaPincipal>();
  const [quadrosAuditoria, setQuadrosAuditoria] = useState<QuadrosSel[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          nomeuser: Yup.string().required('Nome de usuário obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          cpf: Yup.string().required('CPF obrigatório'),
          cnpj: Yup.string().required('CNPJ obrigatório'),
          nascimento: Yup.string().required('Data de nascimento obrigatória'),
          telefone: Yup.string().required('Telefone obrigatório'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas precisam ser iguais',
          ),
        });
        setLoading(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        const obj: PostEmpresa = {
          bairro: data.bairro,
          cidade: data.cidade,
          cnpj: data.cnpj.replace(/\D+/g, ''),
          endereco: data.endereco,
          nome: data.nome,
          numero: data.numero,
          quadroIds: quadrosAuditoria.filter(i => i.select).map(i => i.ID),
          max_number_users: 50,
          userAdmin: {
            cpf: data.cpf.replace(/\D+/g, ''),
            dataNascimento: data.nascimento,
            email: data.email,
            nome: data.nomeuser,
            password: data.password,
            telefone: data.telefone.replace(/\D+/g, ''),
          },
        };

        api
          .post('/empresas', obj)
          .then(() => {
            setLoading(false);
            // console.log(resp.data);
            addToast({
              title: 'Sucesso!',
              description: `A empresa foi cadastrada com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          })
          .catch(err => {
            setLoading(false);
            console.log(err.message);
            addToast({
              type: 'error',
              title: 'Erro no Cadastro',
              description: err.message,
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            'Ocorreu um erro ao fazer o cadastro, Por favor verifique os campos.',
        });
      }
    },
    [addToast, go, quadrosAuditoria],
  );

  const getItems = useCallback(() => {
    setLoading(true);
    api
      .get('/empresas/listmatriz')
      .then(resp => {
        setEmpresas(resp.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getQuadros = useCallback(() => {
    api
      .get('/quadroPadrao')
      .then(resp => {
        const res: QuadroAuditoria[] = resp.data;
        setQuadrosAuditoria(res.map(i => ({ ...i, select: false })));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getItems();
    getQuadros();
  }, [getItems, getQuadros]);

  const isForm = useMemo(() => {
    return isCadastro;
  }, [isCadastro]);

  const listEmpresas = useMemo(() => {
    const list = empresas.filter(i =>
      i.NOME.toLocaleLowerCase().includes(search.toLowerCase()),
    );
    setTotalItems(list.length);
    return list.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, currentPage, empresas, search]);

  return (
    <Container>
      {empresaPrincipal.length > 0 ? (
        <Aba>
          <ContainerAba className="aba" cor={!isForm}>
            <button type="button" onClick={() => setIsCadastro(false)}>
              Listar empresas
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={isForm}>
            <button type="button" onClick={() => setIsCadastro(true)}>
              Cadastrar empresa
            </button>
          </ContainerAba>

          <Tooltip
            title="Nessa página você tem acesso a visualização, cadastro e edição de todas as empresas matriz que fazem parte do sistema"
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </Aba>
      ) : (
        <Aba>
          <ContainerAba className="aba" cor={!isForm}>
            <button type="button">Listar empresas</button>
          </ContainerAba>

          <Tooltip
            title="Nessa página você tem acesso a visualização de todas as empresas matriz"
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </Aba>
      )}
      {!isForm ? (
        <>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />
          {empresas.length === 0 ? (
            <ContainerSemResultados>
              <h2>Não existem empresas matriz cadastradas.</h2>
            </ContainerSemResultados>
          ) : (
            <>
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {listEmpresas.map(res => (
                      <tr key={`${res.ID}-${res.CNPJ}`}>
                        {/* <td>{res.ID ? res.ID : '-'}</td> */}
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>{res.ENDERECO ? res.ENDERECO : '-'}</td>
                        <td>{res.NUMERO ? res.NUMERO : '-'}</td>
                        <td>
                          {res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                        {empresaPrincipal.length > 0 && (
                          <td style={{ width: 'auto' }}>
                            <button
                              type="button"
                              onClick={() => {
                                setEditEmpresa(res);
                                setIsEdit(true);
                              }}
                            >
                              <FiEdit />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table id="tableListagemFiliais" style={{ display: 'none' }}>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {listEmpresas.map(res => (
                      <tr key={`${res.ID}-${res.CNPJ}`}>
                        <td>{res.ID ? res.ID : '-'}</td>
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>{res.ENDERECO ? res.ENDERECO : '-'}</td>
                        <td>{res.NUMERO ? res.NUMERO : '-'}</td>
                        <td>
                          {res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {listEmpresas.length > 0 && (
                  <>
                    <div className="divPaginacao">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page: number) => setCurrentPage(page)}
                      />

                      <DropDownPagination
                        onChangeItems={(value: string) => {
                          setCurrentPage(1);
                          if (value === 'Todos') {
                            setITEMS_PER_PAGE(1);
                          } else {
                            setITEMS_PER_PAGE(Number(value));
                          }
                        }}
                        objetoEnum={dropDown}
                        minWidth={30}
                      />
                    </div>
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemFiliais"
                      filename={`listagem-filiais-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                  </>
                )}
              </ContainerPagination>
            </>
          )}
        </>
      ) : (
        <AnimationContainer>
          <header>
            <h1>Dados da Empresa Matriz</h1>
          </header>

          <Form ref={formRef} onSubmit={handleSubmit}>
            {/* <p>ID</p>
            <InputOverview
              name="idEmpresa"
              value={cpfUserMaster[0].Id_Empresas}
              disabled
            /> */}

            <p>Nome</p>
            <InputOverview name="nome" placeholder="Greendot" />

            <p>CNPJ</p>
            <InputOverview
              name="cnpj"
              placeholder="000.000.000/00000"
              mask="cnpj"
            />

            <p>Endereço</p>
            <InputOverview name="endereco" placeholder="Avenida ..." />

            <p>Número</p>
            <InputOverview name="numero" type="number" placeholder="000" />

            <p>Bairro</p>
            <InputOverview name="bairro" placeholder="Aldeota" />

            <p>Cidade</p>
            <InputOverview name="cidade" placeholder="Fortaleza" />

            <p>Quadros de Auditoria</p>
            <TableDiv>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {quadrosAuditoria.map((item, i) => (
                  <tr key={`${i.toString()}`}>
                    <td>{item.label}</td>
                    <td className="action">
                      <button
                        type="button"
                        onClick={() => {
                          const aux: QuadrosSel[] = quadrosAuditoria.map(
                            (q, index) =>
                              index === i ? { ...q, select: false } : q,
                          );
                          setQuadrosAuditoria(aux);
                        }}
                      >
                        <FiXCircle
                          color={
                            item.select ? colors.grayBlack : colors.redPrimary
                          }
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const aux: QuadrosSel[] = quadrosAuditoria.map(
                            (q, index) =>
                              index === i ? { ...q, select: true } : q,
                          );
                          setQuadrosAuditoria(aux);
                        }}
                      >
                        <FiCheckCircle
                          color={
                            !item.select
                              ? colors.grayBlack
                              : colors.greenPrimary
                          }
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableDiv>

            <Title>
              <h1>Dados do usuário matriz</h1>
            </Title>

            <p>Nome</p>
            <InputOverview name="nomeuser" placeholder="Greendot" />

            <p>CPF</p>
            <InputOverview name="cpf" placeholder="000.000.000-00" mask="cpf" />

            <p>Email</p>
            <InputOverview name="email" placeholder="teste@..." mask="email" />

            <p>Data de Nascimento</p>
            <InputOverview
              name="nascimento"
              placeholder="01/01/2004"
              mask="dateOfBirth"
            />

            <p>Telefone</p>
            <InputOverview
              name="telefone"
              placeholder="(00) 00000-0000"
              mask="phone"
            />

            <p>Senha</p>
            <DivTest>
              <InputOverview name="password" placeholder="********" />
            </DivTest>

            <p style={{ marginTop: 18 }}>Confirmar senha</p>
            <DivTest>
              <InputOverview name="confirmPassword" placeholder="********" />
            </DivTest>

            <Button type="submit" widthProps="100%">
              Cadastrar
            </Button>
          </Form>
        </AnimationContainer>
      )}
      {editEmpresa && (
        <ModalEditEmpresaP
          isVisible={isEdit}
          onClose={() => setIsEdit(false)}
          editEmpresa={editEmpresa}
          quadros={quadrosAuditoria}
        />
      )}
      {loading && <Loading />}
    </Container>
  );
};

import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { DrawerProvider } from './drawer';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <DrawerProvider>
      <AuthProvider>{children}</AuthProvider>
    </DrawerProvider>
  </ToastProvider>
);

export default AppProvider;

import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  padding: 30px 40px 20px 40px;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: ${props => props.theme.colors.textBlack};
  }

  span {
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      color: ${props => props.theme.colors.bluePrimary};
    }
  }
`;

export const ContainerListItens = styled.div<{ $filterIsVisible: boolean }>`
max-height: ${({ $filterIsVisible }) =>
    $filterIsVisible ? "calc(100vh - 430px)" : "calc(100vh - 200px)"};
  max-width: calc(100vw - 388px);
  padding: 0 5px 10px 5px;
  margin-top: 6px;
  overflow: auto;
  animation: ${appearFromLeft} 1s;

  @media screen and (max-width: 1152px) {
    max-width: 100vw;
  }
`;

interface ContainerItemProps {
  isColor: boolean;
}

export const ContainerItem = styled.div<ContainerItemProps>`
  background: ${props =>
    props.isColor
      ? props.theme.colors.grayWhite
      : props.theme.colors.whitePrimary};
  padding: 0 31px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 70px;
  @media screen and (max-width: 1400px) {
    overflow: auto;
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    flex: 1;
    border: none;
    background: transparent;

    ul {
      padding: 21px 31px;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      li {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: ${props => props.theme.colors.textBlack};

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        strong {
          font-weight: 600;
          color: ${props => props.theme.colors.textBlack};
          margin-bottom: 10px;
        }

        span {
          font-weight: normal;

          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.bluePrimary};
    width: 32px;
    height: 32px;

    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  padding: 40px 60px 10px 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .divPaginacao {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    background: ${props => props.theme.colors.bluePrimary};
    border: none;
    border-radius: 16px;
    padding: 12px 35px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${props => props.theme.colors.whitePrimary};

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

interface ContainerSearchProps {
  isFilterDateInterval: boolean;
}

export const ContainerSearch = styled.div<ContainerSearchProps>`
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: ${props =>
    props.isFilterDateInterval ? '1fr 1fr' : '1fr 0.05fr 0.05fr'};
  gap: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    grid-template-columns: ${props =>
    props.isFilterDateInterval ? '1fr' : '1fr 0.05fr 0.05fr'};
  }

  button {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    padding: 16px 14px 17px 14px;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 0.2s;

    svg {
      color: ${props => props.theme.colors.grayBlack};
    }

    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const ContainerSemResultados = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 30px;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  height: 40px;
  background: ${props => props.theme.colors.whitePrimary};
  border: 1px solid ${props => props.theme.colors.grayWhite};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 15px 16px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  select {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    border: none;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.whitePrimary}
      inset;
  }

  select:-webkit-autofill {
    -webkit-text-fill-color: ${props =>
    props.theme.colors.grayBlack} !important;
  }
`;

export const ConteinerFiltros = styled.div<{ $isVisible: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  height: ${(props) => (props.$isVisible ? "auto" : "0px")};
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
`;

export const DivCheck = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-top: 14px;

  input {
    height: 1.2rem;
    width: 1.2rem;
    max-width: 30px;
    padding: 0px;
    margin: 0px;
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  `;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;

  p, h2, h3, strong{
      color: black;
      margin-top: 10px;
  }

`;

export const CloseButton = styled.button`
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
`;

export const ActivitiesList = styled.ul`
margin-top: 8px;
padding-left: 20px;
list-style-type: disc;
`;

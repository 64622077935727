import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: ${props =>
    props.disabled
      ? shade(0.2, props.theme.colors.bluePrimary)
      : props.theme.colors.bluePrimary};
  border-radius: 16px;

  height: 50px;
  width: 345px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: none;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;

  color: ${props => props.theme.colors.whitePrimary};

  transition: background 0.2s;

  &:hover {
    background: ${props => shade(0.2, props.theme.colors.bluePrimary)};
  }

  @media screen and (max-width: 992px) {
    width: 90%;
  }
`;

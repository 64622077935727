export interface Empresa {
  ID: number;
  NOME: string;
  Id_Empresas: number;
  ENDERECO: string;
  CNPJ: string;
  NUMERO: string;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
}

export interface EmpresaPincipal {
  ATIVO: boolean;
  BAIRRO: string;
  CIDADE: string;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: null | string;
  ENDERECO: string;
  ID: number;
  NOME: string;
  NUMERO: string;
  empresasQuadroListaId: number;
  max_number_users: number;
  planId: number;
}

export const empresaEmpty: Empresa = {
  ATIVO: false,
  CNPJ: '12.121.212/1212-12',
  DATA_CRIACAO: '',
  DATA_INATIVACAO: '',
  ENDERECO: '',
  ID: -1,
  Id_Empresas: -1,
  NOME: '',
  NUMERO: '',
};

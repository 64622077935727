import React, {
  useState,
  useCallback,
  FormEvent,
  Fragment,
  useEffect,
  useContext,
} from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FiPlus } from 'react-icons/fi';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { ThemeContext } from 'styled-components';

import api from '../../../../services/api';
import { Loading } from '../../../../components/Loading';
import { useToast } from '../../../../hooks/toast';
import Button from '../../../../components/Button';

import { ResponseGetAmbiente } from '..';
import { Container, CheckLabel, DivActive, ButtonRed } from './styles';
import {
  ButtonPlus,
  DivMensal,
  ValSelectedButton,
} from '../ModalAmbientePeriodicidade/styles';
import { UserGet } from '../../../../models/User';

Modal.setAppElement('#root');

export interface EditAgenda {
  active: boolean;
  date: string;
  agendaId: number;
  ambienteId: number;
  periodicidadeId: number;
  periodicidadeName: string;
  userId: number;
  companyId: number;
  qtdLimpeza: number;
  horarios: HourINIFIM[];
  type: string;
  diasDaSemana: DaySelect[];
  diasDaSemana2: DaySelect[];
  diasSelected: string[];
  diaMesSelected: string[];
  photoUni: boolean;
  vigIni: string;
  vigFim: string;
}

export interface PutAgenda {
  ATIVO: boolean;
  Id_EmpresasFilial: number;
  Id_Environments: number;
  Id_Users: number;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  Periodicidade: {
    ID: number;
    nome: string;
    DIAS_SEMANA: string;
    HORA_BASE_INICIAL: string;
    HORA_BASE_FINAL: string;
    Id_EmpresasFilial: number;
    type: 'sem' | '12X';
    Revisoes: {
      ID: number;
      HORA_INICIAL: string;
      HORA_FINAL: string;
      Id_EmpresasFilial: number;
    }[];
  };
  ID: number;
}

interface HourINIFIM {
  hourIni: string;
  hourFim: string;
  id: number;
}

interface DaySelect {
  day: string;
  d: string;
  selected: boolean;
}

interface ModalProps {
  isOpen: boolean;
  dadoEdit: EditAgenda;
  dadosAmbiente: ResponseGetAmbiente[];
  dadosUsers: UserGet[];
  dadosDay: string;
  onRequestClose: () => void;
}

export function ModalEditScheduling2({
  isOpen,
  dadosDay,
  dadoEdit,
  dadosAmbiente,
  dadosUsers,
  onRequestClose,
}: ModalProps): JSX.Element {
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [ambienteId, setAmbienteId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [optionSemana, setOptionSemana] = useState<DaySelect[]>([]);
  const [optionSemana2, setOptionSemana2] = useState<DaySelect[]>([]);
  // const [qtdLimpeza, setQtdLimpeza] = useState(dadoEdit.qtdLimpeza);
  const [horarios, setHorarios] = useState<HourINIFIM[]>([]);
  const optionType = ['Semanal', '12X'];
  const optionMesAno = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [type, setType] = useState('Semanal');
  const [dayCalendar, setDayCalendar] = useState(1);
  const [mouthCalendar, setMouthCalendar] = useState(optionMesAno[0]);
  const [selectedDias, setSelectedDias] = useState<string[]>([]);
  const [selectedMeses, setSelectedMeses] = useState<string[]>([]);
  const [isPhotoUniq, setIsPhotoUniq] = useState(dadoEdit.photoUni);
  const [vigIni, setVigIni] = useState(dadoEdit.vigIni.slice(0, 10));
  const [vigFim, setVigFim] = useState(dadoEdit.vigFim.slice(0, 10));
  const [active, setActive] = useState(false);

  useEffect(() => {
    setAmbienteId(dadoEdit.ambienteId);
    setUserId(dadoEdit.userId);
    setOptionSemana(dadoEdit.diasDaSemana);
    setOptionSemana2(dadoEdit.diasDaSemana2);
    setHorarios(dadoEdit.horarios);
    setType(dadoEdit.type);
    setSelectedDias(dadoEdit.diasSelected);
    setSelectedMeses(dadoEdit.diaMesSelected);
    setIsPhotoUniq(dadoEdit.photoUni);
    setVigIni(dadoEdit.vigIni.slice(0, 10));
    setVigFim(dadoEdit.vigFim.slice(0, 10));
    setActive(dadoEdit.active);
  }, [
    dadoEdit.active,
    dadoEdit.ambienteId,
    dadoEdit.diaMesSelected,
    dadoEdit.diasDaSemana,
    dadoEdit.diasDaSemana2,
    dadoEdit.diasSelected,
    dadoEdit.horarios,
    dadoEdit.photoUni,
    dadoEdit.type,
    dadoEdit.userId,
    dadoEdit.vigFim,
    dadoEdit.vigIni,
  ]);

  const handleAddDia = useCallback(() => {
    const diaString =
      dayCalendar <= 9 ? `0${dayCalendar}` : String(dayCalendar);
    if (selectedDias.length === 0) {
      setSelectedDias([diaString]);
    } else {
      const newSelectDias: string[] = [];
      let aux = false;
      selectedDias.forEach(i => {
        if (i === diaString) {
          newSelectDias.push(i);
          aux = true;
        } else if (Number(i) < dayCalendar) {
          newSelectDias.push(i);
        } else {
          if (!aux) {
            newSelectDias.push(diaString);
            aux = true;
          }
          newSelectDias.push(i);
        }
      });
      if (!aux) {
        newSelectDias.push(diaString);
      }
      setSelectedDias(newSelectDias);
    }
    setDayCalendar(1);
  }, [dayCalendar, selectedDias]);

  const handleRemoveDia = useCallback(
    (item: string) => {
      const filter = selectedDias.filter(i => i !== item);
      setSelectedDias(filter);
    },
    [selectedDias],
  );

  const getNumberMes = useCallback((name: string): string => {
    switch (name) {
      case 'Janeiro':
        return '01';
      case 'Fevereiro':
        return '02';
      case 'Março':
        return '03';
      case 'Abril':
        return '04';
      case 'Maio':
        return '05';
      case 'Junho':
        return '06';
      case 'Julho':
        return '07';
      case 'Agosto':
        return '08';
      case 'Setembro':
        return '09';
      case 'Outubro':
        return '10';
      case 'Novembro':
        return '11';
      case 'Dezembro':
        return '12';
      default:
        return '';
    }
  }, []);

  const handleAddMes = useCallback(() => {
    const numberMes = getNumberMes(mouthCalendar);
    if (numberMes !== '' && dayCalendar) {
      let auxDia = dayCalendar;
      if (auxDia > 28 && numberMes === '02') {
        auxDia = 28;
      }
      if (auxDia > 30 && ['04', '06', '09', '10'].find(i => i === numberMes)) {
        auxDia = 30;
      }
      const diaString = auxDia < 10 ? `0${auxDia}` : `${auxDia}`;
      const dateString = `${diaString}/${numberMes}`;
      const newSelectedMeses: string[] = [];
      let aux = false;
      selectedMeses.forEach(i => {
        const [dia, mes] = i.split('/');
        if (!aux) {
          if (
            Number(mes) > Number(numberMes) ||
            (Number(dia) > auxDia && Number(mes) === Number(numberMes))
          ) {
            newSelectedMeses.push(...[dateString, i]);
            aux = true;
          } else {
            newSelectedMeses.push(i);
          }
        } else {
          newSelectedMeses.push(i);
        }
      });
      if (!aux) {
        newSelectedMeses.push(dateString);
        aux = true;
      }
      setSelectedMeses(newSelectedMeses);
    }
  }, [dayCalendar, getNumberMes, mouthCalendar, selectedMeses]);

  const handleRemoveMes = useCallback(
    (val: string) => {
      const filter = selectedMeses.filter(i => i !== val);
      setSelectedMeses(filter);
    },
    [selectedMeses],
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      try {
        event.preventDefault();
        setLoading(true);

        if (
          type === 'Semanal' &&
          optionSemana.filter(i => i.selected).length === 0
        )
          throw new Error('Nenhum dia da semana foi selecionado');

        let cont = '';
        horarios.forEach((horario, index) => {
          if (horario.hourIni >= horario.hourFim) {
            throw new Error(
              `Horário incorreto - O ${
                index + 1
              }º horário inicial está maior ou igual ao horário final`,
            );
          }

          if (cont <= horario.hourIni) {
            cont = horario.hourIni;
          } else {
            throw new Error(
              'A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir.',
            );
          }
        });
        const dateEditIni = parseInt(
          dadoEdit.vigIni.slice(0, 10).split('-').join(''),
          10,
        );
        const numberVigIni = parseInt(vigIni.split('-').join(''), 10);
        const todayToOneYear = numberVigIni + 10000;
        // console.log(numberVigIni, dateEditIni, numberVigIni < dateEditIni);
        if (numberVigIni < dateEditIni) {
          throw new Error(
            'A data de vigência inicial está menor que a data anterior.',
          );
        }
        const numberVigFim = parseInt(vigFim.split('-').join(''), 10);
        if (numberVigIni > numberVigFim) {
          throw new Error(
            'A data de vigencia final é menor que a data de vigencia inicial.',
          );
        }
        if (numberVigFim > todayToOneYear) {
          throw new Error(
            'O range das datas de vigencia é maior que o esperado, valor máximo de um ano.',
          );
        }
        // console.log('SUBMIT');
        const putObj: PutAgenda = {
          ATIVO: active,
          ID: dadoEdit.agendaId,
          Id_EmpresasFilial: dadoEdit.companyId,
          Id_Environments: ambienteId,
          Id_Users: userId,
          Periodicidade: {
            DIAS_SEMANA: optionSemana
              .filter(i => i.selected)
              .map(i => i.day)
              .join(' - '),
            HORA_BASE_FINAL: horarios[0].hourFim,
            HORA_BASE_INICIAL: horarios[0].hourIni,
            ID: dadoEdit.periodicidadeId,
            Id_EmpresasFilial: dadoEdit.companyId,
            nome: dadoEdit.periodicidadeName,
            Revisoes: horarios
              .filter((_, index) => index !== 0)
              .map(i => ({
                HORA_FINAL: i.hourFim,
                HORA_INICIAL: i.hourIni,
                ID: i.id,
                Id_EmpresasFilial: dadoEdit.companyId,
              })),
            type: type === '12X' ? '12X' : 'sem',
          },
          revisionPhotos: isPhotoUniq,
          vigFim,
          vigIni,
        };

        api
          .put('/agendas/updateAgenda', putObj)
          .then(() => {
            // console.log(resp);
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'A agenda foi alterado com sucesso!',
            });
            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(err => {
            console.log(err.message);
            // console.log(err.response);
            setLoading(false);
            if (err.response.status === 400) {
              addToast({
                type: 'error',
                title: 'Erro',
                description: `${err.response.data.message}`,
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro',
                description:
                  'Ocorreu um erro ao editar o agenda, tente novamente',
              });
            }
          });
        // @ts-ignore
      } catch (err) {
        setLoading(false);
        if (err.message === 'Nenhum dia da semana foi selecionado') {
          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Nenhum dia da semana foi selecionado!',
          });
        } else if (err.message.split('-')[0].trim() === 'Horário incorreto') {
          addToast({
            type: 'error',
            title: 'Horário incorreto',
            description: err.message.split('-')[1],
          });
        } else if (
          err.message ===
          'A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir.'
        ) {
          addToast({
            type: 'error',
            title: 'Horários incorretos',
            description:
              'A ordem de horários está incorreta ou sua periodicidade está abrangendo mais de 1 dia. Favor corrigir.',
          });
        } else if (
          err.message ===
          'A data de vigencia final é menor que a data de vigencia inicial.'
        ) {
          addToast({
            type: 'error',
            title: 'Erro',
            description:
              'A data de vigencia final é menor que a data de vigencia inicial.',
          });
        } else if (
          err.message ===
          'A data de vigência inicial está menor que a data anterior.'
        ) {
          addToast({
            type: 'error',
            title: 'Erro',
            description:
              'A data de vigencia inicial está menor que a data anterior.',
          });
        } else if (
          err.message ===
          'O range das datas de vigencia é maior que o esperado, valor máximo de um ano.'
        ) {
          addToast({
            type: 'error',
            title: 'Erro',
            description:
              'O range das datas de vigencia é maior que o esperado, valor máximo de um ano.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos',
          });
        }
      }
    },
    [
      active,
      addToast,
      ambienteId,
      dadoEdit.agendaId,
      dadoEdit.companyId,
      dadoEdit.periodicidadeId,
      dadoEdit.periodicidadeName,
      dadoEdit.vigIni,
      go,
      horarios,
      isPhotoUniq,
      optionSemana,
      type,
      userId,
      vigFim,
      vigIni,
    ],
  );

  // const handleDelete = useCallback(
  //   (agendaId: number) => {
  //     setLoading(true);
  //     api
  //       .delete('/agendas/v2', { data: { ID: agendaId } })
  //       .then(() => {
  //         setLoading(false);
  //         addToast({
  //           type: 'success',
  //           title: 'Sucesso',
  //           description: 'Agenda deletada com sucesso',
  //         });
  //         setTimeout(() => {
  //           go(0);
  //         }, 1000);
  //       })
  //       .catch(err => {
  //         setLoading(false);
  //         console.log(err.message);
  //         addToast({
  //           type: 'error',
  //           title: 'Erro',
  //           description: 'Ocoreu um erro ao deletar a agenda.',
  //         });
  //       });
  //   },
  //   [addToast, go],
  // );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>

      <Container onSubmit={handleSubmit}>
        <header>
          <h1>{dadosDay.split('-').reverse().join('/')}</h1>
        </header>
        <DivActive active={active}>
          <p>{active ? 'Agenda ativada' : 'Agenda desativada'}</p>
          <div>
            <button
              type="button"
              className="accept"
              onClick={() => setActive(true)}
            >
              <BsCheckCircle />
            </button>
            <button
              type="button"
              className="reject"
              onClick={() => setActive(false)}
            >
              <BsXCircle />
            </button>
          </div>
        </DivActive>
        <p>Ambiente</p>
        <select
          value={ambienteId}
          onChange={event => {
            setAmbienteId(parseInt(event.target.value, 10));
          }}
        >
          {dadosAmbiente.map(item => (
            <option value={item.ID}>{item.AMBIENTE_NOME}</option>
          ))}
        </select>
        <p>Usuário</p>
        <select
          value={userId}
          onChange={event => {
            setUserId(parseInt(event.target.value, 10));
          }}
        >
          {dadosUsers.map(item => (
            <option value={item.id}>{item.nome}</option>
          ))}
        </select>
        <p>Tipo de Periodicidade</p>
        <select
          value={type}
          onChange={event => {
            setType(event.target.value);
          }}
        >
          {optionType.map(item => (
            <option value={item}>{item}</option>
          ))}
        </select>
        {type === 'Semanal' && (
          <>
            <p>Dias da Semana</p>
            <div className="calendar">
              {optionSemana.map((item, index) => (
                <button
                  type="button"
                  className={item.selected ? 'selected' : 'notSelected'}
                  onClick={() => {
                    setOptionSemana(
                      optionSemana.map((sem, i) => {
                        if (i === index) {
                          return { ...sem, selected: !sem.selected };
                        }
                        return sem;
                      }),
                    );
                  }}
                >
                  {item.d}
                </button>
              ))}
            </div>
          </>
        )}
        {type === 'Semana Alternada' && (
          <>
            <p>Dias da Semana 1</p>
            <div className="calendar">
              {optionSemana.map((item, index) => (
                <button
                  type="button"
                  className={item.selected ? 'selected' : 'notSelected'}
                  onClick={() => {
                    setOptionSemana(
                      optionSemana.map((sem, i) => {
                        if (i === index) {
                          return { ...sem, selected: !sem.selected };
                        }
                        return sem;
                      }),
                    );
                  }}
                >
                  {item.d}
                </button>
              ))}
            </div>
            <p>Dias da Semana 2</p>
            <div className="calendar">
              {optionSemana2.map((item, index) => (
                <button
                  type="button"
                  className={item.selected ? 'selected' : 'notSelected'}
                  onClick={() => {
                    setOptionSemana2(
                      optionSemana2.map((sem, i) => {
                        if (i === index) {
                          return { ...sem, selected: !sem.selected };
                        }
                        return sem;
                      }),
                    );
                  }}
                >
                  {item.d}
                </button>
              ))}
            </div>
          </>
        )}
        {type === 'Mensal' && (
          <DivMensal>
            <p>Selecione os dias</p>
            <div className="input">
              <div style={{ flex: 1 }}>
                <input
                  name="dayCalendar"
                  placeholder="Dia mensal"
                  type="number"
                  value={dayCalendar}
                  onChange={event => {
                    const val = Number(event.target.value);
                    if (val > 0 && val <= 31) {
                      setDayCalendar(val);
                    }
                  }}
                />
              </div>
              <ButtonPlus type="button" onClick={handleAddDia}>
                <FiPlus size={26} />
              </ButtonPlus>
            </div>
            <ValSelectedButton>
              {selectedDias.map((item, index) => {
                if (selectedDias.length === index + 1) {
                  return (
                    <button
                      key={index.toString()}
                      type="button"
                      onClick={() => handleRemoveDia(item)}
                    >
                      <p>{item}</p>
                    </button>
                  );
                }
                return (
                  <div style={{ display: 'flex' }} key={index.toString()}>
                    <button type="button" onClick={() => handleRemoveDia(item)}>
                      <p>{item}</p>
                    </button>
                    <p style={{ marginTop: 5 }}>-</p>
                  </div>
                );
              })}
            </ValSelectedButton>
          </DivMensal>
        )}
        {type === 'Anual' && (
          <DivMensal>
            <p>Adicione as datas</p>
            <div className="input">
              <input
                name="dayCalendar"
                placeholder="Dia mensal"
                type="number"
                value={dayCalendar}
                onChange={event => {
                  const val = Number(event.target.value);
                  if (val > 0 && val <= 31) {
                    setDayCalendar(val);
                  }
                }}
              />
              <p
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                  margin: 0,
                  marginTop: 8,
                }}
              >
                de
              </p>
              <select
                name="type"
                value={mouthCalendar}
                onChange={event => setMouthCalendar(event.target.value)}
              >
                {optionMesAno.map(item => (
                  <option value={item}>{item}</option>
                ))}
              </select>
              <div>
                <ButtonPlus type="button" onClick={handleAddMes}>
                  <FiPlus size={26} />
                </ButtonPlus>
              </div>
            </div>
            <ValSelectedButton>
              {selectedMeses.map((item, index) => {
                if (selectedMeses.length === index + 1) {
                  return (
                    <button
                      key={index.toString()}
                      type="button"
                      onClick={() => handleRemoveMes(item)}
                    >
                      <p>{item}</p>
                    </button>
                  );
                }
                return (
                  <div style={{ display: 'flex' }} key={index.toString()}>
                    <button type="button" onClick={() => handleRemoveMes(item)}>
                      <p>{item}</p>
                    </button>
                    <p style={{ marginTop: 5 }}>-</p>
                  </div>
                );
              })}
            </ValSelectedButton>
          </DivMensal>
        )}
        <p>Horários</p>
        {horarios.map((item, i) => (
          <Fragment key={i.toString()}>
            <span>{i === 0 ? '1° Limpeza' : `${i}° Revisão`}</span>
            <div className="horario">
              <input
                name="horaIni"
                type="time"
                value={item.hourIni}
                onChange={event => {
                  setHorarios(
                    horarios.map((val, index) => {
                      if (index === i) {
                        return { ...val, hourIni: event.target.value };
                      }
                      return val;
                    }),
                  );
                }}
              />
              <input
                name="horaFim"
                type="time"
                value={item.hourFim}
                onChange={event => {
                  setHorarios(
                    horarios.map((val, index) => {
                      if (index === i) {
                        return { ...val, hourFim: event.target.value };
                      }
                      return val;
                    }),
                  );
                }}
              />
            </div>
          </Fragment>
        ))}
        <CheckLabel>
          <input
            checked={isPhotoUniq}
            type="checkbox"
            onChange={() => setIsPhotoUniq(!isPhotoUniq)}
          />
          <p>As Revisões serão com foto única?</p>
        </CheckLabel>

        <p>Datas de vigência</p>
        <div
          style={{
            display: 'flex',
            columnGap: 60,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: 1 }}>
            <input
              type="date"
              value={vigIni}
              onChange={event => {
                setVigIni(event.target.value);
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <input
              type="date"
              value={vigFim}
              onChange={event => {
                setVigFim(event.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
            display: 'flex',
            justifyContent: 'center',
            columnGap: 20,
          }}
        >
          <Button type="submit" widthProps="80%">
            Salvar
          </Button>
          {/* <ButtonRed
            onClick={() => {
              Swal.fire({
                title: 'Tem certeza que deseja remover?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonColor: colors.greenPrimary,
                cancelButtonColor: colors.redPrimary,
                confirmButtonText: 'Sim',
              }).then(result => {
                if (result.isConfirmed) {
                  handleDelete(dadoEdit.agendaId);
                }
              });
            }}
            type="button"
          >
            Remover
          </ButtonRed> */}
        </div>
      </Container>

      {loading && <Loading />}
    </Modal>
  );
}

import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
// import Select from 'react-select';
import moment from 'moment';
import { FiXCircle } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';
import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { SelectCustom } from '../../../../components/SelectCustom';
import { Loading } from '../../../../components/Loading';
// import { maskCpf } from '../../../../components/InputOverview/mask';
import Button from '../../../../components/Button';
import HeaderTable from '../../../../components/HeaderTable';
import { ResponseGetEmpresas, ResponseGetAmbiente } from '../index';

import { AnimationContainer, ContainerTable } from './styles';
// import { InputOverview } from '../../../../components/InputOverview';
import {
  AmbienteEPeriodicidade,
  ModalAmbientePeriodicidade,
} from '../ModalAmbientePeriodicidade';
import { useAuth } from '../../../../hooks/auth';
import { UserGet } from '../../../../models/User';

interface SignUpFormData {
  empresa: string;
  empresaLote: string;
  usuarioAsg: string;
  vigIni: string;
  vigFim: string;
}

export interface AgendaPost {
  // ID: number;
  Id_Environments: number;
  Id_Periodicidade: number;
  Id_Users: number;
  Id_EmpresasFilial: number;
  // FINALIZADO: boolean;
  // DATA_FINALIZACAO: Date;
  // ATIVO: boolean;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
}

interface PostAgenda {
  Id_EmpresasFilial: number;
  Id_Environments: number;
  Id_Users: number;
  revisionPhotos: boolean;
  vigIni: string;
  vigFim: string;
  Periodicidade: {
    nome: string;
    hora_base_inicial: string;
    hora_base_final: string;
    Id_EmpresasFilial: number;
    type: 'sem' | '12X';
    DIAS_SEMANA: string;
    Revisoes: {
      HORA_INICIAL: string;
      HORA_FINAL: string;
      Id_EmpresasFilial: number;
    }[];
  };
}

export interface ResponsePost {
  Id_Users: number[] | number;
  Id_Environments: number[];
  Id_Periodicidade: number[];
  Id_EmpresasFilial: number;
}

interface CadastroProps {
  dadosEmpresas: ResponseGetEmpresas[];
  dadosUsuarios: UserGet[];
  dadosAmbientes: ResponseGetAmbiente[];
  // dadosPeriodicidades: ResponseGetPeriodicidade[];
}

// const headers = [
//   { name: 'Nome', field: 'nome', sortable: true },
//   { name: 'CPF', field: 'cpf', sortable: true },
//   { name: 'Ambientes', field: 'ambientes', sortable: false },
//   { name: 'Periodicidades', field: 'periodicidades', sortable: false },
// ];

const headersAP = [
  { name: 'Ambiente', field: 'ambiente', sortable: true },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'Semana', field: 'semana', sortable: true },
  { name: 'Horários', field: 'horario', sortable: false },
  { name: 'Vigência', field: 'vigencia', sortable: false },
  { name: 'Remover', field: 'remove', sortable: false },
];

export const Cadastro: React.FC<CadastroProps> = ({
  dadosEmpresas,
  dadosUsuarios,
  dadosAmbientes,
  // dadosPeriodicidades,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  // const [registerType, setRegisterType] = useState('individual');
  const formRefIndividual = useRef<FormHandles>(null);
  // const formRefEmLote = useRef<FormHandles>(null);

  const [companyId, setCompanyId] = useState<number>();
  // const [isPhotoUniq, setIsPhotoUniq] = useState(false);
  const [userId, setUserId] = useState<number>(dadosUsuarios[0].id);
  // const [usersIds, setUsersIds] = useState<number[]>([]);
  // const [sorting, setSorting] = useState({ field: '', order: '' });
  const [showModal, setShowModal] = useState(false);
  const [itensAmbPer, setItensAmbPer] = useState<AmbienteEPeriodicidade[]>([]);

  // const [companyIdLote, setCompanyIdLote] = useState<number>(
  //   dadosEmpresas[0].ID,
  // );

  // const [environmentesDataSelect, setEnvironmentesDataSelect] = useState<
  //   SelectInterface[]
  // >([]);
  // const [frequencyDataSelect, setFrequencyDataSelect] = useState<
  //   SelectInterface[]
  // >([]);

  // const [
  //   environmentesDataLoteSelect,
  //   setEnvironmentesDataLoteSelect,
  // ] = useState<SelectInterface[]>([]);
  // const [frequencyDataLoteSelect, setFrequencyDataLoteSelect] = useState<
  //   SelectInterface[]
  // >([]);
  // const [selectEnvironmentsUnic, setSelectEnvironmentsUnic] = useState<
  //   SelectInterface[]
  // >([]);
  // const [selectFrequencyUnic, setSelectFrequencyUnic] = useState<
  //   SelectInterface[]
  // >([]);
  // const [selectEnvironments, setSelectEnvironments] = useState<
  //   SelectInterface[][]
  // >([[]]);
  // const [selectFrequency, setSelectFrequency] = useState<SelectInterface[][]>([
  //   [],
  // ]);

  // useEffect(() => {
  //   const environmentesDataSelectTemp: SelectInterface[] = [];
  //   const frequencyDataSelectTemp: SelectInterface[] = [];
  //   const environmentesDataSelectLoteTemp: SelectInterface[] = [];
  //   const frequencyDataSelectLoteTemp: SelectInterface[] = [];

  //   dadosAmbientes
  //     .filter(ambiente => ambiente.companyId === companyId)
  //     .forEach(dado => {
  //       environmentesDataSelectTemp.push({
  //         value: dado.ID.toString(),
  //         label: dado.AMBIENTE_NOME,
  //       });
  //     });

  //   dadosPeriodicidades
  //     .filter(periodicidade => periodicidade.Id_EmpresasFilial === companyId)
  //     .forEach(dado => {
  //       frequencyDataSelectTemp.push({
  //         value: dado.ID.toString(),
  //         label: dado.NOME,
  //       });
  //     });

  //   dadosAmbientes
  //     .filter(ambiente => ambiente.companyId === companyIdLote)
  //     .forEach(dado => {
  //       environmentesDataSelectLoteTemp.push({
  //         value: dado.ID.toString(),
  //         label: dado.AMBIENTE_NOME,
  //       });
  //     });

  //   dadosPeriodicidades
  //     .filter(
  //       periodicidade => periodicidade.Id_EmpresasFilial === companyIdLote,
  //     )
  //     .forEach(dado => {
  //       frequencyDataSelectLoteTemp.push({
  //         value: dado.ID.toString(),
  //         label: dado.NOME,
  //       });
  //     });

  //   // setEnvironmentesDataSelect([...environmentesDataSelectTemp]);
  //   // setFrequencyDataSelect([...frequencyDataSelectTemp]);

  //   // setEnvironmentesDataLoteSelect([...environmentesDataSelectLoteTemp]);
  //   // setFrequencyDataLoteSelect([...frequencyDataSelectLoteTemp]);
  // }, [dadosAmbientes, dadosPeriodicidades, companyId, companyIdLote]);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});
        const schema = Yup.object().shape({
          empresa: Yup.string().required('Empresa é obrigatória'),
          usuarioAsg: Yup.string().required('Colaborador é obrigatório'),
          // vigIni: Yup.string().required('Data inicial é obrigatória'),
          // vigFim: Yup.string().required('Data Final é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (itensAmbPer.length <= 0) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'É necessário pelo menos uma agenda.',
          });
          return;
        }

        // const environmentsIds = selectEnvironmentsUnic.map(dataEnvironment => {
        //   return Number(dataEnvironment.value);
        // });
        // const frequencyIds = selectFrequencyUnic.map(dataFrequency => {
        //   return Number(dataFrequency.value);
        // });

        // if (
        //   !environmentsIds.length ||
        //   !frequencyIds.length ||
        //   environmentsIds.length !== frequencyIds.length
        // )
        //   throw new Error();

        // const today = parseInt(moment().format('YYYYMMDD'), 10);
        // const numberVigIni = parseInt(data.vigIni.split('-').join(''), 10);
        // if (numberVigIni < today) {
        //   setLoading(false);
        //   addToast({
        //     type: 'error',
        //     title: 'Erro',
        //     description:
        //       'A data de vigencia inicial está menor que a data atual.',
        //   });
        //   return;
        // }
        // const numberVigFim = parseInt(data.vigFim.split('-').join(''), 10);
        // if (numberVigIni > numberVigFim) {
        //   addToast({
        //     type: 'error',
        //     title: 'Erro',
        //     description:
        //       'A data de vigencia final é menor que a data de vigencia inicial.',
        //   });
        //   setLoading(false);
        //   return;
        // }

        // const objPost: AgendaPost[] = [];
        const companyIdAgend = Number(data.empresa.split(' - ')[0]);
        const userIdAgend = Number(data.usuarioAsg.split(' - ')[0]);

        const newAgendas: PostAgenda[] = [];
        itensAmbPer.forEach(item => {
          newAgendas.push({
            Id_EmpresasFilial: companyIdAgend,
            Id_Environments: item.ambienteId,
            Id_Users: userIdAgend,
            Periodicidade: {
              hora_base_final: item.horaPrinFim,
              hora_base_inicial: item.horaPrincIni,
              Id_EmpresasFilial: companyIdAgend,
              nome: `AGENDA_${item.ambienteId}_${userIdAgend}_${moment().format(
                'YYYYMMDD',
              )}`,
              type: item.type === 'Semanal' ? 'sem' : '12X',
              DIAS_SEMANA: item.dataSemana,
              Revisoes: item.revisoes.map(it => ({
                HORA_FINAL: it.horaFim,
                HORA_INICIAL: it.horaIni,
                Id_EmpresasFilial: companyIdAgend,
              })),
            },
            revisionPhotos: item.photoUnic,
            vigFim: item.vigFim,
            vigIni: item.vigIni,
          });
        });
        // console.log(newAgendas);
        // setLoading(false);

        newAgendas.forEach(item => {
          api
            .post('/agendas/v2/saveAgenda', [item])
            .then(() => {
              // console.log(resp.data);
              setLoading(false);
              addToast({
                type: 'success',
                title: 'Sucesso',
                description:
                  'O agendamento foi cadastrado com sucesso e já pode ser visualizado no calendário',
              });
              setTimeout(() => {
                go(0);
              }, 2000);
            })
            .catch(err => {
              console.log(err.message);
              console.log(err.response);
              if (err.response.status === 400) {
                addToast({
                  type: 'error',
                  title: 'Erro',
                  description: `${err.response.data.message}`,
                });
              } else {
                addToast({
                  type: 'error',
                  title: 'Erro',
                  description:
                    'Ocorreu um erro ao cadastrar o agendamento, tente novamente',
                });
              }

              setLoading(false);
            });
        });

        // Promise.all(
        //   objPost.map(item => api.post('/agendas/v2/saveAgenda', item)),
        // )
        //   .then(() => {
        //     // console.log(resp[0].data);
        //     setLoading(false);
        //     addToast({
        //       type: 'success',
        //       title: 'Sucesso',
        //       description:
        //         'O agendamento foi cadastrado com sucesso e já pode ser visualizado no calendário',
        //     });
        //   })
        //   .catch(err => {
        //     console.log(err.message);
        //     console.log(err.response);
        //     if (err.response.status === 400) {
        //       addToast({
        //         type: 'error',
        //         title: 'Erro',
        //         description: `${err.response.data.message}`,
        //       });
        //     } else {
        //       addToast({
        //         type: 'error',
        //         title: 'Erro',
        //         description:
        //           'Ocorreu um erro ao cadastrar o agendamento, tente novamente',
        //       });
        //     }

        //     setLoading(false);
        //   });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Campos em branco',
          description: 'Existem campos obrigatórios vazios, favor preencher',
        });
      }
    },
    [addToast, go, itensAmbPer],
  );

  // const handleSubmitLote = useCallback(
  //   async (data: SignUpFormData) => {
  //     try {
  //       setLoading(true);
  //       formRefEmLote.current?.setErrors({});
  //       const schema = Yup.object().shape({
  //         empresaLote: Yup.string().required('Empresa é obrigatória'),
  //         vigIni: Yup.string().required('Data inicial é obrigatória'),
  //         vigFim: Yup.string().required('Data Final é obrigatória'),
  //       });

  //       await schema.validate(data, {
  //         abortEarly: false,
  //       });

  //       const usersIdsAux: number[] = [];

  //       const environmentsIds: number[] = [];
  //       selectEnvironments.forEach((dataEnvironmentTemp, index) => {
  //         dataEnvironmentTemp.forEach(dataEnvironment => {
  //           environmentsIds.push(Number(dataEnvironment.value));
  //           usersIds[index] !== -1 && usersIdsAux.push(usersIds[index]);
  //         });
  //       });

  //       const frequencyIds: number[] = [];
  //       selectFrequency.forEach(dataFrequencyTemp => {
  //         dataFrequencyTemp.forEach(dataFrequency => {
  //           frequencyIds.push(Number(dataFrequency.value));
  //         });
  //       });

  //       if (
  //         !environmentsIds.length ||
  //         !frequencyIds.length ||
  //         environmentsIds.length !== frequencyIds.length
  //       )
  //         throw new Error();

  //       const today = parseInt(moment().format('YYYYMMDD'), 10);
  //       const numberVigIni = parseInt(data.vigIni.split('-').join(''), 10);
  //       if (numberVigIni < today) {
  //         setLoading(false);
  //         addToast({
  //           type: 'error',
  //           title: 'Erro',
  //           description:
  //             'A data de vigencia inicial está menor que a data atual.',
  //         });
  //         return;
  //       }
  //       const numberVigFim = parseInt(data.vigFim.split('-').join(''), 10);
  //       if (numberVigIni > numberVigFim) {
  //         addToast({
  //           type: 'error',
  //           title: 'Erro',
  //           description:
  //             'A data de vigencia final é menor que a data de vigencia inicial.',
  //         });
  //         setLoading(false);
  //         return;
  //       }

  //       const objPost: AgendaPost[] = [];
  //       environmentsIds.forEach((item, i) => {
  //         objPost.push({
  //           Id_EmpresasFilial: companyId,
  //           Id_Environments: item,
  //           Id_Periodicidade: frequencyIds[i],
  //           Id_Users: usersIdsAux[i],
  //           revisionPhotos: isPhotoUniq,
  //           vigIni: data.vigIni,
  //           vigFim: data.vigFim,
  //         });
  //       });

  //       await api
  //         .post('/agendas/saveAgenda', objPost)
  //         .then(() => {
  //           setLoading(false);
  //           addToast({
  //             type: 'success',
  //             title: 'Sucesso',
  //             description:
  //               'Os agendamentos foram cadastrados com sucesso e já podem ser visualizados no calendário',
  //           });

  //           setTimeout(() => {
  //             go(0);
  //           }, 1000);
  //         })
  //         .catch(err => {
  //           // console.log(err);
  //           if (err.response.status === 400) {
  //             addToast({
  //               type: 'error',
  //               title: 'Erro',
  //               description: `${err.response.data.message}`,
  //             });
  //           } else {
  //             addToast({
  //               type: 'error',
  //               title: 'Erro',
  //               description:
  //                 'Ocorreu um erro ao cadastrar os agendamentos, tente novamente',
  //             });
  //           }

  //           setLoading(false);
  //         });
  //     } catch (err) {
  //       setLoading(false);
  //       if (err instanceof Yup.ValidationError) {
  //         setLoading(false);
  //         const errors = getValidationErrors(err);

  //         formRefEmLote.current?.setErrors(errors);
  //       }

  //       addToast({
  //         type: 'error',
  //         title: 'Campos em branco',
  //         description: 'Existem campos obrigatórios vazios, favor preencher',
  //       });
  //     }
  //   },
  //   [
  //     addToast,
  //     companyId,
  //     go,
  //     isPhotoUniq,
  //     selectEnvironments,
  //     selectFrequency,
  //     usersIds,
  //   ],
  // );

  // const responseData = useMemo(() => {
  //   let computedResponses: ResponseGetUsuario[] = [];
  //   computedResponses = dadosUsuarios.length ? dadosUsuarios : [];

  //   if (sorting.field) {
  //     const reversed = sorting.order === 'asc' ? 1 : -1;
  //     computedResponses = computedResponses.sort((a: any, b: any): any => {
  //       if (typeof a[sorting.field] === 'object') {
  //         return (
  //           reversed *
  //           a[sorting.field]
  //             .join(', ')
  //             .localeCompare(b[sorting.field].join(', '))
  //         );
  //       }
  //       if (typeof a[sorting.field] === 'string') {
  //         return reversed * a[sorting.field].localeCompare(b[sorting.field]);
  //       }
  //       return (
  //         reversed *
  //         a[sorting.field].toString().localeCompare(b[sorting.field].toString())
  //       );
  //     });
  //   }

  //   return computedResponses;
  // }, [dadosUsuarios, sorting.field, sorting.order]);

  const empresaSel = useMemo(() => {
    if (companyId === undefined) {
      const id = isCoordenador ? user.companyId : dadosEmpresas[0].ID;
      let aux = '';
      dadosEmpresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setCompanyId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    dadosEmpresas.forEach(item => {
      if (item.ID === companyId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [companyId, dadosEmpresas, isCoordenador, user.companyId]);

  return (
    <section>
      <ModalAmbientePeriodicidade
        onClose={() => setShowModal(false)}
        visible={showModal}
        dadosAmbiente={dadosAmbientes.filter(i => i.companyId === companyId)}
        getNewObj={e => {
          setItensAmbPer([...itensAmbPer, e]);
        }}
      />
      {/* <ConainerCadastroOption>
        <RadioBox
          type="button"
          onClick={() => {
            setRegisterType('individual');
            setSelectEnvironments([]);
            setSelectFrequency([]);
            setCompanyId(dadosEmpresas[0].ID);
          }}
        >
          Cadastro individual
        </RadioBox>
        <RadioBox
          type="button"
          onClick={() => {
            setRegisterType('lote');
            setSelectEnvironmentsUnic([]);
            setSelectFrequencyUnic([]);
            setCompanyIdLote(dadosEmpresas[0].ID);
          }}
        >
          Cadastro em lote
        </RadioBox>
      </ConainerCadastroOption> */}

      {/* {registerType === 'individual' ? ( */}
      <AnimationContainer>
        <Form ref={formRefIndividual} onSubmit={handleSubmit}>
          <p>Empresa</p>
          <SelectCustom
            key="1"
            name="empresa"
            defaultValue="Empresas"
            optionsDataCompany={dadosEmpresas}
            value={empresaSel}
            disabled={isCoordenador}
            onValue={e => {
              setCompanyId(parseInt(e.split('-')[0], 10));
              // setSelectEnvironmentsUnic([]);
              // setSelectFrequencyUnic([]);
              setUserId(
                dadosUsuarios.filter(
                  usuario =>
                    usuario.companyId === parseInt(e.split('-')[0], 10),
                ).length
                  ? dadosUsuarios.filter(
                      usuario =>
                        usuario.companyId === parseInt(e.split('-')[0], 10),
                    )[0].id
                  : dadosUsuarios[0].id,
              );
              setItensAmbPer([]);
            }}
          />
          <p>Colaborador</p>
          <SelectCustom
            name="usuarioAsg"
            defaultValue="Colaborador"
            type="users"
            optionsDataUsers={dadosUsuarios.filter(
              usuario => usuario.companyId === companyId,
            )}
            onValue={e => setUserId(parseInt(e.split('-')[0], 10))}
          />

          <p>Ambientes e periocidades</p>
          <Button type="button" onClick={() => setShowModal(true)}>
            Criar novo item
          </Button>
          {itensAmbPer.length > 0 && (
            <ContainerTable>
              <table>
                <HeaderTable headers={headersAP} />
                <tbody>
                  {itensAmbPer.map((item, index) => (
                    <tr key={`${item.ambienteId}-${index.toString()}`}>
                      <td>{item.nomeAmbiente}</td>
                      <td>{item.type}</td>
                      <td>{item.dataSemana}</td>
                      <td>
                        <span>
                          Principal: {item.horaPrincIni} a {item.horaPrinFim}
                        </span>
                        {item.revisoes.length > 0 && (
                          <span>
                            <br />
                            Rev.:{' '}
                            {item.revisoes
                              .map(i => `${i.horaIni} a ${i.horaFim}`)
                              .join(' , ')}
                          </span>
                        )}
                      </td>
                      <td>
                        {moment(item.vigIni).format('DD/MM/YYYY')} até{' '}
                        {moment(item.vigFim).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="option"
                          onClick={() => {
                            Swal.fire({
                              title: 'Tem certeza que deseja remover?',
                              icon: 'warning',
                              showCancelButton: true,
                              cancelButtonText: 'Não',
                              confirmButtonColor: colors.greenPrimary,
                              cancelButtonColor: colors.redPrimary,
                              confirmButtonText: 'Sim',
                            }).then(result => {
                              if (result.isConfirmed) {
                                setItensAmbPer(
                                  itensAmbPer.filter((_, i) => i !== index),
                                );
                              }
                            });
                          }}
                        >
                          <FiXCircle color={colors.bluePrimary} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>
          )}
          {/* 
            <p className="p-multi-select">Ambientes</p>
            <Select
              isMulti
              name="selectEnvironmentsUnic"
              // menuPortalTarget={document.querySelector('body')}
              options={environmentesDataSelect}
              placeholder="Selecione o(s) ambiente(s)..."
              className="basic-multi-select"
              classNamePrefix="select"
              value={selectEnvironmentsUnic}
              onChange={e => {
                setSelectEnvironmentsUnic([...e]);

                if (
                  !e.length ||
                  (!!selectFrequencyUnic &&
                    e.length - 1 < selectFrequencyUnic.length)
                ) {
                  setSelectFrequencyUnic([]);
                }
              }}
            />

            <p className="p-multi-select">Periodicidades</p>
            <Select
              isMulti
              name="selectFrequencyUnic"
              // menuPortalTarget={document.querySelector('body')}
              options={frequencyDataSelect}
              placeholder="Selecione a(s) periodicidade(s)..."
              className="basic-multi-select"
              classNamePrefix="select"
              isDisabled={
                !selectEnvironmentsUnic ||
                !selectEnvironmentsUnic.length ||
                (!!selectFrequencyUnic &&
                  (selectEnvironmentsUnic.length ===
                    selectFrequencyUnic.length ||
                    selectEnvironmentsUnic.length - 1 <
                      selectFrequencyUnic.length))
              }
              value={selectFrequencyUnic}
              onChange={e => setSelectFrequencyUnic([...e])}
            />
            <br />
            <CheckLabel>
              <input
                checked={isPhotoUniq}
                type="checkbox"
                onChange={() => setIsPhotoUniq(!isPhotoUniq)}
              />
              <p>As Revisões serão com foto única?</p>
            </CheckLabel> */}

          {/* <p className="p-multi-select">Datas de vigência</p>
            <div style={{ display: 'flex', columnGap: 20 }}>
              <InputOverview
                name="vigIni"
                placeholder="Data Inicial"
                type="date"
              />
              <InputOverview
                name="vigFim"
                placeholder="Data Final"
                type="date"
              />
            </div> */}
          <Button type="submit" widthProps="100%">
            Cadastrar
          </Button>
        </Form>
      </AnimationContainer>
      {/* ) : (
        <AnimationContainer>
          <Form ref={formRefEmLote} onSubmit={handleSubmitLote}>
            <p>Empresa</p>
            <SelectCustom
              key="2"
              name="empresaLote"
              defaultValue="Empresas"
              optionsDataCompany={dadosEmpresas}
              onValue={e => {
                setCompanyIdLote(parseInt(e.split('-')[0], 10));
                setSelectEnvironments([]);
                setSelectFrequency([]);
              }}
            />
            <ContainerTable>
              <table>
                <HeaderTable
                  headers={headers}
                  onSorting={(field: string, order: string) =>
                    setSorting({ field, order })
                  }
                />

                <tbody>
                  {responseData
                    .filter(usuario => usuario.companyId === companyIdLote)
                    .map((usuario, index) => (
                      <tr key={usuario.id}>
                        <td>{usuario.nome}</td>
                        <td>{maskCpf(usuario.cpf)}</td>
                        <td style={{ width: 280 }}>
                          <Select
                            isMulti
                            name="selectEnvironments"
                            // menuPortalTarget={document.querySelector('body')}
                            options={environmentesDataLoteSelect}
                            placeholder="Ambiente(s)..."
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={selectEnvironments[index]}
                            onChange={e => {
                              const selectEnvironmentsTemp = [
                                ...selectEnvironments,
                              ];
                              selectEnvironmentsTemp[index] = [...e];
                              setSelectEnvironments([
                                ...selectEnvironmentsTemp,
                              ]);

                              const usersIdsTemp = [...usersIds];
                              usersIdsTemp[index] = usuario.id;
                              setUsersIds([...usersIdsTemp]);

                              if (
                                !selectEnvironmentsTemp[index].length ||
                                (!!selectFrequency[index] &&
                                  selectEnvironmentsTemp[index].length - 1 <
                                    selectFrequency[index].length)
                              ) {
                                const selectFrequencyTemp = [
                                  ...selectFrequency,
                                ];
                                selectFrequencyTemp[index] = [];
                                setSelectFrequency([...selectFrequencyTemp]);

                                const usersIdsAux = [...usersIds];
                                usersIdsAux[index] = -1;
                                setUsersIds([...usersIdsAux]);
                              }
                            }}
                          />
                        </td>

                        <td style={{ width: 280 }}>
                          <Select
                            isMulti
                            name="selectFrequency"
                            // menuPortalTarget={document.querySelector('body')}
                            options={frequencyDataLoteSelect}
                            placeholder="Periodicidade(s)..."
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isDisabled={
                              !selectEnvironments[index] ||
                              !selectEnvironments[index].length ||
                              (!!selectFrequency[index] &&
                                (selectEnvironments[index].length ===
                                  selectFrequency[index].length ||
                                  selectEnvironments[index].length - 1 <
                                    selectFrequency[index].length))
                            }
                            value={selectFrequency[index]}
                            onChange={e => {
                              const selectFrequencyTemp = [...selectFrequency];
                              selectFrequencyTemp[index] = [...e];
                              setSelectFrequency([...selectFrequencyTemp]);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </ContainerTable>
            <br />
            <CheckLabel>
              <input
                checked={isPhotoUniq}
                type="checkbox"
                onChange={() => setIsPhotoUniq(!isPhotoUniq)}
              />
              <p>As Revisões serão com foto única?</p>
            </CheckLabel>

            <p className="p-multi-select">Datas de vigência</p>
            <div style={{ display: 'flex', columnGap: 20 }}>
              <InputOverview
                name="vigIni"
                placeholder="Data Inicial"
                type="date"
              />
              <InputOverview
                name="vigFim"
                placeholder="Data Final"
                type="date"
              />
            </div>
            <Button type="submit" widthProps="100%">
              Cadastrar
            </Button>
          </Form>
        </AnimationContainer>
      )} */}

      {loading && <Loading />}
    </section>
  );
};

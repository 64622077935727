import React, { useCallback, useState } from 'react';
import { Container } from './styles';

interface DropDownInterface {
  id: number;
  valor: string;
  // onSearch: (arg: string) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DropDownPagination = ({ onChangeItems, objetoEnum, minWidth }: any) => {
  const [value, setValue] = useState(objetoEnum[0].valor);

  const onChangeItem = useCallback(
    (valor: string) => {
      setValue(`${valor}`);
      onChangeItems(valor);
    },
    [onChangeItems],
  );

  return (
    <Container>
      <select
        style={{ minWidth }}
        value={value}
        onChange={e => onChangeItem(e.target.value)}
      >
        {objetoEnum.map((drop: DropDownInterface) => (
          <option key={drop.id} value={drop.valor}>
            {drop.valor}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default DropDownPagination;

import { RevisaoV2 } from './Revisao';

export interface Periodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  Revisoes: RevisaoV2[];
  type: 'sem' | '12X';
}

export const EmptyPeriodicidade: Periodicidade = {
  ATIVO: false,
  DATA_CRIACAO: '',
  DIAS_SEMANA: '',
  HORA_BASE_FINAL: '',
  HORA_BASE_INICIAL: '',
  ID: -1,
  Id_EmpresasFilial: -1,
  NOME: '',
  QTD_DIAS: 0,
  Revisoes: [],
  type: 'sem',
};

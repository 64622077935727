import styled from 'styled-components';

interface ContainerProps {
  className?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${props => props.theme.colors.whitePrimary};
    text-align: center;
    background: ${props => props.theme.colors.redPrimary};
    padding: 10px;
    border-radius: 14px;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);

    &::before {
      content: '';
      border-style: solid;
      border-color: ${props => props.theme.colors.redPrimary} transparent;
      border-width: 6px 6px 0 6px;
      bottom: 20px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;

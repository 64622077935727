import React, { useCallback, useEffect, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import QrCode from 'qrcode';
import QRCode from 'qrcode.react';
import domtoimage from 'dom-to-image-more';
import Button from '../Button';
import { Loading } from '../Loading';
import ImagemDeFundoLimpeza from '../../assets/backgroundPdf.png';

import {
  Container,
  ContainerHeaderDados,
  ContainerButton,
  ContainerHtmlToImage,
  ContainerCanvas,
} from './styles';
import { AmbienteGet } from '../../models/Ambiente';
import { baseUrlWeb } from '../../services/api';

Modal.setAppElement('#root');

interface ModalQrCode {
  isOpen: boolean;
  dados: number;
  dadosGeral?: AmbienteGet;
  onRequestClose: () => void;
}

const ModalQrCode: React.FC<ModalQrCode> = ({
  isOpen,
  dados,
  dadosGeral,
  onRequestClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [dadosTarefas, setDadosTarefas] = useState('');
  const [downloadImage, setDownloadImage] = useState(false);

  const handleDownloadPDF = useCallback(() => {
    try {
      setLoading(true);

      QrCode.toDataURL(`${dadosTarefas}`)
        .then(url => {
          setLoading(false);

          const doc = new jsPDF('p', 'mm', 'a4'); // eslint-disable-line
          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();

          const img = new Image();
          img.src = ImagemDeFundoLimpeza;
          doc.addImage(img, 'PNG', 0, 0, width, height);

          doc.setFontSize(22);

          // margin-left, margin-top, largura, altura
          doc.addImage(url, 'PNG', 56, 85, 100, 100);
          const quebraLinha = doc.splitTextToSize(
            `${dadosGeral?.AMBIENTE_NOME}`,
            110,
          );
          doc.text(quebraLinha, 105, 200, {
            maxWidth: 110,
            align: 'center',
          });

          doc.autoPrint();
          // doc.output('dataurlnewwindow');
          doc.save(`QRCODE - ${dadosGeral?.AMBIENTE_NOME}`);
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, [dadosGeral, dadosTarefas]);

  useEffect(() => {
    setDadosTarefas(JSON.stringify(dados));
  }, [dados, dadosGeral]);

  const exportQRCode = useCallback(
    (imgType: string) => {
      const generator = document.getElementById('htmltoimage');
      const options = {
        quality: 1,
      };

      if (generator) {
        switch (imgType) {
          case 'jpeg':
            domtoimage.toJpeg(generator, options).then((canvas: string) => {
              const link = document.createElement('a');
              link.download = `QRCODE-${dadosGeral?.ID}.${imgType}`;
              link.href = canvas;
              link.click();
            });
            break;
          case 'png':
            domtoimage.toPng(generator, options).then((canvas: string) => {
              const link = document.createElement('a');
              link.download = `QRCODE-${dadosGeral?.ID}.${imgType}`;
              link.href = canvas;
              link.click();
            });
            break;
          case 'svg':
            domtoimage.toSvg(generator, options).then((canvas: string) => {
              const link = document.createElement('a');
              link.download = `QRCODE-${dadosGeral?.ID}.${imgType}`;
              link.href = canvas;
              link.click();
            });
            break;
          default:
            break;
        }
      }
    },
    [dadosGeral],
  );
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onRequestClose()}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container
          widthModal="1000px"
          heigthModal="700px"
          imageStatus={downloadImage}
        >
          <button
            type="button"
            onClick={() => onRequestClose()}
            className="react-modal-close"
          >
            <AiOutlineClose size={22} />
          </button>

          <ContainerHtmlToImage imageStatus={downloadImage}>
            <div id="htmltoimage">
              <QRCode value={`${dadosTarefas}`} />
              <p>{dadosGeral?.AMBIENTE_NOME}</p>
            </div>
          </ContainerHtmlToImage>

          <ContainerCanvas imageStatus={downloadImage}>
            <QRCode value={`${dadosTarefas}`} />
          </ContainerCanvas>

          <ContainerHeaderDados imageStatus={downloadImage}>
            <p className="titulo">{dadosGeral?.AMBIENTE_NOME}</p>
          </ContainerHeaderDados>

          {!downloadImage ? (
            <ContainerButton>
              <Button
                type="button"
                className="divButtonBack"
                onClick={() => handleDownloadPDF()}
              >
                PDF
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setDownloadImage(true);
                }}
              >
                Imagem
              </Button>
            </ContainerButton>
          ) : (
            <ContainerButton>
              <Button
                type="button"
                className="divButtonBack"
                onClick={() => {
                  setDownloadImage(false);
                }}
              >
                Voltar
              </Button>
              <Button
                type="button"
                onClick={() => {
                  exportQRCode('jpeg');
                }}
              >
                JPEG
              </Button>
              <Button
                type="button"
                onClick={() => {
                  exportQRCode('png');
                }}
              >
                PNG
              </Button>
              <Button
                type="button"
                onClick={() => {
                  exportQRCode('svg');
                }}
              >
                SVG
              </Button>
            </ContainerButton>
          )}
        </Container>
      </Modal>

      {loading && <Loading />}
    </>
  );
};

export default ModalQrCode;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import Modal from 'react-modal';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { EmpresaPincipal } from '../../models/Empresa';
import { QuadroAuditoria } from '../../models/QuadroAuditoria';
import { QuadrosSel } from '../../pages/Cadastros/EmpresaPrincipal';
import { InputOverview } from '../InputOverview';
import Button from '../Button';
import { Loading } from '../Loading';

import getValidationErrors from '../../utils/getValidationErrors';
import { Container, DivAtiv, TableDiv } from './styles';

Modal.setAppElement('#root');

interface DataForm {
  bairro: string;
  cidade: string;
  cnpj: string;
  endereco: string;
  max_func: string;
  nome: string;
  numero: string;
}

interface Props {
  isVisible: boolean;
  onClose(): void;
  editEmpresa: EmpresaPincipal;
  quadros: QuadrosSel[];
}

export const ModalEditEmpresaP: React.FC<Props> = ({
  isVisible,
  onClose,
  editEmpresa,
  quadros,
}) => {
  const { colors } = useContext(ThemeContext);
  const { addToast } = useToast();
  const { go } = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [nome, setNome] = useState('');
  const [CNPJ, setCNPJ] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [isAtive, setIsAtive] = useState(false);
  const [numberFunc, setNumberFunc] = useState('');
  const [quadrosAuditoria, setQuadrosAuditoria] = useState<QuadrosSel[]>([]);
  const [loading, setLoading] = useState(false);

  const getQuadrosAuditoria = useCallback(() => {
    setLoading(true);
    api
      .get(`/empresas/quadros?companyId=${editEmpresa.ID}`)
      .then(resp => {
        setLoading(false);
        const aux: QuadroAuditoria[] = resp.data;

        const list: QuadrosSel[] = quadros.map(item => ({
          ...item,
          select: !!aux.find(i => i.ID === item.ID),
        }));

        setQuadrosAuditoria(list);
      })
      .catch(err => {
        setLoading(false);
        console.log(err.message);
      });
  }, [editEmpresa.ID, quadros]);

  useEffect(() => {
    setNome(editEmpresa.NOME);
    setCNPJ(editEmpresa.CNPJ);
    setEndereco(editEmpresa.ENDERECO);
    setNumero(editEmpresa.NUMERO);
    setBairro(editEmpresa.BAIRRO);
    setCidade(editEmpresa.CIDADE);
    setIsAtive(editEmpresa.ATIVO);
    setNumberFunc(`${editEmpresa.max_number_users}`);
    getQuadrosAuditoria();
  }, [
    editEmpresa.ATIVO,
    editEmpresa.BAIRRO,
    editEmpresa.CIDADE,
    editEmpresa.CNPJ,
    editEmpresa.ENDERECO,
    editEmpresa.NOME,
    editEmpresa.NUMERO,
    editEmpresa.max_number_users,
    getQuadrosAuditoria,
  ]);

  const handleSubmit = useCallback(
    async (data: DataForm) => {
      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          cnpj: Yup.string().required('CNPJ obrigatório'),
          max_func: Yup.string().required(
            'Número de funcionários é obrigatório',
          ),
        });
        setLoading(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        const quadrosEmp = quadrosAuditoria
          .filter(i => i.select)
          .map(i => i.ID);
        if (quadrosEmp.length === 0) {
          addToast({
            type: 'error',
            title: 'É necessário pelo menos um quadro',
          });
          setLoading(false);
          return;
        }

        const obj = {
          id: editEmpresa.ID,
          quadroIds: quadrosEmp,
          ativo: isAtive,
          bairro: data.bairro,
          cidade: data.cidade,
          cnpj: data.cnpj.replace(/\D+/g, ''),
          endereco: data.endereco,
          nome: data.nome,
          numero: data.numero,
          max_number_users: Number(data.max_func),
        };

        api
          .put('/empresas', obj)
          .then(() => {
            setLoading(false);
            // console.log(resp.data);
            addToast({
              title: 'Sucesso!',
              description: `A empresa foi editada com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          })
          .catch(err => {
            setLoading(false);
            console.log(err.message);
            addToast({
              type: 'error',
              title: 'Erro na Edição',
              description: err.message,
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao Editar',
          description:
            'Ocorreu um erro ao fazer a edição, por favor verifique os campos.',
        });
      }
    },
    [addToast, editEmpresa.ID, go, isAtive, quadrosAuditoria],
  );

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Container>
        <header>
          <h1>Editar Empresa</h1>
        </header>
        <h2 style={{ color: colors.grayBlack, textAlign: 'center' }}>
          {editEmpresa.NOME}
        </h2>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <DivAtiv>
            <p>Ativo</p>
            <div className="action">
              <button type="button" onClick={() => setIsAtive(false)}>
                <FiXCircle
                  color={isAtive ? colors.grayBlack : colors.redPrimary}
                />
              </button>
              <button type="button" onClick={() => setIsAtive(true)}>
                <FiCheckCircle
                  color={!isAtive ? colors.grayBlack : colors.greenPrimary}
                />
              </button>
            </div>
          </DivAtiv>
          <p>Nome</p>
          <InputOverview name="nome" value={nome} onValue={e => setNome(e)} />
          <p>CNPJ</p>
          <InputOverview
            name="cnpj"
            mask="cnpj"
            value={CNPJ}
            onValue={e => setCNPJ(e)}
          />
          <p>Endereço</p>
          <InputOverview
            name="endereco"
            value={endereco}
            onValue={e => setEndereco(e)}
          />
          <p>Número</p>
          <InputOverview
            name="numero"
            type="number"
            value={numero}
            onValue={e => setNumero(e)}
          />
          <p>Bairro</p>
          <InputOverview
            name="bairro"
            value={bairro}
            onValue={e => setBairro(e)}
          />
          <p>Cidade</p>
          <InputOverview
            name="cidade"
            value={cidade}
            onValue={e => setCidade(e)}
          />
          <p>Número máximo de funcionários</p>
          <InputOverview
            name="max_func"
            type="number"
            placeholder="50"
            value={numberFunc}
            onValue={e => setNumberFunc(e)}
          />

          <p>Quadros de Auditoria</p>
          <TableDiv>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {quadrosAuditoria.map((item, i) => (
                <tr key={`${i.toString()}`}>
                  <td>{item.label}</td>
                  <td className="action">
                    <button
                      type="button"
                      onClick={() => {
                        const aux: QuadrosSel[] = quadrosAuditoria.map(
                          (q, index) =>
                            index === i ? { ...q, select: false } : q,
                        );
                        setQuadrosAuditoria(aux);
                      }}
                    >
                      <FiXCircle
                        color={
                          item.select ? colors.grayBlack : colors.redPrimary
                        }
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const aux: QuadrosSel[] = quadrosAuditoria.map(
                          (q, index) =>
                            index === i ? { ...q, select: true } : q,
                        );
                        setQuadrosAuditoria(aux);
                      }}
                    >
                      <FiCheckCircle
                        color={
                          !item.select ? colors.grayBlack : colors.greenPrimary
                        }
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableDiv>

          <Button type="submit" widthProps="100%">
            Cadastrar
          </Button>
        </Form>
      </Container>
      {loading && <Loading />}
    </Modal>
  );
};

import { shade } from 'polished';
import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  widthProps?: string;
  isDisable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.widthProps};

  background: ${props =>
    props.isDisable
      ? shade(0.05, props.theme.colors.whitePrimary)
      : props.theme.colors.whitePrimary};
  border: 1px solid ${props => props.theme.colors.grayWhite};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 16px;

  display: flex;
  align-items: center;

  & + p {
    margin-top: 18px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.redPrimary};
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 1px solid ${props.theme.colors.bluePrimary};
    `}

  select {
    background: ${props =>
      props.isDisable
        ? shade(0.05, props.theme.colors.whitePrimary)
        : props.theme.colors.whitePrimary};
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    border: none;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${props => props.theme.colors.textBlack};

    &::placeholder {
      color: ${props => props.theme.colors.grayBlack};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.colors.whitePrimary}
      inset;
  }

  select:-webkit-autofill {
    -webkit-text-fill-color: ${props =>
      props.theme.colors.grayBlack} !important;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    width: 20px;
    height: 20px;
    margin: 0;
    color: ${props => props.theme.colors.redPrimary};
  }

  span {
    background: ${props => props.theme.colors.redPrimary};
    color: ${props => props.theme.colors.whitePrimary};

    &::before {
      border-color: ${props => props.theme.colors.redPrimary} transparent;
    }
  }
`;

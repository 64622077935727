import styled from 'styled-components';

export const ContainerModal = styled.div`
  background: ${props => props.theme.colors.backgroundWhite};
  border-radius: 16px;
  position: relative;
`;

export const ContainerHeader = styled.div`
  margin-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1152px) {
    flex-direction: column;
  }

  img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background: ${props => props.theme.colors.whitePrimary};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    object-fit: cover;

    @media screen and (max-width: 1152px) {
      width: 128px;
      height: 128px;
    }
  }

  ul {
    margin-left: 32px;

    @media screen and (max-width: 1152px) {
      margin: 20px 0 0 0;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 27px;

      @media screen and (max-width: 1152px) {
        font-size: 16px;
        line-height: 20px;
      }

      & + li {
        margin-top: 10px;
      }

      display: flex;
      align-items: flex-start;
      justify-content: center;

      strong {
        font-weight: 600;
        color: ${props => props.theme.colors.textBlack};

        @media screen and (max-width: 1152px) {
          margin-bottom: 12px;
        }
      }

      span {
        font-weight: normal;
        color: ${props => props.theme.colors.textBlack};
        margin-left: 12px;

        a {
          margin-left: 12px;
        }
      }
    }
  }
`;

export const ContainerHeaderDados = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${props => props.theme.colors.textBlack};
`;

export const ContainerButtonAtualizar = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;
`;

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Modal from 'react-modal';
import jsPDF from 'jspdf';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { animated } from '@react-spring/web';
import * as XLSX from 'xlsx';

import Select from 'react-select';
import { Tooltip, useMediaQuery, Zoom } from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaCalendar, FaFont, FaSearch, FaTimes } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';
import moment from 'moment';
import { RiFileExcel2Line } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import Search from '../../components/Search';
import {
  ContainerModal,
  Container,
  ButtonFiltro,
  ContainerTitle,
  SemDados,
  HeaderDate,
} from './styles';
import api from '../../services/api';
import { EmpresasInterface } from '../Cadastros/Periodicidade';
import { AmbienteGet } from '../../models/Ambiente';
import { Categoria } from '../../models/Categoria';
import { Setor } from '../../models/Setor';
import { ResponseGetUsuario } from '../Cadastros/Agendamentos';
import IconFilter from '../../assets/IconFilter.svg';
import { ContainerSearch } from '../Inicio/DashboardHistAgenda/styles';
import { Procedure } from '../../models/Procedures';
import { QuadroAuditoria } from '../../models/QuadroAuditoria';
import { Ambientes } from './interfaces';
// import { dataOff } from './data';

interface SelectProps {
  label: string;
  value: number | string;
}
interface SignUpFormData {
  empresas: any;
  ambientes: any;
  setores: any;
  categorias: any;
  colaboradores: any;
  procedimentos: any;
}

export interface CategoriasInterface {
  ID_CATEGORIA: number;
  DESCRICAO: string;
  EMPRESA_ID: number;
}

export interface ProcedimentoInterface {
  ID_PROCEDIMENTO: number;
  NAME_PROCEDIMENTO: string;
  EMPRESA_ID: number;
}

export interface SetoresInterface {
  id: number;
  name: string;
  EMPRESA_ID: number;
}

export function uniqueBy<T>(objects: T[], uniqueKey: keyof T): T[] {
  const ids = objects.map(object => object[uniqueKey]);
  return objects.filter(
    (object, index) => !ids.includes(object[uniqueKey], index + 1),
  );
}

interface UserProps {
  id: number;
  nome: string;
  email: string;
  dataNascimento: string;
  cpf: string;
  telefone: string;
  companyId: number;
  ativo: number;
  coordenador: number;
  avatar: any;
  tipo: string;
  createdAt: string;
  updatedAt: string;
}
interface EmpresaProps {
  ID: number;
  NOME: string;
  Id_Empresas: number;
  ENDERECO: string;
  CNPJ: string;
  NUMERO: string;
  ATIVO: boolean;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: any;
}

interface AgendaProps {
  ID: number;
  Id_Environments: number;
  Id_Periodicidade: number;
  Id_Users: number;
  Id_EmpresasFilial: number;
  FINALIZADO: any;
  DATA_FINALIZACAO: any;
  ATIVO: boolean;
  revisionPhotos: null | boolean;
  vigIni: string;
  vigFim: string;
  Empresa?: EmpresaProps;
  Environment: {
    id: number;
    name: string;
    categoriaId: number;
    companyId: number;
    setorId: number;
    diasLimpezaId: any;
    qtdLimpezaDia: any;
    andar: string;
    createdAt: string;
    updatedAt: string;
    Revisao: any;
    HORA_INICIAL: any;
    HORA_FINAL: any;
    IMAGEM: any;
    Categoria: {
      id: number;
      name: string;
      companyId: number;
      createdAt: string;
      updatedAt: string;
    };
    Procedures: {
      id: number;
      name: string;
      companyId: number;
      image: any;
      imageUrl: any;
      createdAt: string;
      updatedAt: string;
    }[];
    proceduresOptions: {
      environmentId: number;
      procedureId: number;
      mandatory: boolean;
    }[];
    Setor: {
      id: number;
      name: string;
      companyId: number;
      createdAt: string;
      updatedAt: string;
    };
  };
  Periodicidade: {
    ID: number;
    NOME: string;
    DIAS_SEMANA: string;
    QTD_DIAS: number | null;
    HORA_BASE_INICIAL: string;
    HORA_BASE_FINAL: string;
    Id_EmpresasFilial: number;
    ATIVO: boolean;
    DATA_CRIACAO: string;
    Revisoes: {
      ID: number;
      Id_Periodicidades: number;
      HORA_INICIAL: string;
      HORA_FINAL: string;
      Id_EmpresasFilial: number;
      ATIVO: boolean;
      DATA_CRIACAO: string;
    }[];
  };
}

interface HistoricProps {
  agendamentoId: number;
  createdAt: string;
  id: number;
  image: string | null;
  imagem_pre_limpeza: string | null;
  observacao: string | null;
  ocorrencia: string | null;
  revisionId: number | null;
  tasksId: number;
  updatedAt: string;
  userId: number;
}

interface DadosGraficos {
  id: number;
  companyId: number;
  agendaId: number;
  userId: number;
  Agenda: AgendaProps;
  User: UserProps;
  Historic: HistoricProps[];
  // Empresa: EmpresaProps;
  dataAgend: string;
  horaIni: string;
  horaFim: string;
  status: string | null;
  viraNoite: boolean;
  active: boolean;
  justificativa: string | null;
  startDate: string | null;
  createdAt: string;
  updatedAt: string;
}

interface DadosGraficosFuncionariosEmAberto {
  id: string;
  value: number | 0;
  companyId: number;
  userId: number;
  Agenda?: AgendaProps[];
  Procedure?: Procedure[];
  status?: string | null;
}

export const Auditoria: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const isDesktop = useMediaQuery('(min-width:1150px) and (max-width:1500px)');

  const [modalOpen, setModalOpen] = useState(false);
  const [filtroPorData, setFiltroPorData] = useState(false);

  const [loadingColaboradores, setLoadingColaboradores] = useState(false);
  const [loadingAmbientes, setLoadingAmbientes] = useState(false);
  const [loadingDadosGerais, setLoadingDadosGerais] = useState(false);
  const [loadingDados, setLoadingDados] = useState(false);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  const [empresas, setEmpresas] = useState<
    { value: number; label: string }[]
  >();
  const [ambientes, setAmbientes] = useState<Ambientes[]>([]);
  const [categorias, setCategorias] = useState<any>();
  const [procedimentos, setProcedimentos] = useState<any>();
  const [setores, setSetores] = useState<any>();
  const [colaboradores, setColaboradores] = useState<any>();

  const [ambientesHook, setAmbientesHook] = useState<any>();
  const [categoriasHook, setCategoriasHook] = useState<any>();
  const [setoresHook, setSetoresHook] = useState<any>();
  const [procedimentosHook, setProcedimentosHook] = useState<any>();
  const [colaboradoresHook, setColaboradoresHook] = useState<any>();

  // Form

  const [empresasForm, setEmpresasForm] = useState<any | null>(null);
  const [ambientesForm, setAmbientesForm] = useState<any | null>(null);
  const [categoriasForm, setCategoriasForm] = useState<any | null>(null);
  const [procedimentosForm, setProcedimentosForm] = useState<any | null>(null);
  const [setoresForm, setSetoresForm] = useState<any | null>(null);
  const [colaboradoresForm, setColaboradoresForm] = useState<any | null>(null);

  const [searchDataInicial, setSearchDataInicial] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [searchDataFinal, setSearchDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [rangeDataInicial, setRangeDataInicial] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [rangeDataFinal, setRangeDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );
  // Graficos

  const [fontSizeGraph, setFontSizeGraph] = useState(11);
  const [dadosGraficos, setDadosGraficos] = useState<DadosGraficos[]>();
  // const [quadros, setQuadros] = useState<QuadroAuditoria[]>([]);
  // const [quadrosEmp, setQuadrosEmp] = useState<QuadroAuditoria[]>([]);

  const [showAgendaStatus, setShowAgendaStatus] = useState(false);
  const [dadosAgendamentosStatus, setDadosAgendamentosStatus] = useState<any[]>(
    [],
  );
  const [showFuncionarioEmAberto, setShowFuncionarioEmAberto] = useState(false);
  const [dadosFuncionariosEmAberto, setDadosFuncionariosEmAberto] = useState<
    DadosGraficosFuncionariosEmAberto[]
  >([]);
  const [showCNPJEmAberto, setShowCNPJEmAberto] = useState(false);
  const [dadosCPNJEmAberto, setDadosCPNJEmAberto] = useState<any[]>([]);
  const [showDemandaMaisTempo, setShowDemandaMaisTempo] = useState(false);
  const [dadosDemandaMaisTempo, setDadosDemandaMaisTempo] = useState<any[]>([]);
  const [showAtividadeMedia, setShowAtividadeMedia] = useState(false);
  const [dadosAtividadeMedia, setDadosAtividadeMedia] = useState<any[]>([]);
  const [showPrevistoXRealizado, setShowPrevistoXRealizado] = useState(false);
  const [dadosPrevistoXRealizado, setDadosPrevistoXRealizado] = useState<any[]>(
    [],
  );
  const [showUtilizacaoApp, setShowUtilizacaoApp] = useState(false);
  const [dadosUtilizacaoApp, setDadosUtilizacaoApp] = useState<any[]>([]);
  const [showFuncAtividades, setShowFuncAtividades] = useState(false);
  const [dadosFuncAtividades, setDadosFuncAtividades] = useState<any[]>([]);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  useEffect(() => {
    document.querySelectorAll('text').forEach(a => {
      // eslint-disable-next-line no-param-reassign
      a.style.fontSize = `${fontSizeGraph}px`;
    });
  }, [fontSizeGraph]);

  const renderMouth = useCallback((mouth: number): string => {
    switch (mouth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return '';
    }
  }, []);

  const dateRender = useMemo(() => {
    const [yearStart, mouthStart, dayStart] = rangeDataInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = rangeDataFinal.split('-');
    if (rangeDataInicial === rangeDataFinal)
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))} de ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )} de ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )} de ${yearEnd}`;
  }, [renderMouth, rangeDataFinal, rangeDataInicial]);

  const differenceTime = useCallback((dado: string, dado2: string): number => {
    if (dado && dado2) {
      const diff = moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss'),
      );
      return diff;
    }
    return 0;
  }, []);

  const filterGraphs = (data: SignUpFormData): any[] => {
    let dadosGraficosTemp = dadosGraficos ?? [];
    dadosGraficosTemp = dadosGraficosTemp.map(i => {
      const findEmp = empresas?.find(emp => emp.value === i.companyId);
      return { ...i, Empresa: { NOME: findEmp ? findEmp.label : '' } };
    });

    Object.keys(data).forEach(dataElemen => {
      if (dataElemen === 'empresas' && data.empresas?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          data.empresas
            ?.map(({ value }: SelectProps) => value)
            .includes(dado.Agenda.Id_EmpresasFilial),
        );
      else if (dataElemen === 'colaboradores' && data.colaboradores?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          data.colaboradores
            ?.map(({ value }: SelectProps) => value)
            .includes(dado.userId),
        );
      else if (dataElemen === 'ambientes' && data.ambientes?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          data.ambientes
            ?.map(({ value }: SelectProps) => value)
            .includes(dado.Agenda.Id_Environments),
        );
      else if (dataElemen === 'categorias' && data.categorias?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          data.categorias
            ?.map(({ value }: SelectProps) => value)
            .includes(dado.Agenda.Environment.categoriaId),
        );
      else if (dataElemen === 'setores' && data.setores?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          data.setores
            ?.map(({ value }: SelectProps) => value)
            .includes(dado.Agenda.Environment.setorId),
        );
      else if (dataElemen === 'procedimentos' && data.procedimentos?.length > 0)
        dadosGraficosTemp = dadosGraficosTemp.filter(dado =>
          dado.Agenda.Environment.Procedures.map(
            (i: any) => i.id,
          ).some((a: any) =>
            data.procedimentos
              ?.map(({ value }: SelectProps) => value)
              .includes(a),
          ),
        );
    });
    return dadosGraficosTemp;
  };

  const resetDadosAgendamentoStatus = useCallback(
    (dadosGraficosTemp?: any[]) => {
      const agedamentosStatus =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.status || 'A fazer']: [
              ...(groups[items.status || 'A fazer'] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.status || 'A fazer']: [
              ...(groups[items.status || 'A fazer'] || []),
              items,
            ],
          }),
          {},
        );

      const dadosTemp = [
        {
          id: 'A fazer',
          value: agedamentosStatus
            ? agedamentosStatus['A fazer']?.length || 0
            : 0,
        },
        {
          id: 'Andamento',
          value: agedamentosStatus?.Andamento?.length || 0,
        },
        {
          id: 'Completo',
          value: agedamentosStatus?.Completo?.length || 0,
        },
        {
          id: 'Incompleto',
          value: agedamentosStatus?.Incompleto?.length || 0,
        },
      ];
      setDadosAgendamentosStatus(
        dadosTemp.sort((a, b) => b.value - a.value).filter(a => a.value !== 0),
      );
    },
    [dadosGraficos],
  );

  const resetDadosFuncionarios = useCallback(
    (dadosGraficosTemp?: any[]) => {
      const funcionariosEmAberto =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        );
      const dadosTemp: any[] = [];

      Object.keys(funcionariosEmAberto)?.forEach(field => {
        dadosTemp.push({
          id: field,
          value:
            funcionariosEmAberto[field].filter(
              (val: DadosGraficos) => val.status !== 'Completo',
              // (val: DadosGraficos) => val.status === 'Andamento',
            ).length || 0,
          companyId: funcionariosEmAberto[field][0]?.companyId,
          userId: funcionariosEmAberto[field][0]?.userId,
        });
      });
      setDadosFuncionariosEmAberto(
        dadosTemp
          .sort((a, b) => b.value - a.value)
          .filter(a => a.value !== 0)
          .slice(0, 10),
      );
    },
    [dadosGraficos],
  );
  const resetDadosCNPJ = useCallback(
    (dadosGraficosTemp?: any[]) => {
      const cnpjEmAberto =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.Empresa.NOME]: [
              ...(groups[items.Empresa.NOME] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos
          ?.map(i => {
            const findEmp = empresas?.find(emp => emp.value === i.companyId);
            return { ...i, Empresa: { NOME: findEmp ? findEmp.label : '' } };
          })
          .reduce(
            (groups: any, items) => ({
              ...groups,
              [items.Empresa.NOME]: [
                ...(groups[items.Empresa.NOME] || []),
                items,
              ],
            }),
            {},
          );
      const dadosTemp: any[] = [];

      Object.keys(cnpjEmAberto)?.forEach(field => {
        dadosTemp.push({
          id: field,
          aFazer:
            cnpjEmAberto[field].filter(
              (val: DadosGraficos) => val.status === null,
            ).length || 0,
          andamento:
            cnpjEmAberto[field].filter(
              (val: DadosGraficos) => val.status === 'Andamento',
            ).length || 0,
          incompleto:
            cnpjEmAberto[field].filter(
              (val: DadosGraficos) => val.status === 'Incompleto',
            ).length || 0,
          companyId: cnpjEmAberto[field][0]?.companyId,
        });
      });
      setDadosCPNJEmAberto(dadosTemp);
    },
    [dadosGraficos, empresas],
  );

  const resetDadosDemandaMaisTempo = useCallback(
    (dadosGraficosTemp?: any[]) => {
      const dadosDemandaMT =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.Agenda.Environment.name]: [
              ...(groups[items.Agenda.Environment.name] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.Agenda.Environment.name]: [
              ...(groups[items.Agenda.Environment.name] || []),
              items,
            ],
          }),
          {},
        );
      const dadosTemp: any[] = [];
      let demandaTemp: any[] = [];
      Object.keys(dadosDemandaMT)?.forEach(field => {
        demandaTemp = dadosDemandaMT[field]
          .filter((val: DadosGraficos) => val.status === 'Completo')
          .filter((a: DadosGraficos) => a.Historic.length > 0);
        dadosTemp.push({
          id: field,
          value: demandaTemp.map((val: DadosGraficos) =>
            differenceTime(
              val.Historic[0].createdAt,
              val.Historic[0].updatedAt,
            ),
          ),
        });
      });

      setDadosDemandaMaisTempo(
        dadosTemp
          .map((dados: any) => ({
            ...dados,
            value:
              dados.value.length > 0
                ? dados.value.reduce(
                    (a: number, b: number) =>
                      Math.max(
                        a >= 86400000 ? 86400000 : a,
                        b >= 86400000 ? 86400000 : b,
                      ),
                    0,
                  )
                : 0,
          }))
          .sort((a, b) => b.value - a.value)
          .filter(a => a.value !== 0)
          .slice(0, 10),
      );
      setDadosAtividadeMedia(
        dadosTemp
          .map((dados: any) => ({
            ...dados,
            value:
              dados.value.length > 0
                ? Math.round(
                    ((dados.value.reduce(
                      (a: number, b: number) =>
                        (a >= 86400000 ? 86400000 : a) +
                        (b >= 86400000 ? 86400000 : b),
                      0,
                    ) /
                      dados.value.length) *
                      100) /
                      100,
                  ).toFixed(0)
                : 0,
          }))
          .sort((a, b) => b.value - a.value)
          .filter(a => a.value !== 0 && a.value !== '0')
          .slice(0, 10),
      );
    },
    [dadosGraficos, differenceTime],
  );

  const resetDadosPrevistoXRealizado = useCallback(
    (dadosGraficosTemp?: any[]) => {
      const previstoXRealizado =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        );
      const dadosTemp: any[] = [];

      Object.keys(previstoXRealizado)?.forEach(field => {
        const dadosTp = previstoXRealizado[field];
        const previstoPrincipal = dadosTp.map((val: DadosGraficos) =>
          differenceTime(
            `${val.dataAgend.slice(0, 10)}T${val.horaIni}`,
            `${val.dataAgend.slice(0, 10)}T${val.horaFim}`,
          ),
        );
        const previstoRevisao = dadosTp.map((val: DadosGraficos) => {
          return val.Agenda.Periodicidade.Revisoes.length > 0
            ? val.Agenda.Periodicidade.Revisoes.map(rev =>
                differenceTime(
                  `${val.dataAgend.slice(0, 10)}T${rev.HORA_INICIAL}`,
                  `${val.dataAgend.slice(0, 10)}T${rev.HORA_FINAL}`,
                ),
              )
            : 0;
        });

        const realizadoTp = dadosTp
          .map((rev: DadosGraficos) => ({
            ...rev,
            Historic: uniqueBy(rev.Historic, 'createdAt'),
          }))
          .filter((item: any) => item.Historic.length > 0)
          .map((val: DadosGraficos) =>
            val.Historic.map(hist =>
              differenceTime(hist.createdAt, hist.updatedAt),
            ),
          )
          .flat();
        dadosTemp.push({
          id: field,
          previsto: [
            ...previstoPrincipal,
            ...previstoRevisao.flat().filter((a: number) => a !== 0),
          ],
          realizado: realizadoTp,
        });
      });
      let previstoTp;
      let realizadoTp;
      setDadosPrevistoXRealizado(
        dadosTemp
          .map((dados: any) => {
            previstoTp =
              dados.previsto.length > 0
                ? dados.previsto.reduce((a: number, b: number) => a + b)
                : 0;
            realizadoTp =
              dados.realizado.length > 0
                ? dados.realizado.reduce(
                    (a: number, b: number) =>
                      (a >= 86400000 ? 86400000 : a) +
                      (b >= 86400000 ? 86400000 : b),
                    0,
                  )
                : 0;
            return {
              ...dados,
              previsto: previstoTp,
              realizado: realizadoTp,
            };
          })
          .sort((a, b) => b.realizado - a.realizado)
          .filter(a => a.previsto !== 0 && a.realizado !== 0)
          .slice(0, 8),
      );
    },
    [dadosGraficos, differenceTime],
  );

  const resetDadosUtilizacaoApp = useCallback(
    (dadosGraficosTemp?: any[]) => {
      let dadosTempUtilizacao: any[] = [];
      let dadosTempFunc: any[] = [];
      const utilizacaoDoApp =
        dadosGraficosTemp?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        ) ??
        dadosGraficos?.reduce(
          (groups: any, items) => ({
            ...groups,
            [items.User?.nome || 'Sem nome']: [
              ...(groups[items.User?.nome || 'Sem nome'] || []),
              items,
            ],
          }),
          {},
        );
      Object.keys(utilizacaoDoApp)?.forEach(field => {
        const dadosTp = utilizacaoDoApp[field];

        const dadosFiltCompleto = dadosTp
          .filter((item: any) => item.Historic.length > 0)
          .map((rev: DadosGraficos) => ({
            ...rev,
            Historic: uniqueBy(rev.Historic, 'createdAt').filter(
              a => a.observacao === '',
            ),
          }));

        const dadosFiltExtra = dadosTp
          .map((rev: DadosGraficos) => ({
            ...rev,
            Historic: uniqueBy(rev.Historic, 'createdAt')
              .filter(a => a.observacao === '' && a.revisionId === null)
              .slice(1),
          }))
          .filter((item: any) => item.Historic.length > 0);

        const previstoPrincipal = dadosTp.length;
        const previstoRevisao = dadosTp
          .map((val: DadosGraficos) => val.Agenda.Periodicidade.Revisoes.length)
          .reduce((a: any, b: any) => a + b);

        const totalExtra =
          dadosFiltExtra.length === 0
            ? 0
            : dadosFiltExtra
                .map((val: DadosGraficos) => val.Historic.length)
                .reduce((a: any, b: any) => a + b);

        const realizadoTp =
          dadosFiltCompleto.length === 0
            ? 0
            : dadosFiltCompleto
                .map((val: DadosGraficos) => val.Historic.length)
                .reduce((a: any, b: any) => a + b);

        const totalAtividades =
          previstoPrincipal + previstoRevisao + totalExtra;

        dadosTempUtilizacao.push({
          id: field,
          value: realizadoTp / (totalAtividades === 0 ? 1 : totalAtividades),
        });
        dadosTempFunc.push({
          id: field,
          'Não Realizado': totalAtividades - realizadoTp,
          Completo: realizadoTp,
        });
      });

      if (dadosTempUtilizacao.length > 0) {
        dadosTempUtilizacao = dadosTempUtilizacao
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.id === value.id),
          )
          .sort((a, b) => a.value - b.value)
          .filter(a => a.value !== 0)
          .slice(0, 10);
        setDadosUtilizacaoApp(dadosTempUtilizacao);
      }
      if (dadosTempFunc.length > 0) {
        dadosTempFunc = dadosTempFunc
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.id === value.id),
          )
          .sort(
            (a, b) =>
              a.Completo -
              b.Completo -
              (a['Não Realizado'] - b['Não Realizado']),
          )
          .filter(a => a['Não Realizado'] !== 0 && a.Completo !== 0)
          .slice(0, 10);
        setDadosFuncAtividades(dadosTempFunc);
      }
    },
    [dadosGraficos],
  );

  useEffect(() => {
    if (dadosGraficos && dadosGraficos?.length > 0) {
      resetDadosAgendamentoStatus();
      resetDadosFuncionarios();
      resetDadosCNPJ();
      resetDadosUtilizacaoApp();
      resetDadosDemandaMaisTempo();
      resetDadosPrevistoXRealizado();
    }
  }, [
    dadosGraficos,
    resetDadosAgendamentoStatus,
    resetDadosCNPJ,
    resetDadosDemandaMaisTempo,
    resetDadosFuncionarios,
    resetDadosPrevistoXRealizado,
    resetDadosUtilizacaoApp,
  ]);

  const filterDadosAgendamentoStatus = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosAgendamentoStatus(dadosGraficosTemp);
  };

  const filterDadosDemandaMaisTempo = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosDemandaMaisTempo(dadosGraficosTemp);
  };

  const filterFuncionariosEmAberto = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosFuncionarios(dadosGraficosTemp);
  };

  const filterCNPJEmAberto = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosCNPJ(dadosGraficosTemp);
  };

  const filterDadosPrevistoXRealizado = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosPrevistoXRealizado(dadosGraficosTemp);
  };

  const filterDadosUtilizacaoApp = (data: SignUpFormData): void => {
    const dadosGraficosTemp = filterGraphs(data);
    resetDadosUtilizacaoApp(dadosGraficosTemp);
  };

  const onSubmit = (): void => {
    if (
      !(
        empresasForm?.length > 0 ||
        colaboradoresForm?.length > 0 ||
        ambientesForm?.length > 0 ||
        setoresForm?.length > 0 ||
        categoriasForm?.length > 0 ||
        procedimentosForm?.length > 0
      )
    ) {
      addToast({
        type: 'info',
        title: 'Selecione algum filtro',
        description: 'Favor selecionar pelo menos um filtro',
      });
    } else if (dadosGraficos && dadosGraficos?.length > 0) {
      const dadosSubmit = {
        empresas: empresasForm,
        colaboradores: colaboradoresForm,
        setores: setoresForm,
        ambientes: ambientesForm,
        procedimentos: procedimentosForm,
        categorias: categoriasForm,
      };
      filterDadosDemandaMaisTempo(dadosSubmit);
      filterDadosAgendamentoStatus(dadosSubmit);
      filterDadosPrevistoXRealizado(dadosSubmit);
      filterFuncionariosEmAberto(dadosSubmit);
      filterCNPJEmAberto(dadosSubmit);
      filterDadosUtilizacaoApp(dadosSubmit);
      setModalOpen(false);
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados com esse filtro',
      });
    }
  };

  const getAmbientes = useCallback(() => {
    setLoadingAmbientes(true);
    api
      .get<AmbienteGet[]>(
        `/environments/?id=${empresaPrincipal[0]?.Id_Empresas}`,
      )
      .then(({ data }) => {
        setLoadingAmbientes(false);
        const value = data.map(({ ID, AMBIENTE_NOME: nome, companyId }) => ({
          value: ID,
          label: nome,
          companyId,
        }));
        setAmbientes(value);
      })
      .catch(() => {
        setLoadingAmbientes(false);
      });
  }, [empresaPrincipal]);

  // const getQuadros = useCallback(() => {
  //   api
  //     .get('/quadroPadrao')
  //     .then(resp => {
  //       const aux: QuadroAuditoria[] = resp.data;
  //       // console.log('QUADR', aux);
  //       setQuadros(aux);
  //     })
  //     .catch(err => {
  //       console.log(err.message);
  //     });
  // }, []);

  const getQuadrosAuditoria = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : 0;

    api
      .get(`/empresas/quadros?companyId=${companyId}`)
      .then(resp => {
        const aux: QuadroAuditoria[] = resp.data;
        // setQuadrosEmp(resp.data);
        if (aux.find(i => i.ID === 1)) {
          setShowAgendaStatus(true);
        }
        if (aux.find(i => i.ID === 2)) {
          setShowFuncionarioEmAberto(true);
        }
        if (aux.find(i => i.ID === 3)) {
          setShowCNPJEmAberto(true);
        }
        if (aux.find(i => i.ID === 4)) {
          setShowDemandaMaisTempo(true);
        }
        if (aux.find(i => i.ID === 5)) {
          setShowAtividadeMedia(true);
        }
        if (aux.find(i => i.ID === 6)) {
          setShowPrevistoXRealizado(true);
        }
        if (aux.find(i => i.ID === 7)) {
          setShowUtilizacaoApp(true);
        }
        if (aux.find(i => i.ID === 8)) {
          setShowFuncAtividades(true);
        }
      })
      .catch(err => {
        console.log(err.message);
      });
  }, [empresaPrincipal]);

  const getEmpresas = useCallback(() => {
    setLoadingEmpresas(true);
    api
      .get<EmpresasInterface[]>(`empresas/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingEmpresas(false);
        setEmpresas(
          data.map(({ ID, NOME }) => ({
            value: ID,
            label: NOME,
          })),
        );
      })
      .catch(() => {
        setLoadingEmpresas(false);
      });
  }, [empresaPrincipal]);

  // useEffect(() => {
  //   getQuadros();
  // }, [getQuadros]);

  const getUsuarios = useCallback(() => {
    setLoadingColaboradores(true);
    api
      .get<ResponseGetUsuario[]>(`allUsers/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingColaboradores(false);
        setColaboradores(
          data
            .filter(value => value.coordenador === 0)
            .map(({ id, nome, companyId }) => ({
              value: id,
              label: nome,
              companyId,
            })),
        );
      })
      .catch(() => {
        setLoadingColaboradores(false);
      });
  }, [empresaPrincipal]);

  const getDados = useCallback(() => {
    setLoadingDadosGerais(true);
    api
      .get(`/environments/form/${empresaPrincipal[0]?.Id_Empresas}`)
      .then(({ data }) => {
        setLoadingDadosGerais(false);
        const cat = data.categorias.map(
          ({
            ID_CATEGORIA: ID,
            DESCRICAO: nome,
            EMPRESA_ID: companyId,
          }: any) => ({
            value: ID,
            label: nome,
            companyId,
          }),
        );
        const proced = data.procedimentos.map(
          ({
            ID_PROCEDIMENTO: ID,
            NAME_PROCEDIMENTO: nome,
            EMPRESA_ID: companyId,
          }: any) => ({
            value: ID,
            label: nome,
            companyId,
          }),
        );
        const setor = data.setores.map(
          ({ id, name: nome, EMPRESA_ID: companyId }: any) => ({
            value: id,
            label: nome,
            companyId,
          }),
        );
        setCategorias(cat);
        setSetores(setor);
        setProcedimentos(proced);
      })
      .catch(() => {
        setLoadingDadosGerais(false);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    getDados();
    getEmpresas();
    getAmbientes();
    getQuadrosAuditoria();
    getUsuarios();
  }, [getAmbientes, getDados, getEmpresas, getQuadrosAuditoria, getUsuarios]);

  const getDadosGraficos = useCallback(
    (dateStart: string, dateEnd: string) => {
      // setDadosGraficos(dataOff);
      setLoadingDados(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const filialId = user.companyId;

      const aux = isCoordenador
        ? `filialId=${filialId}`
        : user.roleId != 7 ? `companyId=${companyId}` : `filialClientId=${user.filialClientId}`;

      api
        .get(
          `agendas/v2/Agendamentos/company?dataIni=${dateStart}` +
            `&dataFim=${dateEnd}&${aux}`,
        )
        .then(({ data }) => {
          setRangeDataInicial(dateStart);
          setRangeDataFinal(dateEnd);
          setDadosGraficos(data);
          setLoadingDados(false);
        })
        .catch(() => {
          setLoadingDados(false);
        });
    },
    [empresaPrincipal, isCoordenador, user.companyId],
  );

  useEffect(() => {
    const date = moment().format('YYYY-MM-DD');
    getDadosGraficos(date, date);
  }, [getDadosGraficos]);

  const handleChangeEmpresa = (selectOption: any): void => {
    if (!selectOption.length) {
      setAmbientesHook(ambientes);
      setSetoresHook(setores);
      setCategoriasHook(categorias);
      setProcedimentosHook(procedimentos);
      setColaboradoresHook(colaboradores);
    } else {
      setAmbientesHook(
        ambientes?.filter(val =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setColaboradoresHook(
        colaboradores?.filter((val: ResponseGetUsuario) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setCategoriasHook(
        categorias?.filter((val: Categoria) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setSetoresHook(
        setores?.filter((val: Setor) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
      setProcedimentosHook(
        procedimentos?.filter((val: Setor) =>
          selectOption
            .map(({ value }: SelectProps) => value)
            .includes(val.companyId),
        ),
      );
    }
  };

  const resetForm = (): void => {
    setEmpresasForm(null);
    setAmbientesForm(null);
    setSetoresForm(null);
    setCategoriasForm(null);
    setProcedimentosForm(null);
    setColaboradoresForm(null);
  };

  const resetFilters = (): void => {
    resetForm();
    setAmbientesHook(ambientes);
    setSetoresHook(setores);
    setCategoriasHook(categorias);
    setProcedimentosHook(procedimentos);
    setColaboradoresHook(colaboradores);
    if (dadosGraficos && dadosGraficos?.length > 0) {
      resetDadosDemandaMaisTempo();
      resetDadosAgendamentoStatus();
      resetDadosPrevistoXRealizado();
      resetDadosFuncionarios();
      resetDadosCNPJ();
      resetDadosUtilizacaoApp();
    }
  };

  const verificarDados = (): boolean => {
    if (
      (showAgendaStatus && dadosAgendamentosStatus.length > 0) ||
      (showFuncionarioEmAberto && dadosFuncionariosEmAberto.length > 0) ||
      (showCNPJEmAberto && dadosCPNJEmAberto.length > 0) ||
      (showDemandaMaisTempo && dadosDemandaMaisTempo.length > 0) ||
      (showAtividadeMedia && dadosAtividadeMedia.length > 0) ||
      (showPrevistoXRealizado && dadosPrevistoXRealizado.length > 0) ||
      (showUtilizacaoApp && dadosUtilizacaoApp.length > 0) ||
      (showFuncAtividades && dadosFuncAtividades.length > 0)
    )
      return true;
    return false;
  };

  const downloadExcel = (): void => {
    if (verificarDados()) {
      const workbook = XLSX.utils.book_new();
      if (showAgendaStatus && dadosAgendamentosStatus.length > 0) {
        const wkAgendamentosStatus = XLSX.utils.json_to_sheet(
          dadosAgendamentosStatus.map((val: any) => ({
            STATUS: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkAgendamentosStatus,
          'Ambientes',
        );
      }
      if (showFuncionarioEmAberto && dadosFuncionariosEmAberto.length > 0) {
        const wkFuncionariosEmAberto = XLSX.utils.json_to_sheet(
          dadosFuncionariosEmAberto.map((val: any) => ({
            NOME: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkFuncionariosEmAberto,
          'Atv em aberto por func',
        );
      }
      if (showCNPJEmAberto && dadosCPNJEmAberto.length > 0) {
        const wkCPNJEmAberto = XLSX.utils.json_to_sheet(
          dadosCPNJEmAberto.map((val: any) => ({
            NOME: val.id,
            'A FAZER': val.aFazer,
            ANDAMENTO: val.andamento,
            INCOMPLETO: val.incompleto,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkCPNJEmAberto,
          'Atv em aberto por CNPJ',
        );
      }
      if (showDemandaMaisTempo && dadosDemandaMaisTempo.length > 0) {
        const wkDemandaMaisTempo = XLSX.utils.json_to_sheet(
          dadosDemandaMaisTempo.map((val: any) => ({
            ATIVIDADE: val.id,
            TEMPO: `${Math.floor((val.value ?? 0) / 3600000)}h${Math.floor(
              ((val.value ?? 0) / 60000) % 60,
            )}m${Math.floor((val.value ?? 0) / 600000) % 60}s`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDemandaMaisTempo,
          'Atv que demandam mais tempo',
        );
      }
      if (showAtividadeMedia && dadosAtividadeMedia.length > 0) {
        const wkAtividadeMedia = XLSX.utils.json_to_sheet(
          dadosAtividadeMedia.map((val: any) => ({
            ATIVIDADE: val.id,
            TEMPO: `${Math.floor((val.value ?? 0) / 3600000)}h${Math.floor(
              ((val.value ?? 0) / 60000) % 60,
            )}m${Math.floor((val.value ?? 0) / 600000) % 60}s`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkAtividadeMedia,
          'Tempo médio das atv',
        );
      }
      if (showPrevistoXRealizado && dadosPrevistoXRealizado.length > 0) {
        const wkDadosPrevistoXRealizado = XLSX.utils.json_to_sheet(
          dadosPrevistoXRealizado.map((val: any) => ({
            NOME: val.id,
            PREVISTO: `${Math.floor(
              (val.previsto ?? 0) / 3600000,
            )}h${Math.floor(((val.previsto ?? 0) / 60000) % 60)}m${
              Math.floor((val.value ?? 0) / 600000) % 60
            }s`,
            REALIZADO: `${Math.floor(
              (val.realizado ?? 0) / 3600000,
            )}h${Math.floor(((val.realizado ?? 0) / 60000) % 60)}m${
              Math.floor((val.realizado ?? 0) / 600000) % 60
            }s`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDadosPrevistoXRealizado,
          'Previsto X Realizado',
        );
      }
      if (showUtilizacaoApp && dadosUtilizacaoApp.length > 0) {
        const wkUtilizacaoApp = XLSX.utils.json_to_sheet(
          dadosUtilizacaoApp.map(val => ({
            NOME: val.id,
            PORCENTAGEM: `${(val.value * 100).toFixed(2)}%`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkUtilizacaoApp,
          'Utilizacao do App',
        );
      }
      if (showFuncAtividades && dadosFuncAtividades.length > 0) {
        const wkFuncAtividades = XLSX.utils.json_to_sheet(
          dadosFuncAtividades.map((val: any) => ({
            NOME: val.id,
            REALIZADO: val.Completo,
            'NÃO REALIZADO': val['Não Realizado'],
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkFuncAtividades,
          'Resumo das Atividades',
        );
      }
      XLSX.writeFile(workbook, 'DataSheet.xlsx');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  };

  const handleDownloadPDF = (): void => {
    if (verificarDados()) {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text('Resumo', 103, 20, 'center');
      let posY: number;
      posY = 30;
      if (showAgendaStatus && dadosAgendamentosStatus.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Todas as atividades', 103, 40, 'center');
        dadosAgendamentosStatus.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontSize(14);
          doc.setFontType('normal');
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1 ? 'atividade' : 'atividades'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosAgendamentosStatus.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showFuncionarioEmAberto && dadosFuncionariosEmAberto.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Atividades em aberto por funcionário', 103, posY, 'center');
        dadosFuncionariosEmAberto.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1 ? 'atividade' : 'atividades'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosFuncionariosEmAberto.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showCNPJEmAberto && dadosCPNJEmAberto.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Atividades em aberto por CNPJ', 103, posY, 'center');
        dadosCPNJEmAberto.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.aFazer + res.andamento + res.incompleto} ${
              res.aFazer + res.andamento + res.incompleto === 1
                ? 'atividade'
                : 'atividades'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosCPNJEmAberto.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showDemandaMaisTempo && dadosDemandaMaisTempo.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Atividades que demandam mais tempo', 103, posY, 'center');
        dadosDemandaMaisTempo.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${Math.floor((res.value ?? 0) / 3600000)}h${Math.floor(
              ((res.value ?? 0) / 60000) % 60,
            )}m${Math.floor((res.value ?? 0) / 600000) % 60}s`,
            10,
            posY,
            'left',
          );
          if (index === dadosDemandaMaisTempo.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showAtividadeMedia && dadosAtividadeMedia.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo médio das atividades', 103, posY, 'center');
        dadosAtividadeMedia.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${Math.floor((res.value ?? 0) / 3600000)}h${Math.floor(
              ((res.value ?? 0) / 60000) % 60,
            )}m${Math.floor((res.value ?? 0) / 600000) % 60}s`,
            10,
            posY,
            'left',
          );
          if (index === dadosAtividadeMedia.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showPrevistoXRealizado && dadosPrevistoXRealizado.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Previsto X Realizado', 103, posY, 'center');
        dadosPrevistoXRealizado.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id} - previsto: ${Math.floor(
              (res.previsto ?? 0) / 3600000,
            )}h${Math.floor(((res.previsto ?? 0) / 60000) % 60)}m${
              Math.floor((res.previsto ?? 0) / 600000) % 60
            }s`,
            10,
            posY,
            'left',
          );
          posY += 6;
          doc.text(
            `${res.id} - realizado: ${Math.floor(
              (res.realizado ?? 0) / 3600000,
            )}h${Math.floor(((res.realizado ?? 0) / 60000) % 60)}m${
              Math.floor((res.realizado ?? 0) / 600000) % 60
            }s`,
            10,
            posY,
            'left',
          );
          if (index === dadosPrevistoXRealizado.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showUtilizacaoApp && dadosUtilizacaoApp.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Utilização App', 103, posY, 'center');
        dadosUtilizacaoApp.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id} - porcentagem: ${(res.value * 100).toFixed(2)}%`,
            10,
            posY,
            'left',
          );
          if (index === dadosUtilizacaoApp.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (showFuncAtividades && dadosFuncAtividades.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Resumo de Atividades', 103, posY, 'center');
        dadosFuncAtividades.forEach((res: any, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(`${res.id} - realizado: ${res.Completo}`, 10, posY, 'left');
          posY += 6;
          doc.text(
            `${res.id} - não realizado: ${res['Não Realizado']}`,
            10,
            posY,
            'left',
          );
          if (index === dadosFuncAtividades.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  };

  return (
    <Container>
      <ContainerTitle>
        <header>
          <h1>Auditoria</h1>

          <Tooltip
            title={
              'Nessa página você tem acesso a visualização dos gráficos ' +
              'para fim de análises estratégicas.'
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          {(empresasForm?.length > 0 ||
            colaboradoresForm?.length > 0 ||
            ambientesForm?.length > 0 ||
            setoresForm?.length > 0 ||
            categoriasForm?.length > 0 ||
            procedimentosForm?.length > 0) &&
            !modalOpen && (
              <button
                type="button"
                className="danger"
                title="Remover Filtros"
                onClick={() => resetFilters()}
              >
                <FaTimes color="#B0B0B0" />
              </button>
            )}
          <button
            type="button"
            className="danger"
            title="Diminuir fonte"
            onClick={() => setFontSizeGraph(s => s - 1)}
          >
            <FaFont size="15px" color="#B0B0B0" />
          </button>
          <button
            type="button"
            title="Aumentar fonte"
            onClick={() => setFontSizeGraph(s => s + 1)}
          >
            <FaFont size="20px" color="#B0B0B0" />
          </button>
          {!loadingDados && !loadingDados && (
            <>
              <button
                type="button"
                className="btnsvg"
                onClick={() => setModalOpen(true)}
              >
                <img src={IconFilter} alt="iconFilter" title="Filtrar" />
              </button>
              <button
                type="button"
                className="btnsvg"
                onClick={() => setFiltroPorData(d => !d)}
              >
                <FaCalendar color="#B0B0B0" />
              </button>
            </>
          )}
        </div>
      </ContainerTitle>
      <div className="container">
        {filtroPorData && (
          <ContainerSearch
            className="container-searcher"
            isFilterDateInterval={filtroPorData}
          >
            <Search
              onSearch={(value: string) => {
                setSearchDataInicial(value);
              }}
              nomePlaceHolder="Buscar data inicial"
              type="date"
            />

            <Search
              onSearch={(value: string) => {
                setSearchDataFinal(value);
              }}
              nomePlaceHolder="Buscar data final"
              type="date"
            />
            <button
              type="button"
              onClick={() => {
                getDadosGraficos(searchDataInicial, searchDataFinal);
              }}
            >
              <FaSearch color="#B0B0B0" />
            </button>
          </ContainerSearch>
        )}
        <HeaderDate>
          <h2>{dateRender}</h2>
          <div>
            <button
              title="Exportar para PDF"
              type="button"
              className="danger"
              onClick={() => handleDownloadPDF()}
            >
              <VscFilePdf size={22} color="#fff" />
            </button>
            <button
              title="Exportar para Excel"
              type="button"
              onClick={() => downloadExcel()}
            >
              <RiFileExcel2Line size={22} color="#fff" />
            </button>
          </div>
        </HeaderDate>
        <div className="row">
          {showAgendaStatus && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Ambientes</span>
              {loadingDados && <Loading component />}
              {dadosAgendamentosStatus &&
                dadosAgendamentosStatus.length > 0 &&
                !loadingDados && (
                  <ResponsivePie
                    data={dadosAgendamentosStatus}
                    margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'pastel1' }}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLabelsSkipAngle={10}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    legends={
                      !isDesktop
                        ? [
                            {
                              anchor: 'top-left',
                              direction: 'column',
                              justify: false,
                              translateX: 0,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 25,
                              itemsSpacing: 0,
                              symbolSize: 25,
                              itemDirection: 'left-to-right',
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosAgendamentosStatus?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showFuncionarioEmAberto && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">
                Atividades em aberto por funcionário
              </span>
              {loadingDados && <Loading component />}
              {dadosFuncionariosEmAberto &&
                dadosFuncionariosEmAberto.length > 0 &&
                !loadingDados && (
                  <ResponsivePie
                    data={dadosFuncionariosEmAberto}
                    margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    arcLinkLabelsSkipAngle={10}
                    arcLabelsSkipAngle={10}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'pastel1' }}
                    enableArcLinkLabels={false}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    legends={[
                      {
                        anchor: 'top-left',
                        direction: 'column',
                        justify: false,
                        translateX: -130,
                        translateY: 0,
                        itemWidth: 100,
                        itemHeight: 25,
                        itemsSpacing: 0,
                        symbolSize: 25,
                        itemDirection: 'left-to-right',
                      },
                    ]}
                  />
                )}
              {!dadosFuncionariosEmAberto?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showCNPJEmAberto && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Atividades em aberto por CNPJ</span>
              {loadingDados && <Loading component />}
              {dadosCPNJEmAberto &&
                dadosCPNJEmAberto.length > 0 &&
                !loadingDados && (
                  <ResponsiveBar
                    data={dadosCPNJEmAberto}
                    margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                    borderWidth={1}
                    keys={['aFazer', 'incompleto', 'andamento']}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colorBy="indexValue"
                    colors={{ scheme: 'pastel1' }}
                    axisLeft={null}
                    axisBottom={null}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    label={d => (d.value !== 0 ? `${d.value}` : '')}
                    legends={
                      !isDesktop
                        ? [
                            {
                              dataFrom: 'indexes',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 140,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemWidth: 140,
                              itemHeight: 33,
                              itemDirection: 'bottom-to-top',
                              itemOpacity: 0.85,
                              symbolSize: 7,
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemOpacity: 1,
                                  },
                                },
                              ],
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosCPNJEmAberto?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showDemandaMaisTempo && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">
                Atividades que demandam mais tempo
              </span>
              {loadingDados && <Loading component />}
              {dadosDemandaMaisTempo &&
                dadosDemandaMaisTempo.length > 0 &&
                !loadingDados && (
                  <ResponsivePie
                    data={dadosDemandaMaisTempo}
                    margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                    innerRadius={0.5}
                    colors={{ scheme: 'pastel1' }}
                    arcLinkLabelsSkipAngle={10}
                    arcLabelsSkipAngle={10}
                    arcLabelsComponent={({ label, style }: any) => (
                      <animated.g
                        transform={style.transform}
                        style={{ pointerEvents: 'none' }}
                      >
                        <text
                          textAnchor="middle"
                          dominantBaseline="central"
                          fill={style.textColor}
                          style={{
                            fontSize: 13,
                            color: '#000',
                            fontWeight: 400,
                          }}
                        >
                          <tspan>{label}</tspan>
                          <tspan x={0} y={11}>
                            min
                          </tspan>
                        </text>
                      </animated.g>
                    )}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    sortByValue
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    enableArcLinkLabels={false}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    valueFormat={
                      d =>
                        d !== 0
                          ? `${Math.floor((d ?? 0) / 3600000)}h${Math.floor(
                              ((d ?? 0) / 60000) % 60,
                            )}m`
                          : ''
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    legends={
                      !isDesktop
                        ? [
                            {
                              anchor: 'top-left',
                              direction: 'column',
                              justify: false,
                              translateX: -130,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 25,
                              itemsSpacing: 0,
                              symbolSize: 25,
                              itemDirection: 'left-to-right',
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosDemandaMaisTempo?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showAtividadeMedia && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Tempo médio das atividades</span>
              {loadingDados && <Loading component />}
              {dadosAtividadeMedia &&
                dadosAtividadeMedia.length > 0 &&
                !loadingDados && (
                  <ResponsivePie
                    data={dadosAtividadeMedia}
                    margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                    innerRadius={0.5}
                    colors={{ scheme: 'pastel1' }}
                    arcLinkLabelsSkipAngle={10}
                    arcLabelsSkipAngle={10}
                    arcLabelsComponent={({ label, style }: any) => (
                      <animated.g
                        transform={style.transform}
                        style={{ pointerEvents: 'none' }}
                      >
                        <text
                          textAnchor="middle"
                          dominantBaseline="central"
                          fill={style.textColor}
                          style={{
                            fontSize: 13,
                            color: '#000',
                            fontWeight: 400,
                          }}
                        >
                          <tspan>{label}</tspan>
                          <tspan x={0} y={11}>
                            min
                          </tspan>
                        </text>
                      </animated.g>
                    )}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    sortByValue
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    enableArcLinkLabels={false}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    valueFormat={
                      d =>
                        d !== 0
                          ? `${Math.floor((d ?? 0) / 3600000)}h${Math.floor(
                              ((d ?? 0) / 60000) % 60,
                            )}m`
                          : ''
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    legends={
                      !isDesktop
                        ? [
                            {
                              anchor: 'top-left',
                              direction: 'column',
                              justify: false,
                              translateX: -130,
                              translateY: 0,
                              itemWidth: 100,
                              itemHeight: 25,
                              itemsSpacing: 0,
                              symbolSize: 25,
                              itemDirection: 'left-to-right',
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosAtividadeMedia?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showPrevistoXRealizado && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Tempo Previsto x Realizado</span>
              {loadingDados && <Loading component />}
              {dadosPrevistoXRealizado &&
                dadosPrevistoXRealizado.length > 0 &&
                !loadingDados && (
                  <ResponsiveBar
                    data={dadosPrevistoXRealizado}
                    margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                    borderWidth={2}
                    keys={['previsto', 'realizado']}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'pastel1' }}
                    groupMode="grouped"
                    colorBy="indexValue"
                    axisLeft={null}
                    axisBottom={null}
                    borderRadius={5}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    valueFormat={
                      d =>
                        d !== 0
                          ? `${Math.floor((d ?? 0) / 3600000)}h${Math.floor(
                              ((d ?? 0) / 60000) % 60,
                            )}m`
                          : ''
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    legends={
                      !isDesktop
                        ? [
                            {
                              dataFrom: 'indexes',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 140,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemWidth: 140,
                              itemHeight: 33,
                              itemDirection: 'bottom-to-top',
                              itemOpacity: 0.85,
                              symbolSize: 7,
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemOpacity: 1,
                                  },
                                },
                              ],
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosPrevistoXRealizado?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showUtilizacaoApp && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Utilização do App</span>
              {loadingDados && <Loading component />}
              {dadosUtilizacaoApp &&
                dadosUtilizacaoApp.length > 0 &&
                !loadingDados && (
                  <ResponsiveBar
                    data={dadosUtilizacaoApp}
                    margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                    borderWidth={2}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'pastel1' }}
                    groupMode="grouped"
                    colorBy="indexValue"
                    axisLeft={null}
                    axisBottom={null}
                    borderRadius={5}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    valueFormat={d => `${(d * 100).toFixed(2)}%`}
                    legends={
                      !isDesktop
                        ? [
                            {
                              dataFrom: 'indexes',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 140,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemWidth: 140,
                              itemHeight: 33,
                              itemDirection: 'bottom-to-top',
                              itemOpacity: 0.85,
                              symbolSize: 7,
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemOpacity: 1,
                                  },
                                },
                              ],
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosUtilizacaoApp?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
          {showFuncAtividades && (
            <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
              <span className="text-center">Resumo das Atividades</span>
              {loadingDados && <Loading component />}
              {dadosFuncAtividades &&
                dadosFuncAtividades.length > 0 &&
                !loadingDados && (
                  <ResponsiveBar
                    data={dadosFuncAtividades}
                    margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                    borderWidth={2}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    keys={['Completo', 'Não Realizado']}
                    colors={{ scheme: 'pastel1' }}
                    groupMode="grouped"
                    colorBy="indexValue"
                    axisLeft={null}
                    axisBottom={null}
                    borderRadius={5}
                    defs={[
                      {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    legends={
                      !isDesktop
                        ? [
                            {
                              dataFrom: 'indexes',
                              anchor: 'bottom-right',
                              direction: 'column',
                              justify: false,
                              translateX: 140,
                              translateY: 0,
                              itemsSpacing: 0,
                              itemWidth: 140,
                              itemHeight: 33,
                              itemDirection: 'bottom-to-top',
                              itemOpacity: 0.85,
                              symbolSize: 7,
                              effects: [
                                {
                                  on: 'hover',
                                  style: {
                                    itemOpacity: 1,
                                  },
                                },
                              ],
                            },
                          ]
                        : undefined
                    }
                  />
                )}
              {!dadosFuncAtividades?.length && !loadingDados && (
                <SemDados>
                  <FiInfo color="#B0B0B0" size={40} />
                  <span>Sem dados</span>
                </SemDados>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ContainerModal>
          {/* {(loadingDadosGerais ||
            loadingEmpresas ||
            loadingAmbientes ||
            loadingColaboradores) && <Loading />}
          {!(
            loadingDadosGerais ||
            loadingEmpresas ||
            loadingAmbientes ||
            loadingColaboradores
          ) && (
            <> */}
          <header>
            <h1>Filtros</h1>
            <FaTimes
              size={25}
              color="#b0b0b0"
              onClick={() => {
                setModalOpen(false);
                // resetFilters();
              }}
            />
          </header>

          <p>Empresa</p>
          <Select
            isClearable
            isMulti
            name="empresas"
            options={empresas}
            maxMenuHeight={200}
            isLoading={loadingEmpresas}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            value={empresasForm}
            onChange={e => {
              setEmpresasForm(e);
              handleChangeEmpresa(e);
            }}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />
          <p>Colaborador</p>
          <Select
            isClearable
            isMulti
            name="colaboradores"
            options={colaboradoresHook ?? colaboradores}
            maxMenuHeight={200}
            isLoading={loadingColaboradores}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setColaboradoresForm(e)}
            value={colaboradoresForm}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />

          <p>Ambientes</p>
          <Select
            isClearable
            isMulti
            name="ambientes"
            options={ambientesHook ?? ambientes}
            maxMenuHeight={200}
            isLoading={loadingAmbientes}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setAmbientesForm(e)}
            value={ambientesForm}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />

          <p>Categorias</p>
          <Select
            isClearable
            isMulti
            name="categorias"
            options={categoriasHook ?? categorias}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setCategoriasForm(e)}
            value={categoriasForm}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />
          <p>Setores</p>
          <Select
            isClearable
            isMulti
            name="setores"
            options={setoresHook ?? setores}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setSetoresForm(e)}
            value={setoresForm}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />
          <p>Procedimentos</p>
          <Select
            isClearable
            isMulti
            name="procedimentos"
            options={procedimentosHook ?? procedimentos}
            maxMenuHeight={200}
            isLoading={loadingDadosGerais}
            // formatOptionLabel={formatOptionLabel}
            styles={{
              clearIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              dropdownIndicator: styles => ({
                ...styles,
                padding: '2px 8px',
              }),
              menu: styles => ({
                ...styles,
                zIndex: 3,
              }),
            }}
            onChange={e => setProcedimentosForm(e)}
            value={procedimentosForm}
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
          />
          <br style={{ margin: '30px 0px' }} />

          <Button type="button" onClick={() => onSubmit()} widthProps="46%">
            Filtrar
          </Button>
          <ButtonFiltro
            type="button"
            widthProps="50%"
            onClick={() => resetFilters()}
          >
            Limpar filtros
          </ButtonFiltro>
          {/* </> */}
          {/* )} */}
        </ContainerModal>
      </Modal>
    </Container>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  border: 1px solid ${props => props.theme.colors.grayWhite};
  box-sizing: border-box;
  border-radius: 20px;
  padding: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    border: none;
    width: 100%;
  }

  input::placeholder {
    color: ${props => props.theme.colors.grayBlack};
  }
`;

export const ContainerLupa = styled.div`
  padding-right: 17px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.colors.grayBlack};
    width: 19px;
    height: 19px;
  }
`;

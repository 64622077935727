import { shade } from 'polished';
import styled from 'styled-components';

export const Content = styled.div`
  @media screen and (max-width: 700px) {
    width: 90vw;
  }
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 70vh;
  overflow-x: auto;

  header {
    padding-bottom: 21px;
    margin-top: 1rem;
    border-bottom: 0.8px solid ${props => props.theme.colors.grayBlack};

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 16px;
      color: ${props => props.theme.colors.textBlack};
    }
  }
  div.name {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }

  section {
    overflow: auto;

    table {
      width: 100%;
      background: ${props => props.theme.colors.whitePrimary};
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: ${props => props.theme.colors.whitePrimary};

        th {
          padding: 20px 30px;
          text-align: left;
          white-space: nowrap;

          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: ${props => props.theme.colors.textBlack};
        }

        th:nth-last-child(-n + 1) {
          text-align: center;
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: ${props => props.theme.colors.textBlack};
            padding: 20px 30px;
            white-space: nowrap;

            input {
              min-height: 38px;
              background-color: ${props => props.theme.colors.whitePrimary};
              align-items: center;
              border-radius: 4px;
              border-style: solid;
              border-width: 1px;
              border-color: ${props => props.theme.colors.grayWhite};
              display: flex;
              padding: 0.5rem;

              font-family: 'Poppins', sans-serif;
              color: ${props => props.theme.colors.textBlack};
            }

            select {
              min-height: 38px;
              background-color: ${props => props.theme.colors.whitePrimary};
              align-items: center;
              border-radius: 4px;
              border-style: solid;
              border-width: 1px;
              border-color: ${props => props.theme.colors.grayWhite};
              display: flex;
              padding: 0.5rem;

              font-family: 'Poppins', sans-serif;
              color: ${props => props.theme.colors.textBlack};
            }

            div.reset {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              p {
                color: ${props => props.theme.colors.grayBlack};
              }

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: ${props => props.theme.colors.bluePrimary};
                height: 2rem;
                width: 120px;
                padding: 4px;
                font-family: 'Poppins', sans-serif;
                font-size: 1rem;
                color: ${props => props.theme.colors.whitePrimary};
                border-radius: 0.4rem;
                transition: 0.5s;

                &:hover {
                  background: ${props =>
                    shade(0.2, props.theme.colors.bluePrimary)};
                }
              }
            }

            button {
              border: none;
              background: transparent;
              margin: 0 auto;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            aside {
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                background: transparent;
                border: none;
                color: ${props => props.theme.colors.grayBlack};

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                transition: color 0.2s;

                &:hover {
                  color: ${props => props.theme.colors.greenPrimary};
                }

                & + button {
                  margin-left: 15px;

                  transition: color 0.2s;

                  &:hover {
                    color: ${props => props.theme.colors.redPrimary};
                  }
                }

                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: ${props => props.theme.colors.grayWhite};
          }
        }
      }
    }
  }
`;

interface ContainerProps {
  cor: boolean;
}

export const DivAba = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
  margin-top: 1rem;
  background-color: ${props => props.theme.colors.whitePrimary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    border: none;
    background: transparent;

    color: ${props =>
      props.cor ? props.theme.colors.textBlack : props.theme.colors.grayBlack};
    border-bottom: ${props =>
      props.cor
        ? `1px solid ${props.theme.colors.textBlack}`
        : '1px solid transparent'};

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const DivForm = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.colors.whitePrimary};
  border-radius: 10px;

  form {
    width: 100%;
  }
`;

export const DivDual = styled.div`
  display: flex;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  column-gap: 20px;
  align-items: center;

  .ele1 {
    display: flex;
    flex-direction: column;
    flex: 3;
  }
  .ele2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
  }
  .status {
    display: flex;
    button {
      background: transparent;
      border: none;
      color: ${props => props.theme.colors.grayBlack};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      transition: color 0.2s;

      &:hover {
        color: ${props => props.theme.colors.greenPrimary};
      }

      & + button {
        margin-left: 15px;

        transition: color 0.2s;

        &:hover {
          color: ${props => props.theme.colors.redPrimary};
        }
      }

      svg {
        width: 34px;
        height: 32px;
      }
    }
  }
`;

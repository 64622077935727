import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  margin-top: 30px;
  margin-bottom: 45px;

  section {
    animation: ${appearFromRight} 1s;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    transition: transform 0.2s;

    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 16px;
      color: ${props => props.theme.colors.textBlack};
    }

    aside {
      width: 100%;
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 0.6px solid ${props => props.theme.colors.grayBlack};

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 74px;

      @media screen and (max-width: 1152px) {
        gap: 12px;
      }

      article {
        p {
          margin: 0;
        }
      }
    }
  }
`;

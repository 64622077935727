import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Filial } from '../../models/Filial';
import Button from '../Button';
import HeaderTable from '../HeaderTable';
import { SelectCustom } from '../SelectCustom';

import { Container, ContainerSemResultados, ContainerTable } from './styles';

export interface ItemList {
  id: number;
  name: string;
  color: string;
  companyId: number;
}

interface SubmitFormProps {
  Empresa: string;
}

export interface ItemBase {
  name: string;
  color: string;
}

interface Props {
  isVisible: boolean;
  title: string;
  onClose(): void;
  list: ItemList[];
  listAll: ItemList[];
  filiais: Filial[];
  isAtributeExtra: boolean;
  onDuplic(idFilial: number, procs: ItemBase[]): void;
}

export const ModalDuplicar: React.FC<Props> = ({
  isVisible,
  onClose,
  filiais,
  list,
  listAll,
  onDuplic,
  title,
  isAtributeExtra,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const [filialId, setFilialId] = useState<number>();

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const listProc = useMemo(() => {
    const lts: ItemList[] = [];
    list.forEach(i => {
      const find = lts.find(l => l.name === i.name);
      if (!find) {
        lts.push(i);
      }
    });
    return lts;
  }, [list]);

  const listProcedimentos = useMemo(() => {
    const ltsCompany = listAll.filter(i => i.companyId === filialId);

    return listProc.filter(i => {
      return !ltsCompany.find(lc => lc.name === i.name);
    });
  }, [filialId, listAll, listProc]);

  const filialSel = useMemo(() => {
    if (filialId === undefined && filiais.length > 0) {
      const id = isCoordenador ? user.companyId : filiais[0].ID;
      let aux = '';
      filiais.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setFilialId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    filiais.forEach(item => {
      if (item.ID === filialId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [filialId, filiais, isCoordenador, user.companyId]);

  const handleSubmit = useCallback(
    (data: SubmitFormProps) => {
      const companyId = data.Empresa.split(' - ')[0];
      const lts: ItemBase[] = listProcedimentos.map(i => ({
        color: i.color,
        name: i.name,
      }));
      if (lts.length > 0) {
        onDuplic(Number(companyId), lts);
      } else {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Lista inválida',
        });
      }
    },
    [addToast, listProcedimentos, onDuplic],
  );

  const headers = useMemo(() => {
    if (isAtributeExtra) {
      return [
        { name: 'Lista de itens', field: 'name', sortable: true },
        { name: 'Cor', field: 'color', sortable: true },
      ];
    }
    return [{ name: 'Lista de itens', field: 'name', sortable: true }];
  }, [isAtributeExtra]);

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Container>
        <header>
          <h1>{title}</h1>
        </header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <p>Empresa</p>
          <SelectCustom
            name="Empresa"
            defaultValue="Empresa"
            optionsDataCompany={filiais}
            value={filialSel}
            disabled={isCoordenador}
            onValue={e => setFilialId(parseInt(e.split('-')[0], 10))}
          />
          <ContainerTable>
            <table>
              <HeaderTable headers={headers} />
              <tbody>
                {listProcedimentos.map(res => (
                  <tr key={res.name + res.id}>
                    <td>{res.name}</td>
                    {res.color !== '' && (
                      <td>
                        <div
                          style={{
                            background: res.color,
                            width: 43,
                            height: 43,
                            borderRadius: '50%',
                          }}
                        >
                          <span hidden>{res.color}</span>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          {listProcedimentos.length === 0 && (
            <ContainerSemResultados>
              <h2>Os itens já estão presentes na filial.</h2>
            </ContainerSemResultados>
          )}
          <br />
          <Button
            type="submit"
            disabled={listProcedimentos.length === 0}
            widthProps="100%"
          >
            Duplicar
          </Button>
        </Form>
      </Container>
    </Modal>
  );
};

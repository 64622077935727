/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../../hooks/auth';

import { Container } from './styles';

interface TooltipProps {
  title: 'cadastros' | 'usuarios' | 'relatorios' | 'perfil';
  type?: 'drawer' | 'header';
  onMouseHover?: (e: boolean) => void;
}

export const TooltipMenu: React.FC<TooltipProps> = ({
  title,
  type = 'drawer',
  children,
  onMouseHover = (e: boolean) => {
    return e;
  },
}) => {
  const { user, empresaPrincipal, cpfUserMaster } = useAuth();

  const getNivel = useMemo((): string => {
    switch (user.roleId) {
      case 1:
        return 'Admin. Principal';
      case 2:
        return 'Administrador';
      case 3:
        return 'Coordenador';
      case 4:
        return 'Auditor';
      default:
        return 'Sem nível de acesso';
    }
  }, [user.roleId]);

  return (
    <Container
      typeProps={type}
      onMouseOver={() => onMouseHover(true)}
      onMouseLeave={() => onMouseHover(false)}
    >
      {children}

      <ul>
        {title === 'cadastros' && (
          <>
            {user.roleId === 1 && (
              <li>
                <Link
                  to="/empresa-principal"
                // onClick={() => {
                //   Swal.fire({
                //     icon: 'info',
                //     title: 'Permissão negada!',
                //     text:
                //       'Para cadastrar uma nova EMPRESA MATRIZ entre em contato com o suporte do sistema',
                //     showCloseButton: true,
                //     showConfirmButton: false,
                //   });
                // }}
                >
                  Empresa matriz
                </Link>
              </li>
            )}
            {(user.roleId === 1 || user.roleId === 2) && (
              <li>
                <Link
                  to="/filiais"
                  onClick={() => {
                    if (!cpfUserMaster.length) {
                      Swal.fire({
                        icon: 'info',
                        title: 'Permissão negada!',
                        text:
                          'Para cadastrar uma nova FILIAL entre em contato com o administrador do sistema',
                        showCloseButton: true,
                        showConfirmButton: false,
                      });
                    }
                  }}
                >
                  Filiais
                </Link>
              </li>
            )}
            <li>
              <Link to="/procedimentos">Procedimentos</Link>
            </li>
            <li>
              <Link to="/riscos">Riscos</Link>
            </li>
            <li>
              <Link to="/setores">Setores</Link>
            </li>
            <li>
              <Link to="/categorias">Categorias</Link>
            </li>
            <li>
              <Link to="/ambientes">Ambientes</Link>
            </li>
            <li>
              <Link to="/agendamentos">Agendamento</Link>
            </li>
          </>
        )}
        {title === 'usuarios' && (
          <>
            <li>
              <Link to="/pre-cadastro-usuarios">Pré-cadastro</Link>
            </li>
            <li>
              <Link to="/listagem-usuarios">Listagem</Link>
            </li>
          </>
        )}
        {title === 'relatorios' && (
          <>
            {user.roleId != 7 && <><li>
              <Link to="/historico-colaboradores">Colaboradores</Link>
            </li>
              <li>
                <Link to="/historico-ambientes">Ambientes</Link>
              </li>
              <li>
                <Link to="/historico-agenda">Agenda</Link>
              </li>
            </>
            }
            <li>
              <Link to="/historico-tarefas">
                Atividades
              </Link>
            </li>
          </>
        )}

        {/* {title === 'relatoriosv2' && (
          <>
            <li>
              <Link to="/v2/historico-colaboradores">Colaboradores</Link>
            </li>
            <li>
              <Link to="/v2/historico-ambientes">Ambientes</Link>
            </li>
            <li>
              <Link to="/v2/historico-agenda">Agenda</Link>
            </li>
            <li>
              <Link to="/v2/historico-tarefas-incompletas">
                Tarefas incompletas
              </Link>
            </li>
          </>
        )} */}

        {title === 'perfil' && (
          <>
            <li>
              <Link to="/profile" className="linkProfile">
                Acessar meu perfil
              </Link>
            </li>
            <li>
              <p>
                <strong>Empresa principal: </strong>
                {empresaPrincipal.length
                  ? empresaPrincipal[0].NOME
                  : 'Todas as empresas'}
              </p>
            </li>
            <li>
              <p>
                <strong>Nível de acesso: </strong>
                {getNivel}
              </p>
            </li>
          </>
        )}
      </ul>
    </Container>
  );
};

import styled from 'styled-components';

export const ContainerAlert = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  margin: 12px 0;

  border: 1px solid ${props => props.theme.colors.textBlack};

  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.orangePrimary};
  border-color: ${props => props.theme.colors.orangePrimary};

  svg {
    width: 30px;
    height: 30px;

    margin-right: 16px;

    @media screen and (max-width: 1152px) {
      width: 22px;
      height: 22px;
    }
  }

  p {
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
  }
`;

import React, { useCallback, useState, useEffect } from 'react';
import { InputOverview } from '../../../../components/InputOverview';
import { ResponseRevisao, ResponseGet } from '../index';

import { Container } from './styles';

export interface InputTime {
  horario: string;
  posicao: number;
  tipo: string;
}

interface ListaHorariosProps {
  quantLimpezas: number | undefined;
  dadosEdit?: ResponseGet;
  dadosRevisoesEdit?: ResponseRevisao;
  onStartTime: (e: InputTime[]) => void;
  onEndTime: (e: InputTime[]) => void;
}

export const ListaHorarios: React.FC<ListaHorariosProps> = ({
  quantLimpezas,
  dadosEdit,
  dadosRevisoesEdit,
  onStartTime = (e: InputTime[]) => {
    return e;
  },
  onEndTime = (e: InputTime[]) => {
    return e;
  },
}) => {
  const [contLimpezas, setContLimpezas] = useState<number[]>([]);
  const [horariosInicio, setHorariosInicio] = useState<InputTime[]>([]);
  const [horariosFim, setHorariosFim] = useState<InputTime[]>([]);

  useEffect(() => {
    const contVector: number[] = [];
    const horariosInicioAux: InputTime[] = [];
    const horariosFimAux: InputTime[] = [];

    if (quantLimpezas) {
      for (let i = 1; i <= quantLimpezas; i += 1) {
        contVector.push(i);
      }
    }

    if (dadosEdit && dadosRevisoesEdit) {
      horariosInicioAux.push({
        horario: dadosEdit.HORA_BASE_INICIAL,
        posicao: 0,
        tipo: 'inicio',
      });

      horariosFimAux.push({
        horario: dadosEdit.HORA_BASE_FINAL,
        posicao: 0,
        tipo: 'fim',
      });

      contVector.forEach((limpeza, index) => {
        if (index !== 0) {
          horariosInicioAux.push({
            horario: dadosRevisoesEdit.horaInicial[index - 1],
            posicao: limpeza - 1,
            tipo: 'inicio',
          });

          horariosFimAux.push({
            horario: dadosRevisoesEdit.horaFinal[index - 1],
            posicao: limpeza - 1,
            tipo: 'fim',
          });
        }
      });

      onStartTime([...horariosInicioAux]);
      onEndTime([...horariosFimAux]);
    } else {
      contVector.forEach(limpeza => {
        horariosInicioAux.push({
          horario: '',
          posicao: limpeza - 1,
          tipo: 'inicio',
        });

        horariosFimAux.push({
          horario: '',
          posicao: limpeza - 1,
          tipo: 'fim',
        });
      });
    }

    setHorariosInicio([...horariosInicioAux]);
    setHorariosFim([...horariosFimAux]);
    setContLimpezas([...contVector]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosEdit, dadosRevisoesEdit, quantLimpezas]);

  const findInputTime = useCallback(
    (valueInput, indexInput, typeInput) => {
      const horariosInicioAux: InputTime[] = [...horariosInicio];
      const horariosFimAux: InputTime[] = [...horariosFim];

      horariosInicioAux.forEach((horario, index) => {
        if (index === indexInput && typeInput === 'inicio') {
          horariosInicioAux[index] = {
            horario: valueInput,
            posicao: indexInput,
            tipo: typeInput,
          };
        }
      });

      horariosFimAux.forEach((horario, index) => {
        if (index === indexInput && typeInput === 'fim') {
          horariosFimAux[index] = {
            horario: valueInput,
            posicao: indexInput,
            tipo: typeInput,
          };
        }
      });

      setHorariosInicio([...horariosInicioAux]);
      setHorariosFim([...horariosFimAux]);

      onStartTime([...horariosInicioAux]);
      onEndTime([...horariosFimAux]);
    },
    [horariosFim, horariosInicio, onEndTime, onStartTime],
  );

  return (
    <Container>
      {contLimpezas.map((limpeza, index) => (
        <section key={limpeza}>
          {!index ? (
            <h2>{limpeza}ª Limpeza</h2>
          ) : (
            <h2>{limpeza - 1}ª Revisão</h2>
          )}

          <aside
            style={{
              paddingBottom: contLimpezas.length - 1 !== index ? '' : 0,
              borderBottom: contLimpezas.length - 1 !== index ? '' : 0,
              marginBottom: contLimpezas.length - 1 !== index ? '' : 0,
            }}
          >
            <article>
              <p>Horário inicial</p>
              <InputOverview
                name={`inicio${index + 1}`}
                type="time"
                value={horariosInicio[index].horario}
                onChange={e => findInputTime(e.target.value, index, 'inicio')}
              />
            </article>

            <article>
              <p>Horário final</p>
              <InputOverview
                name={`fim${index + 1}`}
                type="time"
                value={horariosFim[index].horario}
                onChange={e => findInputTime(e.target.value, index, 'fim')}
              />
            </article>
          </aside>
        </section>
      ))}
    </Container>
  );
};

import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useAuth } from '../../hooks/auth';
import { TooltipMenu } from '../TooltipMenu';
import ImgLogo from '../../assets/imgBackGroundVersion.png';
import { useDrawer } from '../../hooks/drawer';

import {
  Container,
  Navigator,
  List,
  LiList,
  ButtonIconArrow,
  ButtonSignOut,
  ListMobile,
} from './styles';

export const DrawerHeader: React.FC = () => {
  const { user, signOut } = useAuth();
  const { isActive, updateDrawer } = useDrawer();
  const [mouseHoverCadastros, setMouseHoverCadastros] = useState(false);
  const [mouseHoverUsuarios, setMouseHoverUsuarios] = useState(false);
  const [mouseHoverRelatorios, setMouseHoverRelatorios] = useState(false);
  const [mouseHoverRelatoriosClient, setMouseHoverRelatoriosClient] = useState(false);
  const [show, setShow] = useState(isActive);
  const [statusDrawerTrue, setStatusDrawerTrue] = useState<number[]>([]);
  const [statusDrawerFalse, setStatusDrawerFalse] = useState<number[]>([]);
  useEffect(() => {
    setShow(isActive);
  }, [isActive]);

  useEffect(() => {
    setStatusDrawerTrue([]);
    setStatusDrawerFalse([]);
  }, [isActive]);

  const mql = window.matchMedia('(max-width: 1152px)');

  mql.addEventListener('change', () => {
    if (window.innerWidth > 1152 && !statusDrawerTrue.length) {
      statusDrawerTrue.push(1);
      updateDrawer(true);
    } else if (window.innerWidth < 1152 && !statusDrawerFalse.length) {
      statusDrawerFalse.push(0);
      updateDrawer(false);
    }
  });

  return !!user && show ? (
    <Container>
      <Navigator>
        <Link to="/">
          <img src={ImgLogo} alt="ImgLogo" />
        </Link>

        {user.roleId != 7 ?
         (
          <List>
            <li>
              <Link to="/">
                <svg
                  style={{ width: 22, height: 22 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.60606 20.6791V14.0745H13.3886V20.6791C13.3886 21.4056 13.9267 22 14.5843 22H18.1712C18.8288 22 19.3668 21.4056 19.3668 20.6791V11.4326H21.3994C21.9494 11.4326 22.2125 10.6797 21.794 10.2834L11.7984 0.336836C11.3441 -0.112279 10.6506 -0.112279 10.1963 0.336836L0.200691 10.2834C-0.205827 10.6797 0.0452573 11.4326 0.595253 11.4326H2.62785V20.6791C2.62785 21.4056 3.16588 22 3.82349 22H7.41042C8.06802 22 8.60606 21.4056 8.60606 20.6791Z" />
                </svg>
                Início
              </Link>
            </li>
            {(user.roleId === 1 ||
              user.roleId === 2 ||
              user.roleId === 3 ||
              !user.roleId) && (
                <>
                  <LiList isHover={mouseHoverCadastros}>
                    <span>
                      <article>
                        <svg
                          style={{ width: 24, height: 21 }}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M14.5595 7C14.5595 7.77 13.9639 8.4 13.2359 8.4H1.32359C0.595616 8.4 0 7.77 0 7C0 6.23 0.595616 5.6 1.32359 5.6H13.2359C13.9639 5.6 14.5595 6.23 14.5595 7ZM0 1.4C0 2.17 0.595616 2.8 1.32359 2.8H13.2359C13.9639 2.8 14.5595 2.17 14.5595 1.4C14.5595 0.63 13.9639 0 13.2359 0H1.32359C0.595616 0 0 0.63 0 1.4ZM9.26513 12.6C9.26513 11.83 8.66952 11.2 7.94154 11.2H1.32359C0.595616 11.2 0 11.83 0 12.6C0 13.37 0.595616 14 1.32359 14H7.94154C8.66952 14 9.26513 13.37 9.26513 12.6ZM19.8671 9.618L20.8068 8.624C21.323 8.078 22.1569 8.078 22.6731 8.624L23.6129 9.618C24.1291 10.164 24.1291 11.046 23.6129 11.592L22.6731 12.586L19.8671 9.618ZM18.9273 10.612L12.0976 17.836C11.9785 17.962 11.9123 18.13 11.9123 18.326V20.3C11.9123 20.692 12.2035 21 12.5741 21H14.4404C14.6124 21 14.7845 20.93 14.9036 20.79L21.7334 13.566L18.9273 10.612Z" />
                        </svg>
                        Cadastros
                      </article>

                      <TooltipMenu
                        title="cadastros"
                        onMouseHover={e => setMouseHoverCadastros(e)}
                      >
                        <ButtonIconArrow
                          type="button"
                          isHover={mouseHoverCadastros}
                        >
                          <RiArrowRightSLine />
                        </ButtonIconArrow>
                      </TooltipMenu>
                    </span>

                    <ListMobile>
                      {user.roleId === 1 && (
                        <li>
                          <Link
                            to="/empresa-principal"
                          // onClick={() => {
                          //   Swal.fire({
                          //     icon: 'info',
                          //     title: 'Permissão negada!',
                          //     text:
                          //       'Para cadastrar uma nova EMPRESA MATRIZ entre em contato com o suporte do sistema',
                          //     showCloseButton: true,
                          //     showConfirmButton: false,
                          //   });
                          // }}
                          >
                            Empresa matriz
                          </Link>
                        </li>
                      )}
                      {(user.roleId === 1 || user.roleId === 2) && (
                        <li>
                          <Link to="/filiais">Filiais</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/ambientes">Ambientes</Link>
                      </li>
                      <li>
                        <Link to="/procedimentos">Procedimentos</Link>
                      </li>
                      <li>
                        <Link to="/riscos">Riscos</Link>
                      </li>
                      <li>
                        <Link to="/setores">Setores</Link>
                      </li>
                      <li>
                        <Link to="/categorias">Categorias</Link>
                      </li>
                      <li>
                        <Link to="/agendamentos">Agendamento</Link>
                      </li>
                    </ListMobile>
                  </LiList>

                  <li>
                    <Link to="/listagem-usuarios">
                      <svg
                        style={{ width: 27, height: 17 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M18.4091 7.28571C20.4464 7.28571 22.0786 5.65857 22.0786 3.64286C22.0786 1.62714 20.4464 0 18.4091 0C16.3718 0 14.7273 1.62714 14.7273 3.64286C14.7273 5.65857 16.3718 7.28571 18.4091 7.28571ZM8.59091 7.28571C10.6282 7.28571 12.2605 5.65857 12.2605 3.64286C12.2605 1.62714 10.6282 0 8.59091 0C6.55364 0 4.90909 1.62714 4.90909 3.64286C4.90909 5.65857 6.55364 7.28571 8.59091 7.28571ZM8.59091 9.71429C5.73136 9.71429 0 11.135 0 13.9643V15.7857C0 16.4536 0.552273 17 1.22727 17H15.9545C16.6295 17 17.1818 16.4536 17.1818 15.7857V13.9643C17.1818 11.135 11.4505 9.71429 8.59091 9.71429ZM18.4091 9.71429C18.0532 9.71429 17.6482 9.73857 17.2186 9.775C17.2432 9.78714 17.2555 9.81143 17.2677 9.82357C18.6668 10.8314 19.6364 12.1793 19.6364 13.9643V15.7857C19.6364 16.2107 19.5505 16.6236 19.4155 17H25.7727C26.4477 17 27 16.4536 27 15.7857V13.9643C27 11.135 21.2686 9.71429 18.4091 9.71429Z" />
                      </svg>
                      Usuários
                    </Link>
                  </li>

                  {/* <LiList isHover={mouseHoverUsuarios}>
                <span>
                  <article>
                    <svg
                      style={{ width: 27, height: 17 }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18.4091 7.28571C20.4464 7.28571 22.0786 5.65857 22.0786 3.64286C22.0786 1.62714 20.4464 0 18.4091 0C16.3718 0 14.7273 1.62714 14.7273 3.64286C14.7273 5.65857 16.3718 7.28571 18.4091 7.28571ZM8.59091 7.28571C10.6282 7.28571 12.2605 5.65857 12.2605 3.64286C12.2605 1.62714 10.6282 0 8.59091 0C6.55364 0 4.90909 1.62714 4.90909 3.64286C4.90909 5.65857 6.55364 7.28571 8.59091 7.28571ZM8.59091 9.71429C5.73136 9.71429 0 11.135 0 13.9643V15.7857C0 16.4536 0.552273 17 1.22727 17H15.9545C16.6295 17 17.1818 16.4536 17.1818 15.7857V13.9643C17.1818 11.135 11.4505 9.71429 8.59091 9.71429ZM18.4091 9.71429C18.0532 9.71429 17.6482 9.73857 17.2186 9.775C17.2432 9.78714 17.2555 9.81143 17.2677 9.82357C18.6668 10.8314 19.6364 12.1793 19.6364 13.9643V15.7857C19.6364 16.2107 19.5505 16.6236 19.4155 17H25.7727C26.4477 17 27 16.4536 27 15.7857V13.9643C27 11.135 21.2686 9.71429 18.4091 9.71429Z" />
                    </svg>
                    Usuários
                  </article>

                  <TooltipMenu
                    title="usuarios"
                    onMouseHover={e => setMouseHoverUsuarios(e)}
                  >
                    <ButtonIconArrow type="button" isHover={mouseHoverUsuarios}>
                      <RiArrowRightSLine />
                    </ButtonIconArrow>
                  </TooltipMenu>
                </span>

                <ListMobile>
                  <li>
                    <Link to="/pre-cadastro-usuarios">Pré-cadastro</Link>
                  </li>
                  <li>
                    <Link to="/listagem-usuarios">Listagem</Link>
                  </li>
                </ListMobile>
              </LiList> */}
                </>
              )}
            <LiList isHover={mouseHoverRelatorios}>
              <span>
                <article>
                  <svg
                    style={{ width: 20, height: 20 }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.5833 0.25H2.41667C1.225 0.25 0.25 1.225 0.25 2.41667V17.5833C0.25 18.775 1.225 19.75 2.41667 19.75H17.5833C18.775 19.75 19.75 18.775 19.75 17.5833V2.41667C19.75 1.225 18.775 0.25 17.5833 0.25ZM11.0833 15.4167H5.66667C5.07083 15.4167 4.58333 14.9292 4.58333 14.3333C4.58333 13.7375 5.07083 13.25 5.66667 13.25H11.0833C11.6792 13.25 12.1667 13.7375 12.1667 14.3333C12.1667 14.9292 11.6792 15.4167 11.0833 15.4167ZM14.3333 11.0833H5.66667C5.07083 11.0833 4.58333 10.5958 4.58333 10C4.58333 9.40417 5.07083 8.91667 5.66667 8.91667H14.3333C14.9292 8.91667 15.4167 9.40417 15.4167 10C15.4167 10.5958 14.9292 11.0833 14.3333 11.0833ZM14.3333 6.75H5.66667C5.07083 6.75 4.58333 6.2625 4.58333 5.66667C4.58333 5.07083 5.07083 4.58333 5.66667 4.58333H14.3333C14.9292 4.58333 15.4167 5.07083 15.4167 5.66667C15.4167 6.2625 14.9292 6.75 14.3333 6.75Z" />
                  </svg>
                  Relatórios
                </article>

                <TooltipMenu
                  title="relatorios"
                  onMouseHover={e => setMouseHoverRelatorios(e)}
                >
                  <ButtonIconArrow type="button" isHover={mouseHoverRelatorios}>
                    <RiArrowRightSLine />
                  </ButtonIconArrow>
                </TooltipMenu>
              </span>

              <ListMobile>
                <li>
                   <Link to="/historico-colaboradores">Colaboradores</Link>
                </li>
                <li>
                  <Link to="/historico-ambientes">Ambientes</Link>
                </li>
                <li>
                  <Link to="/historico-agenda">Agenda</Link>
                </li>
                <li>
                  <Link to="/historico-tarefas">
                    Tarefas
                  </Link>
                </li>
              </ListMobile>
            </LiList>

            <li>
              <Link to="/auditoria">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 60 60"
                  style={{ width: 22, height: 22 }}
                >
                  <g>
                    <path d="M59,55.5v-39H47v39h-3v-31H32v31h-3v-23H17v23h-3v-14H2v14H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1h12h3h12h3h12h3h12   c0.552,0,1-0.447,1-1S59.552,55.5,59,55.5z" />
                    <path d="M8.03,27.83c0.169,0,0.342-0.043,0.499-0.134l36.269-20.94l-2.27,4.99c-0.229,0.503-0.007,1.096,0.496,1.324   c0.134,0.062,0.275,0.09,0.414,0.09c0.38,0,0.743-0.218,0.911-0.586l3.562-7.83c0.011-0.025,0.009-0.052,0.018-0.078   c0.019-0.053,0.034-0.104,0.044-0.16c0.005-0.028,0.021-0.051,0.023-0.08c0.001-0.012-0.004-0.022-0.003-0.034   c0.002-0.038-0.002-0.073-0.004-0.111c-0.003-0.055-0.012-0.107-0.024-0.162c-0.008-0.038-0.01-0.077-0.023-0.114   c-0.012-0.036-0.033-0.066-0.049-0.101C47.88,3.881,47.88,3.854,47.866,3.83c-0.014-0.024-0.038-0.038-0.054-0.061   c-0.021-0.031-0.037-0.064-0.062-0.092c-0.026-0.03-0.059-0.051-0.089-0.078c-0.041-0.037-0.082-0.071-0.128-0.101   c-0.031-0.02-0.059-0.042-0.093-0.059c-0.011-0.005-0.017-0.015-0.028-0.02c-0.025-0.011-0.052-0.009-0.077-0.018   c-0.055-0.02-0.109-0.034-0.166-0.044c-0.026-0.005-0.047-0.02-0.074-0.022l-8.562-0.83c-0.555-0.056-1.039,0.35-1.092,0.898   c-0.054,0.55,0.349,1.039,0.898,1.092l5.456,0.529L7.529,25.964C7.05,26.24,6.887,26.852,7.163,27.33   C7.348,27.651,7.684,27.83,8.03,27.83z" />
                  </g>
                </svg>
                Auditoria
              </Link>
            </li>
            {(user.roleId === 1 || user.roleId === 2 || user.roleId === 3) && (
              <li>
                <Link to="/faq">
                  <svg
                    style={{ width: 26, height: 26 }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM13 23.4C7.267 23.4 2.6 18.733 2.6 13C2.6 7.267 7.267 2.6 13 2.6C18.733 2.6 23.4 7.267 23.4 13C23.4 18.733 18.733 23.4 13 23.4ZM11.7 18.2H14.3V20.8H11.7V18.2ZM13.793 5.252C11.115 4.862 8.749 6.513 8.034 8.879C7.8 9.633 8.372 10.4 9.165 10.4H9.425C9.958 10.4 10.387 10.023 10.569 9.529C10.985 8.372 12.22 7.579 13.559 7.865C14.794 8.125 15.704 9.334 15.6 10.595C15.47 12.337 13.494 12.714 12.415 14.339C12.415 14.352 12.402 14.352 12.402 14.365C12.389 14.391 12.376 14.404 12.363 14.43C12.246 14.625 12.129 14.846 12.038 15.08C12.025 15.119 11.999 15.145 11.986 15.184C11.973 15.21 11.973 15.236 11.96 15.275C11.804 15.717 11.7 16.25 11.7 16.9H14.3C14.3 16.354 14.443 15.899 14.664 15.509C14.69 15.47 14.703 15.431 14.729 15.392C14.833 15.21 14.963 15.041 15.093 14.885C15.106 14.872 15.119 14.846 15.132 14.833C15.262 14.677 15.405 14.534 15.561 14.391C16.809 13.208 18.499 12.246 18.148 9.763C17.836 7.501 16.055 5.59 13.793 5.252Z" />
                  </svg>
                  FAQ
                </Link>
              </li>
            )}
            <li>
              <ButtonSignOut onClick={() => signOut()}>
                <svg
                  style={{ width: 22, height: 22 }}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.47312 2.44444H9.89249C10.5726 2.44444 11.1291 1.89444 11.1291 1.22222C11.1291 0.55 10.5726 0 9.89249 0H2.47312C1.11291 0 0 1.1 0 2.44444V19.5556C0 20.9 1.11291 22 2.47312 22H9.89249C10.5726 22 11.1291 21.45 11.1291 20.7778C11.1291 20.1056 10.5726 19.5556 9.89249 19.5556H2.47312V2.44444Z" />
                  <path d="M21.8254 10.5722L18.3754 7.16223C17.9797 6.77112 17.3119 7.04001 17.3119 7.59001V9.77778H8.65599C7.97589 9.77778 7.41943 10.3278 7.41943 11C7.41943 11.6722 7.97589 12.2222 8.65599 12.2222H17.3119V14.41C17.3119 14.96 17.9797 15.2289 18.363 14.8378L21.813 11.4278C22.0603 11.1956 22.0603 10.8045 21.8254 10.5722Z" />
                </svg>
                Sair
              </ButtonSignOut>
            </li>
          </List>
          )
          : (
            <List>
              <li>
                <Link to="/">
                  <svg
                    style={{ width: 22, height: 22 }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.60606 20.6791V14.0745H13.3886V20.6791C13.3886 21.4056 13.9267 22 14.5843 22H18.1712C18.8288 22 19.3668 21.4056 19.3668 20.6791V11.4326H21.3994C21.9494 11.4326 22.2125 10.6797 21.794 10.2834L11.7984 0.336836C11.3441 -0.112279 10.6506 -0.112279 10.1963 0.336836L0.200691 10.2834C-0.205827 10.6797 0.0452573 11.4326 0.595253 11.4326H2.62785V20.6791C2.62785 21.4056 3.16588 22 3.82349 22H7.41042C8.06802 22 8.60606 21.4056 8.60606 20.6791Z" />
                  </svg>
                  Início
                </Link>
              </li>
              <LiList isHover={mouseHoverRelatoriosClient}>
                <span>
                  <article>
                    <svg
                      style={{ width: 20, height: 20 }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.5833 0.25H2.41667C1.225 0.25 0.25 1.225 0.25 2.41667V17.5833C0.25 18.775 1.225 19.75 2.41667 19.75H17.5833C18.775 19.75 19.75 18.775 19.75 17.5833V2.41667C19.75 1.225 18.775 0.25 17.5833 0.25ZM11.0833 15.4167H5.66667C5.07083 15.4167 4.58333 14.9292 4.58333 14.3333C4.58333 13.7375 5.07083 13.25 5.66667 13.25H11.0833C11.6792 13.25 12.1667 13.7375 12.1667 14.3333C12.1667 14.9292 11.6792 15.4167 11.0833 15.4167ZM14.3333 11.0833H5.66667C5.07083 11.0833 4.58333 10.5958 4.58333 10C4.58333 9.40417 5.07083 8.91667 5.66667 8.91667H14.3333C14.9292 8.91667 15.4167 9.40417 15.4167 10C15.4167 10.5958 14.9292 11.0833 14.3333 11.0833ZM14.3333 6.75H5.66667C5.07083 6.75 4.58333 6.2625 4.58333 5.66667C4.58333 5.07083 5.07083 4.58333 5.66667 4.58333H14.3333C14.9292 4.58333 15.4167 5.07083 15.4167 5.66667C15.4167 6.2625 14.9292 6.75 14.3333 6.75Z" />
                    </svg>
                    Relatórios
                  </article>

                  <TooltipMenu
                    title="relatorios"
                    onMouseHover={e => setMouseHoverRelatoriosClient(e)}
                  >
                    <ButtonIconArrow type="button" isHover={mouseHoverRelatoriosClient}>
                      <RiArrowRightSLine />
                    </ButtonIconArrow>
                  </TooltipMenu>
                </span>

                <ListMobile>
                  <li>
                    <Link to="/historico-tarefas">
                      Tarefas
                    </Link>
                  </li>
                </ListMobile>
              </LiList>

              <li>
                <Link to="/auditoria">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 60 60"
                    style={{ width: 22, height: 22 }}
                  >
                    <g>
                      <path d="M59,55.5v-39H47v39h-3v-31H32v31h-3v-23H17v23h-3v-14H2v14H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h1h12h3h12h3h12h3h12   c0.552,0,1-0.447,1-1S59.552,55.5,59,55.5z" />
                      <path d="M8.03,27.83c0.169,0,0.342-0.043,0.499-0.134l36.269-20.94l-2.27,4.99c-0.229,0.503-0.007,1.096,0.496,1.324   c0.134,0.062,0.275,0.09,0.414,0.09c0.38,0,0.743-0.218,0.911-0.586l3.562-7.83c0.011-0.025,0.009-0.052,0.018-0.078   c0.019-0.053,0.034-0.104,0.044-0.16c0.005-0.028,0.021-0.051,0.023-0.08c0.001-0.012-0.004-0.022-0.003-0.034   c0.002-0.038-0.002-0.073-0.004-0.111c-0.003-0.055-0.012-0.107-0.024-0.162c-0.008-0.038-0.01-0.077-0.023-0.114   c-0.012-0.036-0.033-0.066-0.049-0.101C47.88,3.881,47.88,3.854,47.866,3.83c-0.014-0.024-0.038-0.038-0.054-0.061   c-0.021-0.031-0.037-0.064-0.062-0.092c-0.026-0.03-0.059-0.051-0.089-0.078c-0.041-0.037-0.082-0.071-0.128-0.101   c-0.031-0.02-0.059-0.042-0.093-0.059c-0.011-0.005-0.017-0.015-0.028-0.02c-0.025-0.011-0.052-0.009-0.077-0.018   c-0.055-0.02-0.109-0.034-0.166-0.044c-0.026-0.005-0.047-0.02-0.074-0.022l-8.562-0.83c-0.555-0.056-1.039,0.35-1.092,0.898   c-0.054,0.55,0.349,1.039,0.898,1.092l5.456,0.529L7.529,25.964C7.05,26.24,6.887,26.852,7.163,27.33   C7.348,27.651,7.684,27.83,8.03,27.83z" />
                    </g>
                  </svg>
                  Auditoria
                </Link>
              </li>
              <li>
                <ButtonSignOut onClick={() => signOut()}>
                  <svg
                    style={{ width: 22, height: 22 }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.47312 2.44444H9.89249C10.5726 2.44444 11.1291 1.89444 11.1291 1.22222C11.1291 0.55 10.5726 0 9.89249 0H2.47312C1.11291 0 0 1.1 0 2.44444V19.5556C0 20.9 1.11291 22 2.47312 22H9.89249C10.5726 22 11.1291 21.45 11.1291 20.7778C11.1291 20.1056 10.5726 19.5556 9.89249 19.5556H2.47312V2.44444Z" />
                    <path d="M21.8254 10.5722L18.3754 7.16223C17.9797 6.77112 17.3119 7.04001 17.3119 7.59001V9.77778H8.65599C7.97589 9.77778 7.41943 10.3278 7.41943 11C7.41943 11.6722 7.97589 12.2222 8.65599 12.2222H17.3119V14.41C17.3119 14.96 17.9797 15.2289 18.363 14.8378L21.813 11.4278C22.0603 11.1956 22.0603 10.8045 21.8254 10.5722Z" />
                  </svg>
                  Sair
                </ButtonSignOut>
              </li>
            </List>
          )}
      </Navigator>

      <button
        type="button"
        style={{
          flex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.15)',
          border: 'none',
          cursor: 'default',
        }}
        onClick={() => updateDrawer(false)}
      >
        {' '}
      </button>
    </Container>
  ) : (
    <></>
  );
};

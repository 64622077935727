import React, { useRef, InputHTMLAttributes } from 'react';
import { FiSearch } from 'react-icons/fi';
import { maskCpf, maskCnpj, maskTel } from '../InputOverview/mask';

import { Container, ContainerLupa } from './styles';

interface SearchInterface extends InputHTMLAttributes<HTMLInputElement> {
  nomePlaceHolder: string;
  type?: string;
  onValue?: string;
  onSearch: (arg: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Search: React.FC<SearchInterface> = ({
  onSearch,
  onFocus,
  onBlur,
  onValue,
  nomePlaceHolder,
  type,
}: SearchInterface) => {
  const InputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (value: string): void => {
    onSearch(value);
  };

  return (
    <Container>
      <ContainerLupa onClick={() => InputRef.current?.focus()}>
        <FiSearch />
      </ContainerLupa>
      <input
        ref={InputRef}
        type={type}
        placeholder={nomePlaceHolder}
        onFocus={onFocus}
        onBlur={onBlur}
        value={onValue}
        onChange={e => {
          nomePlaceHolder === 'Buscar CPF'
            ? onInputChange((e.target.value = maskCpf(e.target.value)))
            : onInputChange(e.target.value);
          nomePlaceHolder === 'Buscar CNPJ'
            ? onInputChange((e.target.value = maskCnpj(e.target.value)))
            : onInputChange(e.target.value);
          nomePlaceHolder === 'Buscar Telefone'
            ? onInputChange((e.target.value = maskTel(e.target.value)))
            : onInputChange(e.target.value);
        }}
      />
    </Container>
  );
};

export default Search;

import React from 'react';
import { FiInfo } from 'react-icons/fi';
import * as S from './styles';

export const CardEmpty = () => {
  return (
    <S.Wrapper>
      <FiInfo size={30} color="#686868" />
      <S.Title>Não há registros</S.Title>
    </S.Wrapper>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 80px;
  border-radius: 4px;
  border: 1px #dcdcdc solid;
  display: flex;

  svg {
    opacity: 0.5;
  }
`;

export const Title = styled.h2`
  font-size: 12px;
  font-weight: 700;
  color: #252525;
`;

import styled from 'styled-components';

export const Content = styled.div`
  width: 442px;

  @media screen and (max-width: 1152px) {
    width: 100%;
  }

  header {
    padding-bottom: 21px;
    border-bottom: 0.8px solid ${props => props.theme.colors.grayBlack};

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 16px;
      color: ${props => props.theme.colors.textBlack};
    }
  }

  section {
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      background: ${props => props.theme.colors.bluePrimary};
      padding: 5px;
      border: 1px solid ${props => props.theme.colors.grayBlack};
      border-radius: 50%;
      margin-right: 16px;

      div {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: ${props => props.theme.colors.whitePrimary};
      }
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 16px;
      color: ${props => props.theme.colors.textBlack};
    }

    & + section {
      margin-top: 40px;

      @media screen and (max-width: 1400px) {
        margin-top: 20px;
      }
    }
  }

  button:last-child {
    margin-top: 50px;
  }
`;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';

import { IoIosArrowForward } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { FiSearch } from 'react-icons/fi';
import Pagination from '../../../components/Pagination';
import { ModalUserDetails } from '../../../components/ModalDetalhesUser';
import {
  ModalHistoricoColaboradorV2,
  UserModalProps,
  HistoricTaskAmb,
} from '../../../components/ModalHistoricoColaboradorV2';
import { Loading } from '../../../components/Loading';
import Search from '../../../components/Search';
import { maskCpf, maskTel } from '../../../components/InputOverview/mask';
import ModalConexao from '../../../components/ModalConexao';
import HeaderTable from '../../../components/HeaderTable';
import DropDownPagination from '../../../components/DropDownPagination';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import { Empresa } from '../../../models/Empresa';
import { HistoricAgendament } from '../../../models/HistoricAgend';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import imgAvatar from '../../../assets/IconAvatar.svg';
import IconFilter from '../../../assets/IconFilter.svg';
import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
  ContainerSelect,
  DivCheck,
} from './styles';
import { Filial } from '../../../models/Filial';

export interface HistUserAgend {
  name: string;
  avatar: string;
  cpf: string;
  telefone: string;
  email: string;
  id: number;
  createdAt: string;
  dataNascimento: string;
  nameFilial: string;
  companyId: number;
}

interface ResponseGet {
  ativo: number;
  avatar: string;
  avatar_url: string;
  avatarURL: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

interface ResponseGetHistoric {
  AMBIENTE_ID: number;
  AMBIENTE_NOME: string;
  ANDAR: string;
  DATA_CRIACAO: string;
  DATA_FINALIZACAO: string;
  FIM: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_FINAL: string;
  HORA_REVISAO_INICIAL: string;
  ID: number;
  IMAGEM: string;
  INICIO: string;
  OBSERVACAO: string;
  PROCEDIMENTO_NOME: string;
  Revisao: boolean;
  USUARIO: string;
  categoria: string;
  imagem_revisao: string;
  revisao_id_table_revisao: number;
  setor_id: number;
  setor_nome: string;
  escondido: boolean;
}

interface ResponseGetHistoricObs {
  id: number;
  observacao: string;
  environmentId: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  TaskId: number;
}

interface HourRev {
  id: number;
  horaIni: string;
  horaFim: string;
}

interface ListASG {
  id: number;
  nome: string;
  email: string;
  dataNascimento: string;
  cpf: string;
  telefone: string;
  companyId: number;
  ativo: number;
  coordenador: number;
  avatar: string;
  avatar_url: null;
  tipo: string;
  createdAt: string;
  updatedAt: string;
  verificationCodeId: null;
  verified: boolean;
  isPhysicalPerson: boolean;
  loggedFirstTime: boolean;
  roleId: 1 | 2 | 3 | 4 | 5 | null;
  nameFilial?: string;
}

interface StatusObs {
  status: 'Completo' | 'Não Realizado';
  obj: string;
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  { name: 'Nome', field: 'nome', sortable: false },
  { name: 'CPF', field: 'cpf', sortable: false },
  { name: 'E-mail', field: 'email', sortable: false },
  { name: 'Telefone', field: 'telefone', sortable: false },
];

export const DashboardColaboradoresV2: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  // const [response, setResponse] = useState<HistUserAgend[]>([]);
  // const [responseAll, setResponseAll] = useState<HistoricTaskAmb[]>([]);
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [asgs, setAsgs] = useState<ListASG[]>([]);
  const [filialId, setFilialId] = useState(-1);
  const [showModalDetalhesUser, setShowModalDetalhesUser] = useState(false);
  const [showModalDetalhesAmbiente, setShowModalDetalhesAmbiente] = useState(
    false,
  );
  const [dadosModal, setDadosModal] = useState<ListASG | null>(null);
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    HistoricTaskAmb[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'CPF',
    },
    {
      status: false,
      name: 'E-mail',
    },
    {
      status: false,
      name: 'Telefone',
    },
    {
      status: false,
      name: 'Intervalo de datas',
    },
  ]);
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [searchDataFinal, setSearchDataFinal] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [isShowDadosOld, setIsShowDadosOld] = useState(false);
  const [userSelected, setUserSelected] = useState<UserModalProps>();

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  // const getItensOld = useCallback(
  //   async (
  //     companyId: string,
  //     dateStart: string,
  //     dateEnd: string,
  //   ): Promise<HistUserAgend[]> => {
  //     const oldDados: HistUserAgend[] = [];
  //     return api
  //       .get(
  //         `/users/?dataI=${dateStart}&dataF=${dateEnd}&companyId=${companyId}`,
  //       )
  //       .then(resp => {
  //         // console.log('INI OLD', resp.data);
  //         const dados: ResponseGet[] = resp.data;
  //         dados.forEach(item => {
  //           const find = oldDados.find(i => i.id === item.id);
  //           if (!find) {
  //             const obj: HistUserAgend = {
  //               avatar: item.avatar
  //                 ? `https://app-qrcode-avatar.s3.amazonaws.com/${item.avatar}`
  //                 : '',
  //               companyId: item.companyId,
  //               cpf: item.cpf,
  //               createdAt: item.createdAt,
  //               dataNascimento: item.dataNascimento,
  //               email: item.email,
  //               id: item.id,
  //               name: item.nome,
  //               nameFilial: '',
  //               telefone: item.telefone,
  //             };
  //             oldDados.push(obj);
  //           }
  //         });
  //         return oldDados;
  //       })
  //       .catch(err => {
  //         // console.log(err.message);
  //         return oldDados;
  //       });
  //   },
  //   [],
  // );

  const getFiliais = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        const filAll: Filial[] = resp.data;
        setFiliais(filAll);
      })
      .catch(err => {
        console.log(err.mesage);
      });
  }, [empresaPrincipal]);

  const getListAsg = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

    api
      .get(`agendas/v2/Agendamentos/listaAsg`)
      .then(resp => {
        // console.log(resp.data);
        const asgList: ListASG[] = resp.data;
        if (isCoordenador) {
          const aux = asgList
            .filter(i => i.companyId === companyId)
            .map(i => ({
              ...i,
              avatar: i.avatar
                ? `https://app-qrcode-avatar.s3.amazonaws.com/${i.avatar}`
                : '',
            }));
          setAsgs(aux);
        } else {
          const aux = asgList.map(i => ({
            ...i,
            avatar: i.avatar
              ? `https://app-qrcode-avatar.s3.amazonaws.com/${i.avatar}`
              : '',
          }));

          setAsgs(aux);
        }
      })
      .catch(err => {
        console.log('ERR', err.message);
      });
  }, [empresaPrincipal, isCoordenador]);

  const findStatus = useCallback((statObj: StatusObs[], dataAgend: string):
    | 'Completo'
    | 'Incompleto'
    | 'A iniciar'
    | 'Não Realizado'
    | 'Cancelado' => {
    const status = statObj.map(i => i.status);
    const obj = statObj.map(i => (!i.obj ? '' : i.obj)).join('');
    if (!status.includes('Não Realizado')) {
      if (obj === '') {
        return 'Completo';
      }
      return 'Cancelado';
    }
    if (!status.includes('Completo')) {
      if (dataAgend === moment().format('YYYY-MM-DD')) {
        return 'A iniciar';
      }
      return 'Não Realizado';
    }
    return 'Incompleto';
  }, []);

  // const getItens = useCallback(
  //   async (dateStart: string, dateEnd: string) => {
  //     setLoading(true);
  //     const companyId =
  //       empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
  //     const limit = 400;
  //     // api
  //     //   .get(`agendas/v2/Agendamentos/listaAsg`)
  //     //   .then(resp => {
  //     //     console.log(resp.data);
  //     //   })
  //     //   .catch(err => {
  //     //     console.log('ERR', err.message);
  //     //   });

  //     api
  //       .get(
  //         `agendas/v2/Agendamentos/company?dataIni=2010-07-01&dataFim=2023-07-01&asgId=13`,
  //       )
  //       .then(resp => {
  //         console.log(resp.data);
  //       })
  //       .catch(err => {
  //         console.log('AGE', err.message);
  //       });
  //     setLoading(false);
  //     console.log(companyId, dateStart, dateEnd);
  //     api
  //       .get(
  //         `/agendas/v2/Agendamentos/company?companyId=${companyId}&dataIni=${dateStart}&dataFim=${dateEnd}&page=1&limit=${limit}`,
  //       )
  //       .then(async resp => {
  //         setRangeInicial(dateStart);
  //         setRangeFinal(dateEnd);
  //         // console.log('INI RESP', resp.data);
  //         let histAgendt: HistoricAgendament[] = resp.data;
  //         const pages = Number(resp.headers.pages);
  //         // console.log(pages);
  //         for (let i = 1; i < pages; i += 1) {
  //           const respAll = await api.get(
  //             `/agendas/v2/Agendamentos/company?companyId=${companyId}&dataIni=${dateStart}&dataFim=${dateEnd}&page=${
  //               i + 1
  //             }&limit=${limit}`,
  //           );
  //           const aux: HistoricAgendament[] = respAll.data;
  //           // console.log('NEW RESP', i + 1, respAll.data);
  //           histAgendt = [...histAgendt, ...aux];
  //         }

  //         const usersHist: HistUserAgend[] = [];
  //         const histTaskAmb: HistoricTaskAmb[] = [];

  //         histAgendt.forEach(element => {
  //           const hist = element.Historic;
  //           const taskAll = element.Agenda.Environment.Procedures;
  //           const allHour: HourRev[] = [
  //             {
  //               horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
  //               horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
  //               id: -1,
  //             },
  //             ...element.Agenda.Periodicidade.Revisoes.map(i => ({
  //               horaIni: i.HORA_INICIAL,
  //               horaFim: i.HORA_FINAL,
  //               id: i.ID,
  //             })),
  //           ];
  //           const histIdPegos: number[] = [];
  //           allHour.forEach((hour, indexHour) => {
  //             const hists: HistoricTaskAmb[] = [];
  //             taskAll.forEach(task => {
  //               const filterHist = hist
  //                 .filter(i => !histIdPegos.includes(i.id))
  //                 .filter(
  //                   i =>
  //                     i.tasksId === task.id &&
  //                     (i.revisionId === hour.id ||
  //                       (!i.revisionId && hour.id === -1)),
  //                 );
  //               if (filterHist.length > 0) {
  //                 const find = filterHist[0];
  //                 const obj: HistoricTaskAmb = {
  //                   Ambiente: {
  //                     id: element.Agenda.Id_Environments,
  //                     nome: element.Agenda.Environment.name,
  //                   },
  //                   createdAt: find.createdAt.slice(0, 19).split('T').join(' '),
  //                   dateAgend: element.dataAgend,
  //                   finishAt: find.updatedAt.slice(0, 19).split('T').join(' '),
  //                   horaFinalAmbiente: hour.horaFim,
  //                   horaInicialAmbiente: hour.horaIni,
  //                   id: element.id,
  //                   idTask: task.id,
  //                   imagem: !find.image
  //                     ? ''
  //                     : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${find.image}`,
  //                   isRevision: indexHour !== 0,
  //                   nameTask: task.name,
  //                   observacao: find.observacao,
  //                   description: !find.ocorrencia ? '' : find.ocorrencia,
  //                   revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
  //                     horaFim: i.HORA_FINAL,
  //                     horaIni: i.HORA_INICIAL,
  //                   })),
  //                   status: 'Completo',
  //                   userId: element.userId,
  //                 };
  //                 histIdPegos.push(find.id);
  //                 hists.push(obj);
  //               } else {
  //                 const obj: HistoricTaskAmb = {
  //                   Ambiente: {
  //                     id: element.Agenda.Id_Environments,
  //                     nome: element.Agenda.Environment.name,
  //                   },
  //                   createdAt: '',
  //                   dateAgend: element.dataAgend,
  //                   finishAt: '',
  //                   horaFinalAmbiente: hour.horaFim,
  //                   horaInicialAmbiente: hour.horaIni,
  //                   id: element.id,
  //                   idTask: task.id,
  //                   imagem: '',
  //                   isRevision: indexHour !== 0,
  //                   nameTask: task.name,
  //                   observacao: '',
  //                   description: '',
  //                   revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
  //                     horaFim: i.HORA_FINAL,
  //                     horaIni: i.HORA_INICIAL,
  //                   })),
  //                   status: 'Não Realizado',
  //                   userId: element.userId,
  //                 };
  //                 hists.push(obj);
  //               }
  //             });
  //             const status = findStatus(
  //               hists.map(i => ({
  //                 obj: i.observacao,
  //                 status:
  //                   i.status === 'Completo' ? 'Completo' : 'Não Realizado',
  //               })),
  //               element.dataAgend,
  //             );
  //             hists.forEach(item => {
  //               histTaskAmb.push({ ...item, status });
  //             });
  //           });

  //           const histRest = hist.filter(i => !histIdPegos.includes(i.id));
  //           histRest.forEach(histRes => {
  //             const obj: HistoricTaskAmb = {
  //               Ambiente: {
  //                 id: element.Agenda.Id_Environments,
  //                 nome: element.Agenda.Environment.name,
  //               },
  //               createdAt: histRes.createdAt.slice(0, 19).split('T').join(' '),
  //               dateAgend: element.dataAgend,
  //               finishAt: histRes.updatedAt.slice(0, 19).split('T').join(' '),
  //               horaFinalAmbiente: '',
  //               horaInicialAmbiente: '',
  //               id: element.id,
  //               idTask: histRes.tasksId,
  //               imagem: !histRes.image
  //                 ? ''
  //                 : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${histRes.image}`,
  //               isRevision: true,
  //               nameTask:
  //                 taskAll.find(i => histRes.tasksId === i.id)?.name || '',
  //               observacao: histRes.observacao,
  //               description: !histRes.ocorrencia ? '' : histRes.ocorrencia,
  //               revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
  //                 horaFim: i.HORA_FINAL,
  //                 horaIni: i.HORA_INICIAL,
  //               })),
  //               status: 'Completo',
  //               userId: element.userId,
  //             };
  //             histTaskAmb.push(obj);
  //           });
  //         });

  //         histAgendt.forEach(item => {
  //           if (item.User) {
  //             const find = usersHist.find(i => item.User.id === i.id);
  //             if (!find) {
  //               const obj: HistUserAgend = {
  //                 avatar: item.User.avatar
  //                   ? `https://app-qrcode-avatar.s3.amazonaws.com/${item.User.avatar}`
  //                   : '',
  //                 companyId: item.User.companyId,
  //                 cpf: item.User.cpf,
  //                 createdAt: item.User.createdAt,
  //                 dataNascimento: item.User.dataNascimento,
  //                 email: item.User.email,
  //                 id: item.User.id,
  //                 name: item.User.nome,
  //                 nameFilial: '',
  //                 telefone: item.User.telefone,
  //               };
  //               usersHist.push(obj);
  //             }
  //           }
  //         });

  //         const oldDados = isShowDadosOld
  //           ? await getItensOld(String(companyId), dateStart, dateEnd)
  //           : [];

  //         oldDados.forEach(item => {
  //           const find = usersHist.find(i => i.id === item.id);
  //           if (!find) {
  //             usersHist.push(item);
  //           }
  //         });

  //         setResponse(usersHist);
  //         // setResponseAll(histTaskAmb);
  //         setLoading(false);
  //       })
  //       .catch(async () => {
  //         const oldDados = isShowDadosOld
  //           ? await getItensOld(String(companyId), dateStart, dateEnd)
  //           : [];

  //         setResponse(oldDados);
  //         setLoading(false);
  //         // console.log(err);
  //         // console.log(err.message);
  //         // console.log(err.response.data);
  //         addToast({
  //           type: 'error',
  //           title: 'Erro',
  //           description: 'Erro ao carregar dados, por favor atualize a página',
  //         });
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   },
  //   [addToast, empresaPrincipal, findStatus, getItensOld, isShowDadosOld],
  // );

  useEffect(() => {
    getFiliais();
    getListAsg();
  }, [getFiliais, getListAsg]);

  const handleMaskDate = useCallback(data => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoUserOld = useCallback(
    (
      userId: number,
      dataIni: string,
      dataFim: string,
    ): Promise<HistoricTaskAmb[]> => {
      return api
        .get(
          `/dash/user?userId=${userId}&dataInicio=${dataIni}&dataFim=${dataFim}`,
        )
        .then(resp => {
          // console.log('OLD', resp.data);
          const dados: ResponseGetHistoric[] = resp.data.data;
          const obsAll: ResponseGetHistoricObs[] = resp.data.obs;
          const dadosOld: HistoricTaskAmb[] = [];

          dados.forEach(item => {
            const find = dadosOld.find(
              i =>
                i.Ambiente.id === item.AMBIENTE_ID &&
                i.dateAgend === item.INICIO.slice(0, 10),
            );
            const firstCreated = !find ? '' : find.createdAt;
            const observ = obsAll.find(
              i =>
                i.createdAt.slice(0, 19) === item.INICIO.slice(0, 19) &&
                i.environmentId === item.AMBIENTE_ID &&
                i.userId === userId,
            );

            const obj: HistoricTaskAmb = {
              Ambiente: {
                id: item.AMBIENTE_ID,
                nome: item.AMBIENTE_NOME,
              },
              createdAt: item.INICIO,
              dateAgend: item.INICIO.slice(0, 10),
              finishAt: item.FIM,
              horaFinalAmbiente: item.HORA_FINAL_PRINCIPAL_AMBIENTE,
              horaInicialAmbiente: item.HORA_INICIAL_PRINCIPAL_AMBIENTE,
              id: item.ID,
              idTask: -1,
              imagem: item.IMAGEM,
              isRevision:
                firstCreated !== '' && firstCreated !== item.DATA_CRIACAO,
              nameTask: item.PROCEDIMENTO_NOME,
              nameUser: '',
              observacao: !observ ? '' : observ.observacao,
              description: '',
              revisions: [],
              status: !observ ? 'Completo' : 'Incompleto',
              userId,
            };

            dadosOld.push(obj);
          });

          return dadosOld;
        })
        .catch(err => {
          console.log('Err');
          return [];
        });
    },
    [],
  );

  const requestHistoricoUsuario = useCallback(
    async (dado: ListASG, dataIni: string, dateEnd: string) => {
      setDadosHistoricoModal([]);
      setLoading(true);
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '';
      const aux = isCoordenador
        ? `filialId=${filialId}`
        : `companyId=${companyId}`;

      api
        .get(
          `agendas/v2/Agendamentos/company?dataIni=${dataIni}&dataFim=${dateEnd}&asgId=${dado.id}&${aux}`,
        )
        .then(async resp => {
          const histAgendt: HistoricAgendament[] = resp.data;
          const histTaskAmb: HistoricTaskAmb[] = [];
          histAgendt.forEach(element => {
            const hist = element.Historic;
            const taskAll = element.Agenda.Environment.Procedures;
            const allHour: HourRev[] = [
              {
                horaIni: element.Agenda.Periodicidade.HORA_BASE_INICIAL,
                horaFim: element.Agenda.Periodicidade.HORA_BASE_FINAL,
                id: -1,
              },
              ...element.Agenda.Periodicidade.Revisoes.map(i => ({
                horaIni: i.HORA_INICIAL,
                horaFim: i.HORA_FINAL,
                id: i.ID,
              })),
            ];
            const histIdPegos: number[] = [];
            allHour.forEach((hour, indexHour) => {
              const hists: HistoricTaskAmb[] = [];
              taskAll.forEach(task => {
                const filterHist = hist
                  .filter(i => !histIdPegos.includes(i.id))
                  .filter(
                    i =>
                      i.tasksId === task.id &&
                      (i.revisionId === hour.id ||
                        (!i.revisionId && hour.id === -1)),
                  );
                if (filterHist.length > 0) {
                  const find = filterHist[0];
                  const obj: HistoricTaskAmb = {
                    Ambiente: {
                      id: element.Agenda.Id_Environments,
                      nome: element.Agenda.Environment.name,
                    },
                    createdAt: find.createdAt.slice(0, 19).split('T').join(' '),
                    dateAgend: element.dataAgend,
                    finishAt: find.updatedAt.slice(0, 19).split('T').join(' '),
                    horaFinalAmbiente: hour.horaFim,
                    horaInicialAmbiente: hour.horaIni,
                    id: element.id,
                    idTask: task.id,
                    imagem: !find.image
                      ? ''
                      : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${find.image}`,
                    isRevision: indexHour !== 0,
                    nameTask: task.name,
                    nameUser: !find.nameUser ? '' : find.nameUser.name,
                    observacao: find.observacao,
                    description: !find.ocorrencia ? '' : find.ocorrencia,
                    revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
                      horaFim: i.HORA_FINAL,
                      horaIni: i.HORA_INICIAL,
                    })),
                    status: 'Completo',
                    userId: element.userId,
                  };
                  histIdPegos.push(find.id);
                  hists.push(obj);
                } else {
                  const obj: HistoricTaskAmb = {
                    Ambiente: {
                      id: element.Agenda.Id_Environments,
                      nome: element.Agenda.Environment.name,
                    },
                    createdAt: '',
                    dateAgend: element.dataAgend,
                    finishAt: '',
                    horaFinalAmbiente: hour.horaFim,
                    horaInicialAmbiente: hour.horaIni,
                    id: element.id,
                    idTask: task.id,
                    imagem: '',
                    isRevision: indexHour !== 0,
                    nameTask: task.name,
                    nameUser: '',
                    observacao: '',
                    description: '',
                    revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
                      horaFim: i.HORA_FINAL,
                      horaIni: i.HORA_INICIAL,
                    })),
                    status: 'Não Realizado',
                    userId: element.userId,
                  };
                  hists.push(obj);
                }
              });
              const status = findStatus(
                hists.map(i => ({
                  obj: i.observacao,
                  status:
                    i.status === 'Completo' ? 'Completo' : 'Não Realizado',
                })),
                element.dataAgend,
              );
              hists.forEach(item => {
                histTaskAmb.push({ ...item, status });
              });
            });

            const histRest = hist.filter(i => !histIdPegos.includes(i.id));
            histRest.forEach(histRes => {
              const obj: HistoricTaskAmb = {
                Ambiente: {
                  id: element.Agenda.Id_Environments,
                  nome: element.Agenda.Environment.name,
                },
                createdAt: histRes.createdAt.slice(0, 19).split('T').join(' '),
                dateAgend: element.dataAgend,
                finishAt: histRes.updatedAt.slice(0, 19).split('T').join(' '),
                horaFinalAmbiente: '',
                horaInicialAmbiente: '',
                id: element.id,
                idTask: histRes.tasksId,
                imagem: !histRes.image
                  ? ''
                  : `https://app-qrcode-salaprocedimentos.s3.amazonaws.com/${histRes.image}`,
                isRevision: true,
                nameTask:
                  taskAll.find(i => histRes.tasksId === i.id)?.name || '',
                nameUser: !histRes.nameUser ? '' : histRes.nameUser.name,
                observacao: histRes.observacao,
                description: !histRes.ocorrencia ? '' : histRes.ocorrencia,
                revisions: element.Agenda.Periodicidade.Revisoes.map(i => ({
                  horaFim: i.HORA_FINAL,
                  horaIni: i.HORA_INICIAL,
                })),
                status: 'Completo',
                userId: element.userId,
              };
              histTaskAmb.push(obj);
            });
          });

          setUserSelected({
            cpf: dado.cpf,
            email: dado.email,
            name: dado.nome,
            telefone: dado.telefone,
          });

          const oldDados = isShowDadosOld
            ? await requestHistoricoUserOld(dado.id, dataIni, dateEnd)
            : [];

          oldDados.forEach(item => {
            const find = histTaskAmb.find(i => i.id === item.id);
            if (!find) {
              histTaskAmb.push(item);
            }
          });

          setLoading(false);
          setDadosHistoricoDatasModal(
            `De ${handleMaskDate(dataIni)} até ${handleMaskDate(dateEnd)}`,
          );
          setDadosHistoricoModal(histTaskAmb);
          setShowModalDetalhesAmbiente(true);
        })
        .catch(err => {
          console.log('AGE', err.message);
        });
    },
    [
      empresaPrincipal,
      filialId,
      findStatus,
      handleMaskDate,
      isCoordenador,
      isShowDadosOld,
      requestHistoricoUserOld,
    ],
  );

  const loadingItens = useCallback(
    (item: ListASG) => {
      const mesAtual = moment().format('YYYY-MM-DD');
      if (!searchDataInicial || !searchDataFinal) {
        return;
      }
      if (searchDataInicial <= mesAtual && searchDataFinal <= mesAtual) {
        requestHistoricoUsuario(item, searchDataInicial, searchDataFinal);
      } else {
        addToast({
          type: 'info',
          title: 'Filtro inválido',
          description: 'Intervalo de datas inválidos, favor verificar',
        });
      }
    },
    [addToast, requestHistoricoUsuario, searchDataFinal, searchDataInicial],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const asgsMemo = useMemo(() => {
    let computedResponses = asgs;
    computedResponses = computedResponses.map(i => {
      const findEmpresa = filiais.find(emp => emp.ID === i.companyId);
      return { ...i, nameFilial: findEmpresa ? findEmpresa.NOME : '' };
    });

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case 'Colaborador':
                dataFilter.push(res.nome);
                break;
              case 'Filial':
                dataFilter.push(res.nameFilial);
                break;
              case 'CPF':
                dataFilter.push(res.cpf);
                break;
              case 'E-mail':
                dataFilter.push(res.email);
                break;
              case 'Telefone':
                dataFilter.push(res.telefone);
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    if (filialId !== -1) {
      computedResponses = computedResponses.filter(
        i => i.companyId === filialId,
      );
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
    // return computedResponses;
  }, [
    ITEMS_PER_PAGE,
    asgs,
    currentPage,
    dataFilterModal,
    filiais,
    filialId,
    search,
  ]);

  // const responseData = useMemo(() => {
  //   let computedResponses = response;
  //   // console.log(response.map(i => i.avatar));

  //   computedResponses = computedResponses.map(i => {
  //     const findEmpresa = filiais.find(emp => emp.ID === i.companyId);
  //     return { ...i, nameFilial: findEmpresa ? findEmpresa.NOME : '' };
  //   });

  //   if (search) {
  //     computedResponses = computedResponses.filter(res => {
  //       const dataFilter: any = [];

  //       dataFilterModal.forEach(filtro => {
  //         if (filtro.status) {
  //           switch (filtro.name) {
  //             case 'Colaborador':
  //               dataFilter.push(res.name);
  //               break;
  //             case 'Filial':
  //               dataFilter.push(res.nameFilial);
  //               break;
  //             case 'CPF':
  //               dataFilter.push(res.cpf);
  //               break;
  //             case 'E-mail':
  //               dataFilter.push(res.email);
  //               break;
  //             case 'Telefone':
  //               dataFilter.push(res.telefone);
  //               break;
  //             default:
  //               break;
  //           }
  //         }
  //       });

  //       return dataFilter.some(
  //         (item: any) =>
  //           item &&
  //           item
  //             .toString()
  //             .toLowerCase()
  //             .includes(search.toString().toLowerCase()),
  //       );
  //     });
  //   }

  //   if (filialId !== -1) {
  //     computedResponses = computedResponses.filter(
  //       i => i.companyId === filialId,
  //     );
  //   }

  //   setTotalItems(computedResponses.length);
  //   if (ITEMS_PER_PAGE === 1) {
  //     return computedResponses;
  //   }

  //   return computedResponses.slice(
  //     (currentPage - 1) * ITEMS_PER_PAGE,
  //     currentPage * ITEMS_PER_PAGE,
  //   );
  // }, [
  //   response,
  //   search,
  //   filialId,
  //   ITEMS_PER_PAGE,
  //   currentPage,
  //   filiais,
  //   dataFilterModal,
  // ]);

  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashColaboradores"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      {!!dadosModal && (
        <ModalUserDetails
          isOpen={showModalDetalhesUser}
          dados={{
            avatarURL: dadosModal.avatar,
            cpf: dadosModal.cpf,
            dataNascimento: dadosModal.dataNascimento,
            email: dadosModal.email,
            nome: dadosModal.nome,
            telefone: dadosModal.telefone,
          }}
          onRequestClose={() => setShowModalDetalhesUser(false)}
        />
      )}

      {!!userSelected && !!dadosHistoricoModal && !!dadosHistoricoDatasModal && (
        <ModalHistoricoColaboradorV2
          isOpen={showModalDetalhesAmbiente}
          user={userSelected}
          dados={dadosHistoricoModal}
          dadosHistoricoDatas={dadosHistoricoDatasModal}
          onRequestClose={() => {
            setShowModalDetalhesAmbiente(false);
          }}
        />
      )}

      <Container>
        <ContainerTitle>
          <h1>Colaboradores</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização detalhada do histórico de atividades realizadas pelos ASGs. Filtragem a partir dos colaboradores."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        {!isCoordenador && (
          <ContainerSelect>
            <select
              value={filialId}
              disabled={isCoordenador}
              onChange={event => {
                const val = parseInt(event.target.value, 10);
                setFilialId(val);
              }}
            >
              <option value={-1}>Todos</option>
              {filiais.map(i => (
                <option key={i.ID} value={i.ID}>
                  {i.NOME}
                </option>
              ))}
            </select>
          </ContainerSelect>
        )}

        <ContainerSearch isFilterDateInterval>
          <Search
            value={searchDataInicial}
            onSearch={(value: string) => {
              setSearchDataInicial(value);
            }}
            nomePlaceHolder="Buscar data inicial"
            type="date"
          />

          <Search
            value={searchDataFinal}
            onSearch={(value: string) => {
              setSearchDataFinal(value);
            }}
            nomePlaceHolder="Buscar data final"
            type="date"
          />
        </ContainerSearch>

        <ContainerSearch isFilterDateInterval={false}>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />

          <button type="button" onClick={getListAsg}>
            <FiSearch size={20} />
          </button>

          <button type="button" onClick={() => setShowModalFilter(true)}>
            <img src={IconFilter} alt="iconFilter" />
          </button>
        </ContainerSearch>

        <DivCheck>
          <input
            type="checkbox"
            checked={isShowDadosOld}
            onChange={() => setIsShowDadosOld(!isShowDadosOld)}
          />
          <p>Mostrar dados anteriores à Agosto/2022</p>
          <Tooltip
            title="Marque somente caso queira obter dados da versão anterior do sistema referente aos dados inseridos antes de agosto de 2022."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </DivCheck>

        {asgs.length === 0 && (
          <ContainerSemResultados>
            <h2>
              Não existe um histórico de colaboradores para o intervalo de datas
              atual.
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens>
          <>
            {asgsMemo.map((res, index) => (
              <ContainerItem
                key={`${res.id}-${res.createdAt}/Colaboradores`}
                isColor={index % 2 !== 0}
              >
                <button
                  type="button"
                  onClick={() => {
                    setShowModalDetalhesUser(true);
                    setDadosModal(res);
                  }}
                >
                  <img
                    src={!res.avatar ? imgAvatar : res.avatar}
                    alt={res.nome}
                  />
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setDadosModal(res);
                    loadingItens(res);
                  }}
                >
                  <ul>
                    <li>
                      <strong>Nome</strong>
                      <span>{res.nome}</span>
                    </li>
                    <li>
                      <strong>CPF</strong>
                      <span>{maskCpf(res.cpf)}</span>
                    </li>
                    <li>
                      <strong>E-mail</strong>
                      <span>{res.email}</span>
                    </li>
                    <li>
                      <strong>Telefone</strong>
                      <span>{maskTel(res.telefone)}</span>
                    </li>
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}

            <table id="tableRelatorioColaboradores" style={{ display: 'none' }}>
              <HeaderTable headers={headers} />
              <tbody>
                {asgsMemo.map(res => (
                  <tr key={`${res.id}-${res.createdAt}/Colaboradores`}>
                    <td>{res.nome}</td>
                    <td>{maskCpf(res.cpf)}</td>
                    <td>{res.email}</td>
                    <td>{maskTel(res.telefone)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {asgs.length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'Todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioColaboradores"
                filename={`relatorio-colaboradores-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText="Exportar Excel"
              />
            </>
          )}
        </ContainerPagination>
      </Container>

      {loading && <Loading />}
    </>
  );
};

import styled from 'styled-components';

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;

  svg {
    margin: 0px auto;
    height: 100px;
    width: 100px;
    color: gray;
  }
`;

export const ContainerHeaderDados = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;

    & + p {
      font-weight: normal;
      margin-top: 10px;
      font-size: 18px;
    }
  }
`;

export const ContainerButtonAtualizar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 40px;
  }
`;

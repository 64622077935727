import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { FiUser } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';

import moment from 'moment';
import { ContainerHeader, ContainerHeaderDados } from './styles';
import api from '../../services/api';
import { Revisao } from '../../models/Revisao';
import { Loading } from '../Loading';
import { Agendamentos } from '../../models/Agendamentos';
import { HistoryTaskAgendamento } from '../../models/Historic';
import { formatDate } from '../../utils';

interface Obs {
  TaskId: number;
  createdAt: string;
  environmentId: number;
  id: number;
  observacao: string;
  updatedAt: string;
  userId: number;
}

interface DataResponse {
  id_historico: number;
  id_usuario: number;
  nome_usuario: string;
  cpf: string;
  email_usuario: string;
  avatar: string;
  telefone: string;
  ambiente_nome: string;
  procedimento: string;
  setor_id: number;
  setor_nome: string;
  categoria_id: number;
  categoria_nome: string;
  andar: string;
  imagem: string;
  data_inicio: string;
  data_fim: string;
  Revisao: boolean;
  HORA_INICIAL_PRINCIPAL_AMBIENTE: string;
  HORA_FINAL_PRINCIPAL_AMBIENTE: string;
  HORA_REVISAO_INICIAL: string;
  HORA_REVISAO_FINAL: string;
  DATA_CRIACAO: string;
  DATA_FINALIZACAO: string;
  revisao_id_table_revisao: number;
  imagem_revisao: string;
  OBSERVACAO: string;
}

interface ResponseInfoAll {
  data: DataResponse[];
  obs: Obs[];
  revisao: Revisao[];
}

interface HorariosAgenda {
  horaInit: string;
  horaFinal: string;
}

interface HorariosColab {
  horaInit: string;
  horaFinal: string;
  observacao: string;
  isFazendo: boolean;
  isStart: boolean;
}

interface HistoricoMini {
  horaInit: string;
  horaFim: string;
  idUser: number;
  idAmbient: number;
  isRevisao: boolean;
  createAt: string;
  finishAt: string;
  observacao: string;
}

interface DateHorariosColab {
  date: string;
  horarios: HorariosColab[];
}

interface Props {
  visible: boolean;
  onClose(): void;
  agenda: Agendamentos;
  date: string;
}

Modal.setAppElement('#root');

export const ModalAgendaDash: React.FC<Props> = ({
  agenda,
  onClose,
  visible,
  date,
}) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<DateHorariosColab[]>([]);

  function NumberDiasSemana(text: string): number {
    switch (text) {
      case 'Domingo':
        return 0;
      case 'Segunda':
        return 1;
      case 'Terça':
        return 2;
      case 'Quarta':
        return 3;
      case 'Quinta':
        return 4;
      case 'Sexta':
        return 5;
      case 'Sábado':
        return 6;
      default:
        return 0;
    }
  }

  useEffect(() => {
    setLoading(true);
    if (agenda.Agenda.ID === -1) {
      return;
    }
    api
      .get(
        `/historic/v2/agenda?agendaId=${agenda.Agenda.ID}&dataIni=${date}&dataFim=${date}`,
      )
      .then(response => {
        const histAll: HistoryTaskAgendamento[] = response.data;
        
        const horariosDate: DateHorariosColab[] = [];
        histAll.forEach(item => {
          // if(user.id === item.userId){
          const dateItem = item.createdAt.slice(0, 10);
          // console.log(dateItem);
          const findIndex = horariosDate.findIndex(i => i.date === dateItem);
          if (findIndex !== -1) {
            const findCreated = horariosDate[findIndex].horarios.find(
              i => i.horaInit === item.createdAt.slice(11, 16),
            );
            if (!findCreated) {
              horariosDate[findIndex].horarios.push({
                horaFinal: moment(item.updatedAt).format('HH:mm'),
                horaInit: moment(item.createdAt).format('HH:mm'),
                isFazendo: false,
                isStart: false,
                observacao: item.observacao,
              });
            }
          } else {
            const newObj: DateHorariosColab = {
              date: item.createdAt.slice(0, 10),
              horarios: [
                {
                  horaFinal: moment(item.updatedAt).format('HH:mm'),
                  horaInit: moment(item.createdAt).format('HH:mm'),
                  isFazendo: item.Agendamento.status === 'Andamento',
                  isStart: false,
                  observacao: item.observacao,
                },
              ],
            };
            horariosDate.push(newObj);
          }
          // }
        });

        setHistory(horariosDate);
        setLoading(false);
      })
      .catch(error => {
        // console.log("Dados não encontrados.")
        console.log(error.message);
        setLoading(false);
      });
  }, [agenda.Agenda.ID, agenda.Agenda.Id_Environments, agenda.userId, date]);

  const horAgenda: HorariosAgenda[] = useMemo(() => {
    // console.log(agenda.dataAgend, date, agenda.dataAgend === date);
    const temAgenda = String(agenda.dataAgend) === date;
    const horariosAgenda: HorariosAgenda[] = [];

    if (temAgenda) {
      horariosAgenda.push({
        horaFinal: agenda.Agenda.Periodicidade.HORA_BASE_FINAL,
        horaInit: agenda.Agenda.Periodicidade.HORA_BASE_INICIAL,
      });
      agenda.Agenda.Periodicidade.Revisoes.forEach(itemRev => {
        if (itemRev) {
          horariosAgenda.push({
            horaInit: itemRev.HORA_INICIAL,
            horaFinal: itemRev.HORA_FINAL,
          });
        }
      });
      return horariosAgenda;
    }
    return [];
  }, [date, agenda]);

  const horColab: HorariosColab[] = useMemo(() => {
    // console.log('DATE SELECT', date);
    const horariosOfColab: HorariosColab[] = [];

    const ambAll = history.filter(item => date === item.date);
    ambAll.forEach(item => {
      item.horarios.forEach(i => {
        horariosOfColab.push(i);
      });
    });

    horariosOfColab.sort((a, b) => {
      const valueInitA = Number(a.horaInit.split(':').join(''));
      const valueInitB = Number(b.horaInit.split(':').join(''));
      if (valueInitA < valueInitB) return -1;
      if (valueInitA > valueInitB) return 1;

      const valueFimA = Number(a.horaFinal.split(':').join(''));
      const valueFimB = Number(b.horaFinal.split(':').join(''));
      if (valueFimA < valueFimB) return -1;
      if (valueFimA > valueFimB) return 1;
      return 0;
    });

    if (horAgenda.length > horariosOfColab.length) {
      const val = horAgenda.length - horariosOfColab.length;
      for (let i = 0; i < val; i += 1) {
        horariosOfColab.push({
          horaFinal: '',
          horaInit: '',
          isFazendo: agenda.status === 'Andamento',
          isStart: agenda.status !== 'Andamento',
          observacao: '',
        });
      }
    }
    return horariosOfColab;
  }, [date, history, horAgenda.length]);

  const renderItem = useCallback((horaColaborador: HorariosColab) => {
    if (!horaColaborador) {
      return <p />;
    }
    if (horaColaborador.isStart) {
      return <span>A iniciar</span>;
    }
    if (horaColaborador.isFazendo) {
      return <span>Em andamento</span>;
    }
    if (horaColaborador.observacao !== '') {
      return (
        <span>
          {horaColaborador.horaInit} - {horaColaborador.horaFinal}
        </span>
      );
    }

    return (
      <span>
        {horaColaborador.horaInit} - {horaColaborador.horaFinal}
      </span>
    );
  }, []);

  const renderHorAgenda = useCallback((horaAgenda: HorariosAgenda) => {
    if (!horaAgenda) {
      return <span>Fez a mais</span>;
    }
    return (
      <span>
        {horaAgenda.horaInit} - {horaAgenda.horaFinal}
      </span>
    );
  }, []);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <>
        <button type="button" className="react-modal-close" onClick={onClose}>
          <AiOutlineClose />
        </button>

        <ContainerHeader>
          <h1>AGENDA</h1>
        </ContainerHeader>

        <ContainerHeaderDados>
          <div className="info">
            <div className="agenda">
              <p>Ambiente: {agenda.Agenda.Environment.name}</p>
              <span>Setor: {agenda.Agenda.Environment.Setor.name}</span>

              <span>Categoria: {agenda.Agenda.Environment.Categoria.name}</span>
              <span>{agenda.Agenda.Periodicidade.DIAS_SEMANA}</span>
            </div>
            <div className="user">
              {agenda.User.avatar ? (
                <img
                  src={`https://app-qrcode-avatar.s3.amazonaws.com/${agenda.User.avatar}`}
                  alt="foto do asg"
                />
              ) : (
                <FiUser />
              )}
              <span style={{ textAlign: 'center' }}>{agenda.User.nome}</span>
            </div>
          </div>
          <div className="dados">
            <div className="title">
              <p>Horário</p>
              <p>Realizado</p>
            </div>
            <div className="table">
              {horColab.map((rev, index) => (
                <div key={index.toString() + rev.toString()}>
                  {renderHorAgenda(horAgenda[index])}
                  {renderItem(rev)}
                </div>
              ))}
            </div>
          </div>
          {loading && <Loading />}
        </ContainerHeaderDados>
      </>
    </Modal>
  );
};

import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import IconFileUpload from '../../assets/IconFileUpload.svg';

import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  onUpload?(e: File): void;
}

export const UploadImg: React.FC<UploadProps> = ({
  onUpload = () => {
    ('');
  },
}) => {
  const renderDragMessage = useCallback((isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          <img src={IconFileUpload} alt="fileUpload" /> Arraste a imagem para cá
          ou procure
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }, []);

  return (
    <Dropzone accept="image/*" onDropAccepted={(file: any) => onUpload(file)}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          className="dropzone"
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

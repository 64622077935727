export interface User {
  ativo: number;
  avatar: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
  verified: boolean;
  isPhysicalPerson: boolean;
  loggedFirstTime: boolean;
  allowedCreateUsers: boolean;
  roleId: 1 | 2 | 3 | 4 | 5 | null;
  // verificationCodeId: null;
}

export interface UserGet extends User {
  avatar_url: string;
}

export const EmptyUser: UserGet = {
  ativo: -1,
  avatar: '',
  avatar_url: '',
  companyId: -1,
  coordenador: -1,
  cpf: '',
  createdAt: '',
  dataNascimento: '',
  email: '',
  id: -1,
  nome: '',
  telefone: '',
  tipo: '',
  updatedAt: '',
  isPhysicalPerson: false,
  loggedFirstTime: false,
  verified: false,
  allowedCreateUsers: false,
  roleId: null,
};

export interface ItemListName {
  id: number;
  name: string;
  tb_UsersId: number;
  deletedAt: null | string;
  createdAt: string;
  updatedAt: string;
}

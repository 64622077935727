import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  widthProps?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  widthProps = '',
  ...rest
}) => (
  <Container type="button" {...rest} style={{ width: widthProps }}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import {
  AiFillCaretLeft,
  AiFillCaretDown,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'antd';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { InputOverview } from '../../../components/InputOverview';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import Button from '../../../components/Button';
import { SelectCustom } from '../../../components/SelectCustom';
import getValidationErrors from '../../../utils/getValidationErrors';
import { maskCpf, maskCnpj } from '../../../components/InputOverview/mask';
import IconFileUploadBtn from '../../../assets/IconFileUploadBtn.svg';
import { arquivoModeloPreCadastroUrlAws } from '../../../utils/urlsBucketAws';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerPagination,
  ContainerTable,
  AnimationContainer,
  ContainerButton,
  AbaMenu,
  ContainerSemResultados,
  ConainerCadastroOption,
  RadioBox,
} from './styles';
import { SetoresInterface } from '../Ambientes';

interface Uploaded {
  // file: File | null;
  // id: string;
  // name: string;
  // readableSize: string;
  // preview: string;
  // progress: number;
  // uploaded: boolean;
  // error: boolean;
  // url: string;

  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

interface SignUpFormData {
  cpf: string;
}

interface ResponseGet {
  ATIVO: boolean;
  CNPJ: string;
  CPF: string;
  ENDERECO: string;
  ID_FILIAL: number;
  Id_Empresas: number;
  NOME_FILIAL: string;
  NUMERO: string;
  checked: boolean;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

const headers = [
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Empresa', field: 'NOME_FILIAL', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Status', field: 'ATIVO', sortable: true },
  { name: '', field: 'checkbox', sortable: false },
];

const headersViewer = [
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Empresa', field: 'NOME_FILIAL', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Status', field: 'ATIVO', sortable: true },
];

const nivelAcessoViw: SetoresInterface[] = [
  { name: 'Admin', id: 2, EMPRESA_ID: 0 },
  { name: 'Coordenador', id: 3, EMPRESA_ID: 0 },
  { name: 'Auditor', id: 4, EMPRESA_ID: 0 },
  { name: 'ASG', id: 5, EMPRESA_ID: 0 },
];

export const PreCadastroUsuarios: React.FC = () => {
  const { user, cpfUserMaster, empresaPrincipal } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseCpf, setResponseCpf] = useState<string[]>([]);
  const [nivel, setNivel] = useState('ASG');

  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [uploadedFilesTemp, setUploadedFilesTemp] = useState<Uploaded[]>([]);
  const [cadastrar, setCadastrar] = useState(true);

  const [setaExportar, setSetaExportar] = useState('AiFillCaretLeft');

  const [search, setSearch] = useState('');

  const [empresas, setEmpresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>();
  const [empresaIdLote, setEmpresaIdLote] = useState<number>();

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [registerType, setRegisterType] = useState('individual');

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getFuncionario = useCallback(() => {
    setLoadingUser(true);
    let param;

    if (empresaPrincipal.length > 0) param = user.companyId;

    api
      .get(`empresas/funcionarios/${param}`)
      .then(data => {
        const dadosCopiaTemp = data.data.map((dado: ResponseGet) => {
          return { ...dado, checked: false };
        });
        setLoadingUser(false);
        setResponse(dadosCopiaTemp);
      })
      .catch(() => {
        setLoadingUser(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal.length, user.companyId]);

  const getFiliais = useCallback(() => {
    setLoading(true);
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    api
      .get(`empresas/filiais?companyId=${companyId}`)
      .then(resp => {
        const emp: EmpresasInterface[] = [];
        resp.data.forEach((filial: EmpresasInterface) => {
          if (filial.ATIVO === true) {
            emp.push(filial);
          }
        });

        setEmpresas(emp);
        setVerificaResponse(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, empresaPrincipal]);

  useEffect(() => {
    getFuncionario();
    getFiliais();
  }, [getFiliais, getFuncionario]);

  const updateFile = useCallback((id, data) => {
    setUploadedFiles(estadoAnterior =>
      estadoAnterior.map(file =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();

      if (uploadedFile.file && cpfUserMaster[0].Id_Empresas && empresaIdLote) {
        data.append('csv', uploadedFile.file);
        data.append('idEmpresa', cpfUserMaster[0].Id_Empresas.toString());
        data.append('idEmpresaFilial', empresaIdLote.toString());
      }

      api
        .post('empresas/saveCSVFuncionarios', data, {
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then(() => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });

          setUploadedFilesTemp([]);

          setTimeout(() => {
            history.go(0);
          }, 1500);
        })
        .catch(() => {
          updateFile(uploadedFile.id, {
            error: true,
          });
        });
    },
    [cpfUserMaster, empresaIdLote, history, updateFile],
  );

  const handleUpload = useCallback(
    files => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        // PARA ARQUIVO DE IMAGEM
        // file,
        // id: uuid(),
        // name: file.name,
        // readableSize: filesize(file.size),
        // preview: <GrDocumentCsv />, // URL.createObjectURL(file),
        // progress: 0,
        // uploaded: false,
        // error: false,
        // url: '',

        // PARA ARQUIVO CSV
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));

      setUploadedFilesTemp(uploadedFilesHandleUpload);
    },
    [uploadedFiles],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFilesTemp.length) {
      addToast({
        type: 'error',
        title: "Erro ao pré-cadastrar lista de CPF's",
        description:
          'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto',
      });
    } else {
      uploadedFilesTemp.forEach(processUpload);
    }
  }, [addToast, processUpload, uploadedFilesTemp]);

  const handleDelete = useCallback(
    async id => {
      uploadedFiles.splice(
        uploadedFiles.findIndex(element => element.id === id),
        1,
      );

      uploadedFilesTemp.splice(
        uploadedFilesTemp.findIndex(element => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
      setUploadedFilesTemp([...uploadedFilesTemp]);
    },
    [uploadedFiles, uploadedFilesTemp],
  );

  /*-------------------------------------------------------------------------*/

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefIndividual.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF do usuário é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let CPF = data.cpf.replace(/\./g, '');
        CPF = CPF.replace(/-/g, '');

        if (CPF.split('').length !== 11) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Favor inserir um CPF válido',
          });
        } else {
          // if()
          await api
            .post('empresas/saveFuncionarios', {
              cpf: CPF,
              idEmpresa: empresaPrincipal[0].Id_Empresas,
              idEmpresaFilial: empresaId,
            })
            .then(() => {
              setLoading(false);

              addToast({
                type: 'success',
                title: 'Sucesso',
                description: 'CPF pré-cadastrado com sucesso',
              });

              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);

              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao pré-cadastrar CPF',
              });
            });
        }
      } catch (err) {
        // console.log('CACTH', err);
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Houve um erro',
            description:
              'Ocorreu um erro inexperado, tente novamente mais tarde.',
          });
        }
      }
    },
    [addToast, empresaId, empresaPrincipal, history],
  );

  const handleState = useCallback(
    async dataAction => {
      if (!responseCpf.length) {
        addToast({
          type: 'info',
          title: 'Nenhum registro selecionado',
          description: 'Favor selecionar pelo menos um registro',
        });
      } else {
        try {
          setLoading(true);

          let cont = 0;

          const arrayAction: string[] = [];
          for (let i = 0; i < responseCpf.length; i += 1) {
            if (cpfUserMaster[0].CPF === responseCpf[i]) cont += 1;

            if (dataAction === 1) {
              arrayAction.push('1');
            } else if (dataAction === 2) {
              arrayAction.push('2');
            } else if (dataAction === 3) {
              arrayAction.push('3');
            }
          }

          if (cont === 0) {
            api
              .put('empresas/ativaInativaDeleteFuncionarios', {
                acao: arrayAction,
                cpf: responseCpf,
              })
              .then(() => {
                setLoading(false);

                addToast({
                  type: 'success',
                  title: 'Sucesso',
                  description: 'Usuário(s) atualizado(s) com sucesso',
                });

                setTimeout(() => {
                  history.go(0);
                }, 1000);
              })
              .catch(() => {
                setLoading(false);

                addToast({
                  type: 'error',
                  title: 'Erro',
                  description: 'Erro ao atualizar status do(s) usuário(s)',
                });
              });
          } else {
            setLoading(false);

            addToast({
              type: 'info',
              title: 'Função bloqueada',
              description:
                'O usuário ADMINISTRADOR não pode alterar o PRÓPRIO status',
            });
          }
        } catch (err) {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao atualizar status do(s) usuário(s)',
          });
        }
      }
    },
    [addToast, cpfUserMaster, history, responseCpf],
  );

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponse = response.map(dado => {
        return JSON.stringify(dado) === JSON.stringify(dadoParam)
          ? { ...dado, checked: !dado.checked }
          : dado;
      });

      const arrayCpfResponse: string[] = [];
      newResponse.forEach(data => {
        if (data.checked) arrayCpfResponse.push(data.CPF);
      });

      setResponse(newResponse);
      setResponseCpf(arrayCpfResponse);
    },
    [response],
  );

  const confirmHandleStatus = useCallback(
    (dataAction: number) => {
      if (dataAction === 3) {
        Swal.fire({
          title: 'Tem certeza que deseja ativar o(s) usuário(s)?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: 'Sim',
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      } else if (dataAction === 1) {
        Swal.fire({
          title: 'Tem certeza que deseja inativar o(s) usuário(s)?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: 'Sim',
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      } else if (dataAction === 2) {
        Swal.fire({
          title: 'Tem certeza que deseja remover o(s) usuário(s)?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          confirmButtonColor: colors.greenPrimary,
          cancelButtonColor: colors.redPrimary,
          confirmButtonText: 'Sim',
        }).then(result => {
          if (result.isConfirmed) {
            handleState(dataAction);
          }
        });
      }
    },
    [colors.greenPrimary, colors.redPrimary, handleState],
  );

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse) {
      setCadastrar(false);
      setDownload('');
      setSearch('');
    }
  }, [verificaResponse]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (search) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        [res.CPF, res.CNPJ, res.NOME_FILIAL].some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object') {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
        return (
          reversed *
          a[sorting.field].toString().localeCompare(b[sorting.field].toString())
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const empresaSel = useMemo(() => {
    if (empresaId === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setEmpresaId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === empresaId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [empresaId, empresas, isCoordenador, user.companyId]);

  const empresaLoteSel = useMemo(() => {
    if (empresaIdLote === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setEmpresaIdLote(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === empresaIdLote) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [empresaIdLote, empresas, isCoordenador, user.companyId]);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                Listar CPF's
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button type="button" onClick={handleAlertMaster}>
                Cadastrar CPF's
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição (ativar, inativar ou remover) de todos os CPF's liberados para cadastro e acesso ao sistema web e/ou mobile"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                Listar CPF's
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todos os CPF's liberados para cadastro e acesso ao sistema web e/ou mobile"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && (
          <ContainerSearch isAdmin={!!cpfUserMaster.length}>
            <>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar"
              />

              {!!response.length &&
                empresaPrincipal.length > 0 &&
                !!cpfUserMaster.length && (
                  <AbaMenu>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="dropbtn"
                        onFocus={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onMouseOver={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onBlur={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                        onMouseOut={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          Status do(s) Usuário(s)
                        </p>
                        {setaExportar === 'AiFillCaretLeft' ? (
                          <AiFillCaretLeft />
                        ) : (
                          <AiFillCaretDown />
                        )}
                      </button>
                      <div
                        className="dropdown-content"
                        onFocus={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onMouseOver={(): void => {
                          setSetaExportar('AiFillCaretDown');
                        }}
                        onBlur={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                        onMouseOut={(): void => {
                          setSetaExportar('AiFillCaretLeft');
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(3)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          Ativar usuário(s)
                        </button>
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(1)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          Inativar usuário(s)
                        </button>
                        <button
                          type="button"
                          onClick={() => confirmHandleStatus(2)}
                          style={{
                            borderRadius: '0px',
                          }}
                        >
                          Remover usuário(s)
                        </button>
                      </div>
                    </div>
                  </AbaMenu>
                )}
            </>
          </ContainerSearch>
        )}

        <section>
          {!cadastrar && (
            <ConainerCadastroOption>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('individual')}
              >
                Cadastro individual
              </RadioBox>
              <RadioBox type="button" onClick={() => setRegisterType('lote')}>
                Cadastro em lote
              </RadioBox>
            </ConainerCadastroOption>
          )}

          {!cadastrar &&
            (registerType === 'individual' ? (
              <AnimationContainer>
                <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                  <p>Empresa</p>
                  <SelectCustom
                    name="Empresa"
                    defaultValue="Empresa"
                    optionsDataCompany={empresas}
                    value={empresaSel}
                    disabled={isCoordenador}
                    onValue={e => setEmpresaId(parseInt(e.split('-')[0], 10))}
                  />

                  <p>CPF</p>
                  <InputOverview
                    name="cpf"
                    placeholder="CPF do usuário"
                    mask="cpf"
                  />

                  <p>Nível de Acesso</p>
                  <SelectCustom
                    type="sectors"
                    name="Nivel"
                    defaultValue="nivel"
                    value={nivel}
                    optionsDataSectors={nivelAcessoViw}
                    onValue={e => {
                      // console.log(e);
                      setNivel(e);
                    }}
                  />
                  <Button type="submit" widthProps="100%">
                    Cadastrar
                  </Button>
                </Form>
              </AnimationContainer>
            ) : (
              <AnimationContainer>
                <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                  <p>Empresa</p>
                  <SelectCustom
                    name="Empresa"
                    defaultValue="Empresa"
                    optionsDataCompany={empresas}
                    value={empresaLoteSel}
                    disabled={isCoordenador}
                    onValue={e => {
                      setEmpresaIdLote(parseInt(e.split('-')[0], 10));
                    }}
                  />
                  <Content>
                    <Upload onUpload={(e: any) => handleUpload(e)} />
                    {!!uploadedFiles.length && (
                      <FileList
                        files={uploadedFiles}
                        onDelete={(e: string) => handleDelete(e)}
                      />
                    )}
                  </Content>
                  <ContainerButton>
                    <Button type="submit" widthProps="100%">
                      Cadastrar
                    </Button>
                    <Button
                      type="button"
                      widthProps="100%"
                      className="divButtonDownload"
                      onClick={() => {
                        setDownload(arquivoModeloPreCadastroUrlAws);
                        setCount(old => old + 1);
                      }}
                    >
                      <img src={IconFileUploadBtn} alt="iconFileUploadBtn" />
                      Baixar Modelo
                    </Button>
                    {download && (
                      <iframe
                        title="downloadModeloCsv"
                        src={`${download}?c=${count}`}
                        hidden
                      />
                    )}
                  </ContainerButton>
                </Form>
              </AnimationContainer>
            ))}

          {cadastrar && !response.length && (
            <ContainerSemResultados>
              <h2>Não existem CPF's pré-cadastrados ainda.</h2>
            </ContainerSemResultados>
          )}

          {cadastrar && !!response.length && (
            <>
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {responseData.map(res => (
                      <tr
                        key={`${res.CPF}-${res.CNPJ}-${res.NOME_FILIAL}-${res.ATIVO}`}
                      >
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.NOME_FILIAL ? res.NOME_FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {!res.ATIVO && 'Inativo'}
                          {!!res.ATIVO && 'Ativo'}
                        </td>
                        {empresaPrincipal.length > 0 && (
                          <td>
                            <Checkbox
                              onChange={() => onButtonClick(res)}
                              checked={res.checked}
                              disabled={!cpfUserMaster.length}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table id="tableListagemUsuarios" style={{ display: 'none' }}>
                  <HeaderTable
                    headers={
                      empresaPrincipal.length > 0 ? headers : headersViewer
                    }
                    onSorting={(field: string, order: string) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {responseData.map(res => (
                      <tr
                        key={`${res.CPF}-${res.CNPJ}-${res.NOME_FILIAL}-${res.ATIVO}`}
                      >
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.NOME_FILIAL ? res.NOME_FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {!res.ATIVO && 'Inativo'}
                          {!!res.ATIVO && 'Ativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {response.length > 0 && (
                  <>
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />

                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemUsuarios"
                      filename={`listagem-cpfs-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                  </>
                )}
              </ContainerPagination>
            </>
          )}
        </section>
      </Container>

      {loading && <Loading />}
    </>
  );
};

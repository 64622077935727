import styled, { css } from 'styled-components';

interface DropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface UploadMessageProps {
  type?: string;
}

const dragActive = css`
  border-color: ${props => props.theme.colors.greenPrimary};
`;

const dragReject = css`
  border-color: ${props => props.theme.colors.redPrimary};
`;

export const DropContainer = styled.div<DropContainerProps>`
  color: ${props => props.theme.colors.textBlack};
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

const messageColors: any = {
  default: '#B0B0B0',
  error: '#EB5757',
  success: '#219653',
};

export const UploadMessage = styled.p<UploadMessageProps>`
  color: ${props => messageColors[props.type || 'default']};
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: ${props => props.theme.colors.textBlack};

  img {
    width: 24px !important;
    height: 24px !important;
    margin-right: 16px;
  }
`;

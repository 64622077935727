import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerModal = styled.div`
  background: ${props => props.theme.colors.backgroundWhite};
  border-radius: 16px;
  position: relative;
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;

  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  max-height: calc(100vh - 430px);
  padding: 0 30px 5px 5px;
  overflow: auto;
`;

export const HistoricoVazio = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 15px;

  p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: ${props => props.theme.colors.textBlack};
  }
`;

export const ContainerListCards = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
`;

export const ContainerHeaderDados = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${props => props.theme.colors.textBlack};
`;

export const ContainerButtonAtualizar = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 1152px) {
    justify-content: flex-start;

    button {
      width: 25% !important;
    }
  }
`;

export const ContainerButtonsPdfExcel = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;

  button {
    background: ${props => props.theme.colors.bluePrimary};
    border-radius: 8px;
    padding: 12px;
    border: none;

    svg {
      width: 32px;
      height: 32px;
      color: ${props => props.theme.colors.whitePrimary};

      @media screen and (max-width: 1152px) {
        width: 24px;
        height: 24px;
      }
    }

    & + button {
      margin-left: 12px;
    }

    transition: background 0.2s;

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ContainerButtonDetalhes = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  button:last-child {
    background: transparent;
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 16px;
    margin-left: 12px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: ${props => props.theme.colors.textBlack};

    &:hover {
      background: ${props => shade(0.1, props.theme.colors.whitePrimary)};
    }
  }

  @media screen and (max-width: 1152px) {
    justify-content: flex-start;

    button {
      width: 25% !important;
    }
  }
`;

export const ContainerMap = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;
  border: none !important;
  border-left: 24px solid ${props => props.theme.colors.bluePrimary} !important;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 27px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      & + li {
        margin-left: 70px;
      }

      strong {
        font-weight: 600;
        color: ${props => props.theme.colors.textBlack};
        margin-bottom: 5px;
      }

      span {
        max-width: 400px;
        font-weight: normal;
        color: ${props => props.theme.colors.textBlack};
      }
    }
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }

  article {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerMapDetails = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;
  border: none !important;

  display: flex;
  align-items: center;
  flex: 1;

  ul {
    margin-left: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 27px;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      strong {
        font-weight: 600;
        color: ${props => props.theme.colors.textBlack};
        margin-bottom: 5px;
      }

      span {
        max-width: 400px;
        font-weight: normal;
        color: ${props => props.theme.colors.textBlack};
        margin-left: 12px;
      }

      a {
        margin-left: 12px;
        text-decoration: underline;
      }
    }
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }

  article {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerMapObservacao = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin: 30px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1152px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  strong {
    border: 1px solid ${props => props.theme.colors.textBlack};
    box-sizing: border-box;
    border-radius: 20px;
    padding: 13px 34px;

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.textBlack};
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerTableMacon = styled.div`
  display: none;
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 35vh;
  overflow-x: auto;

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid ${props => props.theme.colors.bluePrimary};
    border-top: 0px;

    th {
      border-bottom: 1px solid ${props => props.theme.colors.bluePrimary};
      justify-content: space-between;
      padding: 5px 10px;
      background-color: ${props => props.theme.colors.bluePrimary};
      color: ${props => props.theme.colors.whitePrimary};
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: ${props => props.theme.colors.whitePrimary};
        border: 0;

        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid ${props => props.theme.colors.bluePrimary};
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: ${props => props.theme.colors.textBlack};
      }
      &:hover {
        background-color: ${props => props.theme.colors.grayBlack};
      }
    }
  }
`;

export const IconeAtrasado = styled.div`
  margin: 0 20px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  svg {
    animation: glowing 2000ms infinite;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  @keyframes glowing {
    0% {
      color: ${props => props.theme.colors.redPrimary};
      transform: scale(1);
    }
    25% {
      color: ${props => props.theme.colors.orangePrimary};
      transform: scale(1.1);
    }
    50% {
      color: ${props => props.theme.colors.redPrimary};
      transform: scale(1);
    }
    75% {
      color: ${props => props.theme.colors.orangePrimary};
      transform: scale(1.1);
    }
    100% {
      color: ${props => props.theme.colors.redPrimary};
      transform: scale(1);
    }
  }
`;

export const ButtonDetalhes = styled.button`
  background: transparent;
  border: none;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.colors.bluePrimary};
    width: 26px;
    height: 26px;

    transition: color 0.2s;

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.bluePrimary)};
    }
  }
`;

export const ZoomImg = styled.div`
  background: ${props => props.theme.colors.whitePrimary};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 8px 20px;

  width: calc(100vw - 500px);
  max-height: 400px;
  overflow: auto;

  section {
    background: ${props => props.theme.colors.grayWhite};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin: 12px auto;

    article {
      padding-top: 12px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding: 12px 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1152px) {
    width: calc(100vw - 100px);
    height: 380px;
  }
`;

export const ButtonImgNull = styled.button`
  background-color: ${props => props.theme.colors.whitePrimary};
  border-radius: 50%;
  border: none;
  outline: none;
  width: 128px;
  height: 128px;
  transition: transform 0s;
  cursor: auto;

  &:hover {
    transform: scale(1);
  }

  svg {
    color: ${props => props.theme.colors.textBlack};
    margin-bottom: -5px;
  }
`;

export const ButtonImg = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 0.2s;
  width: 164px;
  height: 164px;

  &:hover {
    transform: scale(1.08);
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

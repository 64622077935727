import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';
import jsPDF from 'jspdf';
import moment from 'moment';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Zoom from 'react-img-zoom';
import HeaderTable from '../HeaderTable';
import Search from '../Search';
import { maskCpf, maskTel } from '../InputOverview/mask';
import imgAvatar from '../../assets/IconAvatar.svg';
import Button from '../Button';

import {
  ContainerModal,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerMap,
  ContainerButtonsPdfExcel,
  ContainerTableMacon,
  ContainerSearch,
  ContainerButtonDetalhes,
  ContainerMapObservacao,
  ButtonImg,
  ZoomImg,
  ContainerListCards,
  ButtonDetalhes,
} from './styles';

Modal.setAppElement('#root');

interface ResponseGetHistoric {
  // ANDAR_ENV_PROCEDURES_NAO_REALIZADO: string;
  AVATAR_TASK_AMBIENTE_NAO_REALIZADO: string;
  // CATEGORIA_ENV_PROCEDURES_NAO_REALIZADO: number;
  // COMPANYID_TASK_AMBIENTE_NAO_REALIZADO: number;
  CPF_TASK_AMBIENTE_NAO_REALIZADO: string;
  // CREATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  // CompanyId_Ambiente: number;
  // DATA_NASCIMENTO_TASK_AMBIENTE_NAO_REALIZADO: string;
  // DIAS_LIMPEZA_ENV_PROCEDURES_NAO_REALIZADO: number;
  // DataCriacaoAmbiente: string;
  DataHoraCreatedAt_task_nao_realizada_duplicada: string;
  // DataHora_RevisaoFinalizada: string;
  EMAIL_TASK_AMBIENTE_NAO_REALIZADO: string;
  // ID_AMBIENTE_HISTORICO: number;
  ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  // ID_ENV_PROCEDURES_NAO_REALIZADO: number;
  // ID_PROCEDIMENTO_PREDEFINIDO: number;
  ID_TASK_PROCEDIMENTO_PREDEFINIDO: number;
  // ID_TASK_REALIZADA: number;
  // Id_Dom_StatusRevisao: number;
  NAME_ENV_PROCEDURES_NAO_REALIZADO: string;
  NAME_PROCEDIMENTO_NAO_REALIZADO: string;
  NOME_USER_TASK_AMBIENTE_NAO_REALIZADO: string;
  // NomeCategoria: string;
  // NomeProcedimentos: string;
  // NomeSetor: string;
  // Nome_Ambiente: string;
  // QTD_DIAS_LIMPEZAE_ENV_PROCEDURES_NAO_REALIZADO: number;
  // REVISAO_ENV_PROCEDURES_NAO_REALIZADO: boolean;
  // REVISAO_HORA_FINAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  // REVISAO_HORA_INICIAL_ENV_PROCEDURES_NAO_REALIZADO: string;
  // Revisao: boolean;
  // RevisaoHoraFinal: string;
  // RevisaoHoraInicial: string;
  // SETORID_ENV_PROCEDURES_NAO_REALIZADO: number;
  TASK_ID_AMBIENTE_NAO_REALIZADO: number;
  TASK_NAO_REALIZADA: boolean;
  TELEFONE_TASK_AMBIENTE_NAO_REALIZADO: string;
  // UPDATEDAT_ENV_PROCEDURES_NAO_REALIZADO: string;
  // USER_ID_AMBIENTE_TASK_NAO_REALIZADO: number;
  // UserId_Historico: number;
  // andar: string;
  // avatar: string;
  // categoriaId: number;
  // companyId: number;
  // cpf: string;
  // createdAt: string;
  createdAt_TASK_NAO_REALIZADO: string;
  // dataNascimento: string;
  // diasLimpezaId: number;
  // email: string;
  // image: string;
  // nome: string;
  observacao: string;
  // setorId: number;
  // statusRevisaoNome: string;
  // telefone: string;
  // updatedAt: string;
  updatedAt_TASK_NAO_REALIZADO: string;
  escondido: boolean;
}

interface ModalDetalhesAmbientes {
  isOpen: boolean;
  dados: ResponseGetHistoric[];
  dadosIdTask: number;
  dadosHistoricoDatas: string;
  onRequestClose: () => void;
}

const headers = [
  { name: 'Ambiente', field: 'ambiente' },
  { name: 'Colaborador', field: 'colaborador' },
  { name: 'CPF', field: 'cpf' },
  { name: 'E-mail', field: 'email' },
  { name: 'Telefone', field: 'telefone' },
  { name: 'Data e hora inicial', field: 'dataInicial' },
  { name: 'Data e hora final', field: 'dataFinal' },
  { name: 'Procedimento', field: 'procedimento' },
  { name: 'Observação', field: 'observacao' },
];

export const ModalHistoricoProcedimento: React.FC<ModalDetalhesAmbientes> = ({
  isOpen,
  dados,
  dadosIdTask,
  dadosHistoricoDatas,
  onRequestClose,
}) => {
  const [search, setSearch] = useState('');
  const [dadosHistoricoExcelPdf, setDadosHistoricoExcelPdf] = useState<
    ResponseGetHistoric[]
  >([]);
  const [dadosCopia, setDadosCopia] = useState<ResponseGetHistoric[]>([]);
  const [zoomImg, setZoomImg] = useState(false);
  const [imgString, setImgString] = useState('');

  useEffect(() => {
    const dadosCopiaTemp = dados.map(dado => {
      return { ...dado, escondido: false };
    });

    setDadosCopia(dadosCopiaTemp);
  }, [dados]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGetHistoric[] = [];
    let computedResponsesExcelPdf: ResponseGetHistoric[] = [];
    const arrayResponseFilter: ResponseGetHistoric[] = [];
    const arrayResponseDataHoraTaskDuplicada: string[] = [];

    dadosCopia.forEach((dado, index) => {
      if (
        dado.TASK_NAO_REALIZADA === true &&
        dado.createdAt_TASK_NAO_REALIZADO !== null &&
        dado.updatedAt_TASK_NAO_REALIZADO !== null &&
        dadosIdTask === dado.ID_TASK_PROCEDIMENTO_PREDEFINIDO &&
        arrayResponseDataHoraTaskDuplicada.indexOf(
          dado.DataHoraCreatedAt_task_nao_realizada_duplicada,
        ) === -1
      ) {
        arrayResponseDataHoraTaskDuplicada.push(
          dado.DataHoraCreatedAt_task_nao_realizada_duplicada,
        );
        arrayResponseFilter.push(dado);
      }

      if (index === dados.length - 1) {
        computedResponses = arrayResponseFilter;
        computedResponsesExcelPdf = arrayResponseFilter;
      }
    });

    if (search) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(
            new Date(res.createdAt_TASK_NAO_REALIZADO).setHours(
              new Date(res.createdAt_TASK_NAO_REALIZADO).getHours() - 3,
            ),
          ).format('DD/MM/YYYY HH:mm:ss');
          const dataFim = moment(
            new Date(res.updatedAt_TASK_NAO_REALIZADO).setHours(
              new Date(res.updatedAt_TASK_NAO_REALIZADO).getHours() - 3,
            ),
          ).format('DD/MM/YYYY HH:mm:ss');

          return [
            res.observacao,
            dataInicio,
            dataFim,
            res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO,
            res.CPF_TASK_AMBIENTE_NAO_REALIZADO,
            res.NAME_ENV_PROCEDURES_NAO_REALIZADO,
          ].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()),
          );
        },
      );

      computedResponsesExcelPdf = computedResponsesExcelPdf.filter(
        (res: ResponseGetHistoric) => {
          const dataInicio = moment(
            new Date(res.createdAt_TASK_NAO_REALIZADO).setHours(
              new Date(res.createdAt_TASK_NAO_REALIZADO).getHours() - 3,
            ),
          ).format('DD/MM/YYYY HH:mm:ss');
          const dataFim = moment(
            new Date(res.updatedAt_TASK_NAO_REALIZADO).setHours(
              new Date(res.updatedAt_TASK_NAO_REALIZADO).getHours() - 3,
            ),
          ).format('DD/MM/YYYY HH:mm:ss');

          return [
            res.observacao,
            dataInicio,
            dataFim,
            res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO,
            res.CPF_TASK_AMBIENTE_NAO_REALIZADO,
            res.NAME_ENV_PROCEDURES_NAO_REALIZADO,
          ].some(
            (item: any) =>
              item &&
              item
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()),
          );
        },
      );
    }

    setDadosHistoricoExcelPdf(computedResponsesExcelPdf);

    return computedResponses;
  }, [dadosCopia, search, dadosIdTask, dados.length]);

  const onButtonClick = useCallback(
    dadoParam => {
      const newResponseData = dadosCopia.map(dado => {
        return JSON.stringify(dado) === JSON.stringify(dadoParam)
          ? { ...dado, escondido: !dado.escondido }
          : dado;
      });
      setDadosCopia(newResponseData);
    },
    [dadosCopia],
  );

  const handleDownloadPDF = useCallback(() => {
    const doc = new jsPDF(); // eslint-disable-line
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.setFontType('bold');
    doc.text('Histórico do Procedimento', 103, 20, 'center');
    doc.setFontSize(14);
    // tamanho 207 tela a4
    let posY: number;
    posY = 30;

    doc.text(
      `${dadosHistoricoExcelPdf[0].NAME_PROCEDIMENTO_NAO_REALIZADO}`,
      103,
      posY,
      'center',
    );

    // inicio bloco de dados pessoais
    dadosHistoricoExcelPdf.forEach((res, index) => {
      index === 0 ? (posY += 28) : (posY += 20);

      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('Ambiente:', 10, posY, 'left');
      let quebraLinha = doc.splitTextToSize(
        `${res.NAME_ENV_PROCEDURES_NAO_REALIZADO}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 32, posY, {
        align: 'left',
        maxWidth: 175,
      });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Colaborador:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('CPF:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${maskCpf(res.CPF_TASK_AMBIENTE_NAO_REALIZADO)}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 21, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('E-mail:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 25, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Telefone:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${maskTel(res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO)}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 31, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Data e hora inicial:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${moment(
          new Date(res.createdAt_TASK_NAO_REALIZADO).setHours(
            new Date(res.createdAt_TASK_NAO_REALIZADO).getHours() - 3,
          ),
        ).format('DD/MM/YYYY HH:mm:ss')}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 50, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Data e hora final:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${moment(
          new Date(res.updatedAt_TASK_NAO_REALIZADO).setHours(
            new Date(res.updatedAt_TASK_NAO_REALIZADO).getHours() - 3,
          ),
        ).format('DD/MM/YYYY HH:mm:ss')}`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 46, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Procedimento:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.NAME_PROCEDIMENTO_NAO_REALIZADO === null ||
          res.NAME_PROCEDIMENTO_NAO_REALIZADO === undefined ||
          res.NAME_PROCEDIMENTO_NAO_REALIZADO === ''
            ? 'Sem procedimento'
            : res.NAME_PROCEDIMENTO_NAO_REALIZADO
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 42, posY, { align: 'left', maxWidth: 175 });

      posY += 6;
      doc.setFontType('bold');
      doc.text('Observação:', 10, posY, 'left');
      quebraLinha = doc.splitTextToSize(
        `${
          res.observacao === null ||
          res.observacao === undefined ||
          res.observacao === ''
            ? 'Sem observação'
            : res.observacao
        }`,
        70,
      );
      doc.setFontType('normal');
      doc.text(quebraLinha, 38, posY, { align: 'left', maxWidth: 175 });

      if (posY > 207) {
        doc.addPage();
        posY = 0;
      }
    });

    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }, [dadosHistoricoExcelPdf]);

  const handleZoomImg = useCallback(
    (image: string) => {
      setZoomImg(!zoomImg);
      setImgString(image);
    },
    [zoomImg],
  );

  const onClose = useCallback(() => {
    setSearch('');
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ContainerModal>
        {!zoomImg && (
          <>
            <ContainerHeaderDados>
              Histórico do Procedimento
            </ContainerHeaderDados>

            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar"
              />

              <strong>{dadosHistoricoDatas}</strong>
            </ContainerSearch>
          </>
        )}

        {!zoomImg && (
          <ContainerHeader>
            <ContainerListCards>
              {responseData.map(res => (
                <ContainerMap
                  key={`${res.TASK_ID_AMBIENTE_NAO_REALIZADO}-${res.observacao}-${res.createdAt_TASK_NAO_REALIZADO}-${res.updatedAt_TASK_NAO_REALIZADO}`}
                >
                  {res.escondido === false && (
                    <>
                      <ul>
                        <li>
                          <strong>Procedimento: </strong>
                          <span>
                            {res.NAME_PROCEDIMENTO_NAO_REALIZADO === null ||
                            res.NAME_PROCEDIMENTO_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : res.NAME_PROCEDIMENTO_NAO_REALIZADO}
                          </span>
                        </li>
                        <li>
                          <strong>Data e hora inicial: </strong>
                          <span>
                            {res.createdAt_TASK_NAO_REALIZADO === null ||
                            res.createdAt_TASK_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : moment(
                                  new Date(
                                    res.createdAt_TASK_NAO_REALIZADO,
                                  ).setHours(
                                    new Date(
                                      res.createdAt_TASK_NAO_REALIZADO,
                                    ).getHours() - 3,
                                  ),
                                ).format('DD/MM/YYYY HH:mm:ss')}
                          </span>
                        </li>
                        <li>
                          <strong>Data e hora final: </strong>
                          <span>
                            {res.updatedAt_TASK_NAO_REALIZADO === null ||
                            res.updatedAt_TASK_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : moment(
                                  new Date(
                                    res.updatedAt_TASK_NAO_REALIZADO,
                                  ).setHours(
                                    new Date(
                                      res.updatedAt_TASK_NAO_REALIZADO,
                                    ).getHours() - 3,
                                  ),
                                ).format('DD/MM/YYYY HH:mm:ss')}
                          </span>
                        </li>
                        <li>
                          <strong>Observação: </strong>
                          <span>
                            {res.observacao === null ||
                            res.observacao.trim() === ''
                              ? '-'
                              : res.observacao}
                          </span>
                        </li>
                      </ul>

                      <ButtonDetalhes
                        type="button"
                        onClick={() => onButtonClick(res)}
                      >
                        <IoIosArrowForward />
                      </ButtonDetalhes>
                    </>
                  )}
                  {res.escondido === true && (
                    <ContainerMapObservacao>
                      <ButtonImg
                        type="button"
                        className="btnImg"
                        onClick={() => {
                          handleZoomImg(
                            res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO
                              ? `https://app-qrcode-avatar.s3.amazonaws.com/${res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO}`
                              : imgAvatar,
                          );
                        }}
                      >
                        <img
                          src={
                            res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO
                              ? `https://app-qrcode-avatar.s3.amazonaws.com/${res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO}`
                              : imgAvatar
                          }
                          alt={
                            res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO
                              ? res.AVATAR_TASK_AMBIENTE_NAO_REALIZADO
                              : 'avatar'
                          }
                        />
                      </ButtonImg>

                      <ul>
                        <li>
                          <strong>Ambiente: </strong>
                          <span>
                            {res.NAME_ENV_PROCEDURES_NAO_REALIZADO === null ||
                            res.NAME_ENV_PROCEDURES_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : res.NAME_ENV_PROCEDURES_NAO_REALIZADO}
                          </span>
                        </li>
                        <li>
                          <strong>Colaborador: </strong>
                          <span>
                            {res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO ===
                              null ||
                            res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO.trim() ===
                              ''
                              ? '-'
                              : res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO}
                          </span>
                        </li>
                        <li>
                          <strong>CPF: </strong>
                          <span>
                            {res.CPF_TASK_AMBIENTE_NAO_REALIZADO === null ||
                            res.CPF_TASK_AMBIENTE_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : maskCpf(res.CPF_TASK_AMBIENTE_NAO_REALIZADO)}
                          </span>
                        </li>
                        <li>
                          <strong>E-mail: </strong>
                          <span>
                            {res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO === null ||
                            res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO.trim() === ''
                              ? '-'
                              : res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO}
                          </span>
                        </li>
                        <li>
                          <strong>Telefone: </strong>
                          <span>
                            {res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO ===
                              null ||
                            res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO.trim() ===
                              ''
                              ? '-'
                              : maskTel(
                                  res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO,
                                )}
                          </span>
                        </li>
                      </ul>

                      <ButtonDetalhes
                        type="button"
                        onClick={() => onButtonClick(res)}
                      >
                        <IoIosArrowBack />
                      </ButtonDetalhes>
                    </ContainerMapObservacao>
                  )}
                </ContainerMap>
              ))}
            </ContainerListCards>
          </ContainerHeader>
        )}

        {zoomImg && (
          <ZoomImg>
            <Zoom img={imgString} zoomScale={1} width="100%" height={380} />
          </ZoomImg>
        )}

        {!zoomImg && (
          <>
            <ContainerButtonAtualizar>
              <Button type="button" onClick={() => onClose()}>
                Ok
              </Button>
            </ContainerButtonAtualizar>

            <ContainerButtonsPdfExcel>
              <button
                type="button"
                id="pdf"
                onClick={() => {
                  handleDownloadPDF();
                }}
              >
                <VscFilePdf size={22} />
              </button>

              <ReactHTMLTableToExcel
                type="button"
                id="excel"
                table="tableListagemDadosProcedimentos"
                filename={`relatorio-procedimentos-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText={<RiFileExcel2Line size={22} />}
              />
            </ContainerButtonsPdfExcel>
          </>
        )}

        {zoomImg && (
          <ContainerButtonDetalhes>
            <Button type="button" widthProps="20%" onClick={() => onClose()}>
              Ok
            </Button>
            <Button
              type="button"
              widthProps="20%"
              onClick={() => {
                setZoomImg(!zoomImg);
              }}
            >
              Voltar
            </Button>
          </ContainerButtonDetalhes>
        )}
      </ContainerModal>

      <ContainerTableMacon>
        <table id="tableListagemDadosProcedimentos">
          <HeaderTable headers={headers} />
          <tbody>
            {dadosHistoricoExcelPdf !== undefined &&
              dadosHistoricoExcelPdf.map(res => (
                <tr key={uuid()}>
                  <td>
                    {res.NAME_ENV_PROCEDURES_NAO_REALIZADO === null
                      ? '-'
                      : res.NAME_ENV_PROCEDURES_NAO_REALIZADO}
                  </td>
                  <td>
                    {res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO === null
                      ? '-'
                      : res.NOME_USER_TASK_AMBIENTE_NAO_REALIZADO}
                  </td>
                  <td>
                    {res.CPF_TASK_AMBIENTE_NAO_REALIZADO === null
                      ? '-'
                      : maskCpf(res.CPF_TASK_AMBIENTE_NAO_REALIZADO)}
                  </td>
                  <td>
                    {res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO === null
                      ? '-'
                      : res.EMAIL_TASK_AMBIENTE_NAO_REALIZADO}
                  </td>
                  <td>
                    {res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO === null
                      ? '-'
                      : res.TELEFONE_TASK_AMBIENTE_NAO_REALIZADO}
                  </td>
                  <td>
                    {res.createdAt_TASK_NAO_REALIZADO === null
                      ? '-'
                      : moment(
                          new Date(res.createdAt_TASK_NAO_REALIZADO).setHours(
                            new Date(
                              res.createdAt_TASK_NAO_REALIZADO,
                            ).getHours() - 3,
                          ),
                        ).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td>
                    {res.updatedAt_TASK_NAO_REALIZADO === null
                      ? '-'
                      : moment(
                          new Date(res.updatedAt_TASK_NAO_REALIZADO).setHours(
                            new Date(
                              res.updatedAt_TASK_NAO_REALIZADO,
                            ).getHours() - 3,
                          ),
                        ).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td>
                    {res.NAME_PROCEDIMENTO_NAO_REALIZADO === null ||
                    res.NAME_PROCEDIMENTO_NAO_REALIZADO.trim() === ''
                      ? '-'
                      : res.NAME_PROCEDIMENTO_NAO_REALIZADO}
                  </td>
                  <td>
                    {res.observacao === null || res.observacao.trim() === ''
                      ? '-'
                      : res.observacao}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </ContainerTableMacon>
    </Modal>
  );
};

import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useDrawer } from '../../hooks/drawer';
import { TooltipMenu } from '../TooltipMenu';

import IconAvatar from '../../assets/IconAvatar.svg';

import { Container, Profile } from './styles';

export const HeaderMenu: React.FC = () => {
  const { user } = useAuth();
  const { isActive, isActiveAux, updateDrawer } = useDrawer();
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(isActiveAux);

  useEffect(() => {
    setShowButton(!isActiveAux);
  }, [isActiveAux]);

  useEffect(() => {
    setShow(isActive);
  }, [isActive]);

  const mql = window.matchMedia('(max-width: 1152px)');

  mql.addEventListener('change', () => {
    if (window.innerWidth > 1152) {
      setShowButton(false);
    } else if (window.innerWidth < 1152) {
      setShowButton(true);
    }
  });

  return (
    !!user && (
      <Container>
        {showButton && show && (
          <button
            type="button"
            id="btnClose"
            onClick={() => {
              setShow(false);
              updateDrawer(false);
            }}
          >
            <AiOutlineClose />
          </button>
        )}

        {showButton && !show && (
          <button
            type="button"
            id="btnMenu"
            onClick={() => {
              setShow(true);
              updateDrawer(true);
            }}
          >
            <AiOutlineMenu />
          </button>
        )}

        {showButton ? (
          <Profile>
            <strong>{user && user.nome}</strong>
            <Link to="/profile">
              <img
                src={user && user.avatar_url ? user.avatar_url : IconAvatar}
                alt="avatar"
              />
            </Link>
          </Profile>
        ) : (
          <Profile>
            <strong>{user && user.nome}</strong>
            <TooltipMenu title="perfil" type="header">
              <img
                src={user && user.avatar_url ? user.avatar_url : IconAvatar}
                alt="avatar"
              />
            </TooltipMenu>
          </Profile>
        )}
      </Container>
    )
  );
};

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FiEye, FiEyeOff, FiSearch } from 'react-icons/fi';

import { IoIosArrowForward } from 'react-icons/io';
import { Loading } from '../../../components/Loading';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';
import HeaderTable from '../../../components/HeaderTable';
import Pagination from '../../../components/Pagination';
import DropDownPagination from '../../../components/DropDownPagination';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import { Filial } from '../../../models/Filial';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import IconFilter from '../../../assets/IconFilter.svg';
import {
  Container,
  ContainerTitle,
  ContainerItem,
  ContainerSearch,
  ContainerSemResultados,
  ContainerListItens,
  ContainerPagination,
  ContainerSelect,
  DivCheck,
  ConteinerFiltros,
  ModalOverlay,
  ModalContent,
  ActivitiesList,
  CloseButton,
} from './styles';
import { HistoricAgendament } from '../../../models/HistoricAgend';
import {
  ModalTarefasIncompletas,
  TarefasImp,
} from '../../../components/ModalTarefasIncompletas';
import { CardAgendamento } from '../../../components/CardAgendamento';

export interface HistUserAgend {
  name: string;
  avatar: string;
  cpf: string;
  telefone: string;
  email: string;
  id: number;
  createdAt: string;
  dataNascimento: string;
  nameFilial: string;
  companyId: number;
}

interface HistLimpeza {
  type: 'Principal' | 'Extra' | 'Revisão';
  revisionId: number | null;
  startAt: string;
  endAd: string;
  observacao: string;
  hist: {
    idTask: number;
    // nameTask: string;
    image: string;
    ocorrencia: string;
  }[];
}

interface HistAmbTarefasIn {
  Ambiente: {
    name: string;
    id: number;
    listTarefas: {
      id: number;
      name: string;
      isOpcional: boolean;
    }[];
  };
  categoria: string;
  companyId: number;
  setor: string;
  andar: string;
  agendmts: {
    dia: string;
    agendaId: number;
    agendamentoId: number;
    userId: number;
    User: {
      nome: string;
      avatar: string;
    };
    limpezas: HistLimpeza[];
  }[];
}

interface HistCreated {
  createAt: string;
  hist: {
    userId: number;
    agendamentoId: number;
    tasksId: number;
    image: string;
    createdAt: string;
    updatedAt: string;
    revisionId: number;
    ocorrencia: string;
    observacao: string;
  }[];
}

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headers = [
  { name: 'Ambiente', field: 'ambiente', sortable: false },
  { name: 'Categoria', field: 'categoria', sortable: false },
  { name: 'Setor', field: 'setor', sortable: false },
  { name: 'Andar', field: 'andar', sortable: false },
  { name: 'Usuario', field: 'user', sortable: false },
  { name: 'Tipo da Limpeza', field: 'type_limpeza', sortable: false },
  {
    name: 'Dia da Limpeza',
    field: 'dia_limpeza',
    sortable: false,
  },
  { name: 'Tarefas Incompletas', field: 'tinc', sortable: false },
  { name: 'Tarefas Completas', field: 'tcomp', sortable: false },
  { name: 'Motivo', field: 'motivo', sortable: false },
];

export const DashboardTarefasIncompletas: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [filialId, setFilialId] = useState(-1);
  const [filtroAtividades, setFiltroAtividades] = useState('todos');
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Categoria',
    },
    {
      status: false,
      name: 'Setor',
    },
    {
      status: false,
      name: 'Andar',
    },
  ]);
  const [ambTI, setAmbTI] = useState<Record<number, HistoricAgendament[]>>([]);
  const [defaultList, setDefaultList] = useState<Record<number, HistoricAgendament[]>>([]);
  const [search, setSearch] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [rangeDataInicial, setRangeDataInicial] = useState('');
  const [rangeDataFinal, setRangeDataFinal] = useState('');
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showContainerFiltros, setShowContainerFiltros] = useState(true);
  const [showCardDetails, setshowCardDetails] = useState(false);
  const [itemSelected, setItemSelected] = useState({} as HistoricAgendament);
  const [showModalTI, setShowModalTI] = useState(false);
  const [isShowDadosOld, setIsShowDadosOld] = useState(false);
  const [tarefas, setTarefas] = useState<TarefasImp[]>([]);
  const [nameAmbiente, setNameAmbiente] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getFiliais = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

    api
      .get(`empresas/all`)
      .then(resp => {
        const filAll: Filial[] = resp.data;
        setFiliais(filAll);
      })
      .catch(err => {
        console.log(err.mesage);
      });
  }, [empresaPrincipal]);

  useEffect(() => {
    if (filtroAtividades == 'todos') {
      setAmbTI(defaultList);
    } else if (filtroAtividades == 'feitas') {
      const aux = Object.values(defaultList).map(i => i.filter(j => j.Historic.length > 0));
      setAmbTI(aux);
    } else if (filtroAtividades == 'pendentes') {
      const aux = Object.values(defaultList).map(i => i.filter(j => j.Historic.length === 0));
      setAmbTI(aux);
    }
  }, [filtroAtividades])

  const getHistLimpeza = useCallback((cretd: HistCreated[]) => {
    const auxLimp: HistLimpeza[] = cretd.map((hist, index) => {
      const obj: HistLimpeza = {
        endAd: hist.hist[0].updatedAt,
        hist: hist.hist.map(i => ({
          idTask: i.tasksId,
          ocorrencia: i.ocorrencia,
          image: i.image,
        })),
        observacao: hist.hist[0].observacao,
        revisionId: hist.hist[0].revisionId,
        startAt: hist.createAt,
        // eslint-disable-next-line no-nested-ternary
        type: hist.hist[0].revisionId
          ? 'Revisão'
          : index === 0
            ? 'Principal'
            : 'Extra',
      };
      return obj;
    });
    return auxLimp;
  }, []);

  const groupByCompanyId = (data: HistoricAgendament[]) => {
    return data.reduce((acc, item) => {
      if (!acc[item.companyId]) {
        acc[item.companyId] = [];
      }
      acc[item.companyId].push(item);

      acc[item.companyId].sort((a, b) => a.dataAgend.localeCompare(b.dataAgend));

      return acc;
    }, {} as Record<number, HistoricAgendament[]>);
  }

  const getItens = useCallback(
    async (dateStart: string, dateEnd: string) => {
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const limit = 500;
      let page = 1;
      const filId = user.companyId;

      const aux = isCoordenador
        ? `filialId=${filId}`
        : user.roleId != 7 ? `companyId=${companyId}` : `filialClientId=${user.filialClientId}`;
      setLoading(true);
      api
        .get(
          `/agendas/v2/Agendamentos/company?dataIni=${dateStart}&dataFim=${dateEnd}&${aux}&limit=${500}&page=${page}`,
        )
        .then(async resp => {
          let histAgendt: HistoricAgendament[] = resp.data;
          setAmbTI(groupByCompanyId(histAgendt));
          setDefaultList(groupByCompanyId(histAgendt));
          console.log("RESPO", histAgendt)
          let histResp: HistoricAgendament[] = resp.data;
          while (histResp.length === limit) {
            page += 1;
            const respW = await api.get(
              `/agendas/v2/Agendamentos/company?dataIni=${dateStart}&dataFim=${dateEnd}&${aux}&limit=${500}&page=${page}`,
            );
            histResp = respW.data;
            histAgendt = [...histAgendt, ...histResp];
          }

          const hists: HistAmbTarefasIn[] = [];
          histAgendt
            .filter(i => i.Historic !== undefined && i.Historic.length > 0)
            .forEach(item => {
              const cretd: HistCreated[] = [];
              item.Historic.filter(i => !!i.observacao).forEach(hit => {
                const findIndex = cretd.findIndex(
                  i => i.createAt === hit.createdAt,
                );
                if (findIndex !== -1) {
                  cretd[findIndex].hist.push({
                    agendamentoId: hit.agendamentoId,
                    createdAt: hit.createdAt,
                    image: hit.image,
                    observacao: hit.observacao,
                    ocorrencia: hit.ocorrencia,
                    revisionId: hit.revisionId,
                    tasksId: hit.tasksId,
                    updatedAt: hit.updatedAt,
                    userId: hit.userId,
                  });
                } else {
                  cretd.push({
                    createAt: hit.createdAt,
                    hist: [
                      {
                        agendamentoId: hit.agendamentoId,
                        createdAt: hit.createdAt,
                        image: hit.image,
                        observacao: hit.observacao,
                        ocorrencia: hit.ocorrencia,
                        revisionId: hit.revisionId,
                        tasksId: hit.tasksId,
                        updatedAt: hit.updatedAt,
                        userId: hit.userId,
                      },
                    ],
                  });
                }
              });
              cretd.sort((a, b) => {
                if (a.createAt > b.createAt) return 1;
                if (a.createAt < b.createAt) return -1;
                return 0;
              });
              if (cretd.length > 0) {
                const ambFindIndex = hists.findIndex(
                  i => i.Ambiente.id === item.Agenda.Id_Environments,
                );
                if (ambFindIndex !== -1) {
                  const agendMFindIndex = hists[
                    ambFindIndex
                  ].agendmts.findIndex(i => i.agendamentoId === item.id);
                  if (agendMFindIndex !== -1) {
                    hists[ambFindIndex].agendmts[agendMFindIndex].limpezas = [
                      ...hists[ambFindIndex].agendmts[agendMFindIndex].limpezas,
                      ...getHistLimpeza(cretd),
                    ];
                  } else {
                    hists[ambFindIndex].agendmts.push({
                      agendaId: item.Agenda.ID,
                      agendamentoId: item.id,
                      dia: item.dataAgend.slice(0, 10),
                      limpezas: getHistLimpeza(cretd),
                      User: {
                        avatar: item.User.avatar,
                        nome: item.User.nome,
                      },
                      userId: item.userId,
                    });
                  }
                } else {
                  const obj: HistAmbTarefasIn = {
                    agendmts: [
                      {
                        agendamentoId: item.id,
                        agendaId: item.Agenda.ID,
                        dia: item.dataAgend.slice(0, 10),
                        limpezas: getHistLimpeza(cretd),
                        User: {
                          avatar: item.User.avatar,
                          nome: item.User.nome,
                        },
                        userId: item.userId,
                      },
                    ],
                    Ambiente: {
                      id: item.Agenda.Environment.id,
                      listTarefas: item.Agenda.Environment.Procedures.map(
                        i => ({
                          id: i.id,
                          isOpcional: false,
                          name: i.name,
                        }),
                      ),
                      name: item.Agenda.Environment.name,
                    },
                    andar: item.Agenda.Environment.andar,
                    categoria: item.Agenda.Environment.Categoria.name,
                    companyId: item.companyId,
                    setor: item.Agenda.Environment.Setor.name,
                  };
                  hists.push(obj);
                }
              }
            });
          setRangeDataInicial(dateStart);
          setRangeDataFinal(dateEnd);
        })
        .catch(err => {
          console.log(err);
          console.log(err.message);
          // console.log(err.response.data);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToast, empresaPrincipal, getHistLimpeza, isCoordenador, user.companyId],
  );

  const loadingItens = useCallback(() => {
    const mesAtual = moment().format('YYYY-MM-DD');
    if (!searchDataInicial || !searchDataFinal) {
      return;
    }
    if (searchDataInicial <= mesAtual && searchDataFinal <= mesAtual) {
      getItens(searchDataInicial, searchDataFinal);
    } else {
      addToast({
        type: 'info',
        title: 'Filtro inválido',
        description: 'Intervalo de datas inválidos, favor verificar',
      });
    }
  }, [addToast, getItens, searchDataFinal, searchDataInicial]);

  useEffect(() => {
    getFiliais();
  }, [getFiliais]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchDataInicial, searchDataFinal]);

  const renderMouth = useCallback((mouth: number): string => {
    switch (mouth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return '';
    }
  }, []);

  // const handleCard = useCallback((item: HistoricAgendament) => {
  //   const tafrs: TarefasImp[] = [];
  //   item.Agenda.agendamentos!.forEach(ag => {
  //     ag.limpezas.forEach(limp => {
  //       const obj: TarefasImp = {
  //         avatarUser: ag.User.avatar,
  //         date: ag.dia,
  //         motivo: limp.observacao,
  //         nomeUser: ag.User.nome,
  //         tarefas: item.Ambiente.listTarefas.map(taf => {
  //           const find = limp.hist.find(i => i.idTask === taf.id);
  //           if (find) {
  //             return { id: taf.id, name: taf.name, isCompleted: true };
  //           }
  //           return { id: taf.id, name: taf.name, isCompleted: taf.isOpcional };
  //         }),
  //       };
  //       tafrs.push(obj);
  //     });
  //   });

  //   setTarefas(tafrs);
  //   setNameAmbiente(item.Ambiente.name);
  //   setShowModalTI(true);
  // }, []);

  const dateRender = useMemo(() => {
    const [yearStart, mouthStart, dayStart] = rangeDataInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = rangeDataFinal.split('-');
    if (rangeDataInicial === rangeDataFinal)
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))} de ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )} de ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )} de ${yearEnd}`;
  }, [rangeDataFinal, rangeDataInicial, renderMouth]);

  const listAmbTI = useMemo(() => {
    if (!ambTI || Object.keys(ambTI).length === 0) {
      setTotalItems(0);
      return [];
    }

    let computedResponses = Object.values(ambTI).flat();
    console.log("COMPUTED", ambTI)
    if (filialId !== -1) {
      computedResponses = computedResponses.filter(i => filialId === i.companyId);
    }

    if (search) {
      computedResponses = computedResponses.filter(res => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            // switch (filtro.name) {
            //   case 'Ambiente':
            //     dataFilter.push(res.Ambiente.name);
            //     break;
            //   case 'Categoria':
            //     dataFilter.push(res.categoria);
            //     break;
            //   case 'Setor':
            //     dataFilter.push(res.setor);
            //     break;
            //   case 'Andar':
            //     dataFilter.push(res.andar);
            //     break;
            //   default:
            //     break;
            // }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item.toString().toLowerCase().includes(search.toString().toLowerCase()),
        );
      });
    }

    setTotalItems(computedResponses.length);

    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [ITEMS_PER_PAGE, ambTI, currentPage, dataFilterModal, filialId, search]);


  return (
    <>
      <ModalConexao />

      <ModalFilter
        isOpen={showModalFilter}
        type="dashAmbientes"
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      <ModalTarefasIncompletas
        isVisible={showModalTI}
        onClose={() => setShowModalTI(false)}
        fraseDatas={dateRender}
        nomeAmbiente={nameAmbiente}
        listaTarefas={tarefas}
      />

      <Container>
        <ContainerTitle>
          <h1>Relatório de atividades</h1>

          <Tooltip
            title="Nessa página você tem acesso a visualização detalhada do histórico de atividades. Filtragem a partir dos procedimentos/tarefas."
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </ContainerTitle>

        <ConteinerFiltros $isVisible={showContainerFiltros}>
          {user.roleId != 7 && <ContainerSelect>
            <select
              value={filialId}
              disabled={isCoordenador}
              onChange={event => {
                const val = parseInt(event.target.value, 10);
                setFilialId(val);
              }}
            >
              <option value={-1}>Selecione uma empresa ou deixe em branco para todas</option>
              {filiais.map(i => (
                <option key={i.ID} value={i.ID}>
                  {i.NOME}
                </option>
              ))}
            </select>
          </ContainerSelect>}
          <ContainerSelect>
            <select
              value={filtroAtividades}
              disabled={isCoordenador}
              onChange={event => {
                setFiltroAtividades(event.target.value);
              }}
            >
              <option value="todos">Mostrar todas atividades</option>
              <option value="feitas">
                Apenas atividades feitas
              </option>
              <option value="pendentes">
                Atividades pendentes
              </option>
            </select>
          </ContainerSelect>

          <ContainerSearch isFilterDateInterval>
            <Search
              value={searchDataInicial}
              onSearch={(value: string) => {
                setSearchDataInicial(value);
              }}
              nomePlaceHolder="Buscar data inicial"
              type="date"
            />

            <Search
              value={searchDataFinal}
              onSearch={(value: string) => {
                setSearchDataFinal(value);
              }}
              nomePlaceHolder="Buscar data final"
              type="date"
            />
          </ContainerSearch>
        </ConteinerFiltros>

        <ContainerSearch isFilterDateInterval={false}>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Digite para filtrar..."
          />

          <button style={{ height: '40px' }} type="button" onClick={loadingItens}>
            <FiSearch size={20} />
          </button>

          <button style={{ height: '40px' }} type="button" onClick={() => setShowContainerFiltros(showContainerFiltros => !showContainerFiltros)}>
            {showContainerFiltros ? <FiEyeOff /> : <FiEye />}
          </button>
        </ContainerSearch>

        {Object.keys(ambTI).length === 0 && (
          <ContainerSemResultados>
            <h2>
              Não existe um histórico de tarefas para o intervalo de
              datas atual.
            </h2>
          </ContainerSemResultados>
        )}

        <ContainerListItens $filterIsVisible={showContainerFiltros}>
          <>
            {listAmbTI.map((res, index) => (
              <ContainerItem
                // key={`${res.Ambiente.id}-${
                //   res.Ambiente.name
                // }-${index.toString()}`}
                isColor={index % 2 !== 0}
              >
                <button type="button" onClick={() => {
                  setshowCardDetails(true)
                  setItemSelected(res)
                }}>
                  <ul>
                    <li>
                      <strong>Empresa</strong>
                      <span>{res.Empresa.NOME}</span>
                    </li>
                    <li>
                      <strong>Ambiente</strong>
                      <span>{res.Agenda.Environment.name}</span>
                    </li>
                    <li>
                      <strong>Data</strong>
                      <span>{res.dataAgend.split("-").reverse().join("-")}</span>
                    </li>
                    <li>
                      <strong>Feito?</strong>
                      <span>{res.Historic.length > 0 ? 'SIM' : 'NÃO'}</span>
                    </li>
                    {/* <li>
                      <strong>Setor</strong>
                      <span>{res.Agenda.Environment.Setor.name}</span>
                    </li>
                    <li>
                      <strong>Andar</strong>
                      <span>{res.Agenda.Environment.andar}</span>
                    </li> */}
                  </ul>
                </button>

                <IoIosArrowForward />
              </ContainerItem>
            ))}
            <table
              id="tableRelatorioTarefaIncompleta"
              style={{ display: 'none' }}
            >
              <HeaderTable headers={headers} />
              <tbody>
                {/* {listAmbTI.map((res, index) =>
                  res.agendmts.map(ag =>
                    ag.limpezas.map(limp => (
                      <tr key={`${res.Ambiente.id}-${index.toString()}`}>
                        <td>{res.Ambiente.name}</td>
                        <td>{res.categoria}</td>
                        <td>{res.setor}</td>
                        <td>{res.andar}</td>
                        <td>{ag.User.nome}</td>
                        <td>{limp.type}</td>
                        <td>{moment(ag.dia).format('DD/MM/YYYY')}</td>
                        <td>
                          {res.Ambiente.listTarefas
                            .filter(
                              i => !limp.hist.find(h => h.idTask === i.id),
                            )
                            .map(i => i.name)
                            .join(' ,')}
                        </td>
                        <td>
                          {res.Ambiente.listTarefas
                            .filter(i => limp.hist.find(h => h.idTask === i.id))
                            .map(i => i.name)
                            .join(' ,')}
                        </td>
                        <td>{limp.observacao}</td>
                      </tr>
                    )),
                  ),
                )} */}
              </tbody>
            </table>
          </>
        </ContainerListItens>

        <ContainerPagination>
          {Object.keys(ambTI).length > 0 && (
            <>
              <div className="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'Todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>

              {/* <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableRelatorioTarefaIncompleta"
                filename={`relatorio-tarefa-incompleta-${rangeDataInicial}-${rangeDataFinal}`}
                sheet="tablexls"
                buttonText="Exportar Excel"
              /> */}
            </>
          )}
        </ContainerPagination>

        {showCardDetails && (
          <ModalOverlay onClick={() => setshowCardDetails(false)}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <h2>Detalhes do Agendamento</h2>
              <p><strong>Data:</strong> {itemSelected.dataAgend.split('-').reverse().join('-')}</p>
              <p><strong>Horário Marcado:</strong> {itemSelected.horaIni} - {itemSelected.horaFim}</p>
              <p><strong>Status:</strong> {itemSelected.Historic.length > 0 ? 'Feito' : 'Pendente'}</p>
              <p><strong>Usuário:</strong> {itemSelected.User.nome}</p>
              <h3>Atividades:</h3>
              <ActivitiesList>
                {itemSelected.Historic.map((item, index) => {
                  let [hours, minutes, seconds] = new Date(item.createdAt).toISOString().split("T")[1].split(":").map((v, i) => i < 2 ? +v : +v.split(".")[0]);
                  let [hoursF, minutesF, secondsF] = new Date(item.updatedAt).toISOString().split("T")[1].split(":").map((v, i) => i < 2 ? +v : +v.split(".")[0]);
                  let horaRealizada = `${hours}:${minutes}:${seconds}`;
                  let horaFRealizada = `${hoursF}:${minutesF}:${secondsF}`;
                  return (<>
                    <li key={index}>{item.Procedure.name}</li>
                    <p><strong>Horário início/fim realizado:</strong> {horaRealizada} - {horaFRealizada}</p>
                    <img src={'https://app-qrcode-salaprocedimentos.s3.amazonaws.com/' + item.image} width={300} height={200} alt="Imagem" />
                  </>
                  )
                })}
              </ActivitiesList>
              <CloseButton onClick={() => setshowCardDetails(false)}>Fechar</CloseButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </Container>

      {loading && <Loading />}
    </>
  );
};

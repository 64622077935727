import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';

import api from '../../services/api';
import { ItemListName } from '../../models/User';
import { useToast } from '../../hooks/toast';
import { InputOverview } from '../InputOverview';
import { Loading } from '../Loading';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  ButtonOption,
  ContainerAba,
  Content,
  DivAba,
  DivDual,
  DivForm,
  TableDiv,
} from './styles';

interface SubmitFormProps {
  nome: string;
}

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
}

export const ModalAddNameUser: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [isCadastro, setIsCadastro] = useState(false);
  const [name, setName] = useState('');
  const [idEdit, setIdEdit] = useState(-1);
  const [listNames, setListNames] = useState<ItemListName[]>([]);

  const formRef = useRef<FormHandles>(null);

  const getNames = useCallback(() => {
    setLoading(true);
    api
      .get(`/nameUser?userId=${userId}`)
      .then(resp => {
        setLoading(false);
        // console.log(resp.data);
        const list: ItemListName[] = resp.data;
        setListNames(list.filter(i => i.tb_UsersId === userId));
      })
      .catch(err => {
        setLoading(false);
        console.log(err.message);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      });
  }, [addToast, userId]);

  useEffect(() => {
    getNames();
  }, [getNames]);

  const handleCreate = useCallback(
    async (data: SubmitFormProps): Promise<void> => {
      try {
        setLoading(true);
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setName('');
        api
          .post('/nameUser', { userId, name: data.nome })
          .then(() => {
            setLoading(false);
            addToast({
              title: 'Sucesso!',
              description: `O usuário foi cadastrado com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          })
          .catch(err => {
            console.log(err.message);
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao cadastrar!',
              description: 'Verifique os dados!',
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, go, userId],
  );

  const handleUpdate = useCallback(
    async (id: number, nome: string) => {
      try {
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
        });

        setLoading(true);

        await schema.validate(
          { nome },
          {
            abortEarly: false,
          },
        );

        setName('');
        api
          .put('/nameUser', { id, name: nome })
          .then(() => {
            setLoading(false);
            addToast({
              title: 'Sucesso!',
              description: `Nome foi alterado com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          })
          .catch(err => {
            console.log(err.message);
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao editar!',
              description: 'Verifique os dados!',
            });
          });
      } catch (err) {
        setLoading(false);
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, go],
  );

  const handleSubmit = useCallback(
    (data: SubmitFormProps) => {
      if (idEdit === -1) {
        handleCreate(data);
      } else {
        handleUpdate(idEdit, data.nome);
      }
    },
    [handleCreate, handleUpdate, idEdit],
  );

  const handleRemove = useCallback(
    (id: number) => {
      try {
        setLoading(true);
        api
          .delete(`/nameUser/${id}`)
          .then(() => {
            setLoading(false);
            addToast({
              title: 'Sucesso!',
              description: `Nome foi removido com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          })
          .catch(err => {
            console.log(err.message);
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao remover item!',
            });
          });
      } catch (err) {
        setLoading(false);
      }
    },
    [addToast, go],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={onClose} className="react-modal-close">
        <AiOutlineClose size={22} />
      </button>
      <Content>
        <header>
          <h1>Nomes do Usuário</h1>
        </header>

        <DivAba>
          <ContainerAba className="aba" cor={!isCadastro}>
            <button type="button" onClick={() => setIsCadastro(false)}>
              Lista de Nomes
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={isCadastro}>
            <button type="button" onClick={() => setIsCadastro(true)}>
              {idEdit === -1 ? 'Cadastrar Nome' : 'Editar Nome'}
            </button>
          </ContainerAba>
        </DivAba>
        {isCadastro ? (
          <DivForm>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <p>Nome do Usuário</p>
              <InputOverview
                name="nome"
                placeholder="Carlos"
                value={name}
                onValue={setName}
              />
              <DivDual>
                <ButtonOption
                  isGreen={false}
                  type="button"
                  onClick={() => {
                    setIdEdit(-1);
                    setName('');
                    setIsCadastro(false);
                  }}
                >
                  Cancelar
                </ButtonOption>
                <ButtonOption isGreen type="submit">
                  Salvar
                </ButtonOption>
              </DivDual>
            </Form>
          </DivForm>
        ) : (
          <TableDiv>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {listNames.map((item, i) => (
                <tr key={`${i.toString()}`}>
                  <td>{item.name}</td>
                  <td className="action">
                    <button
                      type="button"
                      onClick={() => {
                        setIdEdit(item.id);
                        setIsCadastro(true);
                        setName(item.name);
                      }}
                    >
                      <FiEdit />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        Swal.fire({
                          title: 'Tem certeza que deseja remover?',
                          icon: 'warning',
                          showCancelButton: true,
                          cancelButtonText: 'Não',
                          confirmButtonColor: colors.greenPrimary,
                          cancelButtonColor: colors.redPrimary,
                          confirmButtonText: 'Sim',
                        }).then(result => {
                          if (result.isConfirmed) {
                            handleRemove(item.id);
                          }
                        });
                      }}
                    >
                      <FiTrash2 color={colors.redPrimary} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableDiv>
        )}
      </Content>
      {loading && <Loading />}
    </Modal>
  );
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { UserGet } from '../../models/User';
import { Filial } from '../../models/Filial';
import api from '../../services/api';
import Button from '../Button';
import { InputOverview } from '../InputOverview';
import { maskCpf, maskTel } from '../InputOverview/mask';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';
import getValidationErrors from '../../utils/getValidationErrors';

import { ContainerAba, Content, DivAba, DivDual, DivForm } from './styles';

interface DataNivel {
  filial: string;
  nivel:
    | 'Administrador P'
    | 'Administrador'
    | 'Coordenador'
    | 'Auditor'
    | 'ASG'
    | 'Sem nível';
}

interface SubmitFormProps {
  password: string;
  confirmPassword: string;
}

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  userEdit: UserGet;
  filiais: Filial[];
}

export const ModalEditUser: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  userEdit,
  filiais,
}) => {
  const { user, empresaPrincipal } = useAuth();
  const { addToast } = useToast();
  const { go } = useHistory();
  const { colors } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [status, setStatus] = useState(false);
  const [nivel, setNivel] = useState('');
  const [isNivel, setIsNivel] = useState(false);
  const [filialId, setFilialId] = useState<number>();

  const formRefNivel = useRef<FormHandles>(null);
  const formRefInfo = useRef<FormHandles>(null);

  const numberNivelInString = useCallback((niv: number) => {
    switch (niv) {
      case 2:
        return 'Administrador';
      case 3:
        return 'Coordenador';
      case 4:
        return 'Auditor';
      case 5:
        return 'ASG';
      default:
        return 'Sem nível';
    }
  }, []);

  useEffect(() => {
    setName(userEdit.nome);
    setNivel(
      numberNivelInString(userEdit.roleId !== null ? userEdit.roleId : 0),
    );
    setStatus(userEdit.ativo === 1);
    setTelefone(maskTel(userEdit.telefone || '0000'));
    setFilialId(userEdit.companyId);
  }, [
    numberNivelInString,
    userEdit.ativo,
    userEdit.companyId,
    userEdit.nome,
    userEdit.roleId,
    userEdit.telefone,
  ]);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const handleSubmit = useCallback(
    async (data: SubmitFormProps): Promise<void> => {
      try {
        setLoading(true);

        const obj = {
          id: userEdit.id,
          nome: name,
          telefone,
          ativo: status,
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(obj, {
          abortEarly: false,
        });

        if (data.password.length > 0 || data.confirmPassword.length > 0) {
          const schema2 = Yup.object().shape({
            password: Yup.string().min(6, 'No mínimo 6 dígitos'),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Senhas precisam ser iguais',
            ),
          });

          await schema2.validate(data, {
            abortEarly: false,
          });
        }

        let post: any = {};
        if (data.password.length > 0) {
          post = {
            userId: userEdit.id,
            nome: name,
            telefone,
            ativo: status,
            password: data.password,
          };
        } else {
          post = {
            userId: userEdit.id,
            nome: name,
            telefone,
            ativo: status,
          };
        }

        setLoading(false);
        await api
          .put('users', post)
          .then(() => {
            setLoading(false);

            addToast({
              title: 'Sucesso!',
              description: `O usuário foi alterado com sucesso.`,
              type: 'success',
            });

            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(err => {
            console.log(err.response);
            if (err.response.data.message) {
              addToast({
                title: 'Erro ao editar usuário!',
                description: err.response.data.message,
                type: 'error',
              });
            } else {
              addToast({
                title: 'Erro ao editar usuário!',
                description: 'Ocorreu um erro, por favor tente novamente.',
                type: 'error',
              });
            }
          });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefInfo.current?.setErrors(errors);
          return;
        }

        if (err.response.data.errors) {
          addToast({
            title: 'Erro ao editar usuário!',
            description: `${err.response.data.errors}.`,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Erro ao editar usuário!',
            description: 'Ocorreu um erro, por favor tente novamente.',
            type: 'error',
          });
        }
      }
    },
    [addToast, go, name, status, telefone, userEdit.id],
  );

  const updateASG = useCallback(
    (filial: string) => {
      const idFil = Number(filial.split(' ')[0]);

      setLoading(true);
      api
        .post('/empresas/transformToASG', {
          filialId: idFil,
          userId: userEdit.id,
        })
        .then(() => {
          setLoading(false);

          addToast({
            title: 'Sucesso!',
            description: `O acesso do usuário foi alterado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch(err => {
          setLoading(false);

          if (err.response.data.errors) {
            addToast({
              title: 'Erro ao editar usuário!',
              description: `${err.response.data.errors}.`,
              type: 'error',
            });
          } else {
            addToast({
              title: 'Erro ao editar usuário!',
              description: 'Ocorreu um erro, por favor tente novamente.',
              type: 'error',
            });
          }
        });
    },
    [addToast, go, userEdit.id],
  );

  const updateCoordenador = useCallback(
    (filial: string) => {
      const idFil = Number(filial.split(' ')[0]);

      setLoading(true);
      api
        .post('/empresas/transformToCoordenador', {
          filialId: idFil,
          userId: userEdit.id,
        })
        .then(() => {
          setLoading(false);

          addToast({
            title: 'Sucesso!',
            description: `O acesso do usuário foi alterado com sucesso.`,
            type: 'success',
          });

          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch(err => {
          setLoading(false);

          if (err.response.data.errors) {
            addToast({
              title: 'Erro ao editar usuário!',
              description: `${err.response.data.errors}.`,
              type: 'error',
            });
          } else {
            addToast({
              title: 'Erro ao editar usuário!',
              description: 'Ocorreu um erro, por favor tente novamente.',
              type: 'error',
            });
          }
        });
    },
    [addToast, go, userEdit.id],
  );

  const updateAdm = useCallback(() => {
    const empresaId = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;

    setLoading(true);
    api
      .post('/empresas/transformToAdminLocal', {
        companyId: empresaId,
        userId: userEdit.id,
      })
      .then(() => {
        setLoading(false);

        addToast({
          title: 'Sucesso!',
          description: `O acesso do usuário foi alterado com sucesso.`,
          type: 'success',
        });

        setTimeout(() => {
          go(0);
        }, 1000);
      })
      .catch(err => {
        setLoading(false);

        if (err.response.data.errors) {
          addToast({
            title: 'Erro ao editar usuário!',
            description: `${err.response.data.errors}.`,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Erro ao editar usuário!',
            description: 'Ocorreu um erro, por favor tente novamente.',
            type: 'error',
          });
        }
      });
  }, [addToast, empresaPrincipal, go, userEdit.id]);

  const updateAuditor = useCallback(() => {
    const empresaId = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;

    setLoading(true);
    api
      .post('/empresas/transformToAuditor', {
        companyId: empresaId,
        userId: userEdit.id,
      })
      .then(() => {
        setLoading(false);

        addToast({
          title: 'Sucesso!',
          description: `O acesso do usuário foi alterado com sucesso.`,
          type: 'success',
        });

        setTimeout(() => {
          go(0);
        }, 1000);
      })
      .catch(err => {
        setLoading(false);

        if (err.response.data.errors) {
          addToast({
            title: 'Erro ao editar usuário!',
            description: `${err.response.data.errors}.`,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Erro ao editar usuário!',
            description: 'Ocorreu um erro, por favor tente novamente.',
            type: 'error',
          });
        }
      });
  }, [addToast, empresaPrincipal, go, userEdit.id]);

  const handleSubmitNivel = useCallback(
    (data: DataNivel) => {
      if (data.nivel === 'Sem nível') {
        addToast({
          title: 'É necessário escolher um nível para o usuário!',
          type: 'info',
        });
      } else if (data.nivel === 'ASG') {
        updateASG(data.filial);
      } else if (data.nivel === 'Auditor') {
        updateAuditor();
      } else if (data.nivel === 'Coordenador') {
        updateCoordenador(data.filial);
      } else if (data.nivel === 'Administrador') {
        updateAdm();
      }
    },
    [addToast, updateASG, updateAdm, updateAuditor, updateCoordenador],
  );

  const optionNivel = useMemo(() => {
    if (isCoordenador) {
      return ['Coordenador', 'ASG', 'Sem nível'];
    }
    return ['Administrador', 'Coordenador', 'Auditor', 'ASG', 'Sem nível'];
  }, [isCoordenador]);

  const filialSel = useMemo(() => {
    if (filialId === undefined && filiais.length > 0) {
      const id = isCoordenador ? userEdit.companyId : filiais[0].ID;
      let aux = '';
      filiais.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setFilialId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    filiais.forEach(item => {
      if (item.ID === filialId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [filialId, filiais, isCoordenador, userEdit.companyId]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={() => onRequestClose()}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>
      <Content>
        <header>
          <h1>Editar Usuário</h1>
        </header>
        <div className="name">
          <p>{userEdit.nome}</p>
          <p>{maskCpf(userEdit.cpf)}</p>
        </div>
        <p>{userEdit.email}</p>

        <DivAba>
          <ContainerAba className="aba" cor={!isNivel}>
            <button type="button" onClick={() => setIsNivel(false)}>
              Alterar informações
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={isNivel}>
            <button type="button" onClick={() => setIsNivel(true)}>
              Alterar nível de acesso
            </button>
          </ContainerAba>
        </DivAba>

        <DivForm>
          {isNivel ? (
            <Form ref={formRefNivel} onSubmit={handleSubmitNivel}>
              <p>Nível de Acesso</p>
              <SelectCustom
                name="nivel"
                type="status"
                optionsDataStatus={optionNivel}
                value={nivel}
                onValue={e => setNivel(e)}
              />

              {(nivel === 'Coordenador' || nivel === 'ASG') && (
                <>
                  <p>Filial</p>
                  <SelectCustom
                    name="filial"
                    defaultValue="Empresa"
                    optionsDataCompany={filiais}
                    value={filialSel}
                    disabled={isCoordenador}
                    onValue={e => setFilialId(parseInt(e.split('-')[0], 10))}
                  />
                </>
              )}

              <div style={{ height: 30 }} />

              <Button type="submit" widthProps="100%" style={{ marginTop: 20 }}>
                Salvar
              </Button>
            </Form>
          ) : (
            <Form ref={formRefInfo} onSubmit={handleSubmit}>
              <p>Nome</p>
              <InputOverview
                name="nome"
                placeholder="Greendot"
                value={name}
                onValue={e => setName(e)}
              />

              <DivDual>
                <div className="ele1">
                  <p>Telefone</p>
                  <InputOverview
                    name="phone"
                    placeholder="(85) 99999-9999"
                    mask="phone"
                    value={telefone}
                    onValue={e => setTelefone(e)}
                  />
                </div>
                <div className="ele2">
                  <p>Status</p>
                  <div className="status">
                    <button
                      type="button"
                      onClick={() => {
                        setStatus(!status);
                      }}
                    >
                      <BsCheckCircle
                        color={status ? colors.greenPrimary : ''}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStatus(!status);
                      }}
                    >
                      <BsXCircle color={!status ? colors.redPrimary : ''} />
                    </button>
                  </div>
                </div>
              </DivDual>
              <p>Senha</p>
              <InputOverview name="password" placeholder="********" />
              <p>Confirmar Senha</p>
              <InputOverview name="confirmPassword" placeholder="********" />

              <div style={{ height: 30 }} />

              <Button type="submit" widthProps="100%">
                Salvar
              </Button>
            </Form>
          )}
        </DivForm>
      </Content>
      {loading && <Loading />}
    </Modal>
  );
};

import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useContext,
} from 'react';

import { RiQrCodeFill } from 'react-icons/ri';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, Link } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { ThemeContext } from 'styled-components';
// import filesize from 'filesize';
// import { uuid } from 'uuidv4';
// import { FiUpload } from 'react-icons/fi';
import QrCode from 'qrcode';
import jsPDF from 'jspdf';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import api, { baseUrlWeb } from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import ModalConexao from '../../../components/ModalConexao';
import { InputOverview } from '../../../components/InputOverview';
import HeaderTable from '../../../components/HeaderTable';
import Search from '../../../components/Search';
import ModalQrCode from '../../../components/ModalQrCode';
import Pagination from '../../../components/Pagination';
import { SelectCustom } from '../../../components/SelectCustom';
import { ModalFilter, StatusButton } from '../../../components/ModalFilter';
import Button from '../../../components/Button';
import IconFilter from '../../../assets/IconFilter.svg';
import IconEdit from '../../../assets/IconEdit.svg';
import IconRemove from '../../../assets/IconRemove.svg';
import IconAdd from '../../../assets/IconAdd.svg';
import DropDownPagination from '../../../components/DropDownPagination';

import ImagemDeFundoLimpeza from '../../../assets/backgroundPdf.png';
import {
  Container,
  Aba,
  ContainerAba,
  AnimationContainer,
  ContainerFuncoes,
  ContainerTable,
  ContainerPagination,
  ContainerButton,
  ContainerLabel,
  ContainerSemResultados,
  ContainerTitle,
  ContainerSearch,
  CheckLabel,
  // ContainerCadastroOption,
  // RadioBox,
  // Content,
  ButtonQrCode,
  MsgImp,
} from './styles';
import { AmbienteGet as AmbienteMod } from '../../../models/Ambiente';
import { Filial } from '../../../models/Filial';
// import Upload from '../../../components/Upload';
// import FileList from '../../../components/FileList';
// import { arquivoModeloAmbientesUrlAws } from '../../../utils/urlsBucketAws';

interface AmbienteGet extends AmbienteMod {
  checked?: boolean;
}

interface SignUpFormData {
  companyId: string;
  empresa: string;
  nome: string;
  categoria: string;
  setor: string;
  andar: string;
  riscos: [];
  procedimentos: [];
  EMPRESA_ID: number;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
  EMPRESA_ID: number;
}

export interface CategoriasInterface {
  ID_CATEGORIA: number;
  DESCRICAO: string;
  EMPRESA_ID: number;
}

interface ProcedimentoInterface {
  ID_PROCEDIMENTO: number;
  NAME_PROCEDIMENTO: string;
  EMPRESA_ID: number;
}

export interface SetoresInterface {
  id: number;
  name: string;
  EMPRESA_ID: number;
}

interface RiscosInterface {
  id: number;
  name: string;
  EMPRESA_ID: number;
}

// interface Uploaded {
//   file: File | null;
//   id: string;
//   name: string;
//   readableSize: string;
//   progress: number;
//   uploaded: boolean;
//   error: boolean;
//   type: string;
// }

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const headersViewer = [
  { name: 'Empresa', field: 'EMPRESA', sortable: true },
  { name: 'Ambiente', field: 'AMBIENTE_NOME', sortable: true },
  { name: 'Categoria', field: 'CATEGORIA', sortable: true },
  { name: 'Setor', field: 'SETOR', sortable: true },
  { name: 'Andar', field: 'ANDAR', sortable: true },
  { name: 'Riscos', field: 'RISCOS', sortable: true },
  {
    name: 'Procedimentos Obrigatórios',
    field: 'PROCEDIMENTOSOBR',
    sortable: true,
  },
  {
    name: 'Procedimentos Opcionais',
    field: 'PROCEDIMENTOSOPC',
    sortable: true,
  },
  { name: '', field: 'qrCode', sortable: false },
];

export const Ambientes: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  // const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [cadastrar, setCadastrar] = useState(true);
  const [editar, setEditar] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilterModal, setDataFilterModal] = useState<StatusButton[]>([
    {
      status: false,
      name: 'Empresa',
    },
    {
      status: true,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Categoria',
    },
    {
      status: false,
      name: 'Setor',
    },
    {
      status: false,
      name: 'Andar',
    },
    {
      status: false,
      name: 'Risco',
    },
    {
      status: false,
      name: 'Procedimento',
    },
  ]);

  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [editNomeAmbiente, setEditNomeAmbiente] = useState('');
  const [editAndar, setEditAndar] = useState('');
  const [editCategoria, setEditCategoria] = useState<number>(0);
  const [editSetor, setEditSetor] = useState<number>(0);
  const [editIdCategoria, setEditIdCategoria] = useState<number>();
  const [editIdSetor, setEditIdSetor] = useState<number>();

  const [editRisco, setEditRisco] = useState<number[]>([]);
  const [editProcedimento, setEditProcedimento] = useState<number[]>([]);
  const [
    editProcedimentoNotMandatory,
    setEditProcedimentoNotMandatory,
  ] = useState<number[]>([]);

  const [verificaRisco, setVerificaRisco] = useState<string[]>([]);
  const [verificaProcedimento, setVerificaProcedimento] = useState<string[]>(
    [],
  );
  const [ambiente, setAmbiente] = useState<AmbienteGet>();

  const [response, setResponse] = useState<AmbienteGet[]>([]);
  const [procedimentos, setProcedimentos] = useState<ProcedimentoInterface[]>(
    [],
  );
  const [empresas, setEmpresas] = useState<Filial[]>([]);
  const [empresaId, setEmpresaId] = useState<number>();
  // const [empresaIdLote, setEmpresaIdLote] = useState<number>();

  const [categorias, setCategorias] = useState<CategoriasInterface[]>([]);
  const [setores, setSetores] = useState<SetoresInterface[]>([]);
  const [riscos, setRiscos] = useState<RiscosInterface[]>([]);
  const [categoria, setCategoria] = useState<number>();
  const [setor, setSetor] = useState<number>();
  const [risco, setRisco] = useState<number[]>([]);
  const [procedimento, setProcedimento] = useState<number[]>([]);
  const [procedimentoNotMandatory, setProcedimentoNotMandatory] = useState<
    number[]
  >([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const [qrCode, setQrCode] = useState(false);
  const [idQrCode, setIDQrCode] = useState<number>();
  const [dadosQrCode, setDadosQrCode] = useState<AmbienteGet>();
  const [markAllProc, setMarkAllProc] = useState(false);

  const [verificaResponse, setVerificaResponse] = useState(false);
  // const [registerType, setRegisterType] = useState<'individual' | 'lote'>(
  //   'individual',
  // );
  // const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  // const [download, setDownload] = useState('');
  // const [count, setCount] = useState(0);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  // const getDados = useCallback(async () => {
  //   api
  //     .get(`historic/v2/byEnvironment/2`)
  //     .then(resp => {
  //       console.log(resp.data);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, []);

  const getAmbientes = useCallback(() => {
    const companyId =
      empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
    const filialId = user.companyId;

    const aux = isCoordenador
      ? `filialId=${filialId}`
      : `companyId=${companyId}`;

    api
      .get(`/environments?${aux}`)
      .then(resp => {
        // console.log('AMB', resp.data);
        // const ambs: AmbienteGet[] = resp.data;
        const ambs = resp.data.map((item: AmbienteGet) => {
          const procdifs: { name: string; mandatory: boolean }[] = [];
          item.PROCEDIMENTOS.forEach(pro => {
            const find = procdifs.find(pd => pd.name === pro.name);
            if (!find) {
              procdifs.push(pro);
            }
          });
          return {
            ...item,
            PROCEDIMENTOS: procdifs,
          };
        });
        setResponse(ambs.map((i: AmbienteMod) => ({ ...i, checked: false })));
      })
      .catch(err => {
        console.log(err.message);
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            'Erro ao carregar os ambientes, por favor atualize a página.',
        });
      });
  }, [addToast, empresaPrincipal, isCoordenador, user.companyId]);

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      let param;
      if (empresaPrincipal.length > 0) param = empresaPrincipal[0].Id_Empresas;
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';

      const environmentsForm = await api.get(`environments/form/${param}`);
      // console.log(environmentsForm.data);
      // const environments = await api.get(`/environments/?id=${companyId}`);
      // console.log(environments.data);
      const companies = await api.get(
        `empresas/filiais?companyId=${companyId}`,
      );

      const emp: Filial[] = [];
      companies.data.forEach((filial: Filial) => {
        if (filial.ATIVO === true) {
          emp.push(filial);
        }
      });
      setEmpresas(emp);

      setCategorias(environmentsForm.data.categorias);
      setProcedimentos(environmentsForm.data.procedimentos);
      setSetores(environmentsForm.data.setores);
      setRiscos(environmentsForm.data.riscos);
      setCategoria(environmentsForm.data.categorias[0].ID_CATEGORIA);
      setSetor(environmentsForm.data.setores[0].id);

      // console.log('DIRETO', environments.data);
      // setResponse(
      //   environments.data.map((i: AmbienteMod) => ({ ...i, checked: false })),
      // );

      setVerificaResponse(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (
        err.message === "Cannot read property 'ID_CATEGORIA' of undefined" ||
        err.message === "Cannot read property 'id' of undefined"
      ) {
        addToast({
          type: 'info',
          title: 'Cadastros não realizados',
          description:
            'Favor cadastrar ao menos um procedimento, risco, setor e categoria antes de cadastrar um ambiente',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página.',
        });
      }
    }
  }, [addToast, empresaPrincipal]);

  useEffect(() => {
    getItens();
    getAmbientes();
  }, [getAmbientes, getItens]);

  useEffect(() => {
    const filterCategorias = categorias.filter(
      item => item.EMPRESA_ID === empresaId,
    );
    const filterSetores = setores.filter(item => item.EMPRESA_ID === empresaId);

    filterCategorias.length
      ? setCategoria(filterCategorias[0].ID_CATEGORIA)
      : setCategoria(undefined);
    filterSetores.length ? setSetor(filterSetores[0].id) : setSetor(undefined);

    setRisco([]);
    setProcedimento([]);
  }, [categorias, empresaId, setores]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do ambiente é obrigatório'),
          andar: Yup.string().required('Nome do andar é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (risco.length === 0) {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Favor selecionar o(s) risco(s) do ambiente',
          });
        } else if (
          procedimento.length === 0 &&
          procedimentoNotMandatory.length === 0
        ) {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Favor selecionar o(s) procedimento(s) do ambiente',
          });
        } else {
          let verificaAmbienteDuplicado = false;
          response.forEach(elementTemp => {
            if (
              elementTemp.AMBIENTE_NOME.toLowerCase() ===
                data.nome.toLowerCase() &&
              elementTemp.companyId === empresaId
            )
              verificaAmbienteDuplicado = true;
          });

          // console.log({
          //   name: data.nome,
          //   categoriaId: categoria,
          //   companyId: empresaId,
          //   setorId: setor,
          //   riscos: risco,
          //   procedures: procedimento,
          //   proceduresNotMandatory: procedimentoNotMandatory,
          //   andar: data.andar,
          // });

          if (!verificaAmbienteDuplicado) {
            await api
              .post('/environments/', {
                name: data.nome,
                categoriaId: categoria,
                companyId: empresaId,
                setorId: setor,
                riscos: risco,
                procedures: procedimento,
                proceduresNotMandatory: procedimentoNotMandatory,
                andar: data.andar,
              })
              .then(() => {
                // console.log(resp.data);
                setLoading(false);

                addToast({
                  type: 'success',
                  title: 'Sucesso',
                  description: 'Ambiente cadastrado com sucesso',
                });

                setTimeout(() => {
                  history.go(0);
                }, 1000);
              })
              .catch(() => {
                setLoading(false);

                addToast({
                  type: 'error',
                  title: 'Erro',
                  description: 'Erro ao cadastrar o ambiente',
                });
              });
          } else {
            setLoading(false);

            addToast({
              type: 'info',
              title: 'Ambiente duplicado',
              description:
                'Já existe um ambiente cadastrado com o mesmo nome e empresa',
            });
          }
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao cadastrar o dados',
          });
        }
      }
    },
    [
      risco,
      procedimento,
      procedimentoNotMandatory,
      addToast,
      response,
      categoria,
      empresaId,
      setor,
      history,
    ],
  );

  const handleDelete = useCallback(
    async (dados: AmbienteGet) => {
      try {
        setLoading(true);
        await api
          .delete(`/environments/`, { data: dados })
          .then(() => {
            setLoading(false);

            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'Ambiente deletado com sucesso',
            });

            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(err => {
            setLoading(false);
            if (
              err.response.data.message ===
              'Ambiente já possui Historico não pode ser deletado'
            ) {
              addToast({
                type: 'error',
                title: 'Erro ao deletar ambiente',
                description:
                  'Ambiente já possui historico e não pode ser deletado',
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro',
                description: 'Erro ao deletar ambiente',
              });
            }
          });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, history],
  );

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do ambiente é obrigatório'),
          andar: Yup.string().required('Nome do andar é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (editRisco.length === 0) {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Favor selecionar o(s) risco(s) do ambiente',
          });
        } else if (
          editProcedimento.length === 0 &&
          editProcedimentoNotMandatory.length === 0
        ) {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Favor selecionar o(s) procedimento(s) do ambiente',
          });
        } else {
          let verificaAmbienteDuplicado = false;
          response.forEach(elementTemp => {
            if (
              elementTemp.AMBIENTE_NOME.toLowerCase() ===
                data.nome.toLowerCase() &&
              elementTemp.ID !== ambiente?.ID
            )
              verificaAmbienteDuplicado = true;
          });

          if (!verificaAmbienteDuplicado) {
            // console.log(ambiente?.ID);
            // console.log({
            //   name: data.nome,
            //   categoriaId: editIdCategoria,
            //   procedures: editProcedimento,
            //   proceduresNotMandatory: editProcedimentoNotMandatory,
            //   riscos: editRisco,
            //   setorId: editIdSetor,
            //   andar: data.andar,
            // });
            await api
              .put(`/environments/${ambiente?.ID}`, {
                name: data.nome,
                categoriaId: editIdCategoria,
                procedures: editProcedimento,
                proceduresNotMandatory: editProcedimentoNotMandatory,
                riscos: editRisco,
                setorId: editIdSetor,
                andar: data.andar,
              })
              .then(() => {
                setLoading(false);
                // console.log(resp.data);

                addToast({
                  type: 'success',
                  title: 'Sucesso',
                  description: 'Ambiente editado com sucesso',
                });

                setTimeout(() => {
                  history.go(0);
                }, 1000);
              })
              .catch(() => {
                setLoading(false);

                addToast({
                  type: 'error',
                  title: 'Erro',
                  description: 'Erro ao editar ambiente',
                });
              });
          } else {
            setLoading(false);

            addToast({
              type: 'info',
              title: 'Ambiente duplicado',
              description:
                'Já existe um ambiente cadastrado com o mesmo nome e empresa',
            });
          }
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Campos em branco',
            description: 'Existem campos obrigatórios não preenchidos.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao editar o dados',
          });
        }
      }
    },
    [
      addToast,
      ambiente?.ID,
      editIdCategoria,
      editIdSetor,
      editProcedimento,
      editProcedimentoNotMandatory,
      editRisco,
      history,
      response,
    ],
  );

  const handleChangeRisco = useCallback(
    (dados: RiscosInterface, value: boolean) => {
      riscos.forEach(box => {
        if (box.id === dados.id) {
          if (value) {
            risco.push(box.id);
          } else {
            risco.splice(risco.indexOf(dados.id), 1);
          }
        }
      });
    },
    [risco, riscos],
  );

  const handleChangeProcedimento = useCallback(
    (dados: ProcedimentoInterface, value: boolean) => {
      procedimentos.forEach(box => {
        if (box.ID_PROCEDIMENTO === dados.ID_PROCEDIMENTO) {
          if (value) {
            setProcedimento([...procedimento, box.ID_PROCEDIMENTO]);
            setProcedimentoNotMandatory(
              procedimentoNotMandatory.filter(i => i !== dados.ID_PROCEDIMENTO),
            );
          } else {
            setProcedimento([
              ...procedimento.filter(i => i !== dados.ID_PROCEDIMENTO),
            ]);
            // procedimento.splice(procedimento.indexOf(dados.ID_PROCEDIMENTO), 1);
          }
        }
      });
    },
    [procedimento, procedimentoNotMandatory, procedimentos],
  );

  const handleChangeProcedimentoNotMandatory = useCallback(
    (dados: ProcedimentoInterface, value: boolean) => {
      procedimentos.forEach(box => {
        if (box.ID_PROCEDIMENTO === dados.ID_PROCEDIMENTO) {
          // console.log(value);
          if (value) {
            setProcedimentoNotMandatory([
              ...procedimentoNotMandatory,
              box.ID_PROCEDIMENTO,
            ]);
            setProcedimento([
              ...procedimento.filter(i => i !== dados.ID_PROCEDIMENTO),
            ]);
          } else {
            setProcedimentoNotMandatory([
              ...procedimentoNotMandatory.filter(
                i => i !== dados.ID_PROCEDIMENTO,
              ),
            ]);
          }
        }
      });
    },
    [procedimento, procedimentoNotMandatory, procedimentos],
  );

  const dropdownKeySetor = useCallback(
    (valor: string): number => {
      const data = setores.find(
        item => item.EMPRESA_ID === empresaId && item.name === valor,
      );

      const dataIndex = setores.findIndex(
        item => item.EMPRESA_ID === empresaId && item.name === valor,
      );
      dataIndex !== -1 && setEditSetor(dataIndex);

      return data ? data.id : 0;
    },
    [empresaId, setores],
  );

  const dropdownKeyCategorias = useCallback(
    (valor: string): number => {
      const data = categorias.find(
        item => item.EMPRESA_ID === empresaId && item.DESCRICAO === valor,
      );

      const dataIndex = categorias.findIndex(
        item => item.EMPRESA_ID === empresaId && item.DESCRICAO === valor,
      );

      dataIndex !== -1 && setEditCategoria(dataIndex);

      return data ? data.ID_CATEGORIA : 0;
    },
    [categorias, empresaId],
  );

  const categoriaStringToPosition = useCallback(
    (valor: string, set: CategoriasInterface[]) => {
      let i = 0;
      let position = false;
      set.forEach(dados => {
        if (valor === dados.DESCRICAO && !position) {
          position = true;
        }
        position ? (i += 0) : (i += 1);
      });

      return i;
    },
    [],
  );

  const setorStringToPosition = useCallback(
    (valor: string, set: SetoresInterface[]) => {
      let i = 0;
      let position = false;
      set.forEach(dados => {
        if (valor === dados.name && !position) {
          position = true;
        }
        position ? (i += 0) : (i += 1);
      });
      return i;
    },
    [],
  );

  const handleChangeEditRisco = useCallback(
    (dados: RiscosInterface, value: boolean) => {
      if (value) {
        setEditRisco(editRisco.concat(dados.id));
      } else {
        const filterDiferentIds = editRisco.filter(item => item !== dados.id);
        setEditRisco(filterDiferentIds);
      }
    },
    [editRisco],
  );

  const verificaCheckRisco = useCallback(
    (dado: RiscosInterface): boolean => {
      const data = verificaRisco.filter((r: string) => r === dado.name);

      if (data.length > 0) {
        return true;
      }

      return false;
    },

    [verificaRisco],
  );

  const handleChangeEditProcedimento = useCallback(
    (dados: ProcedimentoInterface, value: boolean) => {
      if (value) {
        setEditProcedimento(editProcedimento.concat(dados.ID_PROCEDIMENTO));
        setEditProcedimentoNotMandatory([
          ...editProcedimentoNotMandatory.filter(
            i => i !== dados.ID_PROCEDIMENTO,
          ),
        ]);
      } else {
        const filterDiferentIds = editProcedimento.filter(
          item => item !== dados.ID_PROCEDIMENTO,
        );
        setEditProcedimento(filterDiferentIds);
      }
    },
    [editProcedimento, editProcedimentoNotMandatory],
  );

  const handleChangeEditProcedimentoNotMandatory = useCallback(
    (dados: ProcedimentoInterface, value: boolean) => {
      if (value) {
        setEditProcedimentoNotMandatory([
          ...editProcedimentoNotMandatory,
          dados.ID_PROCEDIMENTO,
        ]);
        setEditProcedimento([
          ...editProcedimento.filter(i => i !== dados.ID_PROCEDIMENTO),
        ]);
      } else {
        const filterDiferentIds = editProcedimentoNotMandatory.filter(
          item => item !== dados.ID_PROCEDIMENTO,
        );
        setEditProcedimentoNotMandatory(filterDiferentIds);
      }
    },
    [editProcedimento, editProcedimentoNotMandatory],
  );

  const verificaCheckProcedimento = useCallback(
    (dado: ProcedimentoInterface): boolean => {
      const data = verificaProcedimento.filter(
        r => r === dado.NAME_PROCEDIMENTO,
      );

      if (data.length > 0) {
        return true;
      }
      return false;
    },
    [verificaProcedimento],
  );

  const handlePreencherEdit = useCallback(
    (dado: AmbienteGet) => {
      setEditCategoria(categoriaStringToPosition(dado.CATEGORIA, categorias));
      setEditSetor(setorStringToPosition(dado.SETOR, setores));
    },
    [categoriaStringToPosition, categorias, setorStringToPosition, setores],
  );

  const handleAlertMaster = useCallback(
    (acao: string, dataEdit?: AmbienteGet) => {
      if (acao === 'editar' && verificaResponse && dataEdit) {
        setEditar(true);
        setEmpresaId(dataEdit.companyId);
        setEditNomeAmbiente(dataEdit.AMBIENTE_NOME);
        const catEdit = categorias.find(
          i =>
            i.DESCRICAO === dataEdit.CATEGORIA &&
            dataEdit.companyId === i.EMPRESA_ID,
        );
        if (catEdit) {
          setEditIdCategoria(catEdit.ID_CATEGORIA);
        }
        const setEdit = setores.find(
          i => i.name === dataEdit.SETOR && dataEdit.companyId === i.EMPRESA_ID,
        );
        if (setEdit) {
          setEditIdSetor(setEdit.id);
        }

        setEditAndar(dataEdit.ANDAR);

        const editRiscosAux = riscos.filter(
          item =>
            item.EMPRESA_ID === dataEdit.companyId &&
            dataEdit.RISCOS.find((itemAux: string) => itemAux === item.name),
        );
        setEditRisco(editRiscosAux.map(item => item.id));
        setVerificaRisco(editRiscosAux.map(item => item.name));

        const editProcedimentosAux = procedimentos.filter(
          item =>
            item.EMPRESA_ID === dataEdit.companyId &&
            dataEdit.PROCEDIMENTOS.find(
              itemAux =>
                itemAux.name === item.NAME_PROCEDIMENTO && itemAux.mandatory,
            ),
        );
        const editProcedimentosNotMandatoryAux = procedimentos.filter(
          item =>
            item.EMPRESA_ID === dataEdit.companyId &&
            dataEdit.PROCEDIMENTOS.find(
              itemAux =>
                itemAux.name === item.NAME_PROCEDIMENTO && !itemAux.mandatory,
            ),
        );
        setEditProcedimento(
          editProcedimentosAux.map(item => item.ID_PROCEDIMENTO),
        );
        setVerificaProcedimento([
          ...editProcedimentosAux.map(i => i.NAME_PROCEDIMENTO),
          ...editProcedimentosNotMandatoryAux.map(
            item => item.NAME_PROCEDIMENTO,
          ),
        ]);
        setEditProcedimentoNotMandatory(
          editProcedimentosNotMandatoryAux.map(item => item.ID_PROCEDIMENTO),
        );

        handlePreencherEdit(dataEdit);
        setAmbiente(dataEdit);
      } else if (verificaResponse) {
        setCadastrar(false);
        setSearch('');
      }
    },
    [
      categorias,
      handlePreencherEdit,
      procedimentos,
      riscos,
      setores,
      verificaResponse,
    ],
  );

  // const handleUpload = useCallback(
  //   files => {
  //     const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
  //       file,
  //       id: uuid(),
  //       name: file.name,
  //       readableSize: filesize(file.size),
  //       progress: 0,
  //       uploaded: false,
  //       error: false,
  //       type: '.csv, application/vnd.ms-excel, text/csv',
  //     }));

  //     setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
  //   },
  //   [uploadedFiles],
  // );

  // const updateFile = useCallback((id, data) => {
  //   setUploadedFiles(estadoAnterior =>
  //     estadoAnterior.map(file =>
  //       id === file.id ? { ...file, ...data } : file,
  //     ),
  //   );
  // }, []);

  // const handleDelete2 = useCallback(
  //   async id => {
  //     uploadedFiles.splice(
  //       uploadedFiles.findIndex(element => element.id === id),
  //       1,
  //     );
  //     setUploadedFiles([...uploadedFiles]);
  //   },
  //   [uploadedFiles],
  // );

  // const processUpload = useCallback(
  //   (uploadedFile: Uploaded) => {
  //     const data = new FormData();

  //     if (uploadedFile.file && empresaIdLote) {
  //       data.append('csvProcedures', uploadedFile.file);
  //       data.append('idEmpresaFilial', empresaIdLote.toString());
  //     }

  //     // api
  //     //   .post('cadastroViaCSV/procedures', data, { // não funciona
  //     //     onUploadProgress: e => {
  //     //       const progress = Math.round((e.loaded * 100) / e.total);

  //     //       updateFile(uploadedFile.id, {
  //     //         progress,
  //     //       });
  //     //     },
  //     //   })
  //     //   .then(() => {
  //     //     updateFile(uploadedFile.id, {
  //     //       uploaded: true,
  //     //     });

  //     //     setTimeout(() => {
  //     //       history.go(0);
  //     //     }, 1500);
  //     //   })
  //     //   .catch(() => {
  //     //     updateFile(uploadedFile.id, {
  //     //       error: true,
  //     //     });
  //     //   });
  //   },
  //   [empresaIdLote],
  // );

  // const clickHandleUpload = useCallback(() => {
  //   if (!uploadedFiles.length) {
  //     addToast({
  //       type: 'error',
  //       title: 'Erro ao cadastrar lista de procedimentos!',
  //       description:
  //         'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto.',
  //     });
  //   } else {
  //     uploadedFiles.forEach(processUpload);
  //   }
  // }, [addToast, processUpload, uploadedFiles]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  const responseData = useMemo(() => {
    let computedResponses: AmbienteGet[] = [];
    computedResponses = response.length ? response : [];
    // console.log(response);

    if (search) {
      computedResponses = computedResponses.filter((res: AmbienteGet) => {
        const dataFilter: any = [];

        dataFilterModal.forEach(filtro => {
          if (filtro.status) {
            switch (filtro.name) {
              case 'Empresa':
                dataFilter.push(res.EMPRESA);
                break;
              case 'Ambiente':
                dataFilter.push(res.AMBIENTE_NOME);
                break;
              case 'Categoria':
                dataFilter.push(res.CATEGORIA);
                break;
              case 'Setor':
                dataFilter.push(res.SETOR);
                break;
              case 'Andar':
                dataFilter.push(res.ANDAR);
                break;
              case 'Risco':
                dataFilter.push(res.RISCOS);
                break;
              case 'Procedimento':
                dataFilter.push(res.PROCEDIMENTOS.join(', '));
                break;
              default:
                break;
            }
          }
        });

        return dataFilter.some(
          (item: any) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
        );
      });
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object' && a[sorting.field] != null) {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }

        const aTemp = a[sorting.field] != null ? a[sorting.field] : '';
        const bTemp = b[sorting.field] != null ? b[sorting.field] : '';
        return reversed * aTemp.toString().localeCompare(bTemp.toString());
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    search,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
    dataFilterModal,
  ]);

  const headers = [
    {
      name:
        responseData.filter(i => !i.checked).length !== 0
          ? 'Marcar Todos'
          : 'Desmarcar Todos',
      field: 'marked',
      sortable: false,
      onClick: () => {
        const isMarkedAll = responseData.filter(i => !i.checked).length === 0;
        if (isMarkedAll) {
          setResponse(
            response.map(res => {
              const find = responseData.find(resData => resData.ID === res.ID);
              if (find) {
                return { ...res, checked: false };
              }
              return res;
            }),
          );
        } else {
          setResponse(
            response.map(res => {
              const find = responseData.find(resData => resData.ID === res.ID);
              if (find) {
                return { ...res, checked: true };
              }
              return res;
            }),
          );
        }
      },
    },
    { name: 'Empresa', field: 'EMPRESA', sortable: true },
    { name: 'Ambiente', field: 'AMBIENTE_NOME', sortable: true },
    { name: 'Categoria', field: 'CATEGORIA', sortable: true },
    { name: 'Setor', field: 'SETOR', sortable: true },
    { name: 'Andar', field: 'ANDAR', sortable: true },
    { name: 'Riscos', field: 'RISCOS', sortable: true },
    {
      name: 'Procedimentos Obrigatórios',
      field: 'PROCEDIMENTOSOBR',
      sortable: true,
    },
    {
      name: 'Procedimentos Opcionais',
      field: 'PROCEDIMENTOSOPC',
      sortable: true,
    },
    { name: 'QrCode', field: 'qrCode', sortable: false },
    { name: 'Editar', field: 'editar', sortable: false },
    { name: 'Remover', field: 'remover', sortable: false },
  ];

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);
      // console.log('TESTE', idQrCodes);
      const idQrCodes = responseData.filter(i => i.checked);
      const doc = new jsPDF('p', 'mm', 'a4'); // eslint-disable-line

      const promises = idQrCodes.map(item => QrCode.toDataURL(`${item.ID}`));
      Promise.all(promises).then(resp => {
        const { length } = resp;
        const width = 215;
        const height = 300;

        resp.forEach((url, index) => {
          const img = new Image();
          img.src = ImagemDeFundoLimpeza;
          doc.addImage(img, 'PNG', 0, 0, width, height);
          doc.addImage(url, 'PNG', 56, 85, 100, 100);
          const quebraLinha = doc.splitTextToSize(
            idQrCodes[index].AMBIENTE_NOME,
            110,
          );
          doc.text(quebraLinha, 105, 200, {
            maxWidth: 110,
            align: 'center',
          });
          // console.log(index, length, index !== length - 1, 'ADD');
          if (index !== length - 1) {
            doc.addPage();
          }
        });

        doc.autoPrint();
        doc.save(`QRCODES-${moment().format('YYYYMMDD-HHmm')}`);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  }, [responseData]);

  const empresaSel = useMemo(() => {
    if (empresaId === undefined && empresas.length > 0) {
      const id = isCoordenador ? user.companyId : empresas[0].ID;
      let aux = '';
      empresas.forEach(item => {
        if (item.ID === id) {
          aux = `${item.ID} - ${item.NOME}`;
          setEmpresaId(item.ID);
        }
      });
      return aux;
    }
    let aux = '';
    empresas.forEach(item => {
      if (item.ID === empresaId) {
        aux = `${item.ID} - ${item.NOME}`;
      }
    });
    return aux;
  }, [empresaId, empresas, isCoordenador, user.companyId]);

  return (
    <>
      {idQrCode && (
        <ModalQrCode
          isOpen={qrCode}
          dados={idQrCode}
          dadosGeral={dadosQrCode}
          onRequestClose={() => setQrCode(false)}
        />
      )}

      <ModalFilter
        isOpen={showModalFilter}
        onRequestClose={() => setShowModalFilter(false)}
        onReturnData={e => setDataFilterModal(e)}
      />

      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button" onClick={() => setCadastrar(true)}>
                Listar ambientes
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button
                type="button"
                onClick={() => handleAlertMaster('cadastrar')}
              >
                Cadastrar ambiente
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição de todos os ambientes que serão gerenciados para limpeza"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button type="button">Listar ambientes</button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todos os ambientes que serão gerenciados para limpeza"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {cadastrar && !editar && (
          <ContainerSearch>
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />

            <button type="button" onClick={() => setShowModalFilter(true)}>
              <img src={IconFilter} alt="iconFilter" />
            </button>
          </ContainerSearch>
        )}

        {/* {!cadastrar && (
          <ContainerCadastroOption>
            <RadioBox
              type="button"
              onClick={() => setRegisterType('individual')}
            >
              Cadastro individual
            </RadioBox>
            <RadioBox
              type="button"
              onClick={() => {
                setRegisterType('lote');
              }}
            >
              Cadastro em lote
            </RadioBox>
          </ContainerCadastroOption>
        )} */}

        {
          !cadastrar && (
            // (registerType === 'individual' ? ( (
            <AnimationContainer>
              <MsgImp>
                Se preferir, pode usar nosso importador de ambientes:{' '}
                <a href="https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/importador_v2.xlsm">
                  Importador de Ambientes
                </a>
              </MsgImp>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <p>Empresa</p>
                <SelectCustom
                  name="empresa"
                  defaultValue="Empresa"
                  optionsDataCompany={empresas}
                  value={empresaSel}
                  disabled={isCoordenador}
                  onValue={e => setEmpresaId(parseInt(e.split('-')[0], 10))}
                />

                <p>Nome</p>
                <InputOverview name="nome" placeholder="Nome do ambiente" />

                <p>Categoria</p>
                <SelectCustom
                  name="categoria"
                  type="categories"
                  defaultValue="Categoria"
                  optionsDataCategories={categorias.filter(
                    item => item.EMPRESA_ID === empresaId,
                  )}
                  onValue={e => setCategoria(dropdownKeyCategorias(e))}
                />

                <Link to="/categorias">
                  <img src={IconAdd} alt="iconAdd" /> Adicionar novo
                </Link>

                <p>Setor</p>
                <SelectCustom
                  name="setor"
                  type="sectors"
                  defaultValue="Setor"
                  optionsDataSectors={setores.filter(
                    item => item.EMPRESA_ID === empresaId,
                  )}
                  onValue={e => setSetor(dropdownKeySetor(e))}
                />

                <Link to="/setores">
                  <img src={IconAdd} alt="iconAdd" /> Adicionar novo
                </Link>

                <p>Andar</p>
                <InputOverview name="andar" placeholder="Andar" />

                <ContainerTitle>Riscos do ambiente</ContainerTitle>
                <ContainerFuncoes>
                  <div className="divCheck">
                    {riscos
                      .filter(item => item.EMPRESA_ID === empresaId)
                      .map(item => (
                        <ContainerLabel
                          key={item.id}
                          htmlFor={`${item.id} - ${item.name}`}
                        >
                          <input
                            type="checkbox"
                            id={`${item.id} - ${item.name}`}
                            onChange={(
                              value: ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChangeRisco(item, value.target.checked);
                            }}
                          />
                          <span>{item.name}</span>
                        </ContainerLabel>
                      ))}
                  </div>
                </ContainerFuncoes>

                <Link to="/riscos">
                  <img src={IconAdd} alt="iconAdd" /> Adicionar novo
                </Link>

                <hr />

                <ContainerTitle>
                  Procedimentos obrigatórios do ambiente
                </ContainerTitle>
                <ContainerFuncoes>
                  <div className="divCheck">
                    {procedimentos
                      .filter(item => item.EMPRESA_ID === empresaId)
                      .map(item => (
                        <ContainerLabel
                          key={item.ID_PROCEDIMENTO}
                          htmlFor={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}`}
                        >
                          <input
                            type="checkbox"
                            checked={
                              procedimento.findIndex(
                                i => i === item.ID_PROCEDIMENTO,
                              ) !== -1
                            }
                            id={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}`}
                            onChange={(
                              value: ChangeEvent<HTMLInputElement>,
                            ) => {
                              // console.log(value.target.checked);
                              handleChangeProcedimento(
                                item,
                                value.target.checked,
                              );
                            }}
                          />
                          <span>{item.NAME_PROCEDIMENTO}</span>
                        </ContainerLabel>
                      ))}
                  </div>
                </ContainerFuncoes>
                <hr />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ContainerTitle>
                    Procedimentos opcionais do ambiente
                  </ContainerTitle>
                  <CheckLabel>
                    <input
                      checked={markAllProc}
                      type="checkbox"
                      onChange={() => {
                        setMarkAllProc(!markAllProc);
                        if (!markAllProc) {
                          setProcedimentoNotMandatory(
                            procedimentos
                              .filter(item => item.EMPRESA_ID === empresaId)
                              .map(i => i.ID_PROCEDIMENTO),
                          );
                          setProcedimento([]);
                        }
                      }}
                    />
                    <p>Marcar todos os procedimentos?</p>
                  </CheckLabel>
                </div>
                <ContainerFuncoes>
                  <div className="divCheck">
                    {procedimentos
                      .filter(item => item.EMPRESA_ID === empresaId)
                      .map(item => (
                        <ContainerLabel
                          key={item.ID_PROCEDIMENTO}
                          htmlFor={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}-opc`}
                        >
                          <input
                            type="checkbox"
                            checked={
                              procedimentoNotMandatory.findIndex(
                                i => i === item.ID_PROCEDIMENTO,
                              ) !== -1
                            }
                            id={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}-opc`}
                            onChange={(
                              value: ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleChangeProcedimentoNotMandatory(
                                item,
                                value.target.checked,
                              );
                            }}
                          />
                          <span>{item.NAME_PROCEDIMENTO}</span>
                        </ContainerLabel>
                      ))}
                  </div>
                </ContainerFuncoes>

                <Link to="/procedimentos">
                  <img src={IconAdd} alt="iconAdd" /> Adicionar novo
                </Link>

                <hr />

                <Button type="submit" widthProps="100%">
                  Cadastrar
                </Button>
              </Form>
            </AnimationContainer>
          )
          // )
          // </Container> ) : (
          // <AnimationContainer>
          //   <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
          //     <p>Empresa</p>
          //     <SelectCustom
          //       name="empresa"
          //       defaultValue="Empresa"
          //       optionsDataCompany={empresas}
          //       onValue={e => setEmpresaIdLote(parseInt(e.split('-')[0], 10))}
          //     />
          //     <Content>
          //       <Upload onUpload={(e: any) => handleUpload(e)} />
          //       {!!uploadedFiles.length && (
          //         <FileList
          //           files={uploadedFiles}
          //           onDelete={(e: string) => handleDelete2(e)}
          //         />
          //       )}
          //     </Content>
          //     <ContainerButton>
          //       <Button type="submit" widthProps="100%">
          //         Cadastrar
          //       </Button>
          //       <Button
          //         type="button"
          //         widthProps="100%"
          //         className="divButtonDownload"
          //         onClick={() => {
          //           setDownload(arquivoModeloAmbientesUrlAws);
          //           setCount(old => old + 1);
          //         }}
          //       >
          //         <FiUpload />
          //         Baixar Modelo
          //       </Button>
          //       {download && (
          //         <iframe
          //           title="downloadModeloCsv"
          //           src={`${download}?c=${count}`}
          //           hidden
          //         />
          //       )}
          //     </ContainerButton>
          //   </Form>
          // </AnimationContainer>
          // ))
        }

        {cadastrar && !response.length && (
          <ContainerSemResultados>
            <h2>Não existem ambientes cadastrados ainda.</h2>
          </ContainerSemResultados>
        )}

        {cadastrar && !editar && !!response.length && (
          <>
            <ContainerTable>
              <table>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.ID}>
                      <td>
                        <input
                          type="checkbox"
                          checked={res.checked}
                          onChange={() => {
                            setResponse(
                              response.map(i =>
                                i.ID === res.ID
                                  ? { ...i, checked: !i.checked }
                                  : i,
                              ),
                            );
                          }}
                        />
                      </td>
                      <td>{res.EMPRESA}</td>
                      <td>{res.AMBIENTE_NOME}</td>
                      <td>{res.CATEGORIA}</td>
                      <td>{res.SETOR}</td>
                      <td>{res.ANDAR}</td>
                      <td>{res.RISCOS.join(', ')}</td>
                      <td>
                        {res.PROCEDIMENTOS !== null &&
                          res.PROCEDIMENTOS.filter(
                            i => i.name !== null && i.mandatory,
                          )
                            .map(i => i.name)
                            .join(', ')}
                      </td>
                      <td>
                        {res.PROCEDIMENTOS !== null &&
                          res.PROCEDIMENTOS.filter(
                            i => i.name !== null && !i.mandatory,
                          )
                            .map(i => i.name)
                            .join(', ')}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            setQrCode(true);
                            setIDQrCode(res.ID);
                            setDadosQrCode(res);
                          }}
                        >
                          <RiQrCodeFill />
                        </button>
                      </td>
                      {empresaPrincipal.length > 0 && (
                        <>
                          <td>
                            <button
                              type="button"
                              onClick={() => handleAlertMaster('editar', res)}
                            >
                              <img src={IconEdit} alt="iconEdit" />
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => {
                                Swal.fire({
                                  title: 'Tem certeza que deseja remover?',
                                  icon: 'warning',
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonColor: colors.greenPrimary,
                                  cancelButtonColor: colors.redPrimary,
                                  confirmButtonText: 'Sim',
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    handleDelete(res);
                                  }
                                });
                              }}
                            >
                              <img src={IconRemove} alt="iconRemove" />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>

              <table id="tableListagemAmbientes" style={{ display: 'none' }}>
                <HeaderTable
                  headers={
                    empresaPrincipal.length > 0 ? headers : headersViewer
                  }
                  onSorting={(field: string, order: string) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {responseData.map(res => (
                    <tr key={res.ID}>
                      <td>{res.EMPRESA}</td>
                      <td>{res.AMBIENTE_NOME}</td>
                      <td>{res.CATEGORIA}</td>
                      <td>{res.SETOR}</td>
                      <td>{res.ANDAR}</td>
                      <td>{res.RISCOS.join(', ')}</td>
                      <td>
                        {res.PROCEDIMENTOS !== null &&
                          res.PROCEDIMENTOS.filter(
                            i => i.name !== null && i.mandatory,
                          )
                            .map(i => i.name)
                            .join(', ')}
                      </td>
                      <td>
                        {res.PROCEDIMENTOS !== null &&
                          res.PROCEDIMENTOS.filter(
                            i => i.name !== null && !i.mandatory,
                          )
                            .map(i => i.name)
                            .join(', ')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {response.length > 0 && (
                <>
                  <div className="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />
                  </div>
                  {console.log('responseData', responseData)}
                  <div style={{ display: 'flex', columnGap: 10 }}>
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemAmbientes"
                      filename={`listagem-ambientes-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                    {responseData.filter(i => i.checked).length > 0 && (
                      <ButtonQrCode type="button" onClick={handleDownload}>
                        Exportar Qr-Codes Marcados
                      </ButtonQrCode>
                    )}
                  </div>
                </>
              )}
            </ContainerPagination>
          </>
        )}

        {cadastrar && editar && (
          <AnimationContainer>
            <header>
              <h1>Editar ambiente</h1>
            </header>

            <Form ref={formRef} onSubmit={handleSubmitEditar}>
              <p>Nome</p>
              <InputOverview
                name="nome"
                placeholder="Nome do ambiente"
                value={editNomeAmbiente}
                onValue={value => setEditNomeAmbiente(value)}
              />

              <p>Categoria</p>
              <SelectCustom
                name="categoria"
                type="categories"
                defaultValue="Categoria"
                value={categorias[editCategoria]?.DESCRICAO || ''}
                optionsDataCategories={categorias.filter(
                  item => item.EMPRESA_ID === empresaId,
                )}
                onValue={e => setEditIdCategoria(dropdownKeyCategorias(e))}
              />

              <Link to="/categorias">
                <img src={IconAdd} alt="iconAdd" /> Adicionar novo
              </Link>

              <p>Setor</p>
              <SelectCustom
                name="setor"
                type="sectors"
                defaultValue="Setor"
                value={setores[editSetor]?.name || ''}
                optionsDataSectors={setores.filter(
                  item => item.EMPRESA_ID === empresaId,
                )}
                onValue={e => setEditIdSetor(dropdownKeySetor(e))}
              />

              <Link to="/setores">
                <img src={IconAdd} alt="iconAdd" /> Adicionar novo
              </Link>

              <p>Andar</p>
              <InputOverview
                name="andar"
                placeholder="Andar"
                value={editAndar}
                onValue={value => setEditAndar(value)}
              />

              <ContainerTitle>
                <p>Riscos do ambiente</p>
              </ContainerTitle>

              <ContainerFuncoes>
                <div className="divCheck">
                  {riscos
                    .filter(item => item.EMPRESA_ID === empresaId)
                    .map(item => (
                      <ContainerLabel
                        key={item.id}
                        htmlFor={`${item.id} - ${item.name}`}
                      >
                        <input
                          type="checkbox"
                          id={`${item.id} - ${item.name}`}
                          onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            handleChangeEditRisco(item, value.target.checked);
                          }}
                          defaultChecked={verificaCheckRisco(item)}
                        />
                        <span>{item.name}</span>
                      </ContainerLabel>
                    ))}
                </div>
              </ContainerFuncoes>

              <Link to="/riscos">
                <img src={IconAdd} alt="iconAdd" /> Adicionar novo
              </Link>

              <hr />

              <ContainerTitle>
                <p>Procedimentos obrigatórios do ambiente</p>
              </ContainerTitle>
              <ContainerFuncoes>
                <div className="divCheck">
                  {procedimentos
                    .filter(item => item.EMPRESA_ID === empresaId)
                    .map(item => (
                      <ContainerLabel
                        key={item.ID_PROCEDIMENTO}
                        htmlFor={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}`}
                      >
                        <input
                          type="checkbox"
                          checked={
                            editProcedimento.findIndex(
                              i => i === item.ID_PROCEDIMENTO,
                            ) !== -1
                          }
                          id={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}`}
                          onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            handleChangeEditProcedimento(
                              item,
                              value.target.checked,
                            );
                          }}
                          defaultChecked={verificaCheckProcedimento(item)}
                        />
                        <span>{item.NAME_PROCEDIMENTO}</span>
                      </ContainerLabel>
                    ))}
                </div>
              </ContainerFuncoes>

              <hr />

              <ContainerTitle>
                <p>Procedimentos opcionais do ambiente</p>
              </ContainerTitle>

              <ContainerFuncoes>
                <div className="divCheck">
                  {procedimentos
                    .filter(item => item.EMPRESA_ID === empresaId)
                    .map(item => (
                      <ContainerLabel
                        key={item.ID_PROCEDIMENTO}
                        htmlFor={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}-opc`}
                      >
                        <input
                          type="checkbox"
                          checked={
                            editProcedimentoNotMandatory.findIndex(
                              i => i === item.ID_PROCEDIMENTO,
                            ) !== -1
                          }
                          id={`${item.ID_PROCEDIMENTO} - ${item.NAME_PROCEDIMENTO}-opc`}
                          onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            handleChangeEditProcedimentoNotMandatory(
                              item,
                              value.target.checked,
                            );
                          }}
                          defaultChecked={verificaCheckProcedimento(item)}
                        />
                        <span>{item.NAME_PROCEDIMENTO}</span>
                      </ContainerLabel>
                    ))}
                </div>
              </ContainerFuncoes>

              <Link to="/procedimentos">
                <img src={IconAdd} alt="iconAdd" /> Adicionar novo
              </Link>

              <hr />

              <ContainerButton>
                <Button
                  className="divButtonCancelar"
                  type="button"
                  widthProps="100%"
                  onClick={() => {
                    setEditar(false);
                    setEditRisco([]);
                    setEditProcedimento([]);
                  }}
                >
                  Cancelar
                </Button>
                <Button type="submit" widthProps="100%">
                  Salvar
                </Button>
              </ContainerButton>
            </Form>
          </AnimationContainer>
        )}
      </Container>

      {loading && <Loading />}
    </>
  );
};

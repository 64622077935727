import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { AmbDados, GetAmbientePub } from './interface';
import imgAvatar from '../../../assets/IconAvatar.svg';

import { Conteiner } from './style';

export const ControlePublica: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState<AmbDados[]>([]);
  const [option, setOption] = useState<'ultimas' | 'proximas' | 'todas'>(
    'ultimas',
  );

  const getItems = useCallback(() => {
    const dateIni = moment().format('YYYY-MM-DD');
    const dateFim = moment().add('day', 1).format('YYYY-MM-DD');

    setLoading(false);
    api
      .get(
        `/historic/v2/byEnvironment/${id}?dataIni=${dateIni}&dataFim=${dateFim}`,
      )
      .then(resp => {
        const get: GetAmbientePub[] = resp.data;
        setDados(resp.data);
        const horarios = get.map(i => {
          return {
            idAgendment: i.Agendamento.id,
            horarios: [
              i.Agendamento.Agenda.Periodicidade.HORA_BASE_INICIAL,
              ...i.Agendamento.Agenda.Periodicidade.Revisoes.map(
                rev => rev.HORA_INICIAL,
              ),
            ],
            dataAgenda: i.Agendamento.dataAgend.slice(0, 10),
            User: {
              id: i.userId,
              nome: i.User.nome,
              avatar: i.User.avatar
                ? `https://app-qrcode-avatar.s3.amazonaws.com/${i.User.avatar}`
                : '',
            },
            Ambiente: {
              id: i.Agendamento.Agenda.Id_Environments,
              nome: i.Agendamento.Agenda.Environment.name,
            },
          };
        });
        const hist = get.map(i => {
          return {
            idAgendmt: i.Agendamento.id,
            horaIni: i.createdAt.slice(11, 16),
            id: i.id,
          };
        });

        const getDados: AmbDados[] = [];
        const idsAgendmts: number[] = [];
        horarios.forEach(hor => {
          const find = idsAgendmts.find(idAgm => idAgm === hor.idAgendment);
          if (!find) {
            idsAgendmts.push(hor.idAgendment);

            const histFil = hist.filter(h => h.idAgendmt === hor.idAgendment);
            const idHF: number[] = [];
            hor.horarios.forEach(h => {
              const hit = histFil.find(i => !idHF.includes(i.id));
              if (hit) {
                idHF.push(hit.id);

                const obj: AmbDados = {
                  Ambiente: hor.Ambiente,
                  dataAgenda: hor.dataAgenda,
                  horaAgenda: h,
                  horaDone: hit.horaIni,
                  User: hor.User,
                };
                getDados.push(obj);
              } else {
                const obj: AmbDados = {
                  Ambiente: hor.Ambiente,
                  dataAgenda: hor.dataAgenda,
                  horaAgenda: h,
                  horaDone: '',
                  User: hor.User,
                };
                getDados.push(obj);
              }
            });

            histFil
              .filter(i => !idHF.includes(i.id))
              .forEach(ht => {
                const obj: AmbDados = {
                  Ambiente: hor.Ambiente,
                  dataAgenda: hor.dataAgenda,
                  horaAgenda: 'EXTRA',
                  horaDone: ht.horaIni,
                  User: hor.User,
                };
                getDados.push(obj);
              });
          }
        });
        setDados(getDados);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, id]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const list = useMemo(() => {
    let ds = dados;
    if (option === 'proximas') {
      ds = ds.filter(i => !i.horaDone && i.horaAgenda);
    } else if (option === 'ultimas') {
      ds = ds.filter(i => i.horaDone);
    }
    return ds;
  }, [dados, option]);

  return (
    <Conteiner option={option}>
      <body>
        <header>
          <div className="titulo">
            <p style={{ fontSize: 40 }}>
              {list.length > 0 ? list[0].Ambiente.nome : 'Sem Informações'}
            </p>
            <span className="data">{moment().format('DD/MM/YYYY')}</span>
          </div>
        </header>
        <main>
          <div className="conteinerBtn">
            <button
              type="button"
              id="btnultimasLimpezas"
              onClick={() => setOption('ultimas')}
              className="btn"
            >
              Últimas Limpezas
            </button>
            <button
              type="button"
              id="btnproximasLimpezas"
              onClick={() => setOption('proximas')}
              className="btn"
            >
              Próximas Limpezas
            </button>
            <button
              type="button"
              id="btnTodasLimpezas"
              onClick={() => setOption('todas')}
              className="btn"
            >
              Todas Limpezas
            </button>
          </div>
          <section className="controleLimpeza">
            {list.map((item, index) => (
              <div
                key={index.toString()}
                id={
                  item.horaDone === '' ? 'proximasLimpezas' : 'ultimasLimpezas'
                }
              >
                <div className="inforFuncionario">
                  <p className="nomeFuncionario"> {item.User.nome} </p>
                  <img
                    className="avatar"
                    src={
                      item.User.avatar !== ''
                        ? `https://app-qrcode-avatar.s3.amazonaws.com/${item.User.avatar}`
                        : imgAvatar
                    }
                    alt="Foto do Usuário"
                  />
                  <div className="controleHorario">
                    <p className="horario" id="horAgendado">
                      Horário Agendado{' '}
                      <span className="datatime" id="timeAgen">
                        {item.horaAgenda}
                      </span>
                    </p>
                    {item.horaDone !== '' && (
                      <p className="horario">
                        Horário Realizado{' '}
                        <span className="datatime">{item.horaDone}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </section>
        </main>
      </body>
      {loading && <Loading />}
    </Conteiner>
  );
};

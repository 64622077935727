import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { ThemeContext } from 'styled-components';
import Button from '../Button';

import { Content } from './styles';

Modal.setAppElement('#root');

export interface StatusButton {
  status: boolean;
  name: string;
}

interface ModalProps {
  isOpen: boolean;
  type?:
    | 'ambientes'
    | 'dashColaboradores'
    | 'dashAmbientes'
    | 'dashTarefasIncompletas'
    | 'dashAgenda';
  onRequestClose: () => void;
  onReturnData: (e: StatusButton[]) => void;
}

export const ModalFilter: React.FC<ModalProps> = ({
  isOpen,
  type = 'ambientes',
  onRequestClose,
  onReturnData = (e: StatusButton[]) => {
    return e;
  },
}) => {
  const { colors } = useContext(ThemeContext);
  const [statusButtonRadio, setStatusButtonRadio] = useState<StatusButton[]>([
    {
      status: false,
      name: 'Empresa',
    },
    {
      status: true,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Categoria',
    },
    {
      status: false,
      name: 'Setor',
    },
    {
      status: false,
      name: 'Andar',
    },
    {
      status: false,
      name: 'Risco',
    },
    {
      status: false,
      name: 'Procedimento',
    },
  ]);

  const [
    statusButtonRadioDashColaboradores,
    setStatusButtonRadioDashColaboradores,
  ] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'CPF',
    },
    {
      status: false,
      name: 'E-mail',
    },
    {
      status: false,
      name: 'Telefone',
    },
    // {
    //   status: false,
    //   name: 'Intervalo de datas',
    // },
  ]);

  const [
    statusButtonRadioDashAmbientes,
    setStatusButtonRadioDashAmbientes,
  ] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Categoria',
    },
    {
      status: false,
      name: 'Setor',
    },
    {
      status: false,
      name: 'Andar',
    },
    // {
    //   status: false,
    //   name: 'Intervalo de datas',
    // },
  ]);

  const [
    statusButtonRadioDashTarefasIncompletas,
    setStatusButtonRadioDashTarefasIncompletas,
  ] = useState<StatusButton[]>([
    {
      status: false,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'Ambiente',
    },
    {
      status: true,
      name: 'Tarefa',
    },
    {
      status: false,
      name: 'Intervalo de datas',
    },
  ]);

  const [
    statusButtonRadioDashAgenda,
    setStatusButtonRadioDashAgenda,
  ] = useState<StatusButton[]>([
    {
      status: true,
      name: 'Colaborador',
    },
    {
      status: false,
      name: 'Filial',
    },
    {
      status: false,
      name: 'Ambiente',
    },
    {
      status: false,
      name: 'Intervalo de datas',
    },
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Content>
        {type === 'ambientes' && (
          <>
            <header>
              <h1>Filtrar por</h1>
            </header>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[0].status = !statusTemp[0].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[0].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Empresa</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[1].status = !statusTemp[1].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[1].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Ambiente</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[2].status = !statusTemp[2].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[2].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Categoria</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[3].status = !statusTemp[3].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[3].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Setor</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[4].status = !statusTemp[4].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[4].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Andar</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[5].status = !statusTemp[5].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[5].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Risco</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadio];

                  statusTemp[6].status = !statusTemp[6].status;

                  setStatusButtonRadio([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadio[6].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Procedimento</p>
            </section>
          </>
        )}

        {type === 'dashColaboradores' && (
          <>
            <header>
              <h1>Filtrar por</h1>
            </header>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashColaboradores];

                  statusTemp[0].status = !statusTemp[0].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashColaboradores([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashColaboradores[0].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Colaborador</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashColaboradores];

                  statusTemp[1].status = !statusTemp[1].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashColaboradores([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashColaboradores[1].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>CPF</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashColaboradores];

                  statusTemp[2].status = !statusTemp[2].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashColaboradores([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashColaboradores[2].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>E-mail</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashColaboradores];

                  statusTemp[3].status = !statusTemp[3].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashColaboradores([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashColaboradores[3].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Telefone</p>
            </section>

            {/* <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    {
                      status: false,
                      name: 'Colaborador',
                    },
                    {
                      status: false,
                      name: 'CPF',
                    },
                    {
                      status: false,
                      name: 'E-mail',
                    },
                    {
                      status: false,
                      name: 'Telefone',
                    },
                    {
                      status: true,
                      name: 'Intervalo de datas',
                    },
                  ];

                  setStatusButtonRadioDashColaboradores([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashColaboradores[4].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Intervalo de datas</p>
            </section> */}
          </>
        )}

        {type === 'dashAmbientes' && (
          <>
            <header>
              <h1>Filtrar por</h1>
            </header>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAmbientes];

                  statusTemp[0].status = !statusTemp[0].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashAmbientes([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAmbientes[0].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Ambiente</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAmbientes];

                  statusTemp[1].status = !statusTemp[1].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashAmbientes([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAmbientes[1].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Categoria</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAmbientes];

                  statusTemp[2].status = !statusTemp[2].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashAmbientes([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAmbientes[2].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Setor</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAmbientes];

                  statusTemp[3].status = !statusTemp[3].status;
                  // statusTemp[4].status = false;

                  setStatusButtonRadioDashAmbientes([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAmbientes[3].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Andar</p>
            </section>

            {/* <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    {
                      status: false,
                      name: 'Ambiente',
                    },
                    {
                      status: false,
                      name: 'Categoria',
                    },
                    {
                      status: false,
                      name: 'Setor',
                    },
                    {
                      status: false,
                      name: 'Andar',
                    },
                    {
                      status: true,
                      name: 'Intervalo de datas',
                    },
                  ];

                  setStatusButtonRadioDashAmbientes([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAmbientes[4].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Intervalo de datas</p>
            </section> */}
          </>
        )}

        {type === 'dashTarefasIncompletas' && (
          <>
            <header>
              <h1>Filtrar por</h1>
            </header>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    ...statusButtonRadioDashTarefasIncompletas,
                  ];

                  statusTemp[0].status = !statusTemp[0].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashTarefasIncompletas([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashTarefasIncompletas[0]
                    .status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Colaborador</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    ...statusButtonRadioDashTarefasIncompletas,
                  ];

                  statusTemp[1].status = !statusTemp[1].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashTarefasIncompletas([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashTarefasIncompletas[1]
                    .status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Ambiente</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    ...statusButtonRadioDashTarefasIncompletas,
                  ];

                  statusTemp[2].status = !statusTemp[2].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashTarefasIncompletas([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashTarefasIncompletas[2]
                    .status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Tarefa</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    {
                      status: false,
                      name: 'Colaborador',
                    },
                    {
                      status: false,
                      name: 'Ambiente',
                    },
                    {
                      status: false,
                      name: 'Tarefa',
                    },
                    {
                      status: true,
                      name: 'Intervalo de datas',
                    },
                  ];

                  setStatusButtonRadioDashTarefasIncompletas([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashTarefasIncompletas[3]
                    .status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Intervalo de datas</p>
            </section>
          </>
        )}

        {type === 'dashAgenda' && (
          <>
            <header>
              <h1>Filtrar por</h1>
            </header>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAgenda];

                  statusTemp[0].status = !statusTemp[0].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashAgenda([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAgenda[0].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Colaborador</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAgenda];

                  statusTemp[1].status = !statusTemp[1].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashAgenda([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAgenda[1].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Filial</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [...statusButtonRadioDashAgenda];

                  statusTemp[2].status = !statusTemp[2].status;
                  statusTemp[3].status = false;

                  setStatusButtonRadioDashAgenda([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAgenda[2].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Ambiente</p>
            </section>

            <section>
              <button
                type="button"
                onClick={() => {
                  const statusTemp = [
                    {
                      status: false,
                      name: 'Colaborador',
                    },
                    {
                      status: false,
                      name: 'Filial',
                    },
                    {
                      status: false,
                      name: 'Ambiente',
                    },
                    {
                      status: true,
                      name: 'Intervalo de datas',
                    },
                  ];

                  setStatusButtonRadioDashAgenda([...statusTemp]);
                }}
                style={{
                  backgroundColor: !statusButtonRadioDashAgenda[3].status
                    ? colors.whitePrimary
                    : '',
                }}
              >
                <div />
              </button>
              <p>Intervalo de datas</p>
            </section>
          </>
        )}

        <Button
          type="button"
          widthProps="100%"
          onClick={() => {
            onRequestClose();

            switch (type) {
              case 'ambientes':
                onReturnData(statusButtonRadio);
                break;
              case 'dashColaboradores':
                onReturnData(statusButtonRadioDashColaboradores);
                break;
              case 'dashAmbientes':
                onReturnData(statusButtonRadioDashAmbientes);
                break;
              case 'dashTarefasIncompletas':
                onReturnData(statusButtonRadioDashTarefasIncompletas);
                break;
              case 'dashAgenda':
                onReturnData(statusButtonRadioDashAgenda);
                break;
              default:
                onReturnData(statusButtonRadio);
            }
          }}
        >
          Filtrar
        </Button>
      </Content>
    </Modal>
  );
};

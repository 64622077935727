import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { Calendario } from '../../../components/Calendario';
import { Cadastro } from './Cadastro';
import Search from '../../../components/Search';
import ModalConexao from '../../../components/ModalConexao';

import { Container, Aba, ContainerAba } from './styles';
import { ModalRemoveAgenda } from '../../../components/ModalRemoveAgenda';
import { Agendamentos } from '../../../models/Agendamentos';
import { UserGet } from '../../../models/User';
import { EditAgenda, ModalEditScheduling2 } from './ModalEditScheduling2';
import { Filial } from '../../../models/Filial';
import { formatDate } from '../../../utils';

export interface ResponseGetEmpresas {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export interface ResponseGetUsuario {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
}

export interface ResponseGetAmbiente {
  AMBIENTE_NOME: string;
  CATEGORIA: string;
  DESC_LIMPEZA: string;
  companyId: number;
  EMPRESA: string;
  ID: number;
  PROCEDIMENTOS: [];
  RISCOS: [];
  SETOR: string;
  ANDAR: string;
}

interface ResponseRevisao {
  idTableRevisao: number[];
  horaInicial: string[];
  horaFinal: string[];
}

export interface ResponseGetPeriodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  revisaoHoras: ResponseRevisao;
}

export const AgendamentosPage: React.FC = () => {
  const { empresaPrincipal, user } = useAuth();
  const { addToast } = useToast();
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAgenda, setLoadingAgenda] = useState(false);
  const [rangeDateGetSchedule, setRangeDateGetSchedule] = useState<{
    firstDayOfMonth: Date;
    lastDayOfMonth: Date;
  }>({
    firstDayOfMonth: new Date(),
    lastDayOfMonth: new Date(),
  });
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [responseAgendamentos, setResponseAgendamentos] = useState<
    Agendamentos[]
  >([]);
  const [responseEmpresas, setResponseEmpresas] = useState<Filial[]>([]);
  const [responseUsuarios, setResponseUsuarios] = useState<UserGet[]>([]);
  const [responseAmbientes, setResponseAmbientes] = useState<
    ResponseGetAmbiente[]
  >([]);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [cadastrar, setCadastrar] = useState(false);
  const [editAgenda, setEditAgenda] = useState<EditAgenda | null>(null);

  const isCoordenador = useMemo(() => {
    return user.roleId === 3 || user.roleId === null;
  }, [user.roleId]);

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      const filialId = user.companyId;

      const aux = isCoordenador
        ? `filialId=${filialId}`
        : `companyId=${companyId}`;
      const dataFilial = await api.get(
        `empresas/filiais?companyId=${companyId}`,
      );
      const dataUsers = await api.get(`/allUsers/${companyId}`);

      const dataAmbientes = await api.get(`/environments?${aux}`);

      const filiaisAtive: Filial[] = [];
      dataFilial.data.forEach((filial: Filial) => {
        if (filial.ATIVO === true) {
          filiaisAtive.push(filial);
        }
      });
      setResponseEmpresas(filiaisAtive);

      const usersAux: UserGet[] = [];
      dataUsers.data.forEach((us: UserGet) => {
        if (us.roleId === 5 || us.coordenador === 0) {
          usersAux.push(us);
        }
      });

      setResponseUsuarios(usersAux);
      setResponseAmbientes(dataAmbientes.data);

      setVerificaResponse(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, favor recarregue a página.',
      });
    }
  }, [addToast, empresaPrincipal]);

  const getAgendaData = useCallback(
    async (dateIni: string, dateEnd: string) => {
      setLoadingAgenda(true);

      try {
        const companyId =
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
        const filialId = user.companyId;

        const aux = isCoordenador
          ? `filialId=${filialId}`
          : `companyId=${companyId}`;

        const dataAgenda = await api.get(
          `agendas/v2/Agendamentos/company?dataIni=${dateIni}&dataFim=${dateEnd}&${aux}&report=true`,
        );

        const age: Agendamentos[] = dataAgenda.data;

        setResponseAgendamentos(
          age.filter(
            item => item.active && item.Agenda.ATIVO && !item.Agenda.deletedAt,
          ),
        );

        setVerificaResponse(true);
        setLoadingAgenda(false);
      } catch (err) {
        setLoadingAgenda(false);

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, favor recarregue a página.',
        });
      }
    },
    [addToast, empresaPrincipal, isCoordenador, user.companyId],
  );

  useEffect(() => {
    getItens();
  }, [getItens]);

  useEffect(() => {
    if (
      rangeDateGetSchedule?.firstDayOfMonth &&
      rangeDateGetSchedule?.lastDayOfMonth
    ) {
      const initDate = formatDate(
        rangeDateGetSchedule.firstDayOfMonth,
        'yyyy-MM-dd',
      );
      const endDate = formatDate(
        rangeDateGetSchedule.lastDayOfMonth,
        'yyyy-MM-dd',
      );

      getAgendaData(initDate, endDate);
    }
  }, [rangeDateGetSchedule]);

  const semanaToDiasSelect = useCallback(
    (val: string) => [
      { day: 'Domingo', d: 'D', selected: val.includes('Domingo') },
      { day: 'Segunda', d: 'S', selected: val.includes('Segunda') },
      { day: 'Terça', d: 'T', selected: val.includes('Terça') },
      { day: 'Quarta', d: 'Q', selected: val.includes('Quarta') },
      { day: 'Quinta', d: 'Q', selected: val.includes('Quinta') },
      { day: 'Sexta', d: 'S', selected: val.includes('Sexta') },
      { day: 'Sábado', d: 'S', selected: val.includes('Sábado') },
    ],
    [],
  );

  const handlePermitionRegister = useCallback(() => {
    if (
      verificaResponse &&
      !!responseEmpresas.length &&
      !!responseUsuarios.length
    ) {
      setSearch('');
      setCadastrar(true);
    } else {
      addToast({
        type: 'info',
        title: 'Cadastros não realizados',
        description:
          'Favor verificar se existe ao menos um usuário ASG e uma filial cadastrada antes de registrar um agendamento',
      });
    }
  }, [
    addToast,
    responseEmpresas.length,
    responseUsuarios.length,
    verificaResponse,
  ]);

  const handleDeleteAgendamento = useCallback(
    (idAgendament: number) => {
      setLoading(true);
      api
        .delete(`/agendas/Agendamentos/${idAgendament}`)
        .then(() => {
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Agendamento deletado com sucesso',
          });
          setTimeout(() => {
            go(0);
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          // console.log(err.message);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Ocoreu um erro ao deletar o agendamento.',
          });
        });
    },
    [addToast, go],
  );
  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button" onClick={() => setCadastrar(false)}>
                Listar agendamentos
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={cadastrar}>
              <button type="button" onClick={() => handlePermitionRegister()}>
                Cadastrar agendamentos
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição de todos os agendamentos para as limpezas e tarefas diárias dos ASGs"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button">Listar agendamentos</button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todos os agendamentos para as limpezas e tarefas diárias dos ASGs"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {!cadastrar && (
          <>
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />

            <Calendario
              onClickCardEdit={(shedules: Agendamentos, y: string) => {
                const edit: EditAgenda = {
                  date: formatDate(new Date(shedules.dataAgend), 'yyyy-MM-dd'),
                  active: shedules.Agenda.ATIVO,
                  agendaId: shedules.Agenda.ID,
                  ambienteId: shedules.Agenda.Id_Environments,
                  companyId: shedules.companyId,
                  diaMesSelected: [],
                  diasDaSemana: semanaToDiasSelect(
                    shedules.Agenda.Periodicidade.DIAS_SEMANA,
                  ),
                  diasDaSemana2: [],
                  diasSelected: [],
                  horarios: [
                    {
                      hourFim: shedules.Agenda.Periodicidade.HORA_BASE_FINAL,
                      hourIni: shedules.Agenda.Periodicidade.HORA_BASE_INICIAL,
                      id: shedules.Agenda.Periodicidade.ID,
                    },
                    ...shedules.Agenda.Periodicidade.Revisoes.map(i => ({
                      hourFim: i.HORA_FINAL,
                      hourIni: i.HORA_INICIAL,
                      id: i.ID,
                    })),
                  ],
                  qtdLimpeza: shedules.Agenda.Periodicidade.QTD_DIAS,
                  type:
                    shedules.Agenda.Periodicidade.type === '12X'
                      ? '12X'
                      : 'Semanal',
                  userId: shedules.userId,
                  periodicidadeId: shedules.Agenda.Id_Periodicidade,
                  periodicidadeName: shedules.Agenda.Periodicidade.NOME,
                  photoUni: shedules.Agenda.revisionPhotos,
                  vigFim: shedules.Agenda.vigFim,
                  vigIni: shedules.Agenda.vigIni,
                };
                setEditAgenda(edit);
                setShowModalEdit(true);
              }}
              type="leitura"
              data={responseAgendamentos}
              dataSearch={search}
              setRange={setRangeDateGetSchedule}
              isVisibleRemoveAgenda
              onClickRemoveAgenda={(idAgend: number) => {
                Swal.fire({
                  title: 'Tem certeza que deseja remover?',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonText: 'Não',
                  confirmButtonColor: colors.greenPrimary,
                  cancelButtonColor: colors.redPrimary,
                  confirmButtonText: 'Sim',
                }).then(result => {
                  if (result.isConfirmed) {
                    handleDeleteAgendamento(idAgend);
                  }
                });
              }}
            />
          </>
        )}

        {cadastrar && (
          <Cadastro
            dadosEmpresas={responseEmpresas}
            dadosUsuarios={responseUsuarios}
            dadosAmbientes={responseAmbientes}
          />
        )}
      </Container>
      {editAgenda !== null && (
        <ModalEditScheduling2
          isOpen={showModalEdit}
          dadosDay={editAgenda.date}
          dadoEdit={editAgenda}
          dadosAmbiente={responseAmbientes?.filter(
            item => item.companyId === editAgenda.companyId,
          )}
          dadosUsers={responseUsuarios.filter(
            item => item.companyId === editAgenda.companyId,
          )}
          onRequestClose={() => setShowModalEdit(false)}
        />
      )}
      {(loading || loadingAgenda) && <Loading />}
    </>
  );
};

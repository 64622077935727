import React from 'react';
import { AiOutlineCopyright } from 'react-icons/ai';
import { Credits } from './styles';

const CreditsSpan: React.FC = () => (
  <Credits>
    <AiOutlineCopyright />
    {new Date().getFullYear()} Greendot
  </Credits>
);

export default CreditsSpan;

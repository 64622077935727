/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { keyframes } from 'styled-components';

interface LoadingProps {
  component?: boolean;
}

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: auto;
`;

export const Content = styled.div<LoadingProps>`
  background: ${props =>
    props.component ? 'transparent' : 'rgba(0, 0, 0, 0.7)'};
  position: ${props => (props.component ? 'absolute' : 'fixed')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${props => (props.component ? '1' : '999')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentItens = styled.div<LoadingProps>`
  position: ${props => (props.component ? 'absolute' : 'fixed')};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${props => (props.component ? '1' : '999')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const appearFromCentro = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 170px;
  }
`;

const appearFromCentroComponent = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 70px;
  }
`;

export const ContentItemCentro = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromCentroComponent : appearFromCentro}
      667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromLeftDownComponent = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemLeftDown = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftDownComponent : appearFromLeftDown}
      667ms;
    animation-delay: 667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightTopComponent = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightTop = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightTopComponent : appearFromRightTop}
      667ms;
    animation-delay: 667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromLeftTopComponent = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemLeftTop = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftTopComponent : appearFromLeftTop}
      667ms;
    animation-delay: 1333ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightDownComponent = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightDown = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightDownComponent : appearFromRightDown}
      667ms;
    animation-delay: 1333ms;
    animation-fill-mode: backwards;
  }
`;

// ////////////////////////////////////////////////////////////

const appearFromCentro1 = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 170px;
  }
`;

const appearFromCentro1Component = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 70px;
  }
`;
export const ContentItemCentro1 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromCentro1Component : appearFromCentro1}
      667ms;
    animation-delay: 2.1s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown1 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromLeftDown1Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemLeftDown1 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftDown1Component : appearFromLeftDown1}
      667ms;
    animation-delay: 2767ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop1 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightTop1Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightTop1 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightTop1Component : appearFromRightTop1}
      667ms;
    animation-delay: 2767ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop1 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromLeftTop1Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemLeftTop1 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftTop1Component : appearFromLeftTop1}
      667ms;
    animation-delay: 3434ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown1 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightDown1Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightDown1 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightDown1Component : appearFromRightDown1}
      667ms;
    animation-delay: 3434ms;
    animation-fill-mode: backwards;
  }
`;
// ////////////////////////////////////////////////////////////

const appearFromCentro2 = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 170px;
  }
`;

const appearFromCentro2Component = keyframes`
  from {
    opacity: 0;
    width: 0;
  }
  to{
    opacity: 1;
    width: 70px;
  }
`;
export const ContentItemCentro2 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromCentro2Component : appearFromCentro2}
      667ms;
    animation-delay: 4.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown2 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromLeftDown2Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemLeftDown2 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftDown2Component : appearFromLeftDown2}
      667ms;
    animation-delay: 4967ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop2 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightTop2Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightTop2 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightTop2Component : appearFromRightTop2}
      667ms;
    animation-delay: 4967ms;
    animation-fill-mode: backwards;
  }
`;

export const ContentItemLeftTop2 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromLeftTop1Component : appearFromLeftTop1}
      667ms;
    animation-delay: 5634ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown2 = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 170px;
    height: 170px;
  }
`;

const appearFromRightDown2Component = keyframes`
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to{
    opacity: 1;
    width: 70px;
    height: 70px;
  }
`;
export const ContentItemRightDown2 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${props =>
        props.component ? appearFromRightDown2Component : appearFromRightDown2}
      667ms;
    animation-delay: 5634ms;
    animation-fill-mode: backwards;
  }
`;

// ////////////////////////////////////////////////////////////

const appearFromCentro3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemCentro3 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${appearFromCentro3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemLeftDown3 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${appearFromLeftDown3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemRightTop3 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${appearFromRightTop3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemLeftTop3 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${appearFromLeftTop3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemRightDown3 = styled.div<LoadingProps>`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: ${props => (props.component ? '70px' : '170px')};
    width: ${props => (props.component ? '70px' : '170px')};
    animation: ${appearFromRightDown3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

export const Text = styled.div<LoadingProps>`
  font-size: 20px;
  color: ${props =>
    props.component ? 'inherit' : props.theme.colors.whitePrimary};
  margin-top: 240px;
  animation: ${appearFromRightDown3} 3s infinite;
  animation-delay: 6.3s;
`;

import { AgendaRem, Agendav2, EmptyAgendav2 } from './Agenda';
// import { Empresa, empresaEmpty } from './Empresa';
import { EmptyUser, User } from './User';

export interface Agendamentos {
  id: number;
  companyId: number;
  agendaId: number;
  Agenda: Agendav2;
  // Empresa: Empresa;
  userId: number;
  User: User;
  dataAgend: Date;
  startDate: string;
  horaIni: string;
  horaFim: string;
  status: string;
  viraNoite: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  justificativa: string;
}

export interface AgendamentoHist {
  id: number;
  companyId: number;
  agendaId: number;
  userId: number;
  dataAgend: string;
  startDate: string;
  horaIni: string;
  horaFim: string;
  status: string;
  viraNoite: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  justificativa: string;
  ocorrencias: string | null;
  userId2: string | null;
  Agenda: AgendaRem;
}

export const EmptyAgendamento: Agendamentos = {
  active: false,
  Agenda: EmptyAgendav2,
  agendaId: -1,
  companyId: -1,
  createdAt: '',
  dataAgend: new Date(),
  horaFim: '',
  horaIni: '',
  id: -1,
  startDate: '',
  status: '',
  updatedAt: '',
  User: EmptyUser,
  userId: -1,
  viraNoite: false,
  // Empresa: empresaEmpty,
  justificativa: '',
};

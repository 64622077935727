import styled from 'styled-components';

export const ContainerTh = styled.th`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${props => props.theme.colors.textBlack};
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.whitePrimary};

  button {
    background-color: ${props => props.theme.colors.whitePrimary};
    border: none;
    color: ${props => props.theme.colors.textBlack};

    svg {
      margin-left: 5px;
      margin-bottom: -4px;
    }
  }
`;

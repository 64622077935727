import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  hasDescription: number;
}

const toastTypeVariations = {
  info: css`
    background: ${props => props.theme.colors.backgroundWhite};
    color: ${props => props.theme.colors.bluePrimary};

    strong {
      color: ${props => props.theme.colors.bluePrimary};
    }
  `,
  success: css`
    background: ${props => props.theme.colors.backgroundWhite};
    color: ${props => props.theme.colors.greenPrimary};

    strong {
      color: ${props => props.theme.colors.greenPrimary};
    }
  `,
  error: css`
    background: ${props => props.theme.colors.backgroundWhite};
    color: ${props => props.theme.colors.redPrimary};

    strong {
      color: ${props => props.theme.colors.redPrimary};
    }
  `,
};

const colorToast = {
  info: '#6591CE',
  success: '#219653',
  error: '#EB5757',
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 360px;
  position: relative;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  div {
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      flex-direction: column;
      padding: 16px;

      p {
        margin-top: 4px;
        font-size: 14px;
        opacity: 0.8;
        line-height: 20px;
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 45px;
      background: ${props => colorToast[props.type || 'info']};

      > svg {
        margin: 0 10px 0 10px;
        color: ${props => props.theme.colors.backgroundWhite};
      }
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: ${props => (!props.hasDescription ? '' : '19px')};
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: ${props => colorToast[props.type || 'info']};

    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;
    `}

  @media screen and (max-width: 1200px) {
    width: 80%;
    position: fixed;
    margin-right: 10%;

    div {
      flex: 1;
    }

    button {
      display: none;
    }
  }
`;

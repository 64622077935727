export const arquivoModeloPreCadastroUrlAws =
  'https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/arquivo-modelo-importacao.csv';
export const arquivoModeloProcedimentosUrlAws =
  'https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/arquivo-modelo-procedimentos-importacao.csv';
export const arquivoModeloSetoresUrlAws =
  'https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/arquivo-modelo-setores-importacao.csv';
export const arquivoModeloCategoriasUrlAws =
  'https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/arquivo-modelo-categorias-importacao.csv';
export const arquivoModeloRiscosUrlAws =
  'https://app-qrcode-aquivo-importacao-modelo.s3.amazonaws.com/arquivo-modelo-riscos-importacao.csv';
export const arquivoModeloAmbientesUrlAws = '';
